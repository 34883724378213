import React from 'react'
import Grid from '@material-ui/core/Grid';

export function Chapitre(props) {


    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <div id="Intgrales_impropres">
                        <span>{props.cours}</span>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div id="ID02112020_dra">
                        <span>{props.date}</span>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div id="Termin">
                        <span>{props.status}</span>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}
