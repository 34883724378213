import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';

import {CircularProgress} from "@material-ui/core";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import FavoriteTeacherListItem from "./FavoriteTeacherLIstItem";
import Pagination from "../../../../Components/Pagination";
import {
    deleteTeacherFromFavorite,
    getTeacherFromFavorite
} from "../../../../../../Redux/ActionCreators";
import {PageViewGoogleAnalytics} from "../../../../Components/GoogleAnalytics";

const usersPerPage = 2;

class FavoriteTeacherList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: this.props.data.isLoadTeachersFromFavorite,
            notify: {message: '', type: ''},
            notyIsOpen: false,
            closeButton: true,
            pageNumber: 0,
            user: this.props.session.user,
            get_teachers_from_favorite: this.props.data.get_teachers_from_favorite,
        };
    };

     componentDidMount() {
        PageViewGoogleAnalytics("Favorite Teacher", window.location.pathname + window.location.search)
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                get_teachers_from_favorite: this.props.data.get_teachers_from_favorite,
            });
        }
    }

    setNotify = (message, type) => {
        this.setState({
            notify: {
                message: message,
                type: type
            },
            notyIsOpen: !this.state.notyIsOpen
        })
    }

    pagesVisited = (pageNumber) => {
        return pageNumber * usersPerPage;
    }

    pageCount = (courses) => {
        Math.ceil(courses.length / usersPerPage);
    }

    changePage = ({selected}) => {
        this.setState({
            pageNumber: selected
        })
    };

    // set notification
    setNotify = (message, type) => {
        this.setState({
            notify: {
                message: message,
                type: type
            },
            notyIsOpen: !this.state.notyIsOpen
        })
    }


     DeleteFromFavorite = (idRemoveFavorite) => {
        this.setState({
            isLoad: false,
            get_teachers_from_favorite: [],
            closeButton: false
        });
        let data = {
            favorite_id: idRemoveFavorite,
        };
        return this.props
            .dispatch(deleteTeacherFromFavorite(data))
            .then(data => {
                if (data.payload.Success) {
                    this.GetTeacherFromFavorite()
                    this.setNotify(data.payload.message, 'success')
                }
            })
            .catch(err => {
                console.error(err.message)
                this.setNotify("There is an error", 'error')
            });
    }
      GetTeacherFromFavorite = () => {
        console.log("enter")
        let data = {
            user_id: this.state.user.id
        };
        return this.props
            .dispatch(getTeacherFromFavorite(data))
            .then(data => {
                if (data.payload.Success) {
                    this.setState({
                        get_teachers_from_favorite: data.payload.teachers_from_favorite,
                        isLoad: true,
                        closeButton: true
                    });
                }
            })
            .catch(err => {
                console.log(err.message)
            });
    }

    render() {
        const {isLoad, pageNumber, get_teachers_from_favorite, closeButton} = this.state;
        return (
            <><div className="cours-store-grid">
                    <Grid container spacing={1} className="grid-style">
                        {((!get_teachers_from_favorite || get_teachers_from_favorite.length === 0) && closeButton) ?
                            <span className="empty-cart"> Vos professeurs favoris est vide</span>
                            :
                            <Grid item xs={11}>
                                <div className="courses-store-container">
                                    <div className="course-container-header">
                                        <div className="found-results">
                                            <span>{get_teachers_from_favorite.length} Cours ajouté(s)</span>
                                        </div>
                                    </div>
                                    <div className="cours-store-grid-list">
                               {((!get_teachers_from_favorite || get_teachers_from_favorite.length === 0) && closeButton) ?
                        <span className="empty-cart"> Vos professeurs favoris est vide</span>
                        :
                        isLoad ? get_teachers_from_favorite && get_teachers_from_favorite.slice(this.pagesVisited(pageNumber), this.pagesVisited(pageNumber) + usersPerPage).map((teacher, key) => (
                            <FavoriteTeacherListItem
                                key={key}
                                teacher={teacher}
                                prix="250DH/h"
                                clickToDeleteFromFavorite={(idRemoveFavorite) => this.DeleteFromFavorite(idRemoveFavorite)}
                            />))

                            :
                            <CircularProgress color="secondary" className="progress-bar"/>
                    }
                                    </div>
                                    <div className="scroll-pages-options-favorite-course">
                                  {Pagination(this.pageCount(get_teachers_from_favorite), this.changePage)}
                                    </div>
                                </div>
                            </Grid>
                        }
                    </Grid>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({session: state.session, student: state.student, data: state.data});
export default connect(mapStateToProps)(withRouter(FavoriteTeacherList));