import React from 'react';
import {short} from "../../../SplitWord";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";


export function BlocCourse(props) {
console.log(props.image)
    return(
        <div className="bloc-course">
            <Card>
                <CardMedia
                    component="img"
                    alt=""
                    image={props.image}
                    className="matiere-image"
                />
                <CardContent>
                    <Typography variant="body2">
                        <span className="matiere-title">
                            {props.title.length > 12 ? props.title.substr(0, 11) + ".." : props.title}
                        </span>
                    </Typography>
                    <Typography variant="body2">
                        <span className="matiere-name">
                            {props.chapitre.length > 18 ? props.chapitre.substr(0, 17) + ".." : props.chapitre}
                        </span>
                    </Typography>
                    <Typography variant="body2" className="courstore-cours-resume">
                        {short(props.resume, 26)}
                    </Typography>
                    <Typography variant="body2">
                        <span className="date-demarrage">
                            {props.demarage ? "Démarre le" : null} {props.demarage}
                        </span>
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}
