import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import promiseMiddleware from 'redux-promise';
import * as objects from './Objects';


export const ConfigureStore = () => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    console.log(process.env.NODE_ENV)
    const store = createStore(
        combineReducers({
            session: objects.Session,
            teacher_calendar: objects.Teacher_calendar,
            student: objects.Student,
            data: objects.Data,
            articles: objects.Articles,
            notifications:objects.Notifications,
            avatars: objects.Avatars,
            messages : objects.Messages,
            adminFormation: objects.AdminFormation,
            classe: objects.Classe,
        }),
        composeEnhancers(
            applyMiddleware(promiseMiddleware)
        )
    );

    return store;
}