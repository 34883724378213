import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {Button} from "@material-ui/core";
import {getArticles, getExamsBlog, getFormations} from "../../Redux/ActionCreators";
import {connect} from "react-redux";
import Loading from "./Loading";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Pagination} from "@material-ui/lab";
import Notification from "./AlertComponent";


class BlogPublic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadExams: this.props.articles.isLoadExams,
            isLoad: this.props.articles.isLoad,
            isLoadFormations: this.props.articles.isLoadFormations,
            articles: this.props.articles.List_articles,
            examens: this.props.articles.list_examens,
            formations: this.props.articles.formations,
            selectedType: "ALL",
            type_exam: "",
            filiere_exam: "",
            annee_exam: "",
            page: 1,
            endPage: Math.ceil((this.props.articles.List_articles.filter(art => art.public).length) / 8),
            notify: {message: '', type: ''},
            notyIsOpen: false,
        };
    };

    handleChange = (event) => {
        const name = event.target.name;
        this.setState({
            [name]: event.target.value,
            page: 1,
        });
        //ce condition permet de vider  filiere_exam lorsque type exame est vide
        if (name === "type_exam") {
            this.setState({
                filiere_exam: ""
            })
        }
    };

    short = (str, n) => {
        return str.substring(0, n);

    }

    handelClick = (type) => {
        this.setState({
                selectedType: type,
                page: 1,
                endPage: 0,
            }
        );
        if (type === "CON") {
            this.setState({
                //endPage: Math.ceil((this.state.examens.filter(exam => exam.public)).length / 8)
            })
        }

    }

    formationById = (id) => {
        return this.state.formations.find(formation => formation.id === id);
    }

    handleChangePage = (event, value) => {
        this.setState({
            page: value,
        });
    };

    setNotify = () => {
        this.setState({
            notify: {
                message: 'error',
                type: 'error'
            },
            notyIsOpen: !this.state.notyIsOpen
        });
    }

    componentDidMount() {
        if (!this.state.isLoadExams) {
            this.props
                .dispatch(getExamsBlog())
                .then(data => {
                    if (data.payload) {
                        this.setState({
                            examens: data.payload.examens_list,
                            isLoadExams: true,
                        })
                    }
                })
                .catch(err => {
                    console.log("err", err);
                    this.setNotify();
                });
        }
        if (!this.props.isLoadFormations) {
            this.props
                .dispatch(getFormations())
                .then(data => {
                    if (data.payload) {
                        this.setState({
                            formations: data.payload.formations,
                            isLoadFormation: true,
                        })

                    }
                })
                .catch(err => {
                    console.log("err", err);
                    this.setNotify();
                });
        }
        if (!this.state.isLoad) {
            return this.props
                .dispatch(getArticles())
                .then(data => {
                    if (data.payload) {
                        this.setState({
                            articles: data.payload.article_list,
                            isLoad: true,
                            endPage: Math.ceil((this.props.articles.List_articles.filter(art => art.public).length) / 8),
                        })

                    }
                })
                .catch(err => {
                    console.log("err", err);
                    this.setNotify();
                });
        }

    };

    filterExamens = () => {
        const list = this.state.examens.filter(examen =>(this.formationById(examen.id_formation)) &&( (examen.public === true) && ((this.formationById(examen.id_formation).type === this.state.type_exam) || (this.state.type_exam === ""))))
            .filter(examen => ((this.formationById(examen.id_formation).filiere === this.state.filiere_exam) || (this.state.filiere_exam === "")))
            .filter(examen => ((examen.exam_year == this.state.annee_exam) || (this.state.annee_exam == "")));
        const endPage = Math.ceil((list.length) / 8)
        if (this.state.endPage !== endPage) {
            this.setState({
                endPage: endPage,
            });
        }
        console.log(list);
        return list;
    }

    filterArticles = () => {
        const list = this.state.articles.filter(article => ((this.state.selectedType === article.type) || (this.state.selectedType === "ALL")) && article.public === true);

        const endPage = Math.ceil((list.length) / 8)
        if (this.state.endPage !== endPage) {
            this.setState({
                endPage: endPage,
            });
        }
        return list;
    }

    changeUserHome = (user) => {
        this.setState({
            selectedPage: user
        });
    }

    render() {
        const {selectedType, formations, page, endPage} = this.state;
        return (
            <>

                <div className="BlogNavigation">
                    <Link to="#" className={selectedType === "ALL" ? "blogLink blogLinkActive" : "blogLink"}
                          onClick={() => this.handelClick("ALL")}>All</Link>
                    <Link to="#" className={selectedType === "CON" ? "blogLink blogLinkActive" : "blogLink"}
                          onClick={() => this.handelClick("CON")}>Concours & Examens</Link>
                    <Link to="#" className={selectedType === "DIG" ? "blogLink blogLinkActive" : "blogLink"}
                          onClick={() => this.handelClick("DIG")}>Digital</Link>
                    {/**********************************************************************************************/}
                    {/*don't delete this comment*/}
                    {/*<Link to="#" className={selectedType === "JEU" ? "blogLink blogLinkActive" : "blogLink"}*/}
                    {/*      onClick={() => this.handelClick("JEU")}>Jeux</Link>*/}
                    {/**********************************************************************************************/}
                </div>
                {
                    selectedType === "CON" ?
                        <>
                            <div className="selectExam">

                                <FormControl style={{"width": "130px", "marginInline": "10px"}}>
                                    <InputLabel htmlFor="type-native-simple">Formation</InputLabel>
                                    <Select

                                        native
                                        value={this.state.age}
                                        onChange={this.handleChange}
                                        inputProps={{
                                            name: 'type_exam',
                                            id: 'type-native-simple',
                                        }}
                                    >
                                        <option aria-label="None" value=""/>
                                        {
                                            [...new Set(formations.map(formation => formation.type))].map(
                                                type => {
                                                    return (<option value={type}>{type}</option>)
                                                }
                                            )
                                        }

                                    </Select>
                                </FormControl>

                                <FormControl style={{"width": "100px", "marginInline": "10px"}}>
                                    <InputLabel htmlFor="filiere-native-simple">Filière</InputLabel>
                                    <Select style={{"minWidth": "80px"}}
                                            native
                                            value={this.state.age}
                                            onChange={this.handleChange}
                                            inputProps={{
                                                name: 'filiere_exam',
                                                id: 'type-native-simple',
                                            }}
                                    >
                                        <option aria-label="None" value=""/>
                                        {
                                            formations.filter(formation => this.state.type_exam === formation.type).map(
                                                formation => {
                                                    return (
                                                        <option value={formation.filiere}>{formation.filiere}</option>)
                                                }
                                            )
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl style={{"width": "100px", "marginInline": "10px"}}>
                                    <InputLabel htmlFor="annee-native-simple">Année</InputLabel>
                                    <Select
                                        style={{"minWidth": "80px"}}
                                        native
                                        value={this.state.age}
                                        onChange={this.handleChange}
                                        inputProps={{
                                            name: 'annee_exam',
                                            id: 'annee-native-simple',
                                        }}
                                    >
                                        <option aria-label="None" value=""/>
                                        {
                                            [...new Set(this.state.examens.map(examen => examen.exam_year))].map(
                                                year => {
                                                    return (<option value={year}>{year}</option>)
                                                }
                                            )
                                        }
                                        }
                                        <years/>
                                    </Select>
                                </FormControl>
                            </div>
                        </>
                        : null
                }
                <div className="BlogContainer">
                    {
                        selectedType === "CON" ?
                            this.state.isLoadExams ?
                                this.filterExamens().slice((this.state.page - 1) * 8, this.state.page * 8)
                                    .map(examen => {
                                        return (
                                            <div className="ArticleContainer">
                                                <img className="article-image" alt=""
                                                     src={examen.picture}/>

                                                <div className="article-content">
                                                    <div className="article-title">
                                                        <span> {this.short(examen.title,77)}</span>
                                                    </div>
                                                    <div className="article-description">
                                        <span>
                                            {this.short(examen.description, 40)}
                                        </span>
                                                    </div>
                                                    <div className="article-date">
                                                        <span><b>{examen.exam_year} </b></span>
                                                    </div>
                                                </div>
                                                <div className="Button_Lire_Article">
                                                    {/*<a*/}
                                                    {/*    href={process.env.REACT_APP_API_KEY + "media/" + examen.exam_file}>*/}
                                                    <a style={{display: "table-cell"}}
                                                       href={examen.exam_file+".png"}
                                                       target="_blank" rel="noreferrer">
                                                        <Button type="submit"
                                                                className="Lire_larticle">ouvrir</Button></a>
                                                </div>
                                                <hr/>
                                            </div>)
                                    })
                                :
                                <Loading/>
                            :
                            this.state.isLoad ?
                                this.filterArticles().slice((page - 1) * 8, page * 8)
                                    .map(article => {

                                        return (

                                            <div className="ArticleContainer">
                                                <img className="article-image" alt=""
                                                     src={article.article_pic}/>

                                                <div className="article-content">


                                                    <div className="article-title">
                                                        <span> {this.short(article.title, 30)}</span>
                                                    </div>


                                                    <div className="article-description">
                                        <span>
                                            {article.introduction=== "" ?
                                                article.parts_article[0] ?
                                            this.short(article.parts_article[0].content, 90) + " ....." :null
                                                :
                                                this.short(article.introduction, 90) + " ....."

                                            }
                                        </span>
                                                    </div>
                                                    {/*<div className="article-date">*/}
                                                    {/*    <span><b>le {article.pub_date} </b></span>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <div className="Button_Lire_Article">
                                                    <Link
                                                        to={"/Actuality/" + article.id}>
                                                        <Button type="submit" className="Lire_larticle">Lire
                                                            l'article</Button>
                                                    </Link>
                                                </div>
                                            </div>)
                                    })
                                :
                                <Loading/>
                    }
                </div>

                <Pagination className="pagination" defaultPage={1} count={endPage} page={page}
                            onChange={this.handleChangePage} color="primary"
                />
                <Notification
                    notify={this.state.notify}
                    setNotify={this.setNotify}
                    isOpen={this.state.notyIsOpen}
                />
            </>
        );
    }
}

const mapStateToProps = state => ({
            session: state.session,
            articles: state.articles
        }
    );
export default connect(mapStateToProps)(withRouter(BlogPublic));
