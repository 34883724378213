import { Grid } from '@material-ui/core'
import React from 'react'
import { Recherche } from '../Recherche'
import { Description } from './Description'
import { Objectif } from './Objectif'
import { Prerequis } from './Prerequis'
import { Profilprof } from './ProfilProf'
import { Resume } from './Resume'

export default function CoursDetails(props) {


    return (
        <>
            
            <div className="cours-details">
                <div className="cour-details-research">
                    <Recherche
                        titre="Recherchez le cours que vous souhaitez suivre"
                        parDefaut="Recherchez par thème, matière, animateur, langue, niveau">
                    </Recherche>
                </div>

                <div className="cours-details-container">
                    <Grid container className="grid-style">
                        <Grid item xs={3}>
                            <Resume></Resume>
                        </Grid>
                        <Grid item xs={9}>
                            <Description></Description>
                            <Objectif></Objectif>
                            <Prerequis></Prerequis>
                            <Profilprof></Profilprof>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}
