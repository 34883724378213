// -----> Common ==> session
export const LOGIN = "session/LOGIN";
export const PARTIALUPDATE = "session/PARTIALUPDATE";
export const LOGOUT = "session/LOGOUT";
export const ACTIVATION = "session/ACTIVATION";
export const RESETPASSWORD = "session/RESETPASSWORD";
export const RESETPASSWORDEDITE = "session/RESETPASSWORDEDITE";
export const SEARCHUSER = "message/SEARCHUSER";
export const GETMESSAGESUSERS = "message/GETMESSAGESUSERS";
export const SENDMESSAGEUSERS = "message/SENDMESSAGEUSERS";
export const READMESSAGES = "message/READMESSAGES";

export const ADDCOURSE = "data/ADDCOURSE";
export const ADDCOURSEPICTURE = "data/ADDCOURSEPICTURE";

export const ADDCOURSECONTINUE = "data/ADDCOURSECONTINUE";
export const TEACERCOURSES = "data/TEACERCOURSES";
export const TEACERSEANCES = "data/TEACERSEANCES";
export const ADDTEACERSEANCE = "data/ADDTEACERSEANCE";
export const DELETETEACERSEANCE = "data/DELETETEACERSEANCE";
export const UPDATESEANCE = "data/UPDATESEANCE";
export const GETALLCOURSES = "data/GETALLCOURSES";
export const GETSYSTEMEDUCATION = "data/GETSYSTEMEDUCATION";
export const GETMATIERES = "data/GETMATIERES";
export const GETMATIERESBYINTITULE = "data/GETMATIERESBYINTITULE";
export const GETMATIERESBYFILTER = "data/GETMATIERESBYFILTER";
export const GETALLCOURSESBYMODULE = "data/GETALLCOURSESBYMODULE";
export const ADDCOURSESTOCART = "data/ADDCOURSESTOCART";
export const GETCOURSESTOCART = "data/GETCOURSESTOCART";
export const DELETECOURSESFROMCART = "data/DELETECOURSESFROMCART";
export const ADDCOURSESTOACHAT = "data/ADDCOURSESTOACHAT";
export const GETCOURSESACHAT = "data/GETCOURSESACHAT";
export const ADDCOURSESTOFAVORITE = "data/ADDCOURSESTOFAVORITE";
export const GETCOURSESFROMFAVORITE = "data/GETCOURSESFROMFAVORITE";
export const DELETECOURSESFROMFAVORITE = "data/DELETECOURSESFROMFAVORITE";
export const ADDTEACHERSTOFAVORITE = "data/ADDTEACHERSTOFAVORITE";
export const GETTEACHERSFROMFAVORITE = "data/GETTEACHERSFROMFAVORITE";
export const DELETETEACHERSFROMFAVORITE = "data/DELETETEACHERSFROMFAVORITE";
export const ADDCOURSESTORATING = "data/ADDCOURSESTORATING";


export const GETARTICLES="articles/GETARTICLES";
export const GETEXAMSBLOG="examens/BLOG";
export const GETFORMATIONS="formations/BLOG";
export const GETNOTIFICATIONS="notification/GETNOTIFICATIONS";
export const READNOTIFICATION="notification/READNOTIFICATION";
export const ADDARTICLECOMMENT="notification/ADDARTICLECOMMENT";
export const GETARTICLECOMMENT="notification/GETARTICLECOMMENT";


// -----> Student
export const STUDENTREGISTER = "session/STUDENTREGISTER";
export const GETTEACHERS = "session/GETTEACHERS";
export const STUDENTADDRESERVATION = "student/STUDENTADDRESERVATION";
export const GETALLTEACHERS= "student/GETALLTEACHERS";
export const PARTIALUPDATESTUDENT = "session/PARTIALUPDATESTUDENT";

export const GETALLTEACHERSSTORE="student/GETALLTEACHERSSTORE";
export const RESERVATIONTEACHERS= "student/RESERVATIONTEACHERS";
export const ADDTOCARTNOAUTHENTIFIED= "student/ADDTOCARTNOAUTHENTIFIED";
export const GETTEACHERSBYDESCRRIPTION= "student/GETTEACHERSBYDESCRRIPTION";
export const GETTEACHERSBYFILTER= "student/GETTEACHERSBYFILTER";
export const GETCOURSEBYMARIERE= "student/GETCOURSEBYMARIERE";
export const SEARCHALL= "student/SEARCHALL";


// -----> Parent

// -----> Teacher
export const TEACHERCONDIDATURE = "session/TEACHERCONDIDATURE";
export const PARTIALUPDATETEACHER = "session/PARTIALUPDATETEACHER";

export const PARTIALCALENDARGET = "teacher_calendar/PARTIALCALENDARGET";
export const PARTIALCALENDARADD = "teacher_calendar/PARTIALCALENDARADD";
export const PARTIALCALENDARMODIFY = "teacher_calendar/PARTIALCALENDARMODIFY";
export const PARTIALCALENDARDELETE = "teacher_calendar/PARTIALCALENDARDELETE";
export const GETAVATARS= "avatars/GETAVATARS";

export const ADDCOURSEPART= "teacher_cours/ADDCOURSEPART";
export const ADDCOURSEQUIZ= "teacher_cours/ADDCOURSEQUIZ";
export const GETCOURSEPARTS="course/GETCOURSEPARTS";
export const GETCOURSEQUIZZES="course/GETCOURSEQUIZZES";

export const UPDATECOURSEPART="course/UPDATECOURSEPART";
export const UPDATECOURSEQUIZ="course/UPDATECOURSEQUIZ";
export const DELETECOURSE="course/DELETECOURSE";
export const DELETECOURSEPART="course/DELETECOURSEPART";
export const DELETECOURSEQUIZ="course/DELETECOURSEQUIZ";
export const GETCOURSEADVANCEMENT="course/GETCOURSEADVANCEMENT";
export const UPDATECOURSEADVANCEMENT="course/UPDATECOURSEADVANCEMENT";

// -----> Admin Formation

export const GETALLSTUDENTS = "adminFormation/GETALLSTUDENTS";
export const GETACHATDETAIL = "adminFormation/GETACHATDETAIL";
export const GETSTUDENTADVANCEMENT = "adminFormation/GETSTUDENTADVANCEMENT";
export const GETSUIVI = "adminFormation/GETSUIVI";
export const GETSTUDENTBYID = "adminFormation/GETSTUDENTBYID";

// -----> Classes
export const GETALLCLASSES = "adminschool/GETALLCLASSES";
export const ADDCLASSE = "adminschool/ADDCLASSE";
