import React, {Component} from 'react';
import {BrowserRouter, Switch, Route, withRouter} from 'react-router-dom';

import Home from './Common/HomeComponent';
import LogInComponent from './Common/LogInComponent';
import RegisterComponent from './Common/RegisterComponent';
import RegisterForm from './Common/RegisterFormComponent';
import ChangePwdComponent from "./Common/ChangePwdComponent";

import StudentDashboard from './Student/StudentDashboard';
import TeacherDashboard from './Teacher/TeacherDashboart';

import {connect} from "react-redux";
import AccountActivationComponent from "./Common/AccountActivationComponent";

import ArticlePublic from "./Common/ArticlePublic";
import BlogPublic from "./Common/BlogPublic";
import {
    getAvatars,
    getMatieres,
    getSystemEducation
} from "../Redux/ActionCreators";
import ChangePwdEdite from "./Common/ChangePwdEdite";
import {ContactUs} from "./Common/Layouts/Shared/ContactUs/ContactUs";

import AdminFormationDashboard from "./Admin Formation/Suivi/AdminFormationDashboard";
import AdminFormationSuivi from "./Admin Formation/Suivi/AdminFormationSuivi";
import ClassesManagement from "./Admin Formation/Classes/ClassesManagement";

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.session.user,
        };
    };

    componentDidMount() {
        this.loadSystemEducation();
        this.loadAllMatires();
        //Get all Avatars
        return this.props
            .dispatch(getAvatars())
            .catch(err => {
                console.log(err.message)
            });
    };
    // Load Education System
    loadSystemEducation = () => {
        return this.props
            .dispatch(getSystemEducation())
            .catch(err => {
                console.log(err.message)
            });
    }

    // Load All Matieres
    loadAllMatires = () => {
        return this.props
            .dispatch(getMatieres())
            .catch(err => {
                console.log(err.message)
            });
    }

    render() {
        return (
            <div>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/connexion" component={LogInComponent}/>
                        <Route exact path="/resetPassword" component={ChangePwdComponent}/>
                        <Route exact path="/reset-password/:token" component={ChangePwdEdite}/>
                        <Route exact path="/inscription/:user" component={RegisterForm}/>
                        <Route exact path="/inscription" component={() => <RegisterComponent/>}/>
                        <Route exact path="/formation" component={AdminFormationDashboard} />
                        <Route exact path="/formation/suivi" component={AdminFormationSuivi} />
                        <Route exact path="/formation/classes" component={ClassesManagement} />
                        <Route path="/Actuality/:id_article" component={ArticlePublic}/>
                        <Route path="/blog" component={BlogPublic}/>
                        {/*<Route path = "/student/chapitre_details" component = {CoursDetails}/>*/}
                        <Route path="/student" component={StudentDashboard}/>
                        <Route path="/teacher" component={TeacherDashboard}/>
                        <Route path="/account/activation/:type_account/:activation_token"
                               component={AccountActivationComponent}/>
                        <Route path="/" component={() => <Home/>}/>


                        {/* <Route path = "*"component = {() => <Home/>}/> */}
                    </Switch>
                </BrowserRouter>
                {/*<ContactUs/>*/}
            </div>
        );
    }
}

const mapStateToProps = state => ({session: state.session});
export default connect(mapStateToProps)(withRouter(Main));