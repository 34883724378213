// src/i18n.js
import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const langDetectorOptions = {
  // order and from where user language should be detected
  order: ['navigator', 'cookie', 'localStorage'],

  // keys or params to lookup language from
  lookupCookie: 'locale',
  lookupLocalStorage: 'locale',

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // only detect languages that are in the whitelist
  checkWhitelist: true,
};

i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: 'fr',
        fallbackLng: "en",
        debug: false,

        whitelist: ['en', 'fr', 'ar'], // available languages for browser detector to pick from
        detection: langDetectorOptions,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        react: {
            wait: true,
            useSuspense: false //   <---- this will do the magic
        }
    });

export default i18n;