import React, {useState} from 'react'
import {short} from '../../../../SplitWord';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Popover from 'react-bootstrap/Popover'
import DeleteForeverSharpIcon from '@material-ui/icons/DeleteForeverSharp';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button"
import {OverlayTrigger} from "react-bootstrap";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import MenuBookTwoToneIcon from "@material-ui/icons/MenuBookTwoTone";
import CastForEducationRoundedIcon from "@material-ui/icons/CastForEducationRounded";
import SchoolTwoToneIcon from "@material-ui/icons/SchoolTwoTone";
import LanguageRoundedIcon from "@material-ui/icons/LanguageRounded";
import DescriptionRoundedIcon from "@material-ui/icons/DescriptionRounded";
import StarIcon from "@material-ui/icons/Star";
import maths_trigo from "../../../../../../Assets/Images/maths_equations.png";



export function DisplayFavoriteCourses(props) {

    const [show, setShow] = useState(false);
    const [showTRigger, setShowTrigger] = useState(false);

    const handleAddToCart = (id) => {
        props.clickToAddCart(id)
    }
    const handleDeleteFromFavorite = (idFavorite) => {
        props.clickToDeleteFromFavorite(idFavorite)
        handleClose()
    }

    const handleClose = () => {
        setShow(false)
    }
    const handleShow = () => {
        setShowTrigger(false)
        setShow(true)
    }

    const popover = (
        <Popover id="popover-basic"
                 onMouseEnter={() => setShowTrigger(true)}
                 onMouseLeave={() => setShowTrigger(false)}
        >
            <Popover.Title as="h2">Détail du Cours</Popover.Title>
            <Popover.Content>
                <div className="popper-course">
                    <div className="popper-course-intitule">
                        <div className="popper-course-intitule-icon">
                            <MenuBookTwoToneIcon/>
                        </div>
                        <div>
                            {props.course.favourite_course.course_intitule}
                        </div>
                    </div>
                    <div className="popper-course-system">
                        <div className="popper-course-system-icon">
                            <CastForEducationRoundedIcon/>
                        </div>
                        <div>
                            Systéme {props.system}
                        </div>
                    </div>
                    <div className="popper-course-module">
                        <div className="popper-course-module-icon">
                            <SchoolTwoToneIcon/>
                        </div>
                        <div>
                            {props.course.favourite_course.course_matiere.matiere_name} - {props.course.favourite_course.course_module}
                        </div>
                    </div>
                    <div className="popper-course-language">
                        <div className="popper-course-language-icon">
                            <LanguageRoundedIcon/>
                        </div>
                        <div>
                            {props.course.favourite_course.course_language}
                        </div>
                    </div>
                    <hr/>
                    <div className="popper-course-description">
                        <div className="popper-course-description-icon">
                            <DescriptionRoundedIcon fontSize="small"/>
                        </div>
                        <div>
                            {props.course.favourite_course.course_description}
                        </div>
                    </div>
                    <div className="popper-course-actions">
                        {props.user.is_teacher ?
                            <CardActions disableSpacing className="courstore-cours-actions-teacher-favorite">
                                <IconButton aria-label="share" color="secondary"
                                            onClick={() => handleShow()}>
                                    <DeleteForeverSharpIcon/>
                                </IconButton>
                            </CardActions>
                            :
                            <CardActions disableSpacing className="courstore-cours-actions">
                                <IconButton aria-label="share" color="secondary"
                                            onClick={() => handleShow()}>
                                    <DeleteForeverSharpIcon/>
                                </IconButton>
                                <IconButton
                                    aria-label="share" color="primary"
                                    onClick={() => handleAddToCart(props.course.favourite_course.id)}>
                                    <ShoppingBasketIcon color="primary"/>
                                </IconButton>
                            </CardActions>}
                    </div>
                </div>
            </Popover.Content>
        </Popover>
    );


    return (
        <>
            <OverlayTrigger show={showTRigger} placement={(props.increment % 2) === 0 ? "left" : "right"}
                            overlay={popover}>
                <div onMouseEnter={() => setShowTrigger(true)}
                     onMouseLeave={() => setShowTrigger(false)}
                     className="matiere-cour-store">
                    <Card>
                        <CardMedia
                            component="img"
                            alt=""
                            image={props.course.favourite_course.course_pic ? props.course.favourite_course.course_pic : maths_trigo}
                            className="matiere-image"
                        />
                        <CardContent>
                            <Typography variant="body2">
                            <span
                                className="matiere-intitule">{short(props.course.favourite_course.course_intitule, 9)}</span>
                            </Typography>
                            <Typography variant="body2" className="courstore-cours-resume">
                                {props.course.favourite_course.course_duree ? "Durée:" : null} {props.course.favourite_course.course_duree !== 0 ? props.course.favourite_course.course_duree : null} {props.course.favourite_course.course_duree ? "h" : null}
                            </Typography>
                           <Typography variant="body2">
                                <StarIcon className="rating-icon" fontSize="small"/>
                                <span
                                    className="rating-style">{props.course.course_is_rating === 0 ? null: props.course.course_is_rating }</span>
                            </Typography>
                            <Typography variant="body2">
                                <hr style={{borderColor: "#c0306e"}}/>
                                {/*<span className="course_price-barre">{props.course.favourite_course.course_price} {props.course.favourite_course.course_price ? "MAD" : null}</span>*/}
                                {/*<span className="course_price-remise">{props.course.favourite_course.course_price ? "-30 %" : null}</span>*/}
                            </Typography>
                            <Typography variant="body2">
                                {/*<span className="course_price-real"> {props.course.favourite_course.course_price ? "357 MAD" : null}</span>*/}
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </OverlayTrigger>
            <Modal show={show} onHide={() => handleClose()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmer la suppression</Modal.Title>
                </Modal.Header>
                <Modal.Body>Vous voulez continuer la suppression!</Modal.Body>
                <Modal.Footer className="style-model_footer">
                    <Button variant="outline-danger"
                            onClick={() => handleDeleteFromFavorite(props.course.id, props.course.favourite_course.id)}>
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

