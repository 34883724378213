import axios from "axios";
import {ConfigureStore} from "../Redux/ConfigureStore";

import {get} from "./Storage"
// to use different URL API: local, pre-production, production
require('dotenv').config()
const instance = axios.create({baseURL: process.env.REACT_APP_API_KEY});

// To get store from Redux, to get connected user details
const store = ConfigureStore().getState();

console.log("store"+store.session)
// to get Authentification token
const user = get("session_user")

const mapRegisterError = response => {
    if (response.data.message === "Username already exist!") {
        return "Username already exist"
    }
    if (response.data.username) {
        if (response.data.username[0] === "This field is required.") {
            return "Error sending data"
        }
        if (response.data.username[0] === "This field is required.") {
            return "Error sending data"
        }
    }
    if (response.data.email) {
        if (response.data.email[0] === "This field is required.") {
            return "Error sending data"
        }
        if (response.data.email[0] === "user with this email already exists.") {
            return "User with this email already exists"
        }
        if (response.data.username[0] === "This field is required.") {
            return "Error sending data"
        }
    }
    return response.data;
};

const mapTeacherRegisterError = response => {
    if (response.data.message === "Username already exist!") {
        return "Username already exist"
    }
    if (response.data.username) {
        if (response.data.username[0] === "This field is required.") {
            return "Error sending data"
        }
        if (response.data.username[0] === "This field is required.") {
            return "Error sending data"
        }
    }
    if (response.data.email) {
        if (response.data.email[0] === "This field is required.") {
            return "Error sending data"
        }
        if (response.data.email[0] === "user with this email already exists.") {
            return "User with this email already exists"
        }
        if (response.data.username[0] === "This field is required.") {
            return "Error sending data"
        }
    }
    return response.data;
};

const mapActivationError = response => {
    console.log(response)
    if (response.data.error === "Activation Expired") {
        return "Token Activation Expired"
    }
    if (response.data.error === "Invalid token") {
        return "Invalid Token Activation"
    }
    if (response.data.email === "Successfully activated") {
        return "Your account Successfully activated"
    }
    return response.data;
};

const mapAuthError = response => {
    if (response.data.message === "wrong username or password!") {
        return "Username or Password incorrect";
    }

    return response.data.message;
};


// Teacher
export const teacher_candidature = _data => {
    return instance
        .post("api/accounts/teacher/candidature/", _data)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const teacher_get_calender = _data => {
    const token = get("session_user").token;
    return instance
        .get(`api/teacher/get_availability/${_data.user_id}`, {
            headers: {Authorization: "Token " + token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const teacher_add_calender = _data => {
    const token = get("session_user").token;
    return instance
        .post("api/teacher/add_availability", _data, {
            headers: {Authorization: "Token " + token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const teacher_delete_calender = _data => {
    const token = get("session_user").token;
    return instance
        .delete(`api/teacher/delete_availability/${_data}`, {
            headers: {Authorization: "Token " + token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const add_course = _data => {
    const user = get("session_user");
    return instance
        .post("api/accounts/add_course/", _data, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const add_course_picture = (data) => {
    const user = get("session_user");
    return instance
        .put(`api/accounts/add_course_picture/${data.id}/`, data.data,{
            headers: { Authorization: "Token " + user.token }
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
    });
};
export const add_course_continue = _data => {
    const token = get("session_user").token;
    return instance
        .post("api/accounts/add_course_continue/", _data, {
            headers: {Authorization: "Token " + token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const add_course_to_cart = _data => {
    const user = get("session_user");
    return instance
        .post(`api/accounts/courses/add_to_cart/`, _data, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const get_courses_from_favorite = _data => {
    const user = get("session_user");
    return instance
        .get(`api/accounts/courses/get_course_from_favorite/${_data.user_id}/`, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const get_teachers_from_favorite = _data => {
    const user = get("session_user");
    return instance
        .get(`api/accounts/favorite/get_teachers_from_favorite/${_data.user_id}/`, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const add_course_to_achat = _data => {
    const user = get("session_user");
    return instance
        .post(`api/accounts/courses/add_to_achat/`, _data, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const get_course_achat = () => {
    const user = get("session_user");
    return instance
        .get(`api/accounts/courses/get_from_achat/${user.id}/`, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const add_course_to_favorite = _data => {
    const user = get("session_user");
    return instance
        .post(`api/accounts/courses/add_course_to_favorite/`, _data, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const add_teacher_to_favorite = _data => {
    const user = get("session_user");
    return instance
        .post(`api/accounts/favorite/add_teacher_to_favorite/`, _data, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const get_courses_to_cart = _data => {
    const user = get("session_user");
    return instance
        .get(`api/accounts/courses/get_course_to_cart/${_data.ordered_user}/`, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const delete_courses_from_favorite = _data => {
    const user = get("session_user");
    return instance
        .post(`api/accounts/courses/delete_course_from_favorite/`,_data, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const delete_teachers_from_favorite = _data => {
    const user = get("session_user");
    return instance
        .post(`api/accounts/favorite/delete_teacher_from_favorite/`,_data, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const delete_courses_from_cart = _data => {
    const user = get("session_user");
    return instance
        .delete(`api/accounts/courses/delete_course_from_cart/${_data.course_id}/`, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const add_course_to_rating = _data => {
    const user = get("session_user");
    return instance
        .post(`api/accounts/courses/add_course_to_rating/`, _data, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const get_techer_courses = _data => {
    const user = get("session_user");
    return instance
        .get(`api/accounts/teacher/courses/${_data.teacher_id}/`, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const get_teacher_courses_by_intitule = _data => {
    return instance
        .get(`api/accounts/teacher/courses/search/intitule/?search=${_data.search}`, _data,)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const get_all_courses_by_module = _data => {
    return instance
        .get(`api/accounts/get_all_courses/module?search=${_data.search}`, _data,)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const get_Filter_teacher_courses = _data => {
    return instance
        .get("api/accounts/teacher/courses/filter/MultiplePrams/", _data,)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};

export const get_all_courses = _data => {
    const user = get("session_user");
    return instance
        .get(`api/accounts/get_courses_authenticated/${_data.user_id}`,{
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        });
};


export const get_teacher_seances = _data => {
    const user = get("session_user");
    return instance
        .get(`api/accounts/teacher/seances/${_data.user_id}`, {
            headers: { Authorization: "Token " + user.token }
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(err.response);
            }
            throw err;
        });
};

export const add_teacher_seance = _data => {
    const user = get("session_user");
    return instance
        .post("api/accounts/teacher/add/seance/", _data, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(err.response);
            }
            throw err;
        });
};

export const delete_teacher_seance = _data => {
    const token = get("session_user").token;
    return instance
        .delete(`api/accounts/teacher/destroy/seance/${_data.seance_id}`, {
            headers: {Authorization: "Token " + token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(err.response);
            }
            throw err;
        });
};

export const update_seance = _data => {
    const user = get("session_user");
    return instance
    .put(`api/accounts/update/seance/${_data.seance_id}/`, _data,{
        headers: { Authorization: "Token " + user.token }
    }
    )
    .then(response => {
        return response.data;
    })
    .catch(err => {
        if (err.response) {
            throw new Error(err.response);
        }
        throw err;
    });
};

// Student
export const student_register = _data => {
    return instance
        .post("api/accounts/student/register/", _data)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        });
};

export const student_edit_profile = _data => {
    const token = get("session_user").token;
    return instance
        .post("api/accounts/student/register/", _data, {
            headers: {Authorization: "Token " + token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        });
};

export const student_add_reservation = _data => {
    const token = get("session_user").token;
    return instance
        .post("api/account/student/add/reservation/", _data, {
            headers: {Authorization: "Token " + token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        });
};

export const get_student_reservation = _data => {
    const token = get("session_user").token;
    return instance
        .get(`api/account/student/add/reservation/`, _data, {
            headers: {Authorization: "Token " + token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        });
};

// Common
export const login = _data => {
    return instance
        .post("api/accounts/login/", JSON.stringify(_data))
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
        });
};

export const reset_password = _data => {
    return instance
        .post("api/reset_password/", JSON.stringify(_data))
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
        });
};

export const reset_password_edite = _data => {
    return instance
        .post("api/reset_password/edit/", JSON.stringify(_data))
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
        });
};

export const partial_update = _data => {
    const user = get("session_user");
    return instance
        .put(`api/accounts/update-partial/${user.id}/`, JSON.stringify(_data), {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
        });
};

export const partial_update_teacher = _data => {
    const user = get("session_user");
    return instance
        .put(`api/accounts/teacher/update-partial/${_data.user_id}/`, _data, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
        });
};
export const partial_update_student = _data => {
    const user = get("session_user");
    return instance
        .put(`api/accounts/student/update/${_data.user_id}/`, _data, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
        });
};

export const logout = () => {
    let data = {"user_id": user.id};
    const token = get("session_user").token;
    return instance
        .post("api/accounts/logout", JSON.stringify(data), {
            headers: {Authorization: "Token " + token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        });
};

export const account_activation = _data => {
    return instance
        .get(`api/accounts/${_data.type}/activated/?token=${_data.token}`)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapActivationError(err.response));
            }
            throw err;
        });
};

export const get_teachers = (data) => {
    const token = get("session_user").token;
    return instance
        .post("api/account/get/teachers/", JSON.stringify(data), {
            headers: {Authorization: "Token " + token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        });
};

export const get_education_systems = () => {
    return instance
        .get("api/accounts/get_system_education/")
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        });
};

export const get_matieres = () => {
    return instance
        .get("api/accounts/get_matieres/")
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        });
};


//no need to be authenticated
export const get_all_teachers = () => {

    return instance
        .get("api/get/teachers/")
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        });
};
//Api Get Avatars
// export const get_avatars = () => {
//     return instance
//         .get("api/accounts/teacher/getAvatars/")
//         .then(response => {
//             return response.data;
//         })
//         .catch(err => {
//             if (err.response) {
//                 throw new Error(mapRegisterError(err.response));
//             }
//             throw err;
//         });
// };

export const get_avatars = () => {
    return instance
        .get("api/getAvatars/")
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        });
};

//Api articles
export const get_articles = () => {

    return instance
        .get("api/get/articles/")
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        });
};

export const get_examens_blog = () => {

    return instance
        .get("api/get/blog_examen/")
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        });
};

export const get_formations = () => {

    return instance
        .get("api/get/formation/")
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        });
};

//Api notification
export const get_notifications = () => {
    const user = get("session_user");
    return instance
    .get('api/get/notif/'+user.id+'/', {
        headers: { Authorization: "Token " + user.token }
    })
    .then(response => {
        return response.data;
    })
    .catch(err => {
        if (err.response) {
        throw new Error(mapRegisterError(err.response));
        }
        throw err;
    });
};

export const notification_read = id => {
    const token = get("session_user").token;
    return instance
        .put(`api/update/notif/${id}/`, {},{
            headers: { Authorization: "Token " + token }
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
    });
};

//get all teachers
export const get_all_teachers_store = _data => {
    return instance
        .get(`api/accounts/get_all_teachers_store/${_data.user_id}/`,)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(err.response.data.message);
            }
            throw err;
        });
};
export const get_teachers_by_description = _data => {
    return instance
        .post(`api/accounts/get_teachers_by_description/`, _data,)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
        });
};
export const get_teacher_by_filters = _data => {
    return instance
        .post("api/accounts/get_teachers/filters/", _data,)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
        });
};
export const get_courses_by_matiere = _data => {
    const user = get("session_user");
    return instance
        .post("api/accounts/get_courses_by_matiere/", _data,)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
        });
};
export const add_course_part = _data => {
    const user = get("session_user");
    return instance
        .post("api/add/course/part/", _data, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(err.response);
            }
            throw err;
        });
};
export const add_course_quiz = _data => {
    const user = get("session_user");
    return instance
        .post("api/add/course/quiz/", _data, {
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(err.response);
            }
            throw err;
        });
};
export const search_all = _data => {
    const user = get("session_user");
    return instance
        .post("api/accounts/get_all/", _data,)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
        });
};
export const get_article_comment = id_article => {
    const user = get("session_user");
    return instance
    .get('api/get/article/comments/'+id_article+'/', )
    .then(response => {
        return response.data;
    })
    .catch(err => {
        if (err.response) {
        throw new Error(mapRegisterError(err.response));
        }
        throw err;
    });
};

export const add_article_comment = data => {
    const user = get("session_user");
    return instance
        .post(`api/add/article/comment/`, data,{
            headers: { Authorization: "Token " + user.token }
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
    });
};
export const get_course_parts = id_course => {
    const user = get("session_user");
    return instance
    .get('api/get/course/parts/'+id_course+'/',{
        headers: { Authorization: "Token " + user.token }
    } )
    .then(response => {
        return response.data;
    })
    .catch(err => {
        if (err.response) {
        throw new Error(mapRegisterError(err.response));
        }
        throw err;
    });
};
export const get_course_quizzes = id_course => {
    const user = get("session_user");
    return instance
    .get('api/get/course/quizzes/'+id_course+'/',{
        headers: { Authorization: "Token " + user.token }
    } )
    .then(response => {
        return response.data;
    })
    .catch(err => {
        if (err.response) {
        throw new Error(mapRegisterError(err.response));
        }
        throw err;
    });
};
export const update_course_part = (data) => {
    const user = get("session_user");
    return instance
        .put(`api/accounts/update_course_part/${data.id}/`, data.data,{
            headers: { Authorization: "Token " + user.token }
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
    });
};
export const update_course_quiz = (data) => {
    const user = get("session_user");
    return instance
        .put(`api/accounts/update_course_quiz/${data.id}/`, data.data,{
            headers: { Authorization: "Token " + user.token }
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
    });
};
export const delete_course = id => {
    let token = user.token
    return instance
        .delete(`api/accounts/courses/delete_course/${id}`, {
            headers: {Authorization: "Token " + token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};
export const delete_course_part = id => {
    let token = user.token
    return instance
        .delete(`api/accounts/delete_course_part/${id}`, {
            headers: {Authorization: "Token " + token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};
export const delete_course_quiz = id => {
    let token = user.token
    return instance
        .delete(`api/accounts/delete_course_quiz/${id}`, {
            headers: {Authorization: "Token " + token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapTeacherRegisterError(err.response));
            }
            throw err;
        });
};
export const get_course_advancement = (id_course) => {
    const user = get("session_user");
    return instance
    .get(`api/get/course/${id_course}/student/${user.id}/advancement/`,{
        headers: { Authorization: "Token " + user.token }
    } )
    .then(response => {
        return response.data;
    })
    .catch(err => {
        if (err.response) {
        throw new Error(mapRegisterError(err.response));
        }
        throw err;
    });
};
export const update_course_advancement = (data) => {
    const user = get("session_user");
    return instance
    .put(`api/update/course/advancement/${data.id}`, data.advancement,{
            headers: { Authorization: "Token " + user.token }
        })
    .then(response => {
        return response.data;
    })
    .catch(err => {
        if (err.response) {
        throw new Error(mapRegisterError(err.response));
        }
        throw err;
    });
};
export const search_user = _data => {
    const user = get("session_user");
    return instance
        .post("api/searsh/user/messages/", _data,{headers: { Authorization: "Token " + user.token }})
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
        });
};
export const get_messages_user = id => {
    const user = get("session_user");
    return instance
        .get(`api/get/messages/user/${id}`,{headers: { Authorization: "Token " + user.token }} )
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
        });
};
export const send_message_user = data => {
    const user = get("session_user");
    return instance
        .post("api/send/messages/user/", data,{headers: { Authorization: "Token " + user.token }})
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
        });
};
export const read_message = message => {
    const user = get("session_user");
    return instance
        .put(`api/read/messages/`, {message},{
            headers: { Authorization: "Token " + user.token }
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response) {
                throw new Error(mapAuthError(err.response));
            }
            throw err;
    });
};

// admin formation

export const get_all_students = _data => {
    const user = get("session_user");
    return instance
        .get(`api/adminFormation/get_all_students/`,{
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if(err.response){
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        });
};
/*${_data.user_id}
* */
export const get_achat_detail = _data => {
    const user = get("session_user");
    return instance
        .get(`api/adminFormation/get_all_students/achat_detail/${_data.student_id}`,{
            headers: {Authorization: "Token " + user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if(err.response){
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        });
};

export const get_advancement = _data =>{
    const user = get("session_user");
    return instance
        .get(`api/adminFormation/get_all_students/advancement/${_data.student_id}/`,{
            headers: {Authorization: "Token " +user.token}
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if(err.response){
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        })

};

export const get_suivi = _data=> {
    const user = get("session_user");
    return instance
        .get(`api/adminFormation/suivi/${_data.teacher_id}/`,{
            headers: {Authorization: "Token "+user.token}
        })
        .then(response=>{
            return response.data;
        })
        .catch(err=>{
            if(err.response){
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        })
};

export const get_student_by_id = _data => {
    const user = get("session_user");
    return instance
        .get(`api/adminFormation/suivi/student/${_data.student_id}/`,{
            headers: {Authorization: "Token "+user.token}
        })
        .then(response=>{
            return response.data;
        })
        .catch(err=>{
            if(err.response){
                throw new Error(mapRegisterError(err.response));
            }
            throw err;
        })
}

export const get_all_classes = id =>{
    const user = get("session_user");
    return instance
        .get(`api/get/all_classes/${id}`, {
            headers: { Authorization: "Token " +user.token}
        })
        .then(response =>{
            return response.data;
        })
        .catch(err => {
            if(err.response){
                throw new Error(mapAuthError(err.response));
            }
            throw err;
        });
};

export const add_classe = data =>{
    const user = get("session_user");
    return instance
        .post(`api/add/classe/`,data, {
            headers: {Authorization: "Token "+user.token}
        })
        .then(response=>{
            return response.data;
        })
        .catch(err=>{
            if(err.response){
                throw new Error(mapAuthError(err.response));
            }
            throw err;
        });
};

