import React, {Component, createRef} from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
// import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
// import SearchIcon from '@material-ui/icons/Search';
import Avatar from '@material-ui/core/Avatar';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuItem from "@material-ui/core/MenuItem";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import user_inconnu from "../../../Assets/Images/user_inconnu.png";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import {connect} from "react-redux";

import {
    getCourseFromFavorite,
    getMessagesUser,
    getNotifications,
    getCourseToCart,
    getTeacherFromFavorite,
    logoutAction,
    Read_Notification, readMessages,
} from "../../../Redux/ActionCreators"
import Menu from "@material-ui/core/Menu";
import {Link} from "react-router-dom";
import {CircularProgress, ListItemIcon} from "@material-ui/core";
import NavbarMobile from "../../Mobile/Common/NavbarMobile";
import {EventGoogleAnalytics} from "../Components/GoogleAnalytics";

//const menuId = 'primary-searchAllCourse-account-menu';
const mobileMenuId = 'primary-searchAllCourse-account-menu-mobile';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openProfile: false,
            anchorEl: null,
            anchorElMSG: null,
            anchorElCart: null,
            anchorElMSGSecondMenu: null,
            notificationsMenu: false,
            user: this.props.session.user,
            notificationsIsLoad: this.props.notifications.isLoad,
            notifications: this.props.notifications.List_notification,
            notificationError: this.props.notifications.errMess,
            invisible: false,
            coursesToCart: 0,
            timeout: new Date(),
            getMessages: true,
            getRequest: true,
            second:10,
        };
        this.wrapper = createRef();
    };

    componentDidMount() {
        this.GetCoursesFromFavorite()
        this.GetTeachersFromFavorite()
        let data = {
            ordered_user: this.state.user ? this.state.user.id : null
        };
        return this.props
            .dispatch(getCourseToCart(data))
            .then(data => {
                if (data.payload.Success) {
                    const numberInCart = data.payload.courses_to_cart.length + data.payload.seances_to_cart.length;
                    this.setState({
                        coursesToCart: numberInCart,
                    });
                }
            })
            .catch(err => {
                console.log(err.message)
            });
    }

    GetCoursesFromFavorite = () => {
        let data = {
            user_id: this.state.user ? this.state.user.id : null
        };
        return this.props
            .dispatch(getCourseFromFavorite(data))
            .then(data => {
            })
            .catch(err => {
                console.log(err.message)
            });
    }
    GetTeachersFromFavorite = () => {
        let data = {
            user_id: this.state.user ? this.state.user.id : null
        };
        return this.props
            .dispatch(getTeacherFromFavorite(data))
            .then(data => {
            })
            .catch(err => {
                console.log(err.message)
            });
    }


    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                coursesToCart: this.props.data.get_courses_to_cart.length + this.props.data.get_seances_to_cart.length
            });
        }
        if (this.state.second > 0) {
                setTimeout(() => this.setState({second :this.state.second- 1}), 10000);
            } else {
            this.setState({second: 10});
            if (this.state.getRequest && !this.state.notificationError) {

                this.setState({
                    getRequest: false,
                });
                this.props
                    .dispatch(getNotifications(this.state.user.id))
                    .then(data => {
                        if (this.props.notification !== prevProps.notifications && !this.state.notificationError) {
                            this.setState({
                                notifications: data.payload.notifications,
                                notificationsIsLoad: true,
                                notificationError: false,
                                getRequest: true,
                                // timeout: new Date(),
                            });
                        }

                    })
                    .catch(err => {
                        this.setState({
                            notificationError: true,
                        });
                        console.log("err", err);

                    });
                // this.props
                //     .dispatch(getMessagesUser(this.props.session.user.id))
                //     .then(data => {
                //         if (this.props.messages !== prevProps.messages) {
                //             this.setState({
                //                 // notifications: data.payload.notifications,
                //                 // notificationsIsLoad: true,
                //                 // notificationError: false,
                //                 getMessages : true,
                //                 timeout: new Date(),
                //             });
                //         }
                //
                //     })
                //     .catch(err => {
                //         this.setState({
                //             notificationError: true,
                //         });
                //         console.log("err", err);
                //
                //     });
            }
        }
        // Typical usage (don't forget to compare props):
        if (this.props.session !== prevProps.session) {
            this.setState({
                user: this.props.session.user
            });
        }
    }

    // to open/close the profile menu
    // Todo: need to be separated
    handleCloseProfile = (event) => {
        this.setState({
            openProfile: !this.state.openProfile,
            anchorEl: event.currentTarget
        });
    };

    handleOpenProfile = () => {
        if (this.state.user.is_student) {
            // Event Google Analytics
            EventGoogleAnalytics("Navbar_Redirect_To_Profile_Button", "Button", `Student Redirects to profile in navbar, userId: ${this.state.user.id}`)
            this.props.history.push("/student/profile");
        }
        if (this.state.user.is_teacher) {
            // Event Google Analytics
            EventGoogleAnalytics("Navbar_Redirect_To_Profile_Button", "Button", `Teacher Redirects to profile in navbar, userId: ${this.state.user.id}`)
            this.props.history.push("/teacher/profile");
        }
        //this.handleCloseProfile(event);
    };

    handleOpenSetting = () => {
        if (this.state.user.is_student) {
            this.props.history.push("/student/setting");
        }
        if (this.state.user.is_teacher) {
            this.props.history.push("/teacher/setting");
        }
    };

    handleOpenOrderSummary = () => {
        if (this.state.user.is_student) {
            // Event Google Analytics
            EventGoogleAnalytics("Navbar_Redirect_to_cart_button", "Button", `Redirect to cart in Navbar's side, userId: ${this.state.user.id}`)
            this.props.history.push("/student/cart");
        }
        this.handleCloseCart();
    };

    handleLogout = () => {
        // Event Google Analytics
        EventGoogleAnalytics("Navbar_Redirect_To_Home_Button", "Button", `Redirect to home by clicking Logout  in navbar, userId: ${this.state.user.id}`)
        this.props.history.push("/home");
        return this.props
            .dispatch(logoutAction())
            .then(data => {
                if (data.payload.Success) {
                    this.props.history.push("/home");
                }
            })
            .catch(err => {
                this.setState({
                    errorGlobal: err.message
                });
            });
    };

    handleClick = (event) => {
        // Event Google Analytics
        EventGoogleAnalytics("Navbar_chat_button", "Button", "Show Menu's messages in Navbar")
        this.setState({
            anchorElMSG: event.currentTarget
        });
    };

    handleClickCart = (event) => {
        // Event Google Analytics
        EventGoogleAnalytics("Navbar_Cart_button", "Button", "Show Menu's cart in Navbar")
        this.setState({
            anchorElCart: event.currentTarget
        });
    };

    handleClickSecondMenu = (event) => {
        // Event Google Analytics
        EventGoogleAnalytics("Navbar_Profile_button", "Button", "Show Menu's profile in Navbar")
        this.setState({
            anchorElMSGSecondMenu: event.currentTarget
        });
    };
    handleClickNotificationsMenu = (event) => {
        // Event Google Analytics
        EventGoogleAnalytics("Navbar_Notification_button", "Button", `Show Menu's notification in Navbar, userId: ${this.state.user.id}`)
        this.setState({
            notificationsMenu: event.currentTarget
        });
    };

    readNotification = (notification) => {
        // Event Google Analytics
        EventGoogleAnalytics("Navbar_Read_Notification_button", "Button", "Read Notification")
        if (!notification.read) {
            this.props
                .dispatch(Read_Notification(notification.id))
                .then(data => {
                    if (data.payload.Success) {
                        console.log("read is true")
                    }
                })
                .catch(err => {
                    console.log(err.message);
                });

        }
        ;
        this.handleCloseNotificationsMenu();
    }
    readMessage = (message) => {
        if (!message.read && message.receiver === this.state.user.id) {
            this.props
                .dispatch(readMessages(message))
                .then(data => {
                    this.props.history.push(
                        this.state.user.is_student ?
                            "/student/messages/" + (this.state.user.id === message.receiver ? message.sender : message.receiver)
                            :
                            "/teacher/messages/" + (this.state.user.id === message.receiver ? message.sender : message.receiver)
                    );
                })
                .catch(err => {
                    console.log(err.message);
                });
        } else {
            this.props.history.push(
                this.state.user.is_student ?
                    "/student/messages/" + (this.state.user.id === message.receiver ? message.sender : message.receiver)
                    :
                    "/teacher/messages/" + (this.state.user.id === message.receiver ? message.sender : message.receiver)
            );
        }
    }

    handleClose = () => {
        this.setState({
            anchorElMSG: null
        });
    }
    getContact = () => {
        let contact = []
        this.props.messages.messages.map(m => {
            if (contact.filter(c => (
                    (c.sender === this.props.session.user.id && (c.receiver === m.receiver || c.receiver === m.sender)
                        && c.receiver !== this.props.session.user.id
                    )
                    || (c.receiver === this.props.session.user.id && (c.sender === m.receiver || c.sender === m.sender)
                        && c.sender !== this.props.session.user.id
                    )
                    || (m.sender === this.props.session.user.id && m.receiver === this.props.session.user.id)
                )
            ).length === 0) {
                contact.push(m);
            }
        });
        return contact;
    }
    handleCloseCart = () => {
        this.setState({
            anchorElCart: null
        });
    }

    handleCloseSecondMenu = () => {
        this.setState({
            anchorElMSGSecondMenu: null
        });
    }
    handleCloseNotificationsMenu = () => {
        this.setState({
            notificationsMenu: null
        });
    }
    diffDate = (date1, date2) => {
        const result = Math.abs(date1 - date2);
        if (result <= 1000) {
            return "maintenant";
        } else {
            if (result < 1000 * 60) {
                return "Il y a " + Math.floor(result / 1000) + " seconds";
            } else {
                if (result < 1000 * 60 * 60) {
                    if (Math.floor(result / (1000 * 60)) === 1) {
                        return "Il y a une minute";
                    } else {
                        return "Il y a " + Math.floor(result / (1000 * 60)) + " minutes";
                    }
                } else {
                    if (result < 1000 * 60 * 60 * 24) {
                        if (Math.floor(result / (1000 * 60 * 60)) === 1) {
                            return "Il y a environ une heure";
                        } else {
                            return "Il y a " + Math.floor(result / (1000 * 60 * 60)) + " heures";
                        }
                    } else {
                        if (result < 1000 * 60 * 60 * 24 * 40) {
                            if (Math.floor(result / (1000 * 60 * 60 * 24)) === 1) {
                                return "Il y a un jour";

                            } else {
                                //console.log(result/100);
                                return "Il y a " + Math.floor(result / (1000 * 60 * 60 * 24)) + " jours";
                            }
                        } else {
                            return "il y a longtemps"
                        }
                    }
                }
            }
        }
    }

    render() {
        const open = this.props.open;
        const {user, invisible, coursesToCart} = this.state;
        return (
            <>

            {this.props.session.user ?


                    <AppBar
                        color="default"
                        position="static"
                        className={`appBardash ${open ? "appBarShift" : ""}`}
                        ref={this.wrapper}
                    >
                        <Toolbar>
                            <div className="grow-bar">
                                <Link to={"/"}>
                                    <div className="app-name">
                                        <span>Talented Academy</span><br/>
                                    </div>
                                </Link>
                            </div>
                            <div className="sectionDesktop">
                                {/*<div className="search-bar">*/}
                                {/*    <div className="searchBarIcon">*/}
                                {/*        <SearchIcon/>*/}
                                {/*    </div>*/}
                                {/*    <InputBase*/}
                                {/*        placeholder="Rechercher"*/}
                                {/*        classes={{*/}
                                {/*            root: "inputRoot",*/}
                                {/*            input: "inputInput",*/}
                                {/*        }}*/}
                                {/*        inputProps={{'aria-label': 'recherche'}}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                {user.is_student ?
                                    <IconButton
                                        edge="end"
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={(e) => this.handleClickCart(e)}

                                        aria-label="show 4 new mails"
                                        color="inherit"
                                    >
                                        <Badge
                                            badgeContent={coursesToCart}
                                            color="primary">
                                            <ShoppingBasketIcon/>
                                        </Badge>
                                    </IconButton>
                                    : null}
                                <IconButton
                                    edge="end"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={(e) => this.handleClick(e)}

                                    aria-label="show 4 new mails"
                                    color="inherit"
                                >
                                    <Badge
                                        badgeContent={this.props.messages.isLoad ? this.getContact().filter(message => (!message.read && message.receiver === this.state.user.id)).length : null}
                                        color="secondary">
                                        <MailIcon/>
                                    </Badge>
                                </IconButton>
                                <IconButton
                                    aria-controls="notifications-menu"
                                    aria-haspopup="true"
                                    onClick={(e) => this.handleClickNotificationsMenu(e)}
                                    aria-label="new notifications"
                                    color="inherit">
                                    <Badge
                                        badgeContent={this.state.notificationsIsLoad ? this.state.notifications.filter(element => !element.read).length : null}
                                        color="secondary">
                                        <NotificationsIcon/>
                                    </Badge>
                                </IconButton>
                                <IconButton
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls="hard-menu"
                                    aria-haspopup="true"
                                    onClick={(e) => this.handleClickSecondMenu(e)}
                                    color="inherit"
                                >
                                    <Avatar alt="A" src={(user && user.profile_pic) ? user.profile_pic : null}/>
                                </IconButton>
                            </div>

                            {/*<Menu*/}
                            {/*    id="simple-menu"*/}
                            {/*    anchorEl={this.state.anchorElMSG}*/}
                            {/*    keepMounted*/}
                            {/*    open={Boolean(this.state.anchorElMSG)}*/}
                            {/*    onClose={this.handleClose}*/}
                            {/*>*/}
                            {/*    <MenuItem onClick={this.handleClose}>MSG 1</MenuItem>*/}
                            {/*    <MenuItem onClick={this.handleClose}>MSG 2</MenuItem>*/}
                            {/*    <MenuItem onClick={this.handleClose}>MSG 3</MenuItem>*/}
                            {/*</Menu>*/}
                            <Menu
                                className="notifications"
                                id="simple-menu"
                                anchorEl={this.state.anchorElMSG}
                                keepMounted
                                open={Boolean(this.state.anchorElMSG)}
                                onClose={this.handleClose}
                                style={{"maxHeight": "450px", "marginRight": "30px"}}>
                                <div style={{"marginLeft": "10px", "fontSize": "22px", "width": "300px"}}>
                                    Messages
                                </div>

                                {
                                    this.props.messages.isLoad ?
                                        <>{

                                            this.getContact().slice(0, 6).map(message => (

                                                <MenuItem onClick={() => {
                                                    this.readMessage(message);
                                                }}
                                                          className={(!message.read && message.receiver === this.state.user.id) ? "notification_container   notification_container_read" : "notification_container"}
                                                          key={message.id}>
                                                    <div
                                                        className={"usernameMessage"}>
                                                        {user.id === message.receiver ? message.sender_username : message.receiver_username}
                                                    </div>
                                                    <div
                                                        className={message.read ? "messageMessage message_not_read" : "messageMessage"}>
                                                        {message.message.length > 25 ? message.message.substring(0, 25) + "..." : message.message}
                                                    </div>
                                                    <div
                                                        className={message.read ? "date_notif" : "date_notif date_notif_not_read"}>
                                                        {this.diffDate(new Date(message.message_date), new Date())}
                                                    </div>
                                                    {
                                                        message.read ? null :
                                                            <div className="notification_point_read"></div>
                                                    }
                                                    <div className="notification_sender">


                                                        <img className="notifications_sender_picture" alt=""
                                                             src={user.id === message.receiver ? message.sender_pic : message.receiver_pic}

                                                        />


                                                    </div>
                                                </MenuItem>

                                            ))
                                        }


                                            <div className="display_all_notification">
                                                <Link onClick={this.handleCloseNotificationsMenu}
                                                      to={this.state.user.is_student ? "/student/messages/" : "/teacher/messages/"}>
                                                    Afficher
                                                    tous les messages</Link>
                                            </div>


                                        </>
                                        :
                                        <MenuItem className="Loading_notifications"
                                                  onClick={this.handleClose}>
                                            <CircularProgress/>
                                        </MenuItem>
                                }
                            </Menu>
                            <Menu
                                id="simple-menu"
                                anchorEl={this.state.anchorElCart}
                                keepMounted
                                open={Boolean(this.state.anchorElCart)}
                                onClose={this.handleCloseCart}
                            >
                                <MenuItem onClick={(e) => this.handleOpenOrderSummary(e)}>
                                    <ListItemIcon>Consulter
                                        <ArrowForwardIosIcon/>
                                    </ListItemIcon>
                                </MenuItem>
                            </Menu>
                            <Menu
                                className="notifications"
                                id="notifications-menu"
                                anchorEl={this.state.notificationsMenu}
                                keepMounted
                                open={Boolean(this.state.notificationsMenu)}
                                onClose={this.handleCloseNotificationsMenu}
                                style={{"maxHeight": "450px", "marginRight": "30px"}}>
                                <div style={{"marginLeft": "10px", "fontSize": "22px", "width": "300px"}}>
                                    Notifications
                                </div>

                                {
                                    this.state.notificationsIsLoad ?
                                        <>{

                                            this.state.notifications.slice(0, 6).map((notification) => (

                                                <MenuItem onClick={() => {
                                                    this.readNotification(notification);
                                                }}
                                                          className={notification.read ? "notification_container" : "notification_container  notification_container_read"}
                                                          key={notification.id}>
                                                    <div
                                                        className={notification.read ? "message_notif message_not_read" : "message_notif"}>
                                                        {notification.message}
                                                    </div>
                                                    <div
                                                        className={notification.read ? "date_notif" : "date_notif date_notif_not_read"}>
                                                        {this.diffDate(new Date(notification.notif_date), new Date())}
                                                    </div>
                                                    {
                                                        !notification.read ?
                                                            <div className="notification_point_read"></div>
                                                            : null
                                                    }
                                                    <div className="notification_sender">
                                                        <img className="notifications_sender_picture" alt=""
                                                             src={notification.notification_pic ?
                                                                 notification.notification_pic
                                                                 : user_inconnu}
                                                        />
                                                    </div>
                                                </MenuItem>
                                            ))
                                        }


                                            {
                                                this.state.notifications.length === 0 ?
                                                    <div className="display_all_notification">
                                                        Aucune notification
                                                    </div>
                                                    :
                                                    <div className="display_all_notification">
                                                        <Link onClick={this.handleCloseNotificationsMenu}
                                                              to={"notifications"}>Afficher
                                                            tous les notifications</Link>
                                                    </div>
                                            }
                                        </>
                                        :
                                        <MenuItem className="Loading_notifications"
                                                  onClick={this.handleClose}>
                                            <CircularProgress/>
                                        </MenuItem>
                                }
                            </Menu>
                            <Menu
                                id="hard-menu"
                                anchorEl={this.state.anchorElMSGSecondMenu}
                                keepMounted
                                open={Boolean(this.state.anchorElMSGSecondMenu)}
                                onClose={this.handleCloseSecondMenu}
                            >
                                <MenuItem onClick={(e) => this.handleOpenProfile(e)}>Profile</MenuItem>
                                <MenuItem onClick={(e) => this.handleOpenSetting(e)}>Setting</MenuItem>
                                <MenuItem onClick={() => this.handleLogout()}>Logout</MenuItem>
                            </Menu>


                            {/* the profile menu */}
                            <NavbarMobile
                                handleClickCart={(e) => this.handleClickCart(e)}
                                invisible={invisible}
                                is_student={user.is_student}
                                coursesToCart={coursesToCart}
                                handleClick={(e) => this.handleClick(e)}
                                handleClickNotificationsMenu={(e) => this.handleClickNotificationsMenu(e)}
                                notificationsIsLoad={this.state.notificationsIsLoad}
                                mobileMenuId={mobileMenuId}
                            />
                        </Toolbar>
                    </AppBar>
                    : null
            }
            </>
        );
    }
}

const mapStateToProps = state => ({
    session: state.session,
    notifications: state.notifications,
    data: state.data,
    messages: state.messages
});
export default connect(mapStateToProps)(Navbar);
