window.dataLayer = window.dataLayer || [];

function gtag() {
    window.dataLayer.push(arguments);
}

export const EventGoogleAnalytics = (action, category, label) => {

    gtag('event', action, {
        event_category: category,
        event_label: label,
    })
}

export const PageViewGoogleAnalytics = (page_title, page_path) => {

    gtag('config', 'UA-200877313-1', {
        'page_title': page_title,
        'page_path': page_path
    });
    gtag('config', 'G-3EMPKJQYC5', {
        'page_title': page_title,
        'page_path': page_path
    });
}

export const PurchaseGoogleAnalytics = (transaction_id, value, items) => {

    gtag('event', 'purchase', {
        "transaction_id": transaction_id,
        "affiliation": "Online Courses",
        "value": value,
        "currency": "MAD",
        "items": items
    });
}