import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {Button, IconButton, Paper} from '@material-ui/core';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AccountActivation from './AccountActivation';

import { studentRegisterAction, teacherCandidatureAction } from '../../Redux/ActionCreators';
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {EventGoogleAnalytics} from "./Components/GoogleAnalytics";
import {ContactUs} from "./Layouts/Shared/ContactUs/ContactUs";

class RegisterForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            errorGlobal: "",
            registerSuccess: false,
            registerMessage: "",
            is_student: this.props.match.params.user === "apprenant" ? true: false,
            is_parent: this.props.match.params.user ==="parent" ? true: false,
            is_teacher: this.props.match.params.user ==="enseignant" ? true: false,
            email: "",
            cvFile: "",
            fileError: "",
            description: "",
        };
    };

    handleFile = ({ target }) => {
        this.setState({
            cvFile: target.files[0],
            fileError: ""
        })
    };

    handleDescription = ({target}) => {
        this.setState({
            description: target.value
        })
    }

    handleSubmit = (values) => {
        let formData = new FormData();
        formData.append("email", values.email);
        formData.append("username", values.username);
        formData.append("password", values.password);
        formData.append("is_student", this.state.is_student);
        formData.append("is_parent", this.state.is_parent);
        formData.append("is_teacher", this.state.is_teacher);
        if (this.state.is_student){
            return this.props.dispatch(studentRegisterAction(formData))
                .then(data => {
                    if (data.payload.Success) {
                        // Event Google Analytics
                        EventGoogleAnalytics("Register","Button",`Show Register credentials student, email: ${values.email}, username: ${values.username}`)
                        this.setState({
                            registerSuccess: true,
                            email: values.email,
                            visibility: false,
                            registerMessage:"Merci d'activer votre compte. Un email à été envoyer à votre compte sur",
                        })
                    }
                })
                .catch(err => {
                    console.log("err", err);
                    this.setState({
                        errorGlobal: err.message
                    });
                });
        }
        if (this.state.is_teacher){
            if (this.state.cvFile){
                formData.append("candidature", this.state.cvFile);
                return this.props.dispatch(teacherCandidatureAction(formData))
                    .then(data => {
                        if (data.payload.Success) {
                            // Event Google Analytics
                            EventGoogleAnalytics("Register","Button",`Show Register credentials teacher, email: ${values.email}, username: ${values.username}`)
                            this.setState({
                                registerSuccess: true,
                                email: values.email,
                                registerMessage: "Votre candidature a été bien enregistrée, vous recevrez un email " +
                                    "aprés étude de votre condidature sur",
                            })
                        }
                    })
                    .catch(err => {
                        console.log("err", err);
                        this.setState({
                            errorGlobal: err.message
                        });
                    });
            }else {
                this.setState({
                    fileError: "Should upload a file"
                })
            }
        }
    };

    handleVisibility = () => {
        this.setState({
            visibility: !this.state.visibility
        })
    }

    render(){
        const { errorGlobal, description, registerMessage } = this.state;
        return (
            <>
            {this.state.registerSuccess === false ? 
                <Row className="register-form">
                    <div className="modal-page">
                        <Container className="modal-title">
                            <h1 className="title">Inscription</h1>
                        </Container>
                        {errorGlobal && (
                            <div className="textErrors">{errorGlobal}</div>
                        )}
                        <Container className="input-container">
                            <Formik
                                initialValues={{ email: '', password: '', username: '' }}
                                validationSchema={Yup.object({
                                        password: Yup.string()
                                            .min(6, 'Must be 6 characters or more')
                                            .required('Required'),
                                        username: Yup.string()
                                            .min(4, 'Must be 6 characters or more')
                                            .required('Required'),
                                        email: Yup.string().email('Invalid email address').required('Required'),
                                    })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        this.handleSubmit(values)
                                        setSubmitting(false);
                                    }, 400);
                                }}
                            >
                                {({
                                    isSubmitting,
                                    values,
                                    errors,
                                    touched,
                                    isValidating,
                                    setFieldValue
                                }) => (
                                    <Form>
                                    <span className="name-field">Email</span>
                                    <Paper className="paper">
                                        <Field
                                            name="email"
                                            value={values.email}
                                            className="text-field"
                                        />
                                    </Paper>
                                    <div className="error-field"> {errors.email && touched.email && errors.email}</div>
                                    <span className="name-field">Username</span>
                                    <Paper className="paper">
                                        <Field
                                            type="text"
                                            name="username"
                                            value={values.username}
                                            className="text-field"
                                        />
                                    </Paper>
                                    <div className="error-field"> {errors.username && touched.username && errors.username}</div>

                                    <span className="name-field">Password</span>
                                    <Paper className="paper">
                                        <Field
                                            type={this.state.visibility ? "text": "password"}
                                            name="password"
                                            value={values.password}
                                            className="password-field"
                                        />
                                        <IconButton
                                            size="small"
                                            onClick={() => this.handleVisibility()}
                                            component="span"
                                            className="icon-visibility"
                                        >
                                            {this.state.visibility ? <VisibilityOffIcon/>: <VisibilityIcon/>}
                                        </IconButton>
                                    </Paper>
                                    <div className="error-field"> {errors.password && touched.password && errors.password}</div>
                                    {this.props.match.params.user === "enseignant" ?
                                    <>
                                        <span className="name-field">Description</span>
                                        <Paper className="paper">
                                            <input
                                                type="text"
                                                name="description"
                                                value={description}
                                                className="text-field"
                                                onChange={this.handleDescription}
                                            />
                                        </Paper>
                                        <Button
                                          variant="contained"
                                          component="label"
                                          onChange={this.handleFile}
                                          className="btn-file"
                                        >
                                          Votre CV {this.state.cvFile && ': '+this.state.cvFile.name}
                                          <input
                                            type="file"
                                            hidden
                                          />
                                        </Button>
                                        <div className="error-field"> {this.state.fileError}</div>
                                    </>
                                        :null
                                    }
                                        <Button type="submit" className="sinscrire" variant="contained" color="secondary">S'inscrire</Button>
                                    </Form>
                                )}
                            </Formik>
                        </Container>
                        <div className="divider">
                            <hr/><div>Ou</div><hr/>
                        </div>
                        <Container className="bloc-MI">
                            <Button className="media-inscription" variant="outlined" color="secondary">S'enregister avec Google</Button>
                            <Button className="media-inscription" variant="outlined" color="primary">S'enregister avec Facebook</Button>
                        </Container>

                        <Container className="connectez-vous">
                            <span>Vous êtes déjà inscrit ?</span><Link to="/connexion"> Connectez-vous à votre compte</Link>
                        </Container>
                    </div>
                </Row>
            :
            <AccountActivation
                email={this.state.email}
                message={registerMessage}
            />
        }
        <ContactUs position={"/form"}/>
        </>
        );
    }
}

const mapStateToProps = state => ({ session: state.session });
export default connect(mapStateToProps)(withRouter(RegisterForm));
