import React, { useState } from "react";
import {Link, useHistory, withRouter} from "react-router-dom";
import logo from '../../Assets/Images/Logo_Talented.png';
import {useTranslation, withTranslation} from "react-i18next";

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Button from "react-bootstrap/Button";

import en from '../../Assets/Images/english_icone.png'
import ar from '../../Assets/Images/morocco_icone.png'
import fr from '../../Assets/Images/french_icone.png'
import {NavDropdown} from "react-bootstrap";
import {connect} from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import {Poll} from "./Layouts/Shared/ContactUs/Poll";


const language = {
  "fr": fr,
  "ar": ar,
  "en": en
}

function Header(props) {
  const { t, i18n } = useTranslation();
  const [isNavOpen, setNavOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLangue, setSelectedLangue] = useState(language[i18n.language]);

  const langue = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLangue(selectedLangue => language[i18n.language]);
  }
  const toggle = () => setDropdownOpen(prevState => !prevState);

  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });

  const headerColorChange = () => {
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > 40) {
      document.body
        .getElementsByTagName("nav")[0]
        .classList.add("navbar-scrolled");
    }
    else {
      document.body
        .getElementsByTagName("nav")[0]
        .classList.add("navbar-light");
      document.body
        .getElementsByTagName("nav")[0]
        .classList.remove("navbar-scrolled");
    }
  };
  function refreshPage() {
    window.location.reload(false);

  }

  return (
      <nav className="mainNav navbar navbar-expand-lg navbar-light fixed-top py-3">
        <Link onClick={refreshPage} className="navbar-brand" to="/home">
          <div className={"TextLogo"}>
            <img style={{height: "1.5em"}} src={logo} alt="TalenTed"/>{/*<span>LOGO</span>*/}
            <span className={"logo1"}>Talented </span>
            <span className={"logo2"}>Academy</span>
          </div>
        </Link>

        <button className="navbar-toggler navbar-toggler-right" type="button" onClick={() => setNavOpen(!isNavOpen)}>
          {isNavOpen ?
              "×":
          <span className="navbar-toggler-icon"></span>
          }
        </button>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav ml-auto my-2 my-lg-0">
            <Button className="button-top button_anim" onClick={() => props.changeUserHome("student")} >
              {t("translation:header-nav.apprenants")}
            </Button>
            <Button className="button-top button_anim" onClick={() => props.changeUserHome("teacher")}>
              {t("translation:header-nav.enseignants")}
            </Button>
            <Button className="button-top button_anim" onClick={() => props.changeUserHome("parent")}>
              {t("translation:header-nav.parents")}
            </Button>
          </ul>
          
          {/* <Link className="navbar-brand" to="/inscription">
            <Button className="button-auth">Inscription</Button>
          </Link> */}
          <Link className="navbar-brand" to="/connexion">
            {
              props.session.authenticated ?
                  <Avatar alt="A"
                          src={(props.session.user && props.session.user.profile_pic) ? props.session.user.profile_pic : null}/>
                  :
                  <Button className="button-auth">{t("translation:header-nav.connexion")}</Button>
            }
          </Link>

          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle className="button-lange" caret>
              <img className="" src={selectedLangue} alt="#"/>
            </DropdownToggle>
            <DropdownMenu className="menu-lange">
              <DropdownItem className="button-lange" onClick={() => langue("ar")}>
                <img className="" src={ar} alt="#"/>
              </DropdownItem>
              <DropdownItem className="button-lange" onClick={() => langue("fr")}>
                <img className="" src={fr} alt="#"/>
              </DropdownItem>
              <DropdownItem className="button-lange" onClick={() => langue("en")} >
                <img className="" src={en} alt="#"/>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        {isNavOpen &&
          <div className="container">
            <ul className="navbar-nav mobile-nav">
              {props.page === "student" ?
                  <Link  onClick={() => props.changeUserHome("student")} class="active">{t("translation:header-nav.apprenants")}</Link>
                  : <Link  onClick={() => props.changeUserHome("student")} >{t("translation:header-nav.apprenants")}</Link> }
              {props.page === "teacher" ?
                  <Link  onClick={() => props.changeUserHome("teacher")} class="active">{t("translation:header-nav.enseignants")}</Link>
                  : <Link to="/propos" onClick={() => props.changeUserHome("teacher")} >{t("translation:header-nav.enseignants")}</Link>}
              {props.page === "parent" ?
                  <Link  onClick={() => props.changeUserHome("parent")} class="active">{t("translation:header-nav.parents")}</Link>
                  : <Link  onClick={() => props.changeUserHome("parent")} >{t("translation:header-nav.parents")}</Link>}

              <Link className="navbar-brand" to="/connexion">{t("translation:header-nav.connexion")}</Link>

            <NavDropdown title={t("translation:header-nav.language")} id="nav-dropdown">
        <NavDropdown.Item onClick={() => langue("ar")} eventKey="4.1">العربية</NavDropdown.Item>
        <NavDropdown.Item onClick={() => langue("fr")} eventKey="4.2">français</NavDropdown.Item>
        <NavDropdown.Item onClick={() => langue("en")} eventKey="4.3">english</NavDropdown.Item>

      </NavDropdown>
            </ul>
          </div>
        }
        {/*<Poll/>*/}
      </nav>
  );
}
const mapStateToProps = state => ( {

    session: state.session
}

);
export default connect(mapStateToProps)(withRouter(withTranslation()(Header)));
