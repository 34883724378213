import React from 'react'

export function Video(props) {

    return (
        <>
            <div className="Ms_cours_acc_bkx">
                <div className="Ms_cours_acc_bkz">

                    <div className="Accueil_cours_en_cours_bng">
                        <span>Mathématiques</span>
                    </div>
                    <div className="Isomtries_et_translations_bni">
                        <span>Isométries et translations</span>
                    </div>
                    <div className="Accueil_cours_en_cours_bnj">
                        <span>18 : 02</span>
                    </div>

                    <div style={{position: "relative", width:"100%", height: "100%"}}>
                        <svg style={{width:"100%", height: "15em"}}>
                            <text x="45%" y="50%" className="fas">&#xf144;</text>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    )
}
