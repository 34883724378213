import React from 'react';
import { Button, Grid } from "@material-ui/core";
import image from "../../../Assets/Images/calc_image.png";
import Radio from '@material-ui/core/Radio';
import NativeSelects from './Shared/Calculator/NativeSelect';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import DiscreteSlider from './Shared/Calculator/CustomMarks';


const Calculator = (props) => {
    const [selectedValue, setSelectedValue] = React.useState('a');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const routeChange = () => {
        let path = `courses`;
        props.history.push(path)
    }
    return (
        <div>
            <div className="calculator">
                <Button type="submit"
                    className="return-courses"
                    variant="contained"
                    onClick={routeChange}
                >
                    Retour
            </Button>
                <Grid container className="calculator-grid">
                    <Grid item xs={8}>
                        <img className="calc-image" alt="" src={image} />
                    </Grid>
                    <Grid item xs={4}>
                        <div className="calculator-container">
                            <div className="niveau-etude">
                                <span>Niveau d'études</span>
                                <div>
                                    <NativeSelects />
                                </div>
                            </div>
                            <div className="annee-etude">
                                <span>Année d'étude</span>
                                <div>
                                    <NativeSelects />
                                </div>
                            </div>
                            <div className="abonnement">
                                <span>Votre abonnement</span>
                                <div>
                                    <NativeSelects />
                                </div>
                            </div>
                            <div className="nb-heure-par-semaine">
                                <span>Nombre d'heure par semaine</span>
                                <DiscreteSlider />
                            </div>
                            <div className="nb-matiere">
                                <span>Nombre de matières</span>
                                <DiscreteSlider />
                            </div>
                            <div className="niveau-prof">
                                <span>Niveau du prof</span>
                                <FormControl component="fieldset">
                                    <RadioGroup row defaultValue={1}>
                                        <FormControlLabel
                                            onChange={handleChange}
                                            checked={selectedValue === "1"}
                                            value={1}
                                            control={<Radio color="primary" />}
                                            
                                            label="Niveau 1"
                                            labelPlacement="bottom"
                                        />
                                        <FormControlLabel
                                            onChange={handleChange}
                                            checked={selectedValue === '2'}
                                            value={2}
                                            control={<Radio  color="primary" />}
                                            
                                            label="Niveau 2"
                                            labelPlacement="bottom"
                                        />
                                        <FormControlLabel
                                            onChange={handleChange}
                                            checked={selectedValue === '3'}
                                            value={3}
                                            control={<Radio color="primary" />}
                                            label="Niveau 3"
                                            labelPlacement="bottom"
                                        />
                                        <FormControlLabel
                                            onChange={handleChange}
                                            checked={selectedValue === '4'}
                                            value={4}
                                            control={<Radio color="primary" />}
                                            label="Niveau 4"
                                            labelPlacement="bottom" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className="taille-groupe">
                                <span>Taille de groupe</span>
                                <NativeSelects />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );

}

export default Calculator;


