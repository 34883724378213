import * as ActionTypes from './ActionTypes';
import * as storage from "../Services/Storage";
import {GETTEACHERSFROMFAVORITE} from "./ActionTypes";


const initialState = {
    user: storage.get("session_user"),
    authenticated: false
};

let user;

if (initialState.user) {
    initialState.authenticated = true;
}

// for session
export const Session = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOGOUT:
            storage.remove("session_user");
            return {authenticated: false, user: null};

        case ActionTypes.LOGIN:
            if (!action.error) {
                user = action.payload.user
                user.token = action.payload.token
                storage.set("session_user", user);
                return {user, authenticated: true};
            }
            return {...state, user, authenticated: false};

        case ActionTypes.PARTIALUPDATE:
            if (!action.error) {
                user = action.payload.user
                user.token = action.payload.token
                storage.set("session_user", user);
                return {user, authenticated: true};
            }
            return {...state, user, authenticated: true};

        case ActionTypes.PARTIALUPDATETEACHER:
            if (!action.error) {
                user = action.payload.user
                user.token = action.payload.token
                storage.set("session_user", user);
                return {user, authenticated: true};
            }
            return {...state, user, authenticated: true};
        case ActionTypes.PARTIALUPDATESTUDENT:
            if (!action.error) {
                user = action.payload.user
                user.token = action.payload.token
                storage.set("session_user", user);
                return {user, authenticated: true};
            }
            return {...state, user, authenticated: true};
        default:
            return state;
    }
}

// for teacher calendar
export const Teacher_calendar = (state =
                                     {
                                         calendar: [],
                                         errMess: null,
                                         user_id: null
                                     }, action) => {
    switch (action.type) {
        case ActionTypes.PARTIALCALENDARGET:
            if (!action.error) {
                return {
                    user_id: action.payload.user_id,
                    calendar: action.payload.teacher_calendar,
                    errMess: null,
                };
            }
            return {...state, calendar: [], errMess: action.error};

        case ActionTypes.PARTIALCALENDARADD:
            if (!action.error) {
                return {
                    user_id: action.payload.user_id,
                    calendar: action.payload.teacher_calendar,
                    errMess: null,
                };
            }
            return {...state, calendar: [], errMess: action.error};

        case ActionTypes.PARTIALCALENDARMODIFY:
            if (!action.error) {
                return {
                    user_id: action.payload.user_id,
                    calender: action.payload.teacher_calendar,
                    errMess: null,
                };
            }
            return {...state, calendar: [], errMess: action.error};

        case ActionTypes.PARTIALCALENDARDELETE:
            if (!action.error) {
                return {
                    ...state,
                    //     calendar: state.calendar.filter(function(value, index, arr){
                    //     return item => item.id !== action.payload.calendar_id;
                    // })
                };
            }
            return {...state};
        default:
            return state;
    }
}
// for student
export const Student = (state =
                            {
                                allTeachers:[],
                                teachers: [],
                                errMess: null,
                                prof_reservation_id: null,
                                course_cart_id : null,
                                isLoad: false,
                            }, action) => {
    switch (action.type) {
        case ActionTypes.GETALLTEACHERS:
            if (!action.error) {
                return {
                    ...state,
                    teachers: action.payload.results,
                    isLoad: true,

                };
            }
            return {...state, teachers: [], errMess: action.error};

        case ActionTypes.RESERVATIONTEACHERS:
            if (!action.error) {
                console.log(action);
                return {
                    ...state,
                    prof_reservation_id: action.payload,
                };
            }
            return {...state, teachers: [], errMess: action.error};

        case ActionTypes.ADDTOCARTNOAUTHENTIFIED:
            if (!action.error) {
                console.log(action);
                return {
                    ...state,
                    course_cart_id: action.payload,
                };
            }
            return {...state, teachers: [], errMess: action.error};

        case ActionTypes.GETALLTEACHERSSTORE:
            if (!action.error) {
                return {
                    ...state,
                    allTeachers: action.payload.teachers,
                    isLoad: true,

                };
            }
            return {...state, teachers: [], errMess: action.error};
        default:
            return state;
    }
}

export const Articles = (state =
                             {
                                 list_examens: [],
                                 List_articles: [],
                                 formations: [],
                                 errMess: null,
                                 isLoad: false,
                                 isLoadExams: false,
                                 isLoadFormations: false,
                             }, action) => {
    switch (action.type) {
        case ActionTypes.GETARTICLES:
            if (!action.error) {
                return {
                    ...state,
                    List_articles: action.payload.article_list,
                    isLoad: true,
                };
            }
            return {...state, List_articles: [], errMess: action.error};

        case ActionTypes.GETEXAMSBLOG:
            if (!action.error) {
                return {
                    ...state,
                    list_examens: action.payload.examens_list,
                    isLoadExams: true,
                };
            }
            return {...state, list_examens: [], errMess: action.error};

        case ActionTypes.GETFORMATIONS:
            if (!action.error) {
                return {
                    ...state,
                    formations: action.payload.formations,
                    isLoadFormations: true,
                };
            }
            return {...state, formations: [], isLoadFormations: false, errMess: action.error};

        default:
            return state;
    }
}
export const Notifications = (state =
                                 {
                                     List_notification: [],
                                     count: null,
                                     errMess: null,
                                     isLoad: false,
                                 }, action) => {
    switch (action.type) {
        case ActionTypes.GETNOTIFICATIONS:
            if (!action.error) {
                return {
                    ...state,
                    List_notification: action.payload.notifications,
                    count: action.payload.count,
                    isLoad: true,
                    errMess:false,

                };
            }
            return {...state, List_notification: [], count: null, isLoad: false, errMess: action.error};
        default:
            return state;
    }
}

export const Avatars = (state =
                            {
                                List_avatars: [],
                                errMess: null,
                                isLoad: false,
                            }, action) => {
    switch (action.type) {
        case ActionTypes.GETAVATARS:
            if (!action.error) {
                return {
                    ...state,
                    List_avatars: action.payload.avatars,
                    isLoad: true,
                };
            }
            return {...state, List_avatars: [], errMess: action.error};
        default:
            return state;
    }
}

export const Data = (state =
                         {
                             techear_courses: [],
                             get_matieres: [],
                             system_education: [],
                             get_all_courses_home: [],
                             get_courses_to_cart: [],
                             get_seances_to_cart: [],
                             get_courses_from_favorite: [],
                             get_teachers_from_favorite: [],
                             get_all_courses: [],
                             total_price: "",
                             errMess: null,
                             isLoad: false,
                             course_achats: [],
                             isLoadCourseAchat: false,
                         }, action) => {
    switch (action.type) {
        case ActionTypes.TEACERCOURSES:
            if (!action.error) {
                return {
                    ...state,
                    techear_courses: action.payload.teacher_course,
                    isLoad: true,
                };
            }
            return {...state, techear_courses: [], errMess: action.error};
        case ActionTypes.ADDCOURSE:
            if (!action.error) {
                return {
                    ...state,
                    techear_courses: action.payload.data,
                    isLoad: true,
                };
            }
            return {...state, techear_courses: [], errMess: action.error};
        case ActionTypes.DELETECOURSE:
            if (!action.error) {
                return {
                    ...state,
                    techear_courses: state.techear_courses.filter(course => course.id !== action.payload.id_course),
                    isLoad: true,
                };
            }
            return {...state, techear_courses: [], errMess: action.error};
        case ActionTypes.ADDCOURSEPICTURE:
            if (!action.error) {
                return {
                    ...state,
                    techear_courses: action.payload.data,
                    isLoad: true,
                };
            }
            return {...state, techear_courses: [], errMess: action.error};
        case ActionTypes.GETALLCOURSES:
            if (!action.error) {
                return {
                    ...state,
                    get_all_courses: action.payload.courses,
                    isLoad: true,
                };
            }
            return {...state, get_all_courses: [], errMess: action.error};
        case ActionTypes.GETSYSTEMEDUCATION:
            if (!action.error) {
                return {
                    ...state,
                    system_education: action.payload.education_system,
                    isLoad: true,
                };
            }
            return {...state, system_education: [], errMess: action.error};
        case ActionTypes.GETMATIERES:
            if (!action.error) {
                return {
                    ...state,
                    get_matieres: action.payload.matieres,
                    isLoad: true,
                };
            }
            return {...state, get_matieres: [], errMess: action.error};
        case ActionTypes.GETCOURSESTOCART:
            if (!action.error) {
                return {
                    ...state,
                    get_courses_to_cart: action.payload.courses_to_cart,
                    get_seances_to_cart: action.payload.seances_to_cart,
                    total_price: action.payload.final_price,
                    isLoad: true,
                };
            }
            return {...state, get_courses_to_cart: [], get_seances_to_cart: [], errMess: action.error};
        case ActionTypes.GETCOURSESFROMFAVORITE:
            if (!action.error) {
                return {
                    ...state,
                    get_courses_from_favorite: action.payload.courses_from_favorite,
                    isLoadFromFavorite: true,
                };
            }
            return {...state, get_courses_from_favorite: [], errMess: action.error};
        case ActionTypes.GETTEACHERSFROMFAVORITE:
            if (!action.error) {
                return {
                    ...state,
                    get_teachers_from_favorite: action.payload.teachers_from_favorite,
                    isLoadTeachersFromFavorite: true,
                };
            }
            return {...state, get_teachers_from_favorite: [], errMess: action.error};
        case ActionTypes.GETCOURSESACHAT:
            if (!action.error) {
                return {
                    ...state,
                    course_achats: action.payload.courseAchat,
                    isLoadCourseAchat: true,
                };
            }
            return {...state, course_achats: [], isLoadCourseAchat: false,};
        case ActionTypes.ADDCOURSESTOACHAT:
            if (!action.error) {
                return {
                    ...state,
                    course_achats: action.payload.courseAchat,
                    isLoadCourseAchat: true,
                };
            }
            return {...state, course_achats: [], isLoadCourseAchat: false,};
        default:
            return state;

    }
}
export const Messages = (state =
                             {
                                 messages: [],
                                 errMess: null,
                                 isLoad: false,
                                 students: [],
                                 teachers:[],
                             }, action) => {
    switch (action.type) {
        case ActionTypes.GETNOTIFICATIONS:
            if (!action.error) {
                if (state.messages.length < action.payload.messages.length) {
                    return {
                        ...state,
                        messages: action.payload.messages,
                        isLoad: true,
                        errMess: false,
                    };
                }
                return {
                    ...state,
                    isLoad: true,
                    errMess: false,
                };
            }
            return {...state, isLoad: false, errMess: action.error};

        case ActionTypes.SENDMESSAGEUSERS:
            if (!action.error) {
                if (action.payload.valid) {
                    return {
                        ...state,
                        messages: [action.payload.message_sent, ...state.messages],
                    };
                }
                return state;
            }
            return {...state, messages: [], isLoad: false, errMess: action.error};
        case ActionTypes.GETALLSTUDENTS:
            if(!action.error){
                if(action.payload.valid){
                    return {
                        ...state,
                        students: action.payload.students_list,
                        isLoad: true,
                    };
                }
                return state;
            }
        default:
            return state;
    }
}

// Admin Formation



export const AdminFormation = (state =
                                   {
                                       allStudents:[],
                                       errMess:null,
                                       isLoad:false,
                                       achatDetail:[],
                                       advancement:[],
                                       suivi:[],
                                       student:[],
                                       totalMontant:0,
                                       totalDuree:0,
                                   } , action) => {
    switch(action.type){
        case ActionTypes.GETALLSTUDENTS:
            if(!action.error){
                return	{
                    ...state,
                    allStudents: action.payload.students_list,
                    isLoad:true,
                };
            }
            return {...state, allStudents:[], errMess: action.error};
        case ActionTypes.GETACHATDETAIL:
            if(!action.error){
                return{
                    ...state,
                    achatDetail: action.payload.achat_detail,
                    isLoad:true,
                };
            }
            return {...state, achatDetail: [], errMess: action.error}
        case ActionTypes.GETSTUDENTADVANCEMENT:
            if(!action.error){
                return{
                    ...state,
                    advancement: action.payload.advancement,
                    isLoad:true,
                }
            }
            return {...state, advancement: [], errMess: action.error}
        case ActionTypes.GETSUIVI:
            if(!action.error){
                return{
                    ...state,
                    suivi: action.payload.suivi,
                    totalMontant: action.payload.total_montant,
                    totalDuree: action.payload.total_duree,
                    isLoad: true,
                }
            }
            return {...state, suivi: [], errMess: action.error}
        case ActionTypes.GETSTUDENTBYID:
            if(!action.error){
                return{
                    ...state,
                    student:action.payload.suivi,
                    isLoad:true,
                }
            }
            return {...state,student: [],errMess: action.error}
        default :
            return state;
    }
}

export const Classe = (state =
                            {
                                classes:[],
                                errMess: null,
                                isLoad: false,
                            }, action)=>{
    switch (action.type){
        case ActionTypes.GETALLCLASSES:
            if(!action.error){
                return{
                    ...state,
                    classes: action.payload.classes,
                    isLoad:true,
                };
            }
            return {...state,classes: [],errMess: action.error}
        case ActionTypes.ADDCLASSE:
            if(!action.error){
                let classe = action.payload.classe;
                let classes = state.classes;
                classes.push(classe);
                return{
                    ...state,
                    classes: classes,
                    isLoad: true,
                };
            }
            return {...state,classes: [], errMess: action.error};
        default:
            return state;
    }
}