import React, {useState} from 'react'
import {short} from '../../Common/SplitWord';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {Checkbox, FormControlLabel, Radio} from "@material-ui/core";
import {Favorite, FavoriteBorder} from "@material-ui/icons";
import DeleteForeverSharpIcon from '@material-ui/icons/DeleteForeverSharp';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button"
import Popover from 'react-bootstrap/Popover'
import MenuBookTwoToneIcon from '@material-ui/icons/MenuBookTwoTone';
import SchoolTwoToneIcon from '@material-ui/icons/SchoolTwoTone';
import {OverlayTrigger} from "react-bootstrap";
import LanguageRoundedIcon from '@material-ui/icons/LanguageRounded';
import CastForEducationRoundedIcon from '@material-ui/icons/CastForEducationRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import {FaStar} from "react-icons/fa";
import maths_trigo from "../../../Assets/Images/maths_equations.png";
import StarIcon from "@material-ui/icons/Star";
import {getImage} from "../../Common/CommonFunction"

export function CartCourseItem(props) {

    const [anchorElThird, setAnchorElThird] = useState(null);
    const [show, setShow] = useState(false);
    const [showTRigger, setShowTrigger] = useState(false);
    const [rating, setRating] = useState(props.rating);
    const [hover, setHover] = useState(null);
    const [state, setState] = useState({
        checkedA: props.course.course_is_favorite,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({...state, [event.target.name]: event.target.checked});
        props.handleAddToFavorite(props.course.cart_course.id)
    };
    const handleDeleteFromCart = (id) => {
        props.clickToDeleteFromCart(id)
        handleClose()
    }

    const handlePopoverOpenThird = (event) => {
        setAnchorElThird(event.currentTarget);
    };

    const handlePopoverCloseThird = () => {
        setAnchorElThird(null);
    };
    const handleClose = () => {
        setShow(false)
    }
    const handleShow = () => {
        setShowTrigger(false)
        setShow(true)
    }

    const openThird = Boolean(anchorElThird);

    const handleChangeRating = (event) => {
        console.log("enter")
        setRating(event.target.value);
        props.clickToAddRating(event.target.value, props.course.cart_course.id)
    };

    const StarRating = () => {
        return (
            <div>
                {[...Array(5)].map((star, i) => {
                    const ratingValue = i + 1;
                    return (
                        <label>
                            <Radio checked={Number(rating) === ratingValue}
                                   onChange={handleChangeRating}
                                   value={ratingValue}
                                   style={{display: "none"}}
                                   name="radio-button-demo"
                                   inputProps={{'aria-label': ratingValue}}
                            />
                            <FaStar
                                className="star"
                                size={20}
                                color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                                onMouseEnter={() => setHover(ratingValue)}
                                onMouseLeave={() => setHover(null)}
                            />
                        </label>
                    );
                })}
            </div>
        );
    }

    const popover = (
        <Popover id="popover-basic"
                 onMouseEnter={() => setShowTrigger(true)}
                 onMouseLeave={() => setShowTrigger(false)}
        >
            <Popover.Title as="h2">Détail du Cours</Popover.Title>
            <Popover.Content>
                <div className="popper-course">
                    <div className="popper-course-intitule">
                        <div className="popper-course-intitule-icon">
                            <MenuBookTwoToneIcon/>
                        </div>
                        <div>
                            {props.course.cart_course.course_intitule}
                        </div>
                    </div>
                    {props.system &&
                    <div className="popper-course-system">
                        <div className="popper-course-system-icon">
                            <CastForEducationRoundedIcon/>
                        </div>
                        <div>
                            Systéme {props.system.system_name}
                        </div>
                    </div>
                    }
                    <div className="popper-course-module">
                        <div className="popper-course-module-icon">
                            <SchoolTwoToneIcon/>
                        </div>
                        <div>
                            {props.course.cart_course.course_matiere.matiere_name} - {props.course.cart_course.course_module}
                        </div>
                    </div>
                    <div className="popper-course-language">
                        <div className="popper-course-language-icon">
                            <LanguageRoundedIcon/>
                        </div>
                        <div>
                            {props.course.cart_course.course_language}
                        </div>
                    </div>
                    <hr/>
                    <div className="popper-course-description">
                        <div className="popper-course-description-icon">
                            <DescriptionRoundedIcon fontSize="small"/>
                        </div>
                        <div>
                            {props.course.cart_course.course_description}
                        </div>
                    </div>
                    <div className="popper-course-actions">
                        <div className="popper-course-actions-icon">
                            <FormControlLabel className="popper-course-actions-icon-favorite"
                                              control={<Checkbox checked={state.checkedA}
                                                                 onChange={handleChange}
                                                                 name="checkedA"
                                                                 icon={<FavoriteBorder/>}
                                                                 aria-label="add to favorites"
                                                                 color="secondary"
                                                                 checkedIcon={<Favorite/>}/>}
                            />
                        </div>
                        <StarRating/>
                        <div className="popper-course-actions-icon_remove">
                            <IconButton aria-owns={openThird ? 'mouse-over-popover-third' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={(e) => handlePopoverOpenThird(e)}
                                        onMouseLeave={(e) => handlePopoverCloseThird(e)}
                                        aria-label="share" color="secondary"
                                        onClick={() => handleShow()}>
                                <DeleteForeverSharpIcon/>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Popover.Content>
        </Popover>
    );
    return (
        <>
            <OverlayTrigger show={showTRigger} placement={(props.increment % 2) === 0 ? "left" : "right"}
                            overlay={popover}>
                <div onMouseEnter={() => setShowTrigger(true)}
                     onMouseLeave={() => setShowTrigger(false)}
                     className="matiere-cour-store">
                    <Card>
                        <CardMedia
                            component="img"
                            alt=""
                            // image={props.course.cart_course.course_pic ? props.course.cart_course.course_pic : maths_trigo}
                            image={getImage(props.course.cart_course)}
                            className="matiere-image"
                        />
                        <CardContent>
                            <Typography variant="body2">
                            <span
                                className="matiere-intitule">{short(props.course.cart_course.course_intitule, 30)}</span>
                            </Typography>
                            <Typography variant="body2" className="courstore-cours-resume">
                                {props.course.cart_course.course_duree ? "Durée:" : null} {props.course.cart_course.course_duree !== 0 ? props.course.cart_course.course_duree : null} {props.course.cart_course.course_duree ? "h" : null}
                            </Typography>
                            <Typography variant="body2">
                                <StarIcon className="rating-icon" fontSize="small"/>
                                <span
                                    className="rating-style">{props.course.course_is_rating === 0 ? null : props.course.course_is_rating}</span>
                            </Typography>
                            <Typography variant="body2">
                                <hr style={{borderColor: "#c0306e"}}/>
                                {/*<span className="course_price-barre">{props.course.cart_course.course_price} {props.course.cart_course.course_price ? "MAD" : null}</span>*/}
                                {/*<span className="course_price-remise">{props.course.cart_course.course_price ? "-30 %" : null}</span>*/}
                            </Typography>
                            <Typography variant="body2">
                                {/*<span className="course_price-real"> {props.course.cart_course.course_price ? "357 MAD" : null}</span>*/}
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </OverlayTrigger>
            <Modal show={show} onHide={() => handleClose()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmer la suppression</Modal.Title>
                </Modal.Header>
                <Modal.Body>Vous voulez continuer la suppression!</Modal.Body>
                <Modal.Footer className="style-model_footer">
                    <Button variant="outline-danger" onClick={() => handleDeleteFromCart(props.course.id)}>
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

