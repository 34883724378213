import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Loading from "./Loading";
import user_inconnu from "../../Assets/Images/user_inconnu.png";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Read_Notification} from "../../Redux/ActionCreators";


class NotificationsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationsIsLoad: this.props.notifications.isLoad,
            notifications: this.props.notifications.List_notification,
            notificationError: this.props.notifications.errMess,
            notify: {message: '', type: ''},
            notyIsOpen: false,
            user: this.props.session.user,

        };
    };

    setNotify = () => {
        this.setState({
            notify: {
                message: 'error',
                type: 'error'
            },
            notyIsOpen: !this.state.notyIsOpen
        });
    }

    short = (str, n) => {
        const length = str.indexOf(" ", n)
        if (n < length) {
            return str.substring(0, length);
        } else {
            return str;
        }
    }
    diffDate = (date1, date2) => {
        const result = Math.abs(date1 - date2);
        if (result <= 1000) {
            return "maintenant";
        } else {
            if (result < 1000 * 60) {
                return "Il y a " + Math.floor(result / 1000) + " seconds";
            } else {
                if (result < 1000 * 60 * 60) {
                    if (Math.floor(result / (1000 * 60)) === 1) {
                        return "Il y a une minute";
                    } else {
                        return "Il y a " + Math.floor(result / (1000 * 60)) + " minutes";
                    }
                } else {
                    if (result < 1000 * 60 * 60 * 24) {
                        if (Math.floor(result / (1000 * 60 * 60)) === 1) {
                            return "Il y a environ une heure";
                        } else {
                            return "Il y a " + Math.floor(result / (1000 * 60 * 60)) + " heures";
                        }
                    } else {
                        if (result < 1000 * 60 * 60 * 24 * 40) {
                            if (Math.floor(result / (1000 * 60 * 60 * 24)) === 1) {
                                return "Il y a un jour";

                            } else {
                                //console.log(result/100);
                                return "Il y a " + Math.floor(result / (1000 * 60 * 60 * 24)) + " jours";
                            }
                        } else {
                            //console.log(result/100);
                            return "il y a longtemps"
                        }
                    }
                }
            }
        }
    }
    readNotification = (notification) => {
        console.log("click")
        if (!notification.read) {
            this.props
                .dispatch(Read_Notification(notification.id))
                .then(data => {
                    if (data.payload.Success) {
                        console.log("notification is readed")
                    }
                })
                .catch(err => {
                    console.log(err.message);
                });

        }
        ;
    }

    render() {
        return (
            <div className='notification_page'>
                {
                    this.props.notifications && this.props.notifications.isLoad ?
                        this.props.notifications.List_notification.map(notification => {
                            return (

                                <Accordion onClick={() => {
                                    this.readNotification(notification);
                                }} className={!notification.read ? "notification_page_read" : null}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="typography_notification">
                                            <img className="img_notification_page" alt=""
                                                 src={notification.notification_pic ? notification.notification_pic
                                                     : user_inconnu}

                                            />

                                            <div
                                                className="notification_page_message"> {notification.message} {this.diffDate(new Date(notification.notif_date), new Date())}</div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                            malesuada lacus ex,
                                            sit amet blandit leo lobortis eget.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>


                            );
                        })
                        : <div>
                            <Loading/>
                        </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({session: state.session, notifications: state.notifications});
export default connect(mapStateToProps)(withRouter(NotificationsPage));

