import React from 'react'
import { Chapitre } from './Chapitre';
//import  {Titrecours } from './TitreCours';
import  Titrecours from './TitreCours';

const Chapitreliste = (props) => {
    

    return (
        <>
           <div id="apprenant_page__18">	
                <div id="energy_btn_dp">
                {/* <svg class="Rectangle_16_dp">
                        <rect id="Rectangle_16_dp" rx="7" ry="7" x="0" y="0" width="592" height="50">
                        </rect>
                    </svg>**/}
                    <div id="Trouver_votre_professeur_idal">
                        <button class="button_1">Trouver votre professeur idéal</button>
                    </div>
                </div>
	
	
	

                <div id="Mathmatiques_dq">
                    <span>Mathématiques</span>
                </div>

                <div id="Les_Intgrales_dq">
                    <span>Les Intégrales</span>
                </div>
                <div id="Chapitres">
                    <span>Chapitres</span>
                </div>
                <div id="Date_dacquisition">
                    <span>Date d'acquisition</span>
                </div>
                <div id="Contenus">
                    <span>Contenus</span>
                </div>
                <div id="Statut">
                    <span>Statut</span>
                </div>

                <div class="Line_88">
                    <hr WIDTH="95%" SIZE="1" ALIGN="center" />
                </div>


                <Titrecours titre= "Intégrales impropres" date= "02/11/2020" status = "Terminé"></Titrecours>
                <Titrecours titre= "Intégrales impropres" date= "02/11/2020" status = "Terminé"></Titrecours>


                <Chapitre cours= "Intégrales impropres" date= "02/11/2020" status = "Terminé"></Chapitre>
                <Chapitre cours= "Intégrales de Riemann" date= "02/11/2020" status = "Terminé"></Chapitre>
                <Chapitre cours= "Intégrales de Gauss" date= "02/11/2020" status = "Terminé"></Chapitre>
                <Chapitre cours= "Intégrales par partie" date= "02/11/2020" status = "Terminé"></Chapitre>
                <Chapitre cours= "Intégrales par partie" date= "02/11/2020" status = "Terminé"></Chapitre>
                <Chapitre cours= "Intégrales par partie" date= "02/11/2020" status = "Terminé"></Chapitre>
                <Chapitre cours= "Intégrales par partie" date= "02/11/2020" status = "Terminé"></Chapitre>
                <Chapitre cours= "Intégrales par partie" date= "02/11/2020" status = "Terminé"></Chapitre>
                <Chapitre cours= "Intégrales par partie" date= "02/11/2020" status = "Terminé"></Chapitre>
                <Chapitre cours= "Intégrales par partie" date= "02/11/2020" status = "Terminé"></Chapitre>


            </div>
        </>
    );
}

export default Chapitreliste;