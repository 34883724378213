import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import {Popup} from "semantic-ui-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SummaryTable from "../../../Student/StudentCart/SummaryTable";
import ButtonGroupPaymentChoice from "../../../Student/StudentCart/ButtonGroupPaymentChoice";
import Paypal from "../../../Student/StudentCart/Paypal";
import {Link, withRouter} from "react-router-dom";
import PaypalPack from "./PaypalPack";
import {connect} from "react-redux";

class LesPacks extends Component {

	constructor(props) {
		super(props);
		this.state={
			user:this.props.session.user,
			lgShow:false,
			lgShowSecond:false,
			showPaymentPaypal: false,
			showPaymentCreditCard: true,
			price:0,
		}
	}

	handleShowLarge = ()=>{
		this.setState({
			lgShow: true
		});
	}

	ChangePaymentPaypal = () => {
		this.setState({
			showPaymentPaypal: true,
			showPaymentCreditCard: false
		});
	}
	ChangePaymentCreditCard = () => {
		this.setState({
			showPaymentPaypal: false,
			showPaymentCreditCard: true
		});
	}

	render() {
		return (
			<div className="les-packs">
				<Grid container spacing={1} className="grid-style">
					<Grid item xs={8}>
						<div className="packs-presentation">
							<div className="offre-h5">
								<div className="offre-h5-container">
									<div className="h5">
										<span>PACK 5h</span>
									</div>
									<div className="prix-offre-h5">
										<span>1000dh</span>
									</div>
								</div>
								<div className="offre-h5-description">
									<span>Le pack 5 heures est utilisable à ta guise, en fonction de tes disponibilités, sur une période de 1 mois. Avec ce pack, tu peuxsuivre les cours pour une matière de ton choix.</span>
								</div>
							</div>
							<div className="offre-h10">
								<div className="offre-h10-container">
									<div className="h10">
										<span>PACK 10h</span>
									</div>
									<div className="prix-offre-h10">
										<span>1800dh</span>
									</div>
								</div>
								<div className="offre-h10-description">
									<span>Le pack 10 heures est utilisable sur une période de 2 mois et te permet d'opter pour une ou 2 matières différentes, toujours selon tes disponibilités</span>
								</div>
							</div>
							<div className="offre-h20">
								<div className="offre-h20-container">
									<div className="h20">
										<span>PACK 20h</span>
									</div>
									<div className="prix-offre-h20">
										<span>3200dh</span>
									</div>
								</div>
								<div className="offre-h20-description">
									<span>Le pack 20 heures est utilisable sur une durée de 3 mois et plus et t'offre la possibilité de suivre jusqu'à 3 matières différentes.</span>
								</div>
							</div>
						</div>
					</Grid>
					<Grid item xs={4}>
						<div className="packs-tittle">
							<span>Les Packs</span>
						</div>
						<div className="packs-description">
							<span>Avec l'offre de pack horaire, tu as non seulement la possibilité  de choisir parmi  plusieurs matières qui t'intéressent mais également d'adapter  ton rythme d'apprentissage  selon tes disponibilités.</span>
						</div>

						<Button type="submit"
								onClick={() => this.handleShowLarge()}
								className="acheter-packs "
								variant="contained">
							Acheter un Pack Maintenant
						</Button>

					</Grid>
				</Grid>
				<Modal
					className="style-model-payment"
					size="lg"
					show={this.state.lgShow}
					onHide={() => this.setState({
						lgShow: false
					})}
					aria-labelledby="example-modal-sizes-title-lg"
					scrollable
				>
					<Modal.Header closeButton>
						<Modal.Title id="example-modal-sizes-title-lg">
							Paiement
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="input-group mb-3">

							<select className="custom-select" id="pack" onChange={(e) => this.setState({ price: e.target.value })}>
								<option selected>Veillez Choisir Un Pack</option>
								<option value="5">5 H</option>
								<option value="10">10 H</option>
								<option value="20">20 H</option>
							</select>
						</div>


					</Modal.Body>
					<Modal.Footer className="mb-5" >
						<Button variant="outline-success"   onClick={() => this.setState({
							lgShow: false,
							lgShowSecond: true,
						})}>
							Continuer paiement
						</Button>
					</Modal.Footer>
				</Modal>
				<Modal animation={false}
					   className="style-model-payment"
					   size="lg"
					   show={this.state.lgShowSecond}
					   onHide={() => this.setState({
						   lgShowSecond: false
					   })}
					   aria-labelledby="example-modal-sizes-title-lg"
					   scrollable
				>
					<Modal.Header closeButton>
						<Modal.Title id="example-modal-sizes-title-lg">
							Paiement
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<ButtonGroupPaymentChoice
							clickToChangePaymentCreditCard={() => this.ChangePaymentCreditCard()}
							clickToChangePaymentPaypal={() => this.ChangePaymentPaypal()}
						/>
						<br/>
						{this.state.showPaymentPaypal && <PaypalPack
							price={this.state.price}
							// clickToAddToAchat={(data) => this.AddToAchat(data)}
							user={this.state.user}
						/>}
						{this.state.showPaymentCreditCard && <div style={{textAlign: "center"}}>
                            <span style={{fontSize: "14px"}}><b>•</b> Effectuez votre paiement par virement bancaire sur le compte :
                                <b>RIB 011780000087210010500259</b> et puis contactez nos services pour confirmer la réception au <Link>RH@talented.academy.</Link>
                            <br/> Le paiement par carte bancaire sera bientôt disponible.</span>
						</div>}
					</Modal.Body>
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = state => ({data: state.data, session: state.session, student: state.student});
export default connect(mapStateToProps)(withRouter(LesPacks));