import React, { useState } from "react";
import {Button, Checkbox, FormControlLabel} from "@material-ui/core";
import MyVerticallyCenteredModal from "./ProfModal/ProfModal";
import { Link, useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import user_inconnu from "../../../../../Assets/Images/user_inconnu.png";
import {Favorite, FavoriteBorder} from "@material-ui/icons";


const ProfDetails = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const history = useHistory();
     const [state, setState] = useState({
        checkedA: false,
    });

    const showModal = () => {
        setOpenModal(true);
    }
    const hideModal = () => {
        setOpenModal(false);
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({...state, [event.target.name]: event.target.checked});
        props.handleAddToFavorite(props.teacher.id)
    };

    const handleChangeRoute = (user_id) => {
        let path = `/student/${user_id}/reserve/calendar`;
        history.push(path);
    }

    return (
        <div className="Profs_details">
                <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                        <div className="Prof_pic">
                            <img className="Profs_picture_insert" src={props.teacher.profile_pic==="" ? user_inconnu : props.teacher.profile_pic} alt="" />
                        </div>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <div id="Nom_profs">
                            <span>{props.teacher.username}</span>
                            <Button
                                variant="contained"
                                className="choisir_un_creneau"
                                onClick={() => handleChangeRoute(props.teacher.id)}
                                >
                                Choisir un créneau
                            </Button>
                        </div>
                        <div id="Matieres_profs">
                            <span>{props.teacher.study_field + " " + props.teacher.level}</span>
                        </div>
                        <div className="Prix_profs">
                            {/*<strike >300 DH</strike>*/}
                            {/*<span> {props.prix}</span>*/}
                        </div>
                        <div id="Presentation_profs">
                            <span>
                                Présentation :{props.teacher.description.length>70 ? props.teacher.description.slice(0,70)+"..." : props.teacher.description}
                                <Link onClick={() => showModal()}> En savoir plus</Link>
                            </span>
                            <FormControlLabel
                                control={<Checkbox checked={state.checkedA || props.teacher.teacher_is_favorite} onChange={handleChange}
                                                   name="checkedA"
                                                   className="favorite-teacher-icon"
                                                   icon={<FavoriteBorder/>} aria-label="add to favorites"
                                                   color="secondary"
                                                   checkedIcon={<Favorite/>}/>}
                            />
                        </div>
                    </Grid>
                </Grid>
            <MyVerticallyCenteredModal
              show={openModal}
              onHide={() => hideModal()}
              tittle = {props.teacher.username}
              body ={props.teacher.description}
              teacher_id={props.teacher.id}
              handleChangeRoute={handleChangeRoute}
            />

        </div>
    );
}

export default ProfDetails;



