import React from 'react';
import {short} from "../../../../SplitWord";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {DeleteCourse} from "../../../../../../Redux/ActionCreators";
import {CircularProgress} from "@material-ui/core";
import {useDispatch} from "react-redux";

export function Cours(props) {
    const history = useHistory()
    const [open, setOpen] = React.useState(false);
    const [deleteCours, setDeleteCours] = React.useState(false);
    const dispatch = useDispatch()
    const GoToCourseContent = (course) => {
        let path = ""
        if (props.user.is_teacher) {
            path = '/teacher/course/content/' + course.id;
            history.push(
                {
                    pathname: path,
                });
        } else {
            path = '/student/course/content/' + course.id;
            history.push(
                {
                    pathname: path,
                });
        }


    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handelDeleteCourse = () => {
        setDeleteCours(true);
       dispatch(DeleteCourse(props.course.id))
            .then(data => {
                // console.log(data.payload);
                // this.updateAllParts(data.payload.parts, data.payload.quizzes);
                setOpen(false);
                setDeleteCours(false);
            })
            .catch(err => {
                console.log("err", err);
                setOpen(false);
                setDeleteCours(false);
            });
    }


    return (
        <div id="Acceuil_cours_bloc">
            <img id="tie-690084_hf_1" src="tie-690084_hf.png" srcSet={props.image ? props.image : props.imageDefault} alt=""/>
            <div className={props.user.is_teacher ? "courseSystem" : "courseSystemEtudiant"}>
                {props.system}
            </div>
            <div className={"courseMatiere"}>
                {props.matiere}
            </div>
            <div className={"courseTitle"}>
                {props.course.course_intitule}
            </div>
            <div className={"courseClasse"}>
                {props.course.course_classe}
            </div>
            <div className={"courseDuree"}>
                {props.course.course_duree}h
            </div>
            {
                props.user.is_teacher &&
                <div>
                    <IconButton className={"DeleteCourse"}
                                onClick={handleClickOpen}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton className={"EditCourse"}
                                onClick={() => GoToCourseContent(props.course)}
                    >
                        <EditIcon/>
                    </IconButton>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Suppression de cours</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Êtes-vous sûr de vouloir supprimer ce cours?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} disabled={deleteCours} color="primary">
                                Annuler
                            </Button>
                            <Button onClick={handelDeleteCourse} disabled={deleteCours} color="primary">
                                {
                                    deleteCours ?
                                        <CircularProgress color="secondary" size={18}/>:
                                        "Supprimer"
                                }
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            }

        {/*<div id="Accueil_cours_en_cours">*/}
        {/*    <div id="Histoire">*/}
        {/*        <button className ="button_2" onClick={props.changeroutes}>{props.nom}</button>*/}
        {/*    </div>*/}
        {/*    <div id="La_Bipolarisation">*/}
        {/*        <span>{props.chapitre}</span>*/}
        {/*    </div>*/}
        {/*    <div id="Lunion_sovietique_dcide_dattaq">*/}
        {/*        <span>{short(props.description,40)}</span>*/}
        {/*    </div>*/}
        {/*</div>*/}


        {props.user.is_student &&
            <>
            <div id="Barre_de_cargement">
                <svg className="Rectangle_93">
                    <rect id="Rectangle_93" rx="4.5" ry="4.5" x="0" y="0" width="200" height="9">
                    </rect>
                </svg>
                <svg className="Rectangle_94">
                    <rect id="Rectangle_94" rx="4.5" ry="4.5" x="0" y="0" width="94" height="9">
                    </rect>
                </svg>
                <div id="ID26">
                    <span>26%</span>
                </div>
            </div>
                <div style={{left: "25px", bottom: "30px", position: "absolute"}}>
                <Button style={{width:"160px", color: "white", backgroundColor:"rgb(111, 8, 236)", height: "30px", textTransform: "none",}}
                        onClick={()=>GoToCourseContent(props.course)} >Accéder au cours </Button>
              </div>
            </>
        }
    </div>
    )
}
