import { Button } from '@material-ui/core'
import React from 'react'

export function Resume(props) {
    

    return (
        <div className="cours-resume">
			<div className="cours-resume-tittle">
				<span>Résumé</span>
			</div>
			
			<div className="fin-inscription">
				<span>Date de fin d'inscription :</span>
			</div>
			<div className="debut-cours">
				<span>Date de début :</span>
			</div>
			<div className="fin-cours">
				<span>Date de fin :</span>
			</div>
			<div className="duree-cours">
				<span>Estimation :</span>
			</div>
			<div className="langue-cours">
				<span>Langue :</span>
			</div>
			<div className="prix-cours">
				<span>Frais d'incription :</span>
			</div>
		
			
			
			<Button
				className="acheter-ce-cours"
				variant="primary"
				>
				Acheter ce cours
			</Button>
			<Button
				className="ajouter-mes-cours"
				variant="primary"
				>
				Ajouter à mes cours
			</Button>
			
        </div>
    )
}
