import React, {useState} from 'react'
import {TextField} from "@material-ui/core";
import Button from "react-bootstrap/Button";

// Languages
const langues = [
    {
        value: 'Français',
        label: 'Français',
    },
    {
        value: 'Anglais',
        label: 'Anglais',
    },
    {
        value: 'Arabe',
        label: 'Arabe',
    },
];

export function ResearchParams(props) {

    let obj = {params: {}}

    const [selectSystem, SetSelectSystem] = useState(undefined);
    const [selectModule, SetSelectModule] = useState(undefined);
    const [selectLanguage, SetselectLanguage] = useState(undefined);
    const [selectSubject, SetselectSubject] = useState(undefined);

    const GetData = () => {
        if (selectSystem) {
            obj.params.course_education_system = props.EdducationalSystem.filter(education_system => education_system.system_name === selectSystem)[0].id
        }
        if (selectLanguage) {
            obj.params.course_language = selectLanguage
        }
        if (selectSubject) {
            obj.params.course_subject = selectSubject
        }
        if (selectModule) {
            obj.params.course_module = selectModule
        }
        props.clickHandler(obj)
    }

    const handleChangeEducationSystem = (event) => {
        SetSelectSystem(event.target.value)
    }
    const handleChangeModule = (event) => {
        SetSelectModule(event.target.value)
    }
    const handleChangeLanguage = (event) => {
        SetselectLanguage(event.target.value)
    }
    const handleChangeSubject = (event) => {
        SetselectSubject(event.target.value)
    }
    return (
        <div class="research-params">
            <span> Filtrer les cours par :</span>
            <div className="search-params-content">
                <TextField
                    id="outlined-select-currency-native"
                    select
                    fullWidth
                    className="outlined-select"
                    name="Systéme"
                    label="Systéme"
                    value={selectSystem}
                    onChange={handleChangeEducationSystem}
                    SelectProps={{
                        native: true,
                    }}
                >
                    {selectSystem === undefined ?
                        <option key="default" value="Choose">
                            Choisir ...
                        </option>
                        : null
                    }
                    {props.EdducationalSystem && props.EdducationalSystem.map((option) => (
                        <option key={option.id} value={option.system_name}>
                            {option.system_name}
                        </option>
                    ))}
                </TextField>
                <TextField
                    id="outlined-select-currency-native"
                    select
                    fullWidth
                    className="outlined-select"
                    name="Matiére"
                    label="Matiére"
                    value={selectSubject}
                    onChange={handleChangeSubject}
                    SelectProps={{
                        native: true,
                    }}
                >
                    {selectSubject === undefined ?
                        <option key="default" value="Choose">
                            Choisir ...
                        </option>
                        : null
                    }
                    {props.Subjects && props.Subjects.map((option) => (
                        <option key={option.id} value={option.matiere_name}>
                            {option.matiere_name}
                        </option>
                    ))}
                </TextField>
                <TextField
                    id="outlined-select-currency-native"
                    select
                    fullWidth
                    className="outlined-select"
                    name="cours_systeme"
                    label="Module"
                    value={selectModule}
                    onChange={handleChangeModule}
                    SelectProps={{
                        native: true,
                    }}
                >
                    {selectModule === undefined ?
                        <option key="default" value="Choose">
                            Choisir ...
                        </option>
                        : null
                    }
                    {selectSubject && props.Subjects.filter(matiere => matiere.matiere_name === selectSubject)[0].matiere_modules.map((option, key) => (
                        <option key={key} value={option}>
                            {option}
                        </option>
                    ))}
                </TextField>
                <TextField
                    id="outlined-select-currency-native"
                    select
                    fullWidth
                    className="outlined-select"
                    name="langue"
                    label="langue"
                    value={selectLanguage}
                    onChange={handleChangeLanguage}
                    SelectProps={{
                        native: true,
                    }}
                >
                    {selectLanguage === undefined ?
                        <option key="default" value="Choose">
                            Choisir ...
                        </option>
                        : null
                    }
                    {langues.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
                <Button size="lg" onClick={() => GetData()} className="create-course"
                         variant="outline-info">Filtrer</Button>
            </div>
        </div>

    );
}


