import React from 'react';

export function Cercle(props) {
    

    return (
        <>
            <div id="artifices_b">
				<svg class="Ellipse_32_b">
					<ellipse id="Ellipse_32_b" rx="294" ry="285.5" cx="294" cy="285.5">
					</ellipse>
				</svg>
				<svg class="Line_58_b" viewBox="0 0 15 159.27">
					<path id="Line_58_b" d="M 0 0 L 0 159.26953125">
					</path>
				</svg>
				<svg class="Path_333" viewBox="0 0 15 159.27">
					<path id="Path_333" d="M 0 0 L 0 32.97377014160156 L 0 159.26953125">
					</path>
				</svg>
				<svg class="Line_60_b" viewBox="0 0 15 159.27">
					<path id="Line_60_b" d="M 0 0 L 0 159.26953125">
					</path>
				</svg>
				<svg class="Ellipse_35_ba">
					<ellipse id="Ellipse_35_ba" rx="248" ry="248" cx="248" cy="248">
					</ellipse>
				</svg>
			</div>
        </>
    )
}
