import React, {useState} from "react";
import MailIcon from "@material-ui/icons/Mail";

export function ContactUs(props) {
    const [display, setDisplay] = useState(false);
    const seeForm = () => {
        return display ? "contact_as_form" : "contact_as_form hide"
    }
    const setPostion = (style)=>{
        if(props.position!="/"){
            return " "+style;
        }
        return "";
    }
    console.log(props)
    // console.log(window.location.pathname);
    return (
        <>
            <div className={"contact_as"+setPostion("contact_as_bottom")} onClick={() => setDisplay(!display)}>
                <span>Contactez-nous</span>
                <span>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                             viewBox="0 0 459.668 459.668">
<g id="XMLID_2_">
	<g>
		<g>
			<path d="M359.574,297.043c-18.204,25.002-47.692,41.286-80.916,41.286h-46.618c-16.104,0-29.818-10.224-35.011-24.534
				c-6.41-1.912-12.696-4.394-18.83-7.442c-12.99-6.454-24.785-15.198-35.168-26.03c-67.35,14.796-117.757,74.808-117.757,146.603
				v9.384c0,12.9,10.458,23.358,23.358,23.358h362.403c12.9,0,23.358-10.458,23.358-23.358v-9.384
				C434.392,371.464,404.309,323.032,359.574,297.043z"/>
			<path d="M118.205,232.178c10.039,0,18.777-5.564,23.304-13.775c0.119,0.325,0.24,0.648,0.362,0.971
				c0.036,0.097,0.072,0.194,0.108,0.291c10.62,27.954,31.284,51.388,58.532,61.627c6.59-10.471,18.243-17.435,31.53-17.435h46.618
				c4.65,0,8.978-1.312,12.772-3.433c6.372-3.563,12.102-12.602,15.061-17.393c4.735-7.667,8.404-15.788,11.657-24.642
				c1.828,3.32,4.342,6.208,7.354,8.471v11.431c0,25.83-21.014,46.845-46.845,46.845H232.04c-8.813,0-15.958,7.145-15.958,15.958
				c0,8.814,7.145,15.958,15.958,15.958h46.618c43.429,0,78.761-35.332,78.761-78.761V226.86
				c6.46-4.853,10.639-12.577,10.639-21.278v-48.119v-18.452c0-8.88-4.355-16.737-11.042-21.568C351.83,51.816,296.77,0,229.833,0
				C162.895,0,107.836,51.816,102.65,117.442c-6.687,4.831-11.042,12.689-11.042,21.568v66.57
				C91.608,220.311,103.575,232.178,118.205,232.178z M229.833,31.917c49.552,0,90.423,37.868,95.2,86.185
				c-3.136,2.467-5.705,5.62-7.475,9.238c-15.058-39.286-48.672-66.638-87.726-66.638c-39.896,0-72.971,28.292-87.667,66.481
				c-0.02,0.052-0.039,0.105-0.059,0.158c-1.77-3.618-4.339-6.771-7.475-9.238C139.411,69.785,180.281,31.917,229.833,31.917z"/>
		</g>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

                    </span>
            </div>

            <div className={seeForm()+setPostion("contact_as_form_bottom")}>
                <div className="body">
                    <button className={"close"} onClick={()=>setDisplay(false)}>+</button>
                    <p> Appelez-nous sur </p>
                    <p className="num"> 0770707097 </p>
                    <p>Ecrivez-nous</p>
                    <a className="btn btn--aide mt-1 mb-1"
                       href="https://share-eu1.hsforms.com/1ENwz_fN0QCayRoKpvShmhAey16d" target="_blank">
                        <p style={{"padding": "2px", "fontSize": "19px"}}>
                            <MailIcon className={"iconMail"}/>
                            Message
                        </p>
                    </a>
                    <div className="btn btn--aide mt-1 mb-1">
                        <a href={"https://api.whatsapp.com/send/?phone=+212770707097&text&app_absent=0"}
                           target="_blank">
                            <img alt="WhatsApp Main Page"
                                 src="https://z-p3-static.xx.fbcdn.net/rsrc.php/ym/r/36B424nhiL4.svg"/>
                        </a>
                    </div>
                    <p>
                    </p>
                </div>
            </div>
        </>
    )
}