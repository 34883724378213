import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";

const PayPalButton = window.paypal.Buttons.driver("react",{React, ReactDOM});

class PaypalPack extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            rate:'',
        }
    }
    componentDidMount() {
        axios({
            method: "GET",
            url: `https://free.currconv.com/api/v7/convert?q=MAD_USD&compact=ultra&apiKey=b1768f928512431bc326`,
        })
            .then((response) => {
                console.log(response.data['MAD_USD'])
                this.setState({
                    rate: response.data['MAD_USD']
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    createOrder(data, actions) {
        return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
                {
                    description: "Payment Pack",
                    amount: {
                        currency_code: "USD",
                        value: (this.props.price * this.state.rate).toFixed(2),
                    },
                },
            ],
        });
    }

}

export default PaypalPack;
