import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import LastCourses from '../Layouts/LastCourses';
import LiveCourses from '../Layouts/LiveCourses';
import StoreCourses from '../Layouts/StoreCourses';
import LibraryComponent from "./LibraryComponent";
import {Popover} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    AppBar: {
        width: "56%",
        backgroundColor: "#f9f9f9",
        boxShadow: "none",
        paddingTop: theme.spacing(2)
    },
    tab: {
        padding: theme.spacing(0),
        whiteSpace: "nowrap",
        textAlign: "left",
        fontFamily: "Biko",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "20px",
        color: "rgba(112,112,112,0.259)",
        display: "block",
        textTransform: "none",
    }
}));

export default function CoursesNavbar(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <>
            {/*todo: need to separate the student CSS from the Teacher CSS*/}
            <div className="teacher-courses-zone">
                <div className="courses-tete">
                    <span>Mes Cours</span>
                </div>
                <AppBar position="static" className={classes.AppBar} color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={(e) => handlePopoverOpen(e)}
                            onMouseLeave={(e) => handlePopoverClose(e)}
                            className={classes.tab} label="Récents" {...a11yProps(0)} />
                        <Tab className={classes.tab} label="Nos Formules" {...a11yProps(1)} />
                        <Tab className={classes.tab} label="Bibliothèque" {...a11yProps(2)} />
                        <Tab className={classes.tab} label="CourStore" {...a11yProps(3)} />
                    </Tabs>
                </AppBar>

                <TabPanel value={value} index={0} dir={theme.direction}>
                    <LastCourses {...props}/>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <LiveCourses {...props}/>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <LibraryComponent/>
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <StoreCourses/>
                </TabPanel>
            </div>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>Bientôt disponible</Typography>
            </Popover>
        </>
    );
}