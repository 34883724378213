import React from 'react';
import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import FavoriteEvent from './Shared/Favorites/FavoriteEvent';
import FavoriteRessource from './Shared/Favorites/FavoriteRessource';
import FavoriteTeacher from './Shared/Favorites/FavoriteTeacher/FavoriteTeacher';
import FavoriteArticle from './Shared/Favorites/FavoriteArticle';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import FavoriteCourse from "./Shared/Favorites/FavoriteCourse";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
    AppBar: {
        width: "56%",
        backgroundColor: "#f9f9f9",
        boxShadow: "none",
        paddingTop: theme.spacing(2)
    },
    tab: {
        padding: theme.spacing(0),
        whiteSpace: "nowrap",
        textAlign: "left",
        fontFamily: "Biko",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "20px",
        color: "rgba(112,112,112,0.259)",
        display: "block",
        textTransform: "none",
        // span: {
        //     alignItems: "start",
        // },
    }
}));

function FavoriteTabs(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div>
            <div className="courses-tete">
                <span>Mes Favoris</span>
            </div>
            <AppBar position="static" className={classes.AppBar} color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab className={classes.tab} label="Cours" {...a11yProps(0)} />
                    <Tab className={classes.tab} label="Evènements" {...a11yProps(1)} />
                    {props.session.user.is_student && <Tab className={classes.tab} label="Vos Profs" {...a11yProps(2)} />}
                    <Tab className={classes.tab} label="Ressources" {...a11yProps(3)} />
                    <Tab className={classes.tab} label="Article" {...a11yProps(3)} />
                </Tabs>
            </AppBar>

            <TabPanel value={value} index={0} dir={theme.direction}>
                <FavoriteCourse />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
                <FavoriteEvent />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
                <FavoriteTeacher />
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
                <FavoriteRessource />
            </TabPanel>
            <TabPanel value={value} index={4} dir={theme.direction}>
                <FavoriteArticle />
            </TabPanel>
        </div>
    );
}
const mapStateToProps = state => ({session: state.session});
export default connect(mapStateToProps)(withRouter(FavoriteTabs));