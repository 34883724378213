import React, {Component, useEffect, useState} from "react";
import AdminFormationNav from "./AdminFormationNav";
import {connect} from "react-redux";
import {useLocation, withRouter} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getAllTeachersAction, getStudentById, getSuivi} from "../../../Redux/ActionCreators";
import {CircularProgress} from "@material-ui/core";
import SuiviBloc from "./SuiviBloc";

function AdminFormationSuivi(props){
    const location = useLocation();
    const {teacher_id} = location.state;
    const dispatch = useDispatch();
    const [suivi, setSuivi] = useState(props.adminFormation.suivi);
    const [totalMontant,setTotalMontant] = useState(props.adminFormation.totalMontant);
    const [totalDuree,setTotalDuree] = useState(props.adminFormation.totalDuree);

    useEffect(()=>{
        let user = props.session.user;
        let data = {
            teacher_id: teacher_id
        }
        if(user !== null && user !== undefined ){
            dispatch(getSuivi(data))
                .then(data => {
                    setSuivi(data.payload.suivi);
                    setTotalMontant(data.payload.total_montant);
                    setTotalDuree(data.payload.total_duree);
                })
                .catch(err => {
                    console.log("err", err);
                });

            console.log("suivi :", suivi);

        }

    }, []);





    const renderSuivi = (suivi) => {

        return suivi.map((currentSuivi,key) => {
            const {id,student_id,seance,montant,duree,date} = currentSuivi;
            return(
                <SuiviBloc id={id}
                           student_id = {student_id}
                           seance = {seance}
                           montant={montant}
                           duree={duree}
                           date={date}
                />
            )

        })
    }

    const renderTotal = (totalMontant,totalDuree) =>{
        return (
            <>
                <tr key="total" className="">
                    <td className="text-center font-weight-bold h5">TOTAL</td>
                    <td className="text-center">-</td>
                    <td className="text-center">-</td>
                    <td className="text-center bg-info font-weight-bold h5" >{totalDuree}</td>
                    <td className="text-center">-</td>
                    <td className="text-center bg-info font-weight-bold h5" >{totalMontant}</td>
                </tr>
            </>
        )
    }

    return(
        <div>
            <div>
                <AdminFormationNav />
            </div>
            <div className="container mt-5">
                    <div>
                        <table className="table table-bordered table-hover">
                            <thead className="thead-dark">
                                <tr>
                                    <th className="text-center">

                                    </th>
                                    <th className="text-center">
                                        Seance
                                    </th>
                                    <th className="text-center">
                                        Eleve
                                    </th>
                                    <th className="text-center">
                                        Duree
                                    </th>
                                    <th className="text-center">
                                        Date
                                    </th>
                                    <th className="text-center">
                                        Montant
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                renderSuivi(suivi)
                            }
                            {
                                renderTotal(totalMontant,totalDuree)
                            }

                            </tbody>
                        </table>
                    </div>


            </div>

        </div>
    )

}

const mapStateToProps = state => ({ adminFormation : state.adminFormation,session:state.session});
export default connect(mapStateToProps)(withRouter(AdminFormationSuivi));