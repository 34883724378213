import React, {Component} from 'react';
import {Toast} from "react-bootstrap";
import {Button, CircularProgress} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {partial_update_studentAction, partial_update_teacherAction} from "../../../Redux/ActionCreators";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import {EventGoogleAnalytics} from "../../Common/Components/GoogleAnalytics";


class Langues extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.session.user,
            alert: "",
            languages: this.props.session.user.languages,
            openEdit: true,
            show: false,
            openProgressBar: false
        }
        this.inputRef = React.createRef();
    }

    handleOpenEdit = () => {
        // Event Google Analytics
        EventGoogleAnalytics("Profile_Language_button","Button","Edit Languages")
        this.setState({
            openEdit: !this.state.openEdit,
        })
    };
    // remove skill
    removeSkill = i => {
        const languages = this.state.languages;
        languages.splice(i, 1);
        this.setState({
            languages: languages
        });
    }
    // add skill TODO:REMOVE DUPLICATION
    addLanguage = e => {
        const languages = this.state.languages;
        const value = e.target.value;
        if (e.key === "Enter" && value) {
            // check duplicate skill
            if (e.key === "Enter" && value) {
                // check if duplicate skill
                if (languages.find(language => language.toLowerCase() === value.toLowerCase())) {
                    return this.setState({
                        alert: "No duplicate value allowed",
                        show: true
                    });
                }
                // else add skill to skills array
                languages.push(value)
                // set new state
                this.setState({
                    languages
                })
                // set current input to null
                this.inputRef.current.value = null
            } else if (e.key === "Backspace" && !value) {
                this.removeSkill(languages.length - 1)
            }
        }
    }
    // Submit skills update
    handleSubmit = () => {
        // Event Google Analytics
        EventGoogleAnalytics("Profile_Language_button","Button","Submit Languages")
        this.setState({
            openProgressBar: !this.state.openProgressBar
        });
        const data = {
            "languages": this.state.languages,
            "user_id": this.state.user.id
        };
        if (this.state.user.is_teacher) {
            return this.props
                .dispatch(partial_update_teacherAction(data))
                .then(data => {
                    if (data.payload.Success) {
                        this.handleOpenEdit();
                        this.setState({
                            openProgressBar: !this.state.openProgressBar,
                        });
                        this.props.setNotify(data.payload.message, 'success')
                    }
                })
                .catch(err => {
                    this.props.setNotify("Erreur est survenue, Réessayez plus tard", 'error')
                    this.setState({
                        openProgressBar: !this.state.openProgressBar
                    });
                });
        } else {
            // TODO:REMOVE DUPLICATION OR REPLACE DISPATCH
            return this.props
                .dispatch(partial_update_studentAction(data))
                .then(data => {
                    if (data.payload.Success) {
                        this.handleOpenEdit();
                        this.setState({
                            openProgressBar: !this.state.openProgressBar,
                        });
                        this.props.setNotify(data.payload.message, 'success')
                    }
                })
                .catch(err => {
                    this.props.setNotify("Erreur est survenue, Réessayez plus tard", 'error')
                    this.setState({
                        openProgressBar: !this.state.openProgressBar
                    });
                });
        }

    };

    render() {
        const {languages, alert, show, openEdit, openProgressBar, user} = this.state;
        const toggleShow = () => this.setState({
            show: !show
        });
        return (
            <>
                <div className="bloc-langagues">
                    {openEdit &&
                    <Button
                        className="edit-btn"
                        onClick={this.handleOpenEdit}
                    >
                        <EditIcon fontSize="large"/>
                    </Button>
                    }
                    {openProgressBar
                    &&
                    <CircularProgress color="secondary" className="progress-bar"/>
                    }
                    <div className="bloc-toast">
                        <Toast show={show} onClose={toggleShow}>
                            <Toast.Header className="toast-header">
                                <img
                                    className="rounded mr-2 picture-toast"
                                    alt=""
                                />
                                <strong className="text-toast">Duplication</strong>
                            </Toast.Header>
                            <Toast.Body>{alert}</Toast.Body>
                        </Toast>
                    </div>
                    <div className="bloc-title">
                        <span>Langues</span>
                        {!openEdit &&
                        <>
                            <Button className="save-profile" variant="contained"
                                    onClick={() => this.handleSubmit()}>
                                <CheckIcon fontSize="medium"/>
                            </Button>
                            <Button className="cancel-profile" variant="contained"
                                    onClick={this.handleOpenEdit}>
                                <CloseIcon fontSize="medium"/>
                            </Button>
                        </>
                        }
                    </div>
                    <div className="bloc-title-mobile">
                        <span>Langues</span>
                        <br/>
                        {!openEdit &&
                        <>
                            <Button className="save-profile" variant="contained"
                                    onClick={() => this.handleSubmit()}>
                                <CheckIcon fontSize="small"/>
                            </Button>
                            <Button className="cancel-profile" variant="contained"
                                    onClick={this.handleOpenEdit}>
                                <CloseIcon fontSize="small"/>
                            </Button>
                        </>
                        }
                    </div>
                    {openEdit ?
                        <div id="Universit_Dipme_ou_formation_P">
                            <Grid container spacing={2}>
                                {user.languages && user.languages.map((language, key) => {
                                    return (
                                        <Grid item key={key}>
                                            <span>{language.trim()}</span>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </div>
                        :
                        <div className="skill">
                            <ul>
                                {languages && languages.map((language, i) => {
                                    return (
                                        <li key={i}> {language.trim()}
                                            <button onClick={() => this.removeSkill(i)}>+</button>
                                        </li>
                                    )

                                })}
                                <li className="input-skill">
                                    <input onKeyDown={this.addLanguage} type="text" size="4" ref={this.inputRef}
                                           placeholder="add your languages"/>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({session: state.session});
export default connect(mapStateToProps)(withRouter(Langues));
