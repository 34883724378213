import React, { Component } from 'react';
import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import {Popup} from "semantic-ui-react";


class Forfaits extends Component {

    render() {
        return (
            <div className="forfaits">
                <Grid container spacing={1} className="grid-style">
                    <Grid item xs={4}>
                        <div className="forfaits-tittle">
                            <span>Forfaits</span>
                        </div>
                        <div className="forfaits-description">
                            <span>Tu as un objectif moyen ou long terme de progresser de progresser dans une matière donnée ?<br /><br />Nos enseignants expérimentés te proposent un accompagnement personnalisé de 3, 6 ou 9 mois.</span>
                        </div>


                        <Popup className="dev-pop-up" position={"bottom-left"} content={
                            <div className="bg-white text-dark m-4 border border-dark p-2" >
                               <p>Bientôt Disponible !</p>
                            </div>
                        } trigger={<Button type="submit" className="acheter-forfaits" variant="contained">
                            Acheter un nouveau forfait
                        </Button>} />
                    </Grid>
                    <Grid item xs={8}>
                        <div className="forfaits-presentation">
                            <div className="offre-trimestre">
                                <div className="offre-trimestre-container">
                                    <div className="trimestre">
                                        <span>TRIMESTRE</span>
                                    </div>
                                    <div className="a-partir-de">
                                        <span>A partir de</span>
                                    </div>
                                    <div className="prix-offre-trimestre">
                                        <span>800dh/mois</span>
                                    </div>
                                </div>
                                <div className="offre-trimestre-description">
                                    <span>Avec le forfait trimestre, bénéficie d'un suivi continuel  pendant 3 mois </span>
                                </div>
                            </div>
                            <div className="offre-semestre">
                                <div className="offre-semestre-container">
                                    <div className="semestre">
                                        <span>SEMESTRE</span>
                                    </div>
                                    <div className="a-partir-de">
                                        <span>A partir de</span>
                                    </div>
                                    <div className="prix-offre-semestre">
                                        <span>700dh/mois</span>
                                    </div>
                                </div>
                                <div className="offre-semestre-description">
                                    <span>Avec le forfait semestre, en plus de l'accompagnement personnalisé pendant les 6 mois, tu bénéficies d'une séance de coaching tous les 2 mois </span>
                                </div>
                            </div>
                            <div className="offre-annuel">
                                <div className="offre-annuel-container">
                                    <div className="annuel">
                                        <span>ANNUEL</span>
                                    </div>
                                    <div className="a-partir-de">
                                        <span>A partir de</span>
                                    </div>

                                    <div className="prix-offre-annuel">
                                        <span>600dh/mois</span>
                                    </div>
                                </div>
                                <div className="offre-annuel-description">
                                    <span>Avec le forfait annuel, en plus des avantages qu'offrent les forfaits trimestre et semestre, tu as également accès à des contenus spéciaux de notre bibliothèque.</span>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>

        )
    }
}

export default Forfaits;