import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Button } from '@material-ui/core';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {ResetPwdEditeAction} from '../../Redux/ActionCreators';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {ContactUs} from "./Layouts/Shared/ContactUs/ContactUs";

class ChangePwdEdite extends Component {
    constructor(props){
        super(props);
        this.state = {
            success: false,
            errorGlobal: ''
        };
    };

    handleSubmit = (values) => {
        const data = {
            "token": this.props.match.params.token,
            "password": values.password
        };
        return this.props
            .dispatch(ResetPwdEditeAction(data))
            .then(data => {
                if (data.payload.Success) {
                    this.setState({
                        success: true
                    })
                }
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message
                });
            });
    };

    render(){
        const { errorGlobal } = this.state;
        return (
            <>
                <Row className="resetPwd-form">
                    <div className="modal-page">
                        {this.state.success ?
                            <>
                                <Container className="success-edite-container">
                                    <p>
                                        Votre mot de passe a été changé avec succès.
                                    </p>
                                </Container>
                            </>
                            :
                            <>
                                <Container className="modal-title">
                                    <h1 className="title">Réinitialisez votre mot de passe</h1>
                                </Container>
                                <Container className="text-container">
                                    <p>
                                        Entrez votre nouveau mot de passe pour votre compte TalenTed.
                                        Nous vous encourageons à ne pas utiliser le même mot de passe que celui que
                                        vous avez utilisé pour un autre site.
                                    </p>
                                </Container>
                                {errorGlobal && (
                                    <div className="textErrors">{errorGlobal}</div>
                                )}
                                <Container className="input-container">
                                    <Formik
                                      initialValues={{ password: '' }}
                                      validationSchema={Yup.object({
                                          password: Yup.string()
                                              .min(6, 'Must be 6 characters or more')
                                              .required('Required'),
                                      })}
                                      onSubmit={(values, { setSubmitting }) => {
                                        setTimeout(() => {
                                          this.handleSubmit(values)
                                          setSubmitting(false);
                                        }, 400);
                                      }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                        }) => (
                                            <Form>
                                                <Field
                                                    name="password"
                                                    type="password"
                                                    value={values.password}
                                                    className="text-field"
                                                    placeholder="Mot de passe"
                                                />
                                                <div className="error-field"> {errors.password && touched.password && errors.password}</div>
                                                <Button
                                                    type="submit"
                                                    className="seconnecter"
                                                    variant="contained"
                                                    color="secondary"
                                                >
                                                    Changer mot de passe
                                                </Button>
                                            </Form>
                                        )}
                                    </Formik>
                                </Container>
                            </>
                        }
                    </div>
                </Row>
                <ContactUs position={"/changePass"}/>

            </>
        );
    }
}

export default connect()(withRouter(ChangePwdEdite));
