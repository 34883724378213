import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {EditorState} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {addArticleComment, getArticleComment, getArticles} from "../../Redux/ActionCreators";
import Loading from "./Loading";
import Notification from "./AlertComponent";
import Grid from "@material-ui/core/Grid";
import inconnu from "../../Assets/Images/user_inconnu.png";
import {CircularProgress} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import {EventGoogleAnalytics} from "./Components/GoogleAnalytics";
import { Helmet } from 'react-helmet';

class ArticleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
            editorState: EditorState.createEmpty(),
            isLoad: this.props.articles.isLoad,
            article: this.props.articles.List_articles.filter(article => this.props.match.params.id_article === article.id)[0],
            allertStatus: false,
            notify: {message: '', type: ''},
            notyIsOpen: false,
            comments : [],
            isLoadComment : false,
            comment:{
                // user:'',
                comment : '',
                // article : this.props.match.params.id_article,
            }
            ,commentIsAdd:true,
        };
    };

    setNotify = () => {
        this.setState({
            notify: {
                message: 'error',
                type: 'error'
            },
            notyIsOpen: !this.state.notyIsOpen
        });
    }

    componentDidMount() {
        // Event Google Analytics
        EventGoogleAnalytics("Read_article_button","Button","Read article in new part")
        if (!this.state.isLoad) {
            this.props
                .dispatch(getArticles())
                .then(data => {
                    if (data.payload) {
                        this.setState({
                            article: this.props.articles.List_articles.filter(article => this.props.match.params.id_article === article.id)[0],
                            isLoad: true,
                        })

                    }
                })
                .catch(err => {
                    console.log("err", err);
                    this.setNotify();
                });
        }
        return this.props
            .dispatch(getArticleComment(this.props.match.params.id_article))
            .then(data => {
                if (data.payload) {
                    this.setState({
                        comments: data.payload.comments,
                        isLoadComment: true,
                    })

                }
            })
            .catch(err => {
                console.log("err", err);
                console.log("errrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
                this.setNotify();
            });

    };
    handelChange=(e)=>{
        if(this.props.session.user){
            this.setState({
                comment:{
                    user:this.props.session.user.id,
                    comment : e.target.value,
                    article : this.props.match.params.id_article,
                }
            })
        } }
    addComment=()=>{
        if(this.props.session.user && this.state.comment.comment!==""){
            this.setState({
                commentIsAdd: false,
            });
            return this.props
                .dispatch(addArticleComment(this.state.comment))
                .then(data => {
                    if (data.payload) {
                        console.log(data.payload.comments);
                        this.setState({
                            comments: data.payload.comments,
                            commentIsAdd: true,
                            comment : {
                                comment:""},
                        })

                    }
                })
                .catch(err => {
                    console.log("err", err);
                    this.setNotify();
                });

        };
    }
    render() {
        return (
             <Grid container spacing={3}>
                 <Grid item xs>

        </Grid>
        <Grid style={{padding:"0px"}} item xs={12} sm={9} >
            <div className="content_article_display">
                {this.state.isLoad ?
                    <>
                        <img className="art_picture"
                            alt="" src={this.state.article ? this.state.article.article_pic : null}/>
                        <div className="title">{this.state.article ? this.state.article.title : null}</div>
                        <div className={"tags"}>
                            <ul>
                                {this.state.article ? this.state.article.tags ? this.state.article.tags.map(tag =>{
                                return (
                                    <li>{tag}</li>
                                )
                            }) : null : null
                                }
                            </ul>
                        </div>
                        <div className="content">
                            {this.state.article ? this.state.article.introduction : null}
                        </div>
                      {
                            this.state.article ? this.state.article.parts_article.map(part =>{
                                return (
                                    <>
                                        <div className="subtitle">
                                            {part.title}
                                        </div>
                                        <div className="content">
                                            {part.content}
                                        </div>
                                    </>
                                )
                            }) : null
                        }
                        {/*<div className="reporter_date">*/}
                        {/*    <span>le {this.state.article ? this.state.article.pub_date : null} par {this.state.article ? this.state.article.reporter : null}</span>*/}
                        {/*</div>*/}
                    </>

                            :
                            <div className="loading">
                                <Loading/>
                            </div>
                        }
                        <Notification
                            notify={this.state.notify}
                            setNotify={this.setNotify}
                            isOpen={this.state.notyIsOpen}
                        />
                        <div className="subtitle">
                            Commentaires
                        </div>
                        <div className="comment_block">
                            {
                                this.props.session.user ?

                                    <div className="create_new_comment">


                                        <div className="user_avatar">
                                            <img src={this.props.session.user.profile_pic==="" ? inconnu : this.props.session.user.profile_pic}/>
                                        </div>

                                        <div className="input_comment">
                                            <form><input type="text" value={this.state.comment.comment}
                                                         onChange={this.handelChange}
                                                         placeholder="Rejoindre la conversation .."/>
                                                {/*<CircularProgress size={68} />*/}
                                                <IconButton color="primary" onClick={this.addComment}
                                                            aria-label="ajouter un commentaire"
                                                            component="span">
                                                    <SendIcon/>
                                                </IconButton>
                                            </form>
                                        </div>

                                    </div>
                                    : null
                            }

                            <div className="new_comment">

                                <ul className="user_comment">
                                    {
                                        this.state.comments.map(comment => {
                                            return (
                                                <li>
                                                    <div className="user_avatar">
                                                        <img src={comment.user_pic == "" ? inconnu : comment.user_pic}/>
                                                    </div>
                                                    <div className="comment_body">
                                                        <p><span className="user">@{comment.username}</span> : {comment.comment}</p>
                                                    </div>

                                                    <div className="comment_toolbar">

                                                        <div className="comment_details">
                                                            <ul>
                                                                <li><i className="fa fa-clock-o"></i>{new Date(comment.comment_date).getHours()}:{new Date(comment.comment_date).getMinutes()}</li>
                                                                <li><i className="fa fa-calendar"></i> {new Date(comment.comment_date).getDate()}/{new Date(comment.comment_date).getMonth()+1}/{new Date(comment.comment_date).getFullYear()}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }

                                </ul>

                            </div>


                        </div>
                        <div>

                        </div>
                    </div>
                </Grid>
                <Grid item xs>

                </Grid>

            </Grid>


        );
    }
}

const mapStateToProps = state => ({session: state.session, articles: state.articles});
export default connect(mapStateToProps)(withRouter(ArticleComponent));
