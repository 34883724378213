import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: false,
        };
    };

    handleChange = (name) => {
        this.setState({
            selected: name
        })
    };

    componentDidMount() {
        const prop = this.props;
        if (prop.location) {
            prop.routes.forEach((route, key) => {
                if (prop.location.pathname.includes(route.path)){
                    this.setState({
                        selected: route.name
                    });
                }
            })
        }
    }

    render() {
        return (
            <Drawer
                variant="permanent"
                className={"drawer drawerClose"}
                classes={{paper: "drawerClose"}}
            >
                <Divider />
                {/*Todo: modify the sidebar */}
                <List>
                    {this.props.routes.filter(route => route.side === true).map((route, key) => {
                        return (
                            <NavLink
                                to={route.layout + route.path}
                                activeClassName="active"
                                key={key}
                            >
                                <ListItem
                                    button
                                    key={route.name}
                                    onClick={() => this.handleChange(route.name)}

                                >
                                    <ListItemIcon>
                                        <div>
                                            {this.state.selected === route.name ?
                                                <route.icon fill="#010329" width="35px" height="35px"/>
                                            :
                                                <route.icon fill="#FFF" width="35px" height="35px"/>
                                            }
                                            <div className="sidebar-item">
                                                <span>{route.name}</span>
                                            </div>
                                        </div>
                                    </ListItemIcon>
                                </ListItem>
                            </NavLink>
                        );
                    })}
                </List>
            </Drawer>
        );
    }
}

export default Sidebar;