import React, {Component, useEffect, useState} from "react";
import AdminFormationNav from "../Suivi/AdminFormationNav";
import {Button} from "react-bootstrap";
import {useLocation, withRouter} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {getAllClasses} from "../../../Redux/ActionCreators";


const ClassesManagement = (props) =>{
    const location = useLocation();
    const {teacher_id} = location.state;
    const dispatch = useDispatch();
    const [classes, setClasses] = useState([]);

    useEffect(()=>{
        let user = props.session.user;
        if(user !== null && user !== undefined){
            dispatch(getAllClasses(teacher_id))
                .then(data => {
                    setClasses(data.payload.classe);
                })
                .catch(err => {
                    console.log("err",err);
                });
        }

    }, []);


    // const renderClasses = (classes) => {
    //     return classes.map((classe,key) =>{
    //         const {id,name, classe_year} = classe;
    //     })
    // }

    return(
        <div>
            <div>
                <AdminFormationNav />
            </div>
            <div className="container mt-5 ">
                <div className="d-flex justify-content-between">
                    <h4>Classes</h4>

                    <Button> Ajouter une classe</Button>
                </div>
                <div>


                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({ adminFormation : state.adminFormation,session:state.session,classe:state.classe});
export default connect(mapStateToProps)(withRouter(ClassesManagement));