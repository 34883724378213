import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import LastCourses from "../../Common/Layouts/LastCourses";
import LibraryComponent from "../../Common/Layouts/LibraryComponent";
import TeacherStoreCourses from "./Layout/TeacherStoreCourses";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
    AppBar: {
        width: "56%",
        backgroundColor: "#f9f9f9",
        boxShadow: "none",
        paddingTop: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            width: "60%",
        },
        [theme.breakpoints.down('sm')]: {
            width: "80%",
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
    },
    tab: {
        padding: theme.spacing(0),
        whiteSpace: "nowrap",
        textAlign: "left",
        fontFamily: "Biko",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "20px",
        color: "rgba(112,112,112,0.259)",
        display: "block",
        textTransform: "none",
    }
}));

export default function CoursesNavbar(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="teacher-courses-zone">
            <div className="courses-tete">
                <span>Mes Cours</span>
            </div>
            <AppBar position="static" className={classes.AppBar} color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab className={classes.tab} label="Récents" {...a11yProps(0)} />
                    <Tab className={classes.tab} label="CourStore" {...a11yProps(1)} />
                    <Tab className={classes.tab} label="Bibliothèque" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} dir={theme.direction}>
                <LastCourses {...props}/>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <TeacherStoreCourses/>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
                <LibraryComponent/>
            </TabPanel>
        </div>
    );
}