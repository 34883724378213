import React, { Component } from 'react';
import { Button, Grid } from "@material-ui/core";
import {Popup} from "semantic-ui-react";

class Intensifs extends Component {

	render() {
		return (
			<div className="les-intensifs">
				<Grid container spacing={1} className="grid-style">
					<Grid item xs={4}>
						<div className="intensifs-tittle">
							<span>Les Intensifs</span>
						</div>
						<div className="intensifs-description">
							<span>Tu as une échéance importante à atteindre en peu de temps: un examen particulier, un rattrapage, un concours ? <br/><br/> Nos meilleurs enseignants ont un programme bien préparé pour toi, en mode intensif pour le réussir avec brio. </span>
						</div>

						<Popup className="dev-pop-up" position={"bottom-left"} content={
							<div className="bg-white text-dark m-4 border border-dark p-2" >
								<p>Bientôt Disponible !</p>
							</div>
						} trigger={<Button type="submit" className="acheter-intensifs" variant="contained">
							Acheter un Intensif Maintenant
						</Button>} />

					</Grid>
					<Grid item xs={8}>
						<div className="intensifs-presentation">
							<div className="offre-jour">
								<div className="offre-jour-container">
									<div className="jour">
										<span>JOUR</span>
									</div>
								</div>
								<div className="offre-jour-description">
									<span>Choisis cette offre et fais tes révisions en mode intensif, pendant 6h de temps dans la journée, avec l'enseignant de ton choix.</span>
								</div>
							</div>
							<div className="offre-weekend">
								<div className="offre-weekend-container">
									<div className="week-end">
										<span>WEEK-END</span>
									</div>
								</div>
								<div className="offre-weekend-description">
									<span>Choisis cette offre pour un objectif plus grand et prépare toi à le réaliser en optant pour un rythme intensif de 6h par jour pendant le weekend</span>
								</div>
							</div>
							<div className="offre-semaine">
								<div className="offre-semaine-container">
									<div className="semaine">
										<span>SEMAINE</span>
									</div>
								</div>
								<div className="offre-semaine-description">
									<span>Tu es en vacances ou en période de préparation d'un examen, le mode intensif pendant une semaine est l'offre qu'il te faut.</span>
								</div>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		)
	}
}

export default Intensifs;