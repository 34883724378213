import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { TextField } from '@material-ui/core';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { partial_updateAction } from "../../../../../Redux/ActionCreators";

class EditProfil extends Component {
    constructor(props){
        super(props);
        this.state = {
            accept: false,
            errorGlobal: "",
            user: this.props.session.user,
            open: this.props.open,
        }
    }

    EditModal = () => {
        this.setState({
            errorGlobal: ""
        });
        this.props.handleEditOpen();
    };

    getValidationSchema = () => {
        return Yup.object().shape({
          first_name: Yup.string().required("First Name required"),
          last_name: Yup.string().required("Last Name required")
        });
    };

    handleSubmit = (values) => {
        const data = {
            "first_name": values.first_name,
            "last_name": values.last_name,
            "username": this.state.user.username,
            "description": values.description,
        };
        return this.props
        .dispatch(partial_updateAction(data))
        .then(data => {
            if (data.payload.Success) {
                this.props.handleEditOpen();
            }
        })
        .catch(err => {
            this.setState({
                errorGlobal: err.message
            });
        });
    };

    render(){
        const { user, open } = this.state;
        return (
            <Modal
                isOpen={open}
                toggle={this.EditModal}
                centered
            >
                <ModalHeader className="Nouveau_message_cw" toggle={this.EditModal}>Edit Profile</ModalHeader>
                {this.state.errorGlobal && (
                    <div className="textErrors">{this.state.errorGlobal}</div>
                )}
                <ModalBody>
                <Formik
                    initialValues={{
                        first_name: user.first_name,
                        last_name: user.last_name,
                        description: user.description,
                    }}
                    validationSchema={this.getValidationSchema}
                    onSubmit={values => {
                        this.handleSubmit(values);
                    }}
                    >
                    {({ errors, touched, values, handleChange }) => {
                        return (
                            <Form>
                                <TextField
                                    fullWidth
                                    className={`textInput`}
                                    type="text"
                                    name="first_name"
                                    label="First Name"
                                    value={values.first_name}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                                {errors.first_name && touched.first_name ? (
                                    <div className="textErrors">{errors.first_name}</div>
                                ) : null}
                                <TextField
                                    fullWidth
                                    className={`textInput`}
                                    type="last_name"
                                    name="last_name"
                                    label="Last Name"
                                    value={values.last_name}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                                {errors.last_name && touched.last_name ? (
                                    <div className="textErrors">{errors.last_name}</div>
                                ) : null}
                                <TextField
                                    fullWidth
                                    type="description"
                                    name="description"
                                    label="Description"
                                    multiline
                                    rows={3}
                                    value={values.description}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                                <ModalFooter className="">
                                    <Button type="cancel" className="energy_btn" onClick={this.EditModal} variant="contained">Cancel</Button>
                                    <Button type="submit" className="edit_btn" variant="contained">Submit</Button>
                                </ModalFooter>
                            </Form>
                        );}}
                    </Formik>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({ session: state.session });
export default connect(mapStateToProps)(withRouter(EditProfil));