import TeacherDashboard from '../Common/Layouts/DashboardComponent';
import Teacherprofil from '../Teacher/TeacherProfil/TeacherProfil';
import PartialCalender from "./TeacherCalender/PartialCalender";

import {
    HomeIcon,
    CoursesIcon,
    CalenderIcon,
    //ResourceIcon,
    //EvaluationIcon,
    FavoriteIcon,
    //BlogIcon,
    InfoIcon,
} from "../Common/Layouts/Icones";
//import Blog from '../Common/Layouts/Blog';
import Helper from '../Common/Layouts/Help';
import TeacherCalendarComponent from "./TeacherCalender/TeacherCalendarComponent";
import TeacherCoursComponent from "./TeacherCours/TeacherCoursComponent";
import CourseDetailsComponent from "./TeacherCours/Layout/CourseDetailsComponent";
import CustomizedSteppers from "../Student/StudentCours/StudentCoursDetails";
import FavoritesTabs from '../Common/Layouts/MyFavorite';
import CoursesList from "../Common/Layouts/Shared/Chapitres/CoursesList";
import ArticleComponent from "../Common/ArticleComponent";
import NotificationsPageComponent from "../Common/NotificationsPageComponent";
import Cart from "../Student/StudentCart/Cart";
import CourseContentComponent from "./TeacherCours/Layout/CourseContentComponent";
import SettingsComponent from "../Common/Layouts/Shared/SettingsComponent";
// todo: to remove
//import StudentCourseContentComponent from "../Student/StudentCours/StudentCourseContentComponent";
import StudentCourseContentComponent from "../Student/StudentCours/StudentCourseContentComponent";
import MessagingComponent from "../Common/Messaging/MessagingComponent";


const teacherRoutes = [
    {
        side: true,
        path: "/dashboard",
        name: "Accueil",
        icon: HomeIcon,
        component: TeacherDashboard,
        layout: "/teacher"
    },
    {
        side: true,
        path: "/courses",
        name: "Cours",
        icon: CoursesIcon,
        component: TeacherCoursComponent,
        layout: "/teacher"
    },
    {
        side: true,
        path: "/calendrier",
        name: "Calendriers",
        icon: CalenderIcon,
        component: TeacherCalendarComponent,
        layout: "/teacher"
    },
    // {
    //     side: true,
    //     path: "/resource",
    //     name: "Ressources",
    //     icon: ResourceIcon,
    //     component: CoursesIcon,
    //     layout: "/teacher"
    // },
    // {
    //     side: true,
    //     path: "/evalution",
    //     name: "Evaluations",
    //     icon: EvaluationIcon,
    //     component: CoursesIcon,
    //     layout: "/teacher"
    // },
    {
        side: true,
        path: "/favorite",
        name: "Favoris",
        icon: FavoriteIcon,
        component: FavoritesTabs,
        layout: "/teacher"
    },
    // {
    //     side: true,
    //     path: "/blog",
    //     name: "Blog",
    //     icon: BlogIcon,
    //     component: Blog,
    //     layout: "/teacher"
    // },
    {
        side: true,
        path: "/help",
        name: "Help",
        icon: InfoIcon,
        component: Helper,
        layout: "/teacher"
    },
    {
        side: false,
        path: "/profile",
        name: "profile",
        icon: HomeIcon,
        component: Teacherprofil,
        layout: "/teacher"
    },
    {
        side: false,
        path: "/setting",
        name: "setting",
        icon: HomeIcon,
        component: SettingsComponent,
        layout: "/teacher"
    },
    {
        side: false,
        path: "/:id_teacher/partial-calender",
        name: "partial-calender",
        component: PartialCalender,
        layout: "/teacher"
    },
    {
        side: false,
        path: "/:id_teacher/courses/chapter/:id",
        name: "chapters",
        icon: "",
        component: CoursesList,
        layout: "/teacher",
    },
    {
        side: false,
        path: "/courses/details",
        name: "cours-details",
        component: CourseDetailsComponent,
        layout: "/teacher",
    },
    {
        side: false,
        path: "/details",
        name: "cours-details",
        component: CustomizedSteppers,
        layout: "/teacher",
    },
     {
        side: false,
        path: "/cart",
        name: "Cart",
        icon: "",
        component: Cart,
        layout: "/teacher",
    },
    {
        side: false,
        path: "/articles/:id_article",
        name: "articles",
        component: ArticleComponent,
        layout: "/teacher",
    },
    {
        side: false,
        path: "/notifications",
        name: "Notifications",

        component: NotificationsPageComponent,
        layout: "/teacher"
    },
    {
        side: false,
        path: "/course/content/:id_course",
        name: "CourseContent",
        component: CourseContentComponent,
        layout: "/teacher"
    },
    {
        side: false,
        path: "/messages/:id_user",
        name: "Message",
        component: MessagingComponent,
        layout: "/teacher"
    },
    {
        side: false,
        path: "/messages",
        name: "Message",
        component: MessagingComponent,
        layout: "/teacher"
    },
];

export default teacherRoutes;
