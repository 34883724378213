import { Grid } from '@material-ui/core';
import React from 'react';
import profPic from "../../../../../../Assets/Images/ProfsAvatars/face_co_99.png"

export function Profilprof(props) {


	return (
		<>
			<div className="prof-profil-container">
				<div className="prof-profil-tittle">
					<span>Profil de l'enseignant</span>
				</div>
				<Grid container>
					<Grid item xs={3}>
						<div className="prof-pic-container">
							<img className="prof-picture" src={profPic} alt="" />
						</div>
					</Grid>
					<Grid item xs={9}>
						<div className="prof-details">
							<span>Prénom : Bouchra<br/>Présentation :  Actuellement en école d'ingénieur, j'ai rejoins DIGITED dans le but de partager  mes connaissances expériences. Et ce, dans l'espoir d'inspirer mes jeunes sœurs et frères  à entamer des études supérieures et devenir des ingénieurs. Ce cours est une introduction à la trigonométrie il s'adresse essentiellement aux élèves de 3ème année de collège</span>
						</div>
					</Grid>
				</Grid>
			</div>
		</>
	)
}
