import React, {useState} from 'react';
import AddIcon from '@material-ui/icons/Add';
import AddCoursModal from "../../../../../Teacher/TeacherCours/Layout/CourseModal";
import Notification from "../../../../AlertComponent";
import {EventGoogleAnalytics} from "../../../../Components/GoogleAnalytics";


export function AddCoursButton (props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [notyIsOpen, setIsNotyIsOpen] = useState(false);
    const [notify, setNotify] = useState({message: '', type: '' });

    const handleOpenModal = (msg) => {
        // Event Google Analytics
        EventGoogleAnalytics("Add_course_button","Button","Add course")
        console.log(msg)
        setIsModalOpen(!isModalOpen)
        if(msg === 'success'){
          ChangeNotify()
        }
    }
    const ChangeNotify = () => {
        setNotify({message: 'Course Added Successfully', type: 'success' })
		setIsNotyIsOpen(!notyIsOpen)
	}
    return (
        <>
            <div id="Acceuil_cours_bloc" onClick={() => handleOpenModal()}>
                <div id="Accueil_cours_en_cours">
                    <div id="Histoire">
                        <AddIcon fontSize="large"/>
                    </div>
                    <div id="La_Bipolarisation">
                        <span>{props.addCourse}</span>
                    </div>
                </div>
            </div>
            {isModalOpen &&
            <AddCoursModal
                open={isModalOpen}
                handleOpenModal={(msg) => handleOpenModal(msg)}
            />
            }
            <Notification
					notify={notify}
					setNotify={ChangeNotify}
					isOpen={notyIsOpen}
				/>
        </>
    )
}
