import React, { Component } from 'react';
import {Switch, Route, Redirect, withRouter} from "react-router-dom";
import {Breakpoint} from "react-socks";
import Navbar from '../Common/Layouts/Navbar';
import Sidebar from '../Common/Layouts/Sidebar';

import StudentRoutes from "./StudentRoutes";

import {connect} from "react-redux";
import {addCourseToCart, AddToCartNoAuthentifiedAction, getCourseAchat} from "../../Redux/ActionCreators";
import Notification from "../Common/AlertComponent";
import {ContactUs} from "../Common/Layouts/Shared/ContactUs/ContactUs";

function switchRoutes (user, props) {
    return <Switch>
    {StudentRoutes.map((prop, key) => {
      if (prop.layout === "/student") {
        if (user!== undefined && user!== null && user.is_student && props.session.authenticated) {
          return (
            <Route
              exact
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        else{
          return (
            <Redirect key="to_home" from="*" to="/connexion" />
          );
        }
      }
      return null;
    })}
    <Redirect key="student_dashboard" from="/student" to="/student/dashboard" />
  </Switch>
}

class StudentDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
        notify: {message: '', type: ''},
        notyIsOpen: false,
    };
    this.user = this.props.session.user
  };
  componentDidMount() {
      return this.props
            .dispatch(getCourseAchat())
            .then(data => {
                if (data.payload) {
                    this.setState({
                        teachers: data.payload.results,//.payload.teachers,
                    })
                }
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message
                });
            });
  }

    setNotify = (message, type) => {
        this.setState({
            notify: {
                message: message,
                type: type
            },
            notyIsOpen: !this.state.notyIsOpen
        })
    }

  render() {
      const {notify,notyIsOpen} = this.state;
    return (

      <div className="grow-dashboard">
        <Navbar
          {...this.props}
        />
        <Breakpoint large up>
            <Sidebar
              routes={StudentRoutes}
              {...this.props}
            />
        </Breakpoint>
          <div className="dashboard-content">
            {switchRoutes(this.user, this.props)}
          </div>
           <Notification
                    notify={notify}
                    setNotify={this.setNotify}
                    isOpen={notyIsOpen}
                />
                          <ContactUs position={"window.location.pathname"}/>

      </div>
    );
  }
}

const mapStateToProps = state => ({ session: state.session, student: state.student, data: state.data});
export default connect(mapStateToProps)(withRouter(StudentDashboard));