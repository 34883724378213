import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';

import SearchBar from "./SearchBar";
import {Resultcourse} from "./ResultCourse";
import {CircularProgress} from "@material-ui/core";
import Notification from "../../../AlertComponent";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {ResearchParams} from "./ResearchParams";
import {
    addCourseToCart,
    addCourseToFavorite,
    addCourseToRating, AddToCartNoAuthentifiedAction,
    getAllCoursesAction,
    getCourseFromFavorite,
    getCourseToCart,
    getTeacherCoursesByFilterAction,
    getTeacherCoursesByIntituleAction
} from "../../../../../Redux/ActionCreators";
import {Breakpoint} from "react-socks";
import Pagination from "../../../../Common/Components/Pagination"


let number = 0;
const usersPerPage = 12;

class CourseContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.session.user,
            allertStatus: false,
            isLoad: false,
            teacher_courses: [],
            notify: {message: '', type: ''},
            notyIsOpen: false,
            pageNumber: 0,
            education_system: this.props.data.system_education,
            matieres: this.props.data.get_matieres,
        };
    };

    pagesVisited = (pageNumber) => {
        return pageNumber * usersPerPage;
    }

    pageCount = (courses) => {
        Math.ceil(courses.length / usersPerPage);
    }

    changePage = ({selected}) => {
        this.setState({
            pageNumber: selected
        })
    };

    handleIncrement = () => {
        number += 1
        return number
    }

    filterCourses = (courses) =>{
        const list_courses = this.props.data.course_achats;
        let l=[];
        list_courses.map((course)=>{
            l.push(course.achat_course.id);
        });
        return courses.filter((c)=>{
            return l.indexOf(c.id)===-1;
        });
    }

    // TODO: To Replace for optimisation
    componentDidMount() {
        if(this.props.student.course_cart_id){
            alert(this.props.student.cours_cart_id+"======cours_id in cart");
            console.log(" cours_cart in course cibtainer okay")
            this.AddToCart(this.props.student.cours_cart_id);
            this.props.dispatch(AddToCartNoAuthentifiedAction(null));
        }
        let data = {
            user_id: this.state.user.id
        };
        return this.props
            .dispatch(getAllCoursesAction(data))
            .then(data => {
                if (data.payload) {
                    this.setState({
                        teacher_courses: this.filterCourses(data.payload.courses),
                        isLoad: !this.state.isLoad
                    });
                }
            })
            .catch(err => {
                console.log(err.message)
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                teacher_courses: this.filterCourses(this.props.data.get_all_courses),
            });
        }
    }

    // set notification
    setNotify = (message, type) => {
        this.setState({
            notify: {
                message: message,
                type: type
            },
            notyIsOpen: !this.state.notyIsOpen
        })
    }

    SearchByIntitule = (searchText) => {
        this.setState({
            isLoad: !this.state.isLoad
        })
        console.log(searchText)
        let data = {
            search: searchText
        };
        return this.props
            .dispatch(getTeacherCoursesByIntituleAction(data))
            .then(data => {
                if (data.payload.results) {
                    this.setState({
                        teacher_courses: this.filterCourses(data.payload.results),
                        isLoad: !this.state.isLoad
                    });
                }

            })
            .catch(err => {
                console.log(err.message)
            });
    }

    FilterCourses = (data) => {
        this.setState({
            isLoad: !this.state.isLoad
        })
        console.log(data)
        return this.props
            .dispatch(getTeacherCoursesByFilterAction(data))
            .then(data => {
                if (data.payload.matieres) {
                    this.setState({
                        teacher_courses: this.filterCourses(data.payload.matieres),
                        isLoad: !this.state.isLoad
                    });
                }
            })
            .catch(err => {
                console.log(err.message)
            });
    }
    AddToCart = (id) => {
        console.log(id)
        let data = {
            cart_course: id,
            cart_user: this.state.user.id
        };
        return this.props
            .dispatch(addCourseToCart(data))
            .then(data => {
                if (data.payload.Success) {
                    this.UpdateToCart()
                    this.setNotify(data.payload.message, 'success')
                }
            })
            .catch(err => {
                console.error(err.message)
                this.setNotify("There is an error", 'error')
            });
    }

    UpdateToCart = () => {
        let data = {
            ordered_user: this.state.user.id
        };
        return this.props
            .dispatch(getCourseToCart(data))
            .then(data => {
            })
            .catch(err => {
                console.log(err.message)
            });
    }
    handleAddToFavorite = (id) => {
        let data = {
            favourite_course: id,
            favourite_user: this.state.user.id
        }
        return this.props
            .dispatch(addCourseToFavorite(data))
            .then(data => {
                if (data.payload.Success) {
                    this.GetCoursesFromFavorite()
                    this.setNotify(data.payload.message, 'success')
                }
            })
            .catch(err => {
                console.log(err.message)
            });
    }
    GetCoursesFromFavorite = () => {
        let data = {
            user_id: this.state.user.id
        };
        return this.props
            .dispatch(getCourseFromFavorite(data))
            .then(data => {
            })
            .catch(err => {
                console.log(err.message)
            });
    }
    AddToRating = (score, id) => {
        let data = {
            rating_course: id,
            rating_user: this.state.user.id,
            score: score
        }
        return this.props
            .dispatch(addCourseToRating(data))
            .then(data => {
                if (data.payload.Success) {
                    this.setNotify(data.payload.message, 'success')
                }
            })
            .catch(err => {
                console.log(err.message)
            });
    }

    getSystemEducation=(course)=>{
        const e =this.props.data.system_education.filter(system=>{return course.course_systeme===system.id});
        return e.length>0 ? e[0].system_name : "";
    }
    render() {
        const {
            notify,
            notyIsOpen,
            isLoad,
            teacher_courses,
            education_system,
            matieres,
            pageNumber,
        } = this.state;
        return (
            <>
                <div className="cour-store-research">
                    <div className="search-profs">
                        <div className="search-tittle">
                            <span>Recherchez le cours que vous souhaitez suivre</span>
                        </div>
                        <div className="course-search-bar">
                            <SearchBar
                                Default_text="Recherchez par intitulé"
                                clickHandler={(searchText) => this.SearchByIntitule(searchText)}
                                setNotify={(message, type) => this.setNotify(message, type)}
                            />
                        </div>
                    </div>
                </div>
                <div className="cours-store-grid">
                    <Breakpoint medium up>
                        <Grid container spacing={1} className="grid-style">
                            <Grid item xs={2}>

                                <ResearchParams
                                    EdducationalSystem={education_system}
                                    Subjects={matieres}
                                    clickHandler={(params) => this.FilterCourses(params)}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <div className="courses-store-container">
                                    <div className="course-container-header">
                                        <div className="found-results">
                                            <span>{teacher_courses.length}résultat(s) trouvé(s)</span>
                                        </div>
                                    </div>
                                    <div className="cours-store-grid-list">
                                        {isLoad ?
                                            <Grid container spacing={1}>
                                                {teacher_courses && teacher_courses.slice(this.pagesVisited(pageNumber), this.pagesVisited(pageNumber) + usersPerPage).map((course, key) => (
                                                    <Grid item key={key} spacing={2} xs={6} sm={3}>
                                                        <Resultcourse
                                                            course={course}
                                                            user={this.props.session.user}
                                                            system={this.getSystemEducation(course)}
                                                            increment={this.handleIncrement()}
                                                            handleAddToFavorite={(id) => this.handleAddToFavorite(id)}
                                                            clickToAddCart={(id) => this.AddToCart(id)}
                                                            clickToAddRating={(score, course_id) => this.AddToRating(score, course_id)}

                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            :
                                            <CircularProgress color="secondary" className="second-progress-bar"/>
                                        }
                                    </div>
                                    <div className="scroll-pages-options">
                                        {Pagination(this.pageCount(teacher_courses),this.changePage)}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Breakpoint>
                    <Breakpoint medium down>
                        <Grid container spacing={1} className="grid-style">
                            <Grid item xs={10}>
                                <div className="courses-store-container">
                                    <div className="course-container-header">
                                        <div className="found-results">
                                            <span>{teacher_courses.length} résultat(s) trouvé(s)</span>
                                        </div>
                                    </div>
                                    <div className="cours-store-grid-list">
                                        {isLoad ?
                                            <Grid container spacing={1}>
                                                {teacher_courses && teacher_courses.slice(this.pagesVisited(pageNumber), this.pagesVisited(pageNumber) + usersPerPage).map((course, key) => (
                                                    <Grid item key={key} spacing={2} xs={12} sm={6}>
                                                        <Resultcourse
                                                            course={course}
                                                            user={this.props.session.user}
                                                            system={this.getSystemEducation(course)}
                                                            increment={this.handleIncrement()}
                                                            handleAddToFavorite={(id) => this.handleAddToFavorite(id)}
                                                            clickToAddCart={(id) => this.AddToCart(id)}
                                                            clickToAddRating={(score, course_id) => this.AddToRating(score, course_id)}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            :
                                            <CircularProgress color="secondary" className="second-progress-bar"/>
                                        }
                                    </div>
                                    <div className="scroll-pages-options">
                                       {Pagination(this.pageCount(teacher_courses),this.changePage)}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Breakpoint>
                </div>
                <Notification
                    notify={notify}
                    setNotify={this.setNotify}
                    isOpen={notyIsOpen}
                />
            </>
        );
    }
}

const mapStateToProps = state => ({session: state.session, data: state.data, student: state.student});
export default connect(mapStateToProps)(withRouter(CourseContainer));