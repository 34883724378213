import {createAction} from "redux-actions";
import * as types from './ActionTypes';
import {
    student_register,
    login,
    logout,
    teacher_candidature,
    partial_update,
    teacher_get_calender,
    teacher_add_calender,
    teacher_delete_calender,
    account_activation,
    get_teachers,
    student_add_reservation,
    get_education_systems,
    get_matieres,
    add_course,
    get_all_teachers,
    get_notifications,
    get_examens_blog,
    get_formations,
    get_articles,
    partial_update_teacher,
    get_techer_courses,
    get_avatars,
    partial_update_student,
    notification_read,
    get_all_courses,
    get_teacher_courses_by_intitule,
    get_Filter_teacher_courses,
    get_all_courses_by_module,
    get_all_courses_home,
    add_course_to_cart,
    get_courses_to_cart,
    delete_courses_from_cart,
    add_course_to_achat,
    get_teacher_seances,
    add_teacher_seance,
    delete_teacher_seance,
    update_seance,
    add_course_continue,
    get_teachers_by_description,
    get_teacher_by_filters,
    add_course_part,
    add_course_quiz,
    search_all,
    get_all_teachers_store,
    get_courses_by_matiere,
    add_course_to_favorite,
    get_courses_from_favorite,
    delete_courses_from_favorite,
    add_article_comment,
    get_article_comment,
    get_course_parts,
    get_course_quizzes,
    add_course_picture,
    reset_password,
    reset_password_edite,
    update_course_part,
    update_course_quiz,
    delete_course,
    delete_course_part,
    delete_course_quiz,
    get_course_advancement,
    update_course_advancement,
    search_user,
    send_message_user,
    read_message,
    get_messages_user,
    add_course_to_rating,
    delete_teachers_from_favorite,
    add_teacher_to_favorite,
    get_teachers_from_favorite,
    get_course_achat,
    get_all_students,
    get_achat_detail,
    get_advancement,
    get_suivi,
    get_student_by_id, get_all_classes, add_classe,
} from "../Services/Api";


// Common
export const loginAction = createAction(types.LOGIN, async obj => {
    return await login(obj);
});

export const ResetPwdAction = createAction(types.RESETPASSWORD, async obj => {
    return await reset_password(obj);
});

export const ResetPwdEditeAction = createAction(types.RESETPASSWORDEDITE, async obj => {
    return await reset_password_edite(obj);
});

export const partial_updateAction = createAction(types.PARTIALUPDATE, async obj => {
    return await partial_update(obj);
});

export const logoutAction = createAction(types.LOGOUT, async () => {
    return await logout();
});

export const accountActivationAction = createAction(types.ACTIVATION, async obj => {
    return await account_activation(obj);
});

export const getTeachersAction = createAction(types.GETTEACHERS, async obj => {
    return await get_teachers(obj);
});

// Student
export const studentRegisterAction = createAction(types.STUDENTREGISTER, async obj => {
    return await student_register(obj);
});

export const studentReservationAction = createAction(types.STUDENTADDRESERVATION, async obj => {
    return await student_add_reservation(obj);
});
export const partial_update_studentAction = createAction(types.PARTIALUPDATESTUDENT, async obj => {
    return await partial_update_student(obj);
});

// Teacher
export const teacherCandidatureAction = createAction(types.TEACHERCONDIDATURE, async obj => {
    return await teacher_candidature(obj);
});

export const teacherGetCalendarAction = createAction(types.PARTIALCALENDARGET, async obj => {
    return await teacher_get_calender(obj);
});

export const teacherAddCalendarAction = createAction(types.PARTIALCALENDARADD, async obj => {
    return await teacher_add_calender(obj);
});

export const teacherDeleteCalendarAction = createAction(types.PARTIALCALENDARDELETE, async obj => {
    return await teacher_delete_calender(obj);
});

export const addCourseAction = createAction(types.ADDCOURSE, async obj => {
    return await add_course(obj);
});
export const addCoursePictureAction = createAction(types.ADDCOURSEPICTURE, async obj => {
    return await add_course_picture(obj);
});

export const addCourseContinueAction = createAction(types.ADDCOURSECONTINUE, async obj => {
    return await add_course_continue(obj);
});

export const getTeacherCoursesAction = createAction(types.TEACERCOURSES, async obj => {
    return await get_techer_courses(obj);
});

// Seances
export const getTeacherSeancesAction = createAction(types.TEACERSEANCES, async obj => {
    return await get_teacher_seances(obj);
});

export const addTeacherSeanceAction = createAction(types.ADDTEACERSEANCE, async obj => {
    return await add_teacher_seance(obj);
});

export const deleteTeacherSeanceAction = createAction(types.DELETETEACERSEANCE, async obj => {
    return await delete_teacher_seance(obj);
});

export const updateSeanceAction = createAction(types.UPDATESEANCE, async obj => {
    return await update_seance(obj);
});

export const getTeacherCoursesByIntituleAction = createAction(types.GETMATIERESBYINTITULE, async obj => {
    return await get_teacher_courses_by_intitule(obj);
});

export const getAllTeachersStoreAction = createAction(types.GETALLTEACHERSSTORE, async obj => {
    return await get_all_teachers_store(obj);
});

export const getTeachersByDescriptionAction = createAction(types.GETTEACHERSBYDESCRRIPTION, async obj => {
    return await get_teachers_by_description(obj);
});

export const addCourseToCart = createAction(types.ADDCOURSESTOCART, async obj => {
    return await add_course_to_cart(obj);
});

export const addCourseToFavorite = createAction(types.ADDCOURSESTOFAVORITE, async obj => {
    return await add_course_to_favorite(obj);
});

export const addTeacherToFavorite = createAction(types.ADDTEACHERSTOFAVORITE, async obj => {
    return await add_teacher_to_favorite(obj);
});

export const getTeacherFromFavorite = createAction(types.GETTEACHERSFROMFAVORITE, async obj => {
    return await get_teachers_from_favorite(obj);
});

export const deleteTeacherFromFavorite = createAction(types.DELETETEACHERSFROMFAVORITE, async obj => {
    return await delete_teachers_from_favorite(obj);
});

export const addCourseToRating = createAction(types.ADDCOURSESTORATING, async obj => {
    return await add_course_to_rating(obj);
});

export const getCourseFromFavorite = createAction(types.GETCOURSESFROMFAVORITE, async obj => {
    return await get_courses_from_favorite(obj);
});

export const deleteCourseFromFavorite = createAction(types.DELETECOURSESFROMFAVORITE, async obj => {
    return await delete_courses_from_favorite(obj);
});

export const addCourseToAchat= createAction(types.ADDCOURSESTOACHAT, async obj => {
    return await add_course_to_achat(obj);
});

export const getCourseAchat= createAction(types.GETCOURSESACHAT, async obj => {
    return await get_course_achat(obj);
});

export const getCourseToCart = createAction(types.GETCOURSESTOCART, async obj => {
    return await get_courses_to_cart(obj);
});



export const deleteCourseFromCart = createAction(types.DELETECOURSESFROMCART, async obj => {
    return await delete_courses_from_cart(obj);
});

export const getAllCoursesByModuleAction = createAction(types.GETALLCOURSESBYMODULE, async obj => {
    return await get_all_courses_by_module(obj);
});

export const getTeacherCoursesByFilterAction = createAction(types.GETMATIERESBYFILTER, async obj => {
    return await get_Filter_teacher_courses(obj);
});

export const getAllCoursesAction = createAction(types.GETALLCOURSES, async obj => {
    return await get_all_courses(obj);
});

export const getSystemEducation = createAction(types.GETSYSTEMEDUCATION, async obj => {
    return await get_education_systems();
});

export const getMatieres = createAction(types.GETMATIERES, async obj => {
    return await get_matieres();
});

export const getAllTeachersAction = createAction(types.GETALLTEACHERS, async obj => {
    return await get_all_teachers(obj);
});

export const reservationTeachersAction = createAction(types.RESERVATIONTEACHERS, async obj => {
    return await obj;
});

export const AddToCartNoAuthentifiedAction = createAction(types.ADDTOCARTNOAUTHENTIFIED, async obj => {
    return await obj;
});

export const getAvatars = createAction(types.GETAVATARS, async obj => {
    return await get_avatars(obj);
});

export const getArticles = createAction(types.GETARTICLES, async obj => {
    return await get_articles(obj);
});

export const getExamsBlog = createAction(types.GETEXAMSBLOG, async obj => {
    return await get_examens_blog(obj);
});

export const getFormations = createAction(types.GETFORMATIONS, async obj => {
    return await get_formations(obj);
});

export const getNotifications = createAction(types.GETNOTIFICATIONS, async obj => {
    return await get_notifications(obj);
});

export const partial_update_teacherAction = createAction(types.PARTIALUPDATETEACHER, async obj => {
    return await partial_update_teacher(obj);
});
export const Read_Notification = createAction(types.READNOTIFICATION, async obj => {
    return await notification_read(obj);
});
export const getTeachersByFilterAction = createAction(types.GETTEACHERSBYFILTER, async obj => {
    return await get_teacher_by_filters(obj);
});
export const getCoursesByMatiere = createAction(types.GETCOURSEBYMARIERE, async obj => {
    return await get_courses_by_matiere(obj);
});
export const addCoursePart = createAction(types.ADDCOURSEPART, async obj => {
    return await add_course_part(obj);
});
export const addCourseQuize = createAction(types.ADDCOURSEQUIZ, async obj => {
    return await add_course_quiz(obj);
});
export const searchAll = createAction(types.SEARCHALL, async obj => {
    return await search_all(obj);
});
export const addArticleComment = createAction(types.ADDARTICLECOMMENT, async obj => {
    return await add_article_comment(obj);
});
export const getArticleComment = createAction(types.GETARTICLECOMMENT, async obj => {
    return await get_article_comment(obj);
});
export const getCourseParts = createAction(types.GETCOURSEPARTS, async obj => {
    return await get_course_parts(obj);
});
export const updateCoursePart = createAction(types.UPDATECOURSEPART, async obj => {
    return await update_course_part(obj);
});
export const getCourseQuizzes = createAction(types.GETCOURSEQUIZZES, async obj => {
    return await get_course_quizzes(obj);
});
export const updateCourseQuiz = createAction(types.UPDATECOURSEQUIZ, async obj => {
    return await update_course_quiz(obj);
});
export const DeleteCourse = createAction(types.DELETECOURSE, async obj => {
    return await delete_course(obj);
});
export const DeleteCoursePart = createAction(types.DELETECOURSEPART, async obj => {
    return await delete_course_part(obj);
});
export const DeleteCourseQuiz = createAction(types.DELETECOURSEQUIZ, async obj => {
    return await delete_course_quiz(obj);
});
export const getCourseAdvancement = createAction(types.GETCOURSEADVANCEMENT, async obj => {
    return await get_course_advancement(obj);
});
export const updateCourseAdvancement = createAction(types.UPDATECOURSEADVANCEMENT, async obj => {
    return await update_course_advancement(obj);
});
export const searchUser = createAction(types.SEARCHUSER, async obj => {
    return await search_user(obj);
});
export const getMessagesUser = createAction(types.GETMESSAGESUSERS, async obj => {
    return await get_messages_user(obj);
});
export const sendMessageUser = createAction(types.SENDMESSAGEUSERS, async obj => {
    return await send_message_user(obj);
});
export const readMessages = createAction(types.READMESSAGES, async obj => {
    return await read_message(obj);
});
export const getAllStudents = createAction(types.GETALLSTUDENTS , async obj => {
    return await get_all_students(obj);
});
export const getAchatDetail = createAction(types.GETACHATDETAIL, async obj => {
    return await get_achat_detail(obj);
});
export const getStudentAdvancement = createAction(types.GETACHATDETAIL, async obj => {
    return await get_advancement(obj);
});
export const getSuivi = createAction(types.GETSUIVI, async obj =>{
    return await get_suivi(obj);
});
export const getStudentById = createAction(types.GETSTUDENTBYID, async obj => {
    return await get_student_by_id(obj);
});
export const getAllClasses = createAction(types.GETALLCLASSES,async obj=>{
    return await get_all_classes(obj);
});
export const addClasse = createAction(types.ADDCLASSE, async obj =>{
    return await add_classe(obj);
});
