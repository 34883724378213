import React, {Component} from 'react'

import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Notification from "../../AlertComponent";
import {partial_update_teacherAction} from "../../../../Redux/ActionCreators";
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


class SettingsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.session.user,
            errorGlobal: "",
            notify: {message: '', type: ''},
            notyIsOpen: false,
            confirmPassword: "",
            newPassword: "",
            oldPassword: "",
            value: 0,
        }
    }

    handleChange = (event, newValue) => {
        this.setState({
            value:newValue
        })
    };


    setNotify = (message, type) => {
        this.setState({
            notify: {
                message: message,
                type: type
            },
            notyIsOpen: !this.state.notyIsOpen
        })
    }

    // submit updated information's teacher and student
    handleSubmit = () => {
        const dataTeacher = {
            "user_id": this.state.user.id
        };
        return this.props
            .dispatch(partial_update_teacherAction(dataTeacher))
            .then(data => {
                if (data.payload.Success) {
                    this.setNotify(data.payload.message, 'success')
                }
            })
            .catch(err => {
                this.setNotify("Erreur est survenue: "+err, 'error')
            });
    }

    render() {
        const {value, confirmPassword, newPassword, oldPassword} = this.state;
        return (
            <div className="setting-section">
                <div className="setting-entete">
                    <span>Settings</span>
                </div>

                <div className="setting">
                      <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={this.handleChange}
                        aria-label="Vertical tabs example"
                      >
                        <Tab label="Account security" {...a11yProps(0)} />
                        <Tab label="Email" {...a11yProps(1)} />
                        <Tab label="Preference" {...a11yProps(2)} />
                        <Tab label="Item Four" {...a11yProps(3)} />
                        <Tab label="Item Five" {...a11yProps(4)} />
                      </Tabs>

                      <TabPanel className="setting-panel" value={value} index={0}>
                          <h2>Change password</h2>
                          <form className="setting-form" noValidate autoComplete="off">
                              <TextField
                                  className="outlined-basic"
                                  label="Old password"
                                  variant="outlined"
                                  fullWidth={true}
                                  size="small"
                                  value={oldPassword}
                                  onChange={this.handleChangeFirstname}
                              />
                              <TextField
                                  className="outlined-basic"
                                  label="New password"
                                  variant="outlined"
                                  fullWidth={true}
                                  size="small"
                                  value={newPassword}
                                  onChange={this.handleChangeFirstname}
                              />
                              <TextField
                                  className="outlined-basic"
                                  label="Confirm password"
                                  variant="outlined"
                                  fullWidth={true}
                                  size="small"
                                  value={confirmPassword}
                                  onChange={this.handleChangeLastname}
                              />
                              <Button type="submit" className="edit_btn" variant="contained">Modifier le profil</Button>
                          </form>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        Item Two
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        Item Three
                      </TabPanel>
                      <TabPanel value={value} index={3}>
                        Item Four
                      </TabPanel>
                      <TabPanel value={value} index={4}>
                        Item Five
                      </TabPanel>
                      <TabPanel value={value} index={5}>
                        Item Six
                      </TabPanel>
                      <TabPanel value={value} index={6}>
                        Item Seven
                      </TabPanel>
                </div><div>
                              {/*todo: add title here*/}
                          </div>

                <Notification
                    notify={this.state.notify}
                    setNotify={this.setNotify}
                    isOpen={this.state.notyIsOpen}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({session: state.session, data: state.data});
export default connect(mapStateToProps)(withRouter(SettingsComponent));
