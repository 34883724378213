import React from 'react'
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Component } from 'react';

export default class Notification extends Component {

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.setNotify()
    }

    render (){
        const {notify, isOpen} =this.props;
        return (
            <Snackbar
                style={{paddingTop: "20px"}}
                open={isOpen}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={this.handleClose}>
                <Alert
                    severity={notify.type}
                    onClose={this.handleClose}>
                    {notify.message}
                </Alert>
            </Snackbar>
        )
    }
}