import React, {Component} from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Editor} from "react-draft-wysiwyg";
import {EditorState} from "draft-js";
import {convertToHTML} from 'draft-convert';
import {
    CircularProgress,
    FormControlLabel,
    Radio,
    RadioGroup,
    Select,
    TextField
} from "@material-ui/core";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import {Form, Formik} from "formik";
import {addCoursePart, addCourseQuize} from "../../../../Redux/ActionCreators";
import Notification from "../../../Common/AlertComponent";


class CourseDetailsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            activeStep: 0,
            numberStep: 2,
            quizzes: [{
                title: "",
                order: 0,
                course: "",
                questions: [{question: "", options: [], answer: "",},],
            }],
            partes: [{
                title: "",
                order: 0,
                course: "",
                part_video: null,
                part_pdf: null,
                part_content: null
            },],
            typePart: "part",
            addPart: false,
            notyIsOpen: false,
            notify: {message: '', type: ''},
        }
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        if ((this.props.location.state === undefined || this.props.location.state.course === undefined)) {
            let path = '/teacher/';
            this.props.history.push(path);
        }
    }

    TextEditor(props) {
        const onEditorStateChange = (editorState) => {
            let partes = this.state.partes;
            partes[partes.length - 1].part_content = convertToHTML(editorState.getCurrentContent());
            this.setState({
                editorState: editorState,
                partes: partes,
            });
        };

        return (
            <div
                style={{backgroundColor: "white", position: "relative", height: "12em"}}
            >
                <Editor
                    className="editor-bloc"
                    editorState={this.state.editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                />
            </div>
        );
    }

    VideoInsertion(props) {

        return (
            <div
                style={{backgroundColor: "white", height: "12em"}}
            >

                <div style={{textAlign: "center", fontSize: "28px", paddingTop: "80px"}}>
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Upload File
                        <input
                            name="part_video"
                            accept="video/*"
                            type="file"
                            hidden
                        />
                    </Button>
                </div>
            </div>
        );
    }

    // PdfInsertion(props) {
    //     return (
    //         <div
    //             style={{backgroundColor: "white", height: "12em"}}
    //         >
    //
    //             <div style={{textAlign: "center", fontSize: "28px", paddingTop: "80px"}}>
    //                 <Button
    //                     variant="contained"
    //                     component="label"
    //                 >
    //                     Upload PDF
    //                     <input
    //                         name="part_pdf"
    //                         accept="application/pdf"
    //                         type="file"
    //                         // onChange={handleChange}
    //                         hidden
    //                     />
    //                 </Button>
    //             </div>
    //         </div>
    //     );
    // }

    Quize(props) {
        const removeOption = (i, j) => {
            const quizzes = this.state.quizzes;
            quizzes[quizzes.length - 1].questions[i].options.splice(j, 1);
            this.setState({
                quizzes: quizzes,
            });
        }
        const addOption = (e, i) => {
            const quizzes = this.state.quizzes;
            const value = e.target.value;
            if (e.key === "Enter" && value) {
                // check duplicate skill
                if (e.key === "Enter" && value) {
                    // check if duplicate skill
                    if (quizzes[quizzes.length - 1].questions[i].options.find(skill => skill.option.toLowerCase() === value.toLowerCase())) {
                        return this.setState({
                            alert: "No duplicate value allowed",
                            show: true
                        });
                    }
                    // else add skill to skills array
                    quizzes[quizzes.length - 1].questions[i].options.push({
                        option: value,
                    })
                    // set new state
                    this.setState({
                        quizzes: quizzes,
                    })
                    this.inputRef.current.value = null
                } else if (e.key === "Backspace" && !value) {
                    removeOption(i, quizzes[quizzes.length - 1].questions[i].options.length - 1)
                }
            }

        }

        const removeQuestion = i => {
            const quizzes = this.state.quizzes;
            quizzes[quizzes.length - 1].questions.splice(i, 1);
            this.setState({
                quizzes: quizzes
            });
        }
        const handleAddQuestion = () => {
            const quizzes = this.state.quizzes;
            quizzes[quizzes.length - 1].questions.push({
                question: "",
                answer: "",
                options: [],
            },)
            this.setState({
                quizzes: quizzes,
            })
        }
        const handelChangeQuestion = (e, i) => {
            let quizzes = this.state.quizzes;
            quizzes[quizzes.length - 1].questions[i].question = e.target.value;
        }
        const handelChangeAnswer = (e, i) => {
            let quizzes = this.state.quizzes;
            quizzes[quizzes.length - 1].questions[i].answer = e.target.value;
        }
        const handelChangeTitleQuize = (e, i) => {
            let quizzes = this.state.quizzes;
            quizzes[quizzes.length - 1].title = e.target.value;
        }

        return (
            <div
                style={{backgroundColor: "white", margin: "20px"}}
                className={""}
            >

                <h1>create quize</h1>
                <p>Quiz 1:</p>
                <TextField id="outlined-basic" onChange={(e) => handelChangeTitleQuize(e)} className="title_input"
                           label={"Titre "}/>

                {this.state.quizzes[this.state.quizzes.length - 1].questions.map((question, i) => {
                    return (

                        <div className="quize_content">
                            <TextField id="outlined-basic" onChange={(e) => handelChangeQuestion(e, i)}
                                       className="question_input" label={"Question " + (i + 1)}/>
                            <div className="Quize">
                                <ul>
                                    {question.options && question.options.map((option, j) => {
                                        return (
                                            <li key={i}> {option.option}
                                                <button onClick={() => removeOption(i, j)}>+</button>
                                            </li>
                                        )
                                    })}
                                    <li className="input-Quize">
                                        <input onKeyDown={(e) => {
                                            addOption(e, i)
                                        }} type="text" size="4" ref={this.inputRef}
                                               placeholder={"Ajouter un option"}/>
                                    </li>
                                </ul>

                            </div>
                            <Select
                                className="correct_answer"
                                native

                                // value={state.age}
                                onChange={(e) => handelChangeAnswer(e, i)}


                            >
                                <option style={{padding: "12px",}} aria-label="None" value="" disabled selected>Choisir
                                    la
                                    réponse
                                </option>

                                {question && question.options.map((option) => {
                                    return (
                                        <option style={{margin: "12px",}} value={option.option}>{option.option}</option>

                                    )
                                })}

                            </Select>
                            <Button
                                variant="contained"
                                color="primary"
                                className="delete_question"
                                startIcon={<DeleteIcon/>}
                                onClick={this.state.quizzes[this.state.quizzes.length - 1].questions.length === 1 ? null : () => {
                                    removeQuestion(i)
                                }}
                            >
                                Delete
                            </Button>
                        </div>
                    );
                })
                }
                <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    className="add_questions"
                    onClick={handleAddQuestion}
                >
                    Ajouter une question
                </Button>

            </div>

        );
    }

    getSteps() {
        let setps = this.state.numberStep;
        let l = [];
        for (let i = 0; i < setps; i++) {
            l.push("partie " + (i + 1));
        }
        return l;
    }

    // getStepContent(step) {
    //     switch (step) {
    //         case 0:
    //             return this.TextEditor(step);
    //         case 1:
    //             return this.TextEditor(step);
    //         case 2:
    //             return this.VideoInsertion(step);
    //         case 3:
    //             return this.VideoInsertion(step);
    //         case 4:
    //             return this.VideoInsertion(step);
    //         case 5:
    //             return this.VideoInsertion(step);
    //         default:
    //             return this.VideoInsertion(step);
    //     }
    // }

    handleNext = () => {
        // if(this.state.typePart==="part"){
        //     this.setState({
        //         partes
        //     })
        // }

        let n = this.state.numberStep;
        if ((this.state.activeStep + 1) === n) {
            this.setState({
                numberStep: n + 1,
            });
        }
        this.setState({
            activeStep: this.state.activeStep + 1,
        })
    }
    ;

    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1,
        })
    }
    ;

    handleReset = () => {
        this.setState({
            activeStep: 0,
        })
    }
    ;
    handleTypeChange = (e) => {
        this.setState({
            typePart: e.target.value,
        });
    }
    setNotify = (message, type) => {
        this.setState({
            notify: {
                message: message,
                type: type
            },
            notyIsOpen: !this.state.notyIsOpen
        })
    }
    handleSubmitPart = (values) => {
        let index = this.state.partes.length - 1;
        let formData = new FormData();
        formData.append("title", this.state.partes[index].title);
        formData.append("order", this.state.activeStep);
        formData.append("course", this.props.location.state.course.id);
        // formData.append("video", "first part");
        if (this.state.partes[index].part_video) {
            formData.append("video", this.state.partes[index].part_video);
        }
        if (this.state.partes[index].part_pdf) {
            formData.append("support", this.state.partes[index].part_pdf);
        }
        if (this.state.partes[index].part_content) {
            formData.append("partContent", this.state.partes[index].part_content);
        }
        this.setState({
            addPart: true
        })
        return this.props
            .dispatch(addCoursePart(formData))
            .then(data => {
                this.setState({
                    addPart: false
                });
                this.setNotify(data.payload.message, 'success');
                this.handleNext();

            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message,
                    addPart: false
                });
                this.setNotify("l'opération a echoué", 'error');
            });
    }
    handleSubmitQuiz = (values) => {
        let quize = this.state.quizzes[this.state.quizzes.length - 1];
        quize.course = this.props.location.state.course.id;
        quize.order = this.state.activeStep;
        this.setState({
            addPart: false
        });

        return this.props
            .dispatch(addCourseQuize(quize))
            .then(data => {
                // this.props.handleOpenModal("success");
                // setTimeout(2000);
                this.setState({
                    addPart: true
                });
                // let path = '/teacher/courses/'+courseId +'/details';
                // this.props.history.push(path)
                this.handleNext();
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message
                });
            });
    }

    handelChangeVideo = (event) => {
        let partes = this.state.partes;
        partes[partes.length - 1].part_video = event.currentTarget.files[0];

        this.setState({partes: partes});
        console.log(this.state.partes);
    }
    handelChangePdf = (event) => {
        let partes = this.state.partes;
        partes[partes.length - 1].part_pdf = event.currentTarget.files[0];

        this.setState({partes: partes});
    }

    onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    handelChangeTitlePart = (e, i) => {
        let partes = this.state.partes;
        partes[partes.length - 1].title = e.target.value;
        this.setState({partes: partes});
    }

    render() {
        console.log(this.props);
        return (
            <div className="cours_details_container">
                {this.props.location.state ?

                    <div>
                        <div id="Mathmatiques_xw">
                            <span>{this.props.location.state.course.course_matiere.matiere_name}</span>
                        </div>
                        <div id="Les_Intgrales_xw">
                            <span>{this.props.location.state.course.course_module} :{this.props.location.state.course.course_intitule}</span>
                        </div>
                    </div>
                    : null
                }
                <Stepper alternativeLabel activeStep={this.state.activeStep} className="stepper">
                    {/*<Stepper alternativeLabel activeStep={this.state.activeStep} connector={<QontoConnector/>}>*/}
                    {this.getSteps().map((label) => (
                        <Step key={label}>
                            {/*<StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>*/}
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>


                <div className="background">


                    <RadioGroup aria-label="gender" name="gender1" row={true}
                                onChange={this.handleTypeChange}>
                        <FormControlLabel value="part" control={<Radio/>} checked={this.state.typePart === "part"}
                                          label="Partie de cours"/>
                        <FormControlLabel value="quiz" control={<Radio/>} checked={this.state.typePart === "quiz"}
                                          disabled={this.state.activeStep === 0} label="Quize"/>
                    </RadioGroup>
                    {this.state.activeStep === this.getSteps().length ? (
                        <div>
                            <Typography className="instructions">
                                All completed - you&apos;re finished
                            </Typography>
                            <Button onClick={this.handleReset} className="button">
                                Réinitialiser
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <div>
                                {
                                    this.state.typePart === "part" ?
                                        <Formik key="1"
                                                initialValues={{
                                                    part_pdf: null,
                                                    part_video: null,
                                                    // content: this.state.editorState,
                                                }}
                                                onSubmit={values => {
                                                    this.handleSubmitPart(values);
                                                }}
                                        >
                                            {({errors, touched, values, handleChange}) => {
                                                return (
                                                    <Form>
                                                        <>Partie {this.state.activeStep + 1}
                                                            <TextField id="outlined-basic"
                                                                       onChange={(e) => this.handelChangeTitlePart(e)}
                                                                       className="title_input" label={"Titre "}/>

                                                            <h1>Contenu video </h1>
                                                            <div
                                                                style={{backgroundColor: "white", height: "12em"}}
                                                            >

                                                                <div style={{
                                                                    textAlign: "center",
                                                                    fontSize: "28px",
                                                                    paddingTop: "80px"
                                                                }}>
                                                                    <Button
                                                                        variant="contained"
                                                                        component="label"
                                                                        disabled={this.state.part_pdf}
                                                                    >
                                                                        {
                                                                            this.state.partes[this.state.partes.length - 1].part_video ?
                                                                                this.state.partes[this.state.partes.length - 1].part_video.name
                                                                                :
                                                                                "Upload Video"
                                                                        }
                                                                        <input
                                                                            name="part_video"
                                                                            accept="video/*"
                                                                            type="file"
                                                                            onChange={this.handelChangeVideo}
                                                                            hidden
                                                                        />
                                                                    </Button>
                                                                </div>
                                                                <div style={{fontSize: "12px", textAlign: "center"}}>
                                                                    la taille de video doit etre inferieur à 100 Mo
                                                                </div>
                                                            </div>
                                                            <h1>Contenu Text </h1>
                                                            <div className="editorContainer">
                                                                {this.TextEditor()}
                                                            </div>
                                                            <h1>Contenu PDF </h1>
                                                            <div
                                                                style={{backgroundColor: "white", height: "12em"}}
                                                            >

                                                                <div style={{
                                                                    textAlign: "center",
                                                                    fontSize: "28px",
                                                                    paddingTop: "80px"
                                                                }}>
                                                                    <Button
                                                                        variant="contained"
                                                                        component="label"
                                                                    >
                                                                        {
                                                                            this.state.partes[this.state.partes.length - 1].part_pdf ?
                                                                                this.state.partes[this.state.partes.length - 1].part_pdf.name
                                                                                :
                                                                                "Upload document PDF"
                                                                        }
                                                                        <input
                                                                            name="part_pdf"
                                                                            accept="application/pdf"
                                                                            type="file"
                                                                            onChange={this.handelChangePdf}

                                                                            hidden
                                                                        />
                                                                    </Button>
                                                                </div>
                                                            </div>

                                                        </>
                                                        <div style={{position: "relative"}}>
                                                            <Button disabled={this.state.activeStep === 0}
                                                                    onClick={this.handleBack} className="button">
                                                                Précedant
                                                            </Button>
                                                            <Button
                                                                style={{width: "140px"}}
                                                                variant="contained"
                                                                color="primary"
                                                                className="button"
                                                                type="submit"
                                                                disabled={this.state.addPart}
                                                            >

                                                                {
                                                                    this.state.addPart ?
                                                                        <CircularProgress color="secondary" size={24}/>
                                                                        : this.state.activeStep === 0 ? 'Suivant' : 'Ajouter une nouvelle partie'}

                                                            </Button>

                                                        </div>
                                                    </Form>
                                                );
                                            }}

                                        </Formik>
                                        : <>
                                            <Formik key="2"
                                                    initialValues={this.state.quizzes[this.state.quizzes.length - 1]}
                                                    onSubmit={values => {
                                                        this.handleSubmitQuiz(values);
                                                    }}
                                            >
                                                {({errors, touched, values, handleChange}) => {
                                                    return (
                                                        <Form onKeyDown={this.onKeyDown}>


                                                            {this.Quize()}
                                                            <div style={{position: "relative"}}>
                                                                <Button disabled={this.state.activeStep === 0}
                                                                        onClick={this.handleBack} className="button">
                                                                    Précedant
                                                                </Button>
                                                                <Button
                                                                    style={{width: "140px"}}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className="button"
                                                                    type="submit"
                                                                    disabled={this.state.addPart}
                                                                >

                                                                    {
                                                                        !this.state.addPart ?
                                                                            <CircularProgress color="secondary" size={24}/>
                                                                            : this.state.activeStep === 0 ? 'Suivant' : 'Ajouter une nouvelle partie'}
                                                                </Button>
                                                                {/*</Button>*/}
                                                                {/*    */}
                                                                {/*    <Button*/}
                                                                {/*        variant="contained"*/}
                                                                {/*        color="primary"*/}
                                                                {/*        // onClick={this.handleNext}*/}
                                                                {/*        className="button"*/}
                                                                {/*        type="submit"*/}
                                                                {/*    >*/}
                                                                {/*        /!*{this.state.activeStep === this.getSteps().length - 1 ? 'Terminer' : 'Suivant'}*!/*/}
                                                                {/*        {this.state.activeStep === 0 ? 'Suivant' : 'Ajouter une nouvelle partie'}*/}
                                                                {/*        /!*Ajouter une nouvelle partie*!/*/}
                                                                {/*    </Button>*/}
                                                            </div>

                                                        </Form>
                                                    );
                                                }}

                                            </Formik>
                                        </>
                                }
                            </div>
                        </div>
                    )}
                </div>
                <Notification
                    notify={this.state.notify}
                    setNotify={this.setNotify}
                    isOpen={this.state.notyIsOpen}
                />
            </div>);
    }
}

const mapStateToProps = state => (
    {
        session: state.session
    }
);
export default connect(mapStateToProps)(withRouter(CourseDetailsComponent));