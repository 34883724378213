import React, { Component } from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button} from "@material-ui/core";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class CustomizedSteppers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
            editorState: EditorState.createEmpty(),
        };
    };

    handleSelectedTab = (tab) => {
        this.setState({
            selectedTab: tab
        })
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState: editorState,
        });
    };

    render() {
        const { editorState } = this.state;
        console.log(editorState)
    	return (
            <>
                <div>
                    <div id="Mathmatiques_xw">
                        <span>Mathématiques</span>
                    </div>
                    <div id="Les_Intgrales_xw">
                        <span>Les Intégrales</span>
                    </div>
                </div>

                {/*Tabs navs*/}
                    <ul className="nav nav-tabs nav-fill mb-3" id="ex1" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a
                          className={`nav-link Annexes_xv ${this.state.selectedTab === 0 ? 'active' : ''}`}
                          data-mdb-toggle="tab"
                          href="#Cours"
                          role="tab"
                          aria-controls="Cours"
                          aria-selected="true"
                          onClick={() => this.handleSelectedTab(0)}
                        >
                            Cours
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className={`nav-link Annexes_xv ${this.state.selectedTab === 1 ? 'active' : ''}`}
                          onClick={() => this.handleSelectedTab(1)}
                          data-mdb-toggle="tab"
                          href="#Vidéo"
                          role="tab"
                          aria-selected="false"
                        >Vidéo</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className={`nav-link Annexes_xv ${this.state.selectedTab === 2 ? 'active' : ''}`}
                          onClick={() => this.handleSelectedTab(2)}
                          data-mdb-toggle="tab"
                          href="#Quizz"
                          role="tab"
                          aria-selected="false"
                        >Quiz</a>
                      </li>
                        <li className="nav-item" role="presentation">
                            <a
                                className={`nav-link Annexes_xv ${this.state.selectedTab === 3 ? 'active' : ''}`}
                                onClick={() => this.handleSelectedTab(3)}
                                data-mdb-toggle="tab"
                                href="#Exercices"
                                role="tab"
                                aria-selected="false"
                            >Exercices</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                className={`nav-link Annexes_xv ${this.state.selectedTab === 4 ? 'active' : ''}`}
                                onClick={() => this.handleSelectedTab(4)}
                                data-mdb-toggle="tab"
                                href="#Synthèse"
                                role="tab"
                                aria-selected="false"
                            >Synthèse</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                className={`nav-link Annexes_xv ${this.state.selectedTab === 5 ? 'active' : ''}`}
                                onClick={() => this.handleSelectedTab(5)}
                                data-mdb-toggle="tab"
                                href="#Annexes"
                                role="tab"
                                aria-selected="false"
                            >Annexes</a>
                        </li>
                    </ul>
                    {/* Tabs navs */}

                     {/* Tabs content */}
                    <div className="tab-content" id="ex1-content">
                      <div
                        className={`tab-pane fade ${this.state.selectedTab === 0 ? 'show active' : ''}`}
                        role="tabpanel"
                        style={{backgroundColor: "white", height: "12em"}}
                      >
                          <Editor
                              className="editor-bloc"
                              editorState={editorState}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={this.onEditorStateChange}
                          />
                      </div>
                      <div
                          className={`tab-pane fade ${this.state.selectedTab === 1 ? 'show active' : ''}`}
                      >
                        Tab 2 content
                      </div>
                      <div
                          className={`tab-pane fade ${this.state.selectedTab === 2 ? 'show active' : ''}`}
                          role="tabpanel"
                      >
                        Tab 3 content
                      </div>
                        <div
                            className={`tab-pane fade ${this.state.selectedTab === 3 ? 'show active' : ''}`}
                            role="tabpanel"
                        >
                            Tab 4 content
                        </div>
                        <div
                            className={`tab-pane fade ${this.state.selectedTab === 4 ? 'show active' : ''}`}
                            role="tabpanel"
                        >
                            Tab 5 content
                        </div>
                        <div
                            className={`tab-pane fade ${this.state.selectedTab === 5 ? 'show active' : ''}`}
                            role="tabpanel"
                        >
                            <Button className="add-reference" variant="contained">Ajouter une reference</Button>

                            Tab 6 content
                        </div>
                    </div>
                    {/* Tabs content */}
            </>
        );
    }
}

const mapStateToProps = state => ({ session: state.session});
export default connect(mapStateToProps)(withRouter(CustomizedSteppers));
