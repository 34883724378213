import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {connect} from "react-redux";

import WeekCalendar from 'react-week-calendar';

import moment from "moment";
import {
    teacherAddCalendarAction,
    teacherDeleteCalendarAction,
    teacherGetCalendarAction
} from "../../../Redux/ActionCreators";

class PartialCalender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.session.user,
            calendar_intervals: this.props.teacher_calendar.calendar,
            intervals: []
        };
    };

    componentDidMount() {
        let data = {
            user_id: this.state.user.id
        }
        return this.props
            .dispatch(teacherGetCalendarAction(data))
            .then(data => {
                if (data.payload.Success) {
                    let _data = data.payload.teacher_calendar;
                    let max = _data.length;
                    let i;
                    for (i = 0; i < max; i++) {
                        let interval = {
                            end: moment(_data[i].end_time),
                            start: moment(_data[i].start_time),
                            value: _data[i].availability_name,
                            id: _data[i].id
                        }
                        this.setState({
                            intervals: this.state.intervals.concat(interval)
                        });
                    }
                }
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message
                });
            });
    }

    handeSelectedInterval = (evt) => {
        let max = evt.length;
        let i;
        for (i = 0; i < max; i++) {
            let data = {
                user_id: this.state.user.id,
                start_time: evt[i].start,
                end_time: evt[i].end,
                availability_name: evt[i].value
            }
            return this.props
                .dispatch(teacherAddCalendarAction(data))
                .then(data => {
                    if (data.payload.Success) {
                        let _data = data.payload.teacher_calendar
                        let interval = {
                            end: moment(_data.end_time),
                            start: moment(_data.start_time),
                            value: _data.availability_name,
                            id: _data.id
                        }
                        this.setState({
                            intervals: this.state.intervals.concat(interval)
                        });
                    }
                })
                .catch(err => {
                    console.log("err", err);
                    this.setState({
                        errorGlobal: err.message
                    });
                });
        }
    }

    handeRemovedInterval = (evt) => {
        let filtered = {}
        return this.props
            .dispatch(teacherDeleteCalendarAction(evt.id))
            .then(data => {
                if (data.payload.Success) {
                    filtered = this.state.intervals.filter(function(value, index, arr){
                        return value.id !== evt.id;
                    });
                    this.setState({
                        intervals: filtered
                    });
                }
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message
                });
            });
    }

    handeUpdatedInterval = (evt) => {
        let intervalsVar = this.state.intervals;
        let filtered = intervalsVar.filter(function(value, index, arr){
            if (evt.start.isSame(value.start) && evt.end.isSame(value.end)) {
                value.value = evt.value;
            }
            return intervalsVar;
            });
        this.setState({
            intervals: filtered
        });
    }

    render() {
        return (
            <div>
                <WeekCalendar
                    scaleUnit={60}
                    startTime={moment({h: 6, m: 0})}
                    selectedIntervals={this.state.intervals}
                    onIntervalSelect={(evt) => this.handeSelectedInterval(evt)}
                    onIntervalRemove={(evt) => this.handeRemovedInterval(evt)}
                    showModalCase={['create', 'edit']}
                    onIntervalUpdate={(evt) => this.handeUpdatedInterval(evt)}
                />

            </div>
        );
    }
}

const mapStateToProps = state => ({ session: state.session, teacher_calendar: state.teacher_calendar });
export default connect(mapStateToProps)(withRouter(PartialCalender));