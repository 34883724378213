import React, { Component } from 'react';
import {Switch, Route, Redirect, withRouter} from "react-router-dom";

import {Breakpoint} from "react-socks";
import Navbar from '../Common/Layouts/Navbar';
import Sidebar from '../Common/Layouts/Sidebar';

import TeacherRoutes from "./TeacherRoutes";
import {connect} from "react-redux";
import {ContactUs} from "../Common/Layouts/Shared/ContactUs/ContactUs";

function switchRoutes (user, props) {
    return <Switch>
            {TeacherRoutes.map((prop, key) => {
                if (prop.layout === "/teacher") {
                      if (user!== undefined && user!== null && user.is_teacher && props.session.authenticated) {
                          return (
                            <Route
                              exact
                              path={prop.layout + prop.path}
                              component={prop.component}
                              key={key}
                            />
                          );
                      }
                      else{
                          return (
                            <Redirect key="to_home" from="*" to="/connexion" />
                          );
                      }
                  }
                  return null;
                })}
                <Redirect from="/teacher" to="/teacher/dashboard" />
    </Switch>
}

class TeacherDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.user = this.props.session.user
  };
  
  handleDrawerClose = () => {
    this.setState({
      open: false
    });
  };

  handleDrawerOpen = () => {
    this.setState({
      open: true
    })
  };

  render() {
    return (

      <div className="grow-dashboard">
        <Navbar
            open={this.state.open}
            handleDrawerOpen={this.handleDrawerOpen}
            {...this.props}
        />
        <Breakpoint large up>
            <Sidebar
              routes={TeacherRoutes}
              {...this.props}
            />
        </Breakpoint>

        <div className="dashboard-content">
          <div>
            {switchRoutes(this.user, this.props)}
          </div>
        </div>
                                    <ContactUs position={"window.location.pathname"}/>

      </div>
    );
  }
}

const mapStateToProps = state => ({ session: state.session,article:state.article});
export default connect(mapStateToProps)(withRouter(TeacherDashboard));