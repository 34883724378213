import React, { Component } from 'react';
import Header from "./Header";
import FooterContainer from "./Footer/Footer";

import ParentHome from "../Parent/ParentHome";
import TeacherHome from "../Teacher/TeacherHome";
import StudentHome from "../Student/StudentHome";
import BlogPublic from "./BlogPublic";
import {ContactUs} from "./Layouts/Shared/ContactUs/ContactUs";

class Home extends Component {
     componentDidMount() {
         var scrollComponent = this;
         document.addEventListener("scroll", function (e) {
             scrollComponent.toggleVisibility();
         });

         window.addEventListener('resize', this.updateDimensions);
     }
    constructor(props){
        super(props);
        this.state = {
            selectedPage: "student",
        };
    };

    changeUserHome = (user) => {
        this.setState({
            selectedPage: user
        });
    }
toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
    render(){
        const { selectedPage,is_visible } = this.state
        return (
            <>
                <Header
                    color="transparent"
                    changeColorOnScroll
                    changeUserHome={(user) => this.changeUserHome(user)}
                    page="accueil"
                />

                {selectedPage === "teacher" ? <TeacherHome/>:
                    selectedPage === "parent" ? <ParentHome/>:
                        selectedPage === "student" ?<StudentHome changeUserHome={(user) => this.changeUserHome(user)}/> :
                            <BlogPublic />

                }
                <div className="scroll-to-top">
                    {is_visible && (
                        <div onClick={() => this.scrollToTop()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"  viewBox="0 0 24 24">
                                <path style={{fill:"#ce0d48"}} d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 7.58l5.995 5.988-1.416 1.414-4.579-4.574-4.59 4.574-1.416-1.414 6.006-5.988z"/></svg>
                        </div>
                    )}
                </div>
                          <ContactUs position={"/"}/>

                <FooterContainer/>
            </>
        );
    }
}
export default Home