import React from 'react'
import { Article } from './Article'
import Grid from '@material-ui/core/Grid';

export function Actualitsaaccueil(props) {
    

    return (
        <div className="Actualitsa_accueil">
			<div id="Actualits">
				<span>Actualités</span>
			</div>
		{/*<Grid container spacing={1}  sm={12} justify="center" alignItems="center">*/}
		<Grid container spacing={8}>
			<Grid item xs={6} sm={4}>
				<Article article={props.articles[0]}/>
			</Grid>
			<Grid item xs={6} sm={4}>
				<Article article={props.articles[1]}/>
			</Grid>
			<Grid item xs={6} sm={4}>
				<Article article={props.articles[2]}/>
				</Grid>
			<Grid item xs={6} sm={4}>
				<Article article={props.articles[3]}/>
			</Grid>
		</Grid>
	</div>
    )
}
