import React, { Component } from 'react';
//import { Recherche } from './Shared/CourStore/Recherche';

import CourseContainer from './Shared/CourStore/CourseContainer';

class StoreCourses extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    };

    render() {
        return (
            // <>
			// 	<div className="cour-store-research">
			// 		<Recherche
			// 			titre ="Recherchez le cours que vous souhaitez suivre"
			// 			parDefaut = "Recherchez par intitulé">
			// 		</Recherche>
			// 	</div>
			// 	<div className="cours-store-grid">
			// 		<Grid container spacing={1} className="grid-style">
			//
			// 			<Grid item xs={10}>
							<CourseContainer/>
			// 			</Grid>
			// 		</Grid>
			// 	</div>
			//
            // </>
        );
    }
}

export default StoreCourses;