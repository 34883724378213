import React, {Component} from 'react';
import {CircularProgress} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {DisplayFavoriteCourses} from "./FavoriteCourse/DisplayFavoriteCourses";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {
    addCourseToCart,
    deleteCourseFromFavorite,
    getCourseFromFavorite,
    getCourseToCart
} from "../../../../../Redux/ActionCreators";
import Notification from "../../../AlertComponent";
import Pagination from "../../../Components/Pagination";
import {PageViewGoogleAnalytics} from "../../../Components/GoogleAnalytics";

let number = 0;
const usersPerPage = 12;

class FavoriteCourse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.session.user,
            is_Load: this.props.data.isLoadFromFavorite,
            notify: {message: '', type: ''},
            notyIsOpen: false,
            GetCoursesFromFavorite: this.props.data.get_courses_from_favorite,
            closeButton: true,
            pageNumber: 0,
        }
    }
    componentDidMount() {
        PageViewGoogleAnalytics("Favorite Course", window.location.pathname + window.location.search)
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                GetCoursesFromFavorite: this.props.data.get_courses_from_favorite,
            });
        }
    }

    // set notification
    setNotify = (message, type) => {
        this.setState({
            notify: {
                message: message,
                type: type
            },
            notyIsOpen: !this.state.notyIsOpen
        })
    }
    pagesVisited = (pageNumber) => {
        return pageNumber * usersPerPage;
    }

    pageCount = (courses) => {
        Math.ceil(courses.length / usersPerPage);
    }

    changePage = ({selected}) => {
        this.setState({
            pageNumber: selected
        })
    };

    handleIncrement = () => {
        number += 1
        return number
    }

    DeleteFromFavorite = (idRemoveFavorite) => {
        this.setState({
            is_Load: false,
            GetCoursesFromFavorite: [],
            closeButton: false
        });
        let data = {
            favorite_id: idRemoveFavorite,
        };
        return this.props
            .dispatch(deleteCourseFromFavorite(data))
            .then(data => {
                if (data.payload.Success) {
                    this.GetCoursesFromFavorite()
                    this.setNotify(data.payload.message, 'success')
                }
            })
            .catch(err => {
                console.error(err.message)
                this.setNotify("There is an error", 'error')
            });
    }
    GetCoursesFromFavorite = () => {
        let data = {
            user_id: this.state.user.id
        };
        return this.props
            .dispatch(getCourseFromFavorite(data))
            .then(data => {
                if (data.payload.Success) {
                    console.log("enter")
                    this.setState({
                        GetCoursesFromFavorite: data.payload.courses_from_favorite,
                        is_Load: true,
                        closeButton: true
                    });
                }
            })
            .catch(err => {
                console.log(err.message)
            });
    }
    AddToCart = (id) => {
        let data = {
            cart_course: id,
            cart_user: this.state.user.id
        };
        return this.props
            .dispatch(addCourseToCart(data))
            .then(data => {
                if (data.payload.Success) {
                    this.UpdateToCart()
                    this.setNotify(data.payload.message, 'success')
                }
            })
            .catch(err => {
                console.error(err.message)
                this.setNotify("There is an error", 'error')
            });
    }

    UpdateToCart = () => {
        let data = {
            ordered_user: this.state.user.id
        };
        return this.props
            .dispatch(getCourseToCart(data))
            .then(data => {
            })
            .catch(err => {
                console.log(err.message)
            });
    }
getSystemEducation=(course)=>{
        const e =this.props.data.system_education.filter(system=>{return course.course_systeme===system.id});
        return e.length>0 ? e[0].system_name : "";
    }
    render() {
        const {
            GetCoursesFromFavorite,
            is_Load,
            closeButton,
            notify,
            notyIsOpen,
            pageNumber,
            user,
        } = this.state;
        return (
            <>
                <div className="cours-store-grid">
                    <Grid container spacing={1} className="grid-style">
                        {((!GetCoursesFromFavorite || GetCoursesFromFavorite.length === 0) && closeButton) ?
                            <span className="empty-cart"> Vos cours favoris est vide</span>
                            :
                            <Grid item xs={11}>
                                <div className="courses-store-container">
                                    <div className="course-container-header">
                                        <div className="found-results">
                                            <span>{GetCoursesFromFavorite.length} Cours ajouté(s)</span>
                                        </div>
                                    </div>
                                    <div className="cours-store-grid-list">
                                        {is_Load ?
                                            <Grid container spacing={3} style={{flexGrow:"1",marginLeft:"2%"}}>
                                                {GetCoursesFromFavorite && GetCoursesFromFavorite.slice(this.pagesVisited(pageNumber), this.pagesVisited(pageNumber) + usersPerPage).map((course, key) => (
                                                    <Grid item key={key} spacing={2} xs sm={3}>
                                                        <DisplayFavoriteCourses
                                                            course={course}
                                                            system={this.getSystemEducation(course)}
                                                            user={user}
                                                            increment={this.handleIncrement()}
                                                            clickToAddCart={(id) => this.AddToCart(id)}
                                                            clickToDeleteFromFavorite={(idRemoveFavorite) => this.DeleteFromFavorite(idRemoveFavorite)}
                                                            />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            :
                                            <CircularProgress color="secondary" className="second-progress-bar"/>
                                        }
                                    </div>
                                    <div className="scroll-pages-options-favorite-course">
                                 {Pagination(this.pageCount(GetCoursesFromFavorite), this.changePage)}
                                    </div>
                                </div>
                            </Grid>
                        }
                    </Grid>
                </div>
                <Notification
                    notify={notify}
                    setNotify={this.setNotify}
                    isOpen={notyIsOpen}
                />
            </>
        );
    }
}

const mapStateToProps = state => ({data: state.data, session: state.session});
export default connect(mapStateToProps)(withRouter(FavoriteCourse));





