import React, {Component} from "react";
import {Trans, withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Carousel from "react-multi-carousel";
import user_inconnu from "../../../Assets/Images/user_inconnu.png";
import {Button} from "@material-ui/core";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        paritialVisibilityGutter: 60,
    },
    tablet: {
        breakpoint: { max: 1024, min: 800 },
        items: 2,
        paritialVisibilityGutter: 80
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        paritialVisibilityGutter: 30
    }
};


class StudentProfComponentMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teachers: this.props.teachers,
        }
    }

    render() {
        return (
            <section className="section-4">
                <div >
                    <div className="section-name">
                        <span><Trans i18nKey="translation:bloc-professeur.bloc-name"></Trans></span>
                    </div>
                            <div className="Prof_vedette_Mobile">

                                {/*<Carousel*/}
                                {/*    ssr*/}
                                {/*    deviceType="desktop"*/}
                                {/*    itemClass="image-item"*/}
                                {/*    responsive={responsive}*/}
                                {/*    style={{ "padding-bottom": "10px" }}*/}
                                {/*>*/}
                                <Carousel
                                    arrows={false} showDots={true} partialVisible={true} responsive={responsive}>
                                {/*---------------------------------*/}
                                <div className="course__event_item_bf__Mobile">



                                    <div id="courses_item_bg_Mobile">
                                        <svg className="Rectangle_239_bh">
                                            <rect id="Rectangle_239_bh" rx="12" ry="12" x="0" y="0" width="100%"
                                                  height="243">
                                            </rect>
                                        </svg>
                                        <div id="Username_Prof_vedette">
                                            <span>{this.props.teachers[0] ? this.props.teachers[0].username : null}</span>
                                        </div>
                                        <div id="specialite_prof_vedette">
                                            <span>{this.props.teachers[0] ? this.props.teachers[0].study_field : null}</span>
                                        </div>
                                        <div id="Group_228_bk_Mobile">
                                            {/*<svg className="Rectangle_11_bl">*/}
                                            {/*    <rect id="Rectangle_11_bl" rx="13.538150787353516"*/}
                                            {/*          ry="13.538150787353516" x="0"*/}
                                            {/*          y="0" width="170" height="27.076">*/}
                                            {/*    </rect>*/}
                                            {/*</svg>*/}
                                            <div className="Programme">
                                                <span>{this.props.teachers[0] ? this.props.teachers[0].education_system : null}</span>
                                            </div>
                                            {/*<div id="Group_246_bn">*/}
                                            {/*    <svg className="Rectangle_10_bo">*/}
                                            {/*        <rect id="Rectangle_10_bo" rx="13.5" ry="13.5" x="0" y="0"*/}
                                            {/*              width="104"*/}
                                            {/*              height="27">*/}
                                            {/*        </rect>*/}
                                            {/*    </svg>*/}
                                            {/*    <div id="ID48_______153">*/}
                                            {/*        <span>4.8       (153)</span>*/}
                                            {/*    </div>*/}
                                            {/*    <svg className="Path_20_bq" viewBox="0 0 19.033 18.85">*/}
                                            {/*        <path id="Path_20_bq"*/}
                                            {/*              d="M 9.516317367553711 0 L 12.13562774658203 6.665873527526855 L 19.03263473510742 7.200098991394043 L 13.75451755523682 11.85408115386963 L 15.39772510528564 18.85013389587402 L 9.516317367553711 15.06072044372559 L 3.634911298751831 18.85013389587402 L 5.278272151947021 11.85408115386963 L 0 7.200098991394043 L 6.897006034851074 6.665873527526855 L 9.516317367553711 0 Z">*/}
                                            {/*        </path>*/}
                                            {/*    </svg>*/}
                                            {/*</div>*/}
                                        </div>

                                        <div id="Group_229">
                                            <Link onClick={() => this.props.reservationHundler(0)}
                                                  to={(this.props.teachers[0] ? "/student/" + this.props.teachers[0].id + "/reserve/calendar" : "home")}>
                                                <svg className="Rectangle_240_bs">
                                                    <rect id="Rectangle_240_bs" rx="13.538150787353516"
                                                          ry="13.538150787353516"
                                                          x="0" y="0" width="171" height="27.076">
                                                    </rect>
                                                </svg>
                                                <div className="Reserver_bt_Mobile">
                                                    <span>Réserver</span>
                                                </div>
                                            </Link>

                                        </div>
                                        <div id="description_prof_vedette">
                                            <span>
                                            {this.props.teachers[0] ?
                                                this.props.teachers[0].description.length>100 ?
                                                    this.props.teachers[0].description.slice(0,100)+"..." :
                                                    this.props.teachers[0].description
                                                : null}
                                            </span>
                                        </div>
                                        <div id="carosel_item1_bv_Mobile">
                                            <svg className="Rectangle_9_bw_Mobile">
                                                <rect id="Rectangle_9_bw_Mobile" rx="91.5" ry="91.5" x="0" y="0">
                                                </rect>
                                            </svg>
                                           {this.props.teachers[0] ?
                                                <img id="face_co_Mobile" alt="" src={this.props.teachers[0].profile_pic==="" ? user_inconnu : this.props.teachers[0].profile_pic}/>:
                                                <img id="face_co_Mobile"  src={user_inconnu} alt=""/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/*--------------------------------------------------------------*/}
                                <div className="course__event_item_bf__Mobile">
                                    <div id="courses_item_bg_Mobile">
                                        <svg className="Rectangle_239_bh">
                                            <rect id="Rectangle_239_bh" rx="12" ry="12" x="0" y="0" width="100%"
                                                  height="243">
                                            </rect>
                                        </svg>
                                        <div id="Username_Prof_vedette">
                                            <span>{this.props.teachers[1] ? this.props.teachers[1].username : null}</span>
                                        </div>
                                        <div id="specialite_prof_vedette">
                                            <span>{this.props.teachers[1] ? this.props.teachers[1].study_field : null}</span>
                                        </div>
                                        <div id="Group_228_bk_Mobile">
                                            {/*<svg className="Rectangle_11_bl">*/}
                                            {/*    <rect id="Rectangle_11_bl" rx="13.538150787353516"*/}
                                            {/*          ry="13.538150787353516" x="0"*/}
                                            {/*          y="0" width="165" height="27.076">*/}
                                            {/*    </rect>*/}
                                            {/*</svg>*/}
                                            <div className="Programme">
                                                <span>{this.props.teachers[1] ? this.props.teachers[1].education_system : null}</span>
                                            </div>
                                            {/*<div id="Group_246_bn">*/}
                                            {/*    <svg className="Rectangle_10_bo">*/}
                                            {/*        <rect id="Rectangle_10_bo" rx="13.5" ry="13.5" x="0" y="0"*/}
                                            {/*              width="104"*/}
                                            {/*              height="27">*/}
                                            {/*        </rect>*/}
                                            {/*    </svg>*/}
                                            {/*    <div id="ID48_______153">*/}
                                            {/*        <span>4.8       (153)</span>*/}
                                            {/*    </div>*/}
                                            {/*    <svg className="Path_20_bq" viewBox="0 0 19.033 18.85">*/}
                                            {/*        <path id="Path_20_bq"*/}
                                            {/*              d="M 9.516317367553711 0 L 12.13562774658203 6.665873527526855 L 19.03263473510742 7.200098991394043 L 13.75451755523682 11.85408115386963 L 15.39772510528564 18.85013389587402 L 9.516317367553711 15.06072044372559 L 3.634911298751831 18.85013389587402 L 5.278272151947021 11.85408115386963 L 0 7.200098991394043 L 6.897006034851074 6.665873527526855 L 9.516317367553711 0 Z">*/}
                                            {/*        </path>*/}
                                            {/*    </svg>*/}
                                            {/*</div>*/}
                                        </div>

                                        <div id="Group_229">
                                            <Link onClick={() => this.props.reservationHundler(1)}
                                                  to={(this.props.teachers[1] ? "/student/" + this.props.teachers[1].id + "/reserve/calendar" : "home")}>
                                                <svg className="Rectangle_240_bs">
                                                    <rect id="Rectangle_240_bs" rx="13.538150787353516"
                                                          ry="13.538150787353516"
                                                          x="0" y="0" width="171" height="27.076">
                                                    </rect>
                                                </svg>
                                                <div className="Reserver_bt_Mobile">
                                                    <span>Réserver</span>
                                                </div>
                                            </Link>

                                        </div>
                                        <div id="description_prof_vedette">
                                            <span>
                                            {this.props.teachers[1] ?
                                                this.props.teachers[1].description.length > 100 ?
                                                    this.props.teachers[1].description.slice(0, 100) + "..." :
                                                    this.props.teachers[1].description
                                                : null}
                                            </span>                                        </div>
                                        <div id="carosel_item1_bv_Mobile">
                                            <svg className="Rectangle_9_bw_Mobile">
                                                <rect id="Rectangle_9_bw_Mobile" rx="91.5" ry="91.5" x="0" y="0">
                                                </rect>
                                            </svg>
                                            {
                                            this.props.teachers[1] ?
                                                <img id="face_co_Mobile" src={this.props.teachers[1].profile_pic==="" ? user_inconnu : this.props.teachers[1].profile_pic} alt=""/>:
                                                <img alt="" id="face_co_Mobile"  src={user_inconnu}/>
                                            }
                                        </div>
                                    </div>
                                </div>



                                {/*--------------------------------------------------------------*/}




                                 {/*---------------------------------*/}
                                <div className="course__event_item_bf__Mobile">



                                    <div id="courses_item_bg_Mobile">
                                        <svg className="Rectangle_239_bh">
                                            <rect id="Rectangle_239_bh" rx="12" ry="12" x="0" y="0" width="100%"
                                                  height="243">
                                            </rect>
                                        </svg>
                                        <div id="Username_Prof_vedette">
                                            <span>{this.props.teachers[2] ? this.props.teachers[2].username : null}</span>
                                        </div>
                                        <div id="specialite_prof_vedette">
                                            <span>{this.props.teachers[2] ? this.props.teachers[2].study_field : null}</span>
                                        </div>
                                        <div id="Group_228_bk_Mobile">
                                            {/*<svg className="Rectangle_11_bl">*/}
                                            {/*    <rect id="Rectangle_11_bl" rx="13.538150787353516"*/}
                                            {/*          ry="13.538150787353516" x="0"*/}
                                            {/*          y="0" width="165" height="27.076">*/}
                                            {/*    </rect>*/}
                                            {/*</svg>*/}
                                            <div className="Programme">
                                                <span>{this.props.teachers[2] ? this.props.teachers[2].education_system : null}</span>
                                            </div>
                                            {/*<div id="Group_246_bn">*/}
                                            {/*    <svg className="Rectangle_10_bo">*/}
                                            {/*        <rect id="Rectangle_10_bo" rx="13.5" ry="13.5" x="0" y="0"*/}
                                            {/*              width="104"*/}
                                            {/*              height="27">*/}
                                            {/*        </rect>*/}
                                            {/*    </svg>*/}
                                            {/*    <div id="ID48_______153">*/}
                                            {/*        <span>4.8       (153)</span>*/}
                                            {/*    </div>*/}
                                            {/*    <svg className="Path_20_bq" viewBox="0 0 19.033 18.85">*/}
                                            {/*        <path id="Path_20_bq"*/}
                                            {/*              d="M 9.516317367553711 0 L 12.13562774658203 6.665873527526855 L 19.03263473510742 7.200098991394043 L 13.75451755523682 11.85408115386963 L 15.39772510528564 18.85013389587402 L 9.516317367553711 15.06072044372559 L 3.634911298751831 18.85013389587402 L 5.278272151947021 11.85408115386963 L 0 7.200098991394043 L 6.897006034851074 6.665873527526855 L 9.516317367553711 0 Z">*/}
                                            {/*        </path>*/}
                                            {/*    </svg>*/}
                                            {/*</div>*/}
                                        </div>

                                        <div id="Group_229">
                                            <Link onClick={() => this.props.reservationHundler(2)}
                                                  to={(this.props.teachers[2] ? "/student/" + this.props.teachers[2].id + "/reserve/calendar" : "home")}>
                                                <svg className="Rectangle_240_bs">
                                                    <rect id="Rectangle_240_bs" rx="13.538150787353516"
                                                          ry="13.538150787353516"
                                                          x="0" y="0" width="171" height="27.076">
                                                    </rect>
                                                </svg>
                                                <div className="Reserver_bt_Mobile">
                                                    <span>Réserver</span>
                                                </div>
                                            </Link>

                                        </div>
                                        <div id="description_prof_vedette">
                                            <span>
                                            {this.props.teachers[2] ?
                                                this.props.teachers[2].description.length>100 ?
                                                    this.props.teachers[2].description.slice(0,100)+"..." :
                                                    this.props.teachers[2].description
                                                : null}
                                            </span>                                        </div>
                                        <div id="carosel_item1_bv_Mobile">
                                            <svg className="Rectangle_9_bw_Mobile">
                                                <rect id="Rectangle_9_bw_Mobile" rx="91.5" ry="91.5" x="0" y="0">
                                                </rect>
                                            </svg>
                                            {
                                            this.props.teachers[2] ?
                                                <img id="face_co_Mobile" src={this.props.teachers[2].profile_pic==="" ? user_inconnu : this.props.teachers[2].profile_pic} alt=""/>:
                                                <img alt="" id="face_co_Mobile"  src={user_inconnu}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/*--------------------------------------------------------------*/}

                                <div className="course__event_item_bf__Mobile">
                                    <div id="courses_item_bg_Mobile">
                                        <svg className="Rectangle_239_bh">
                                            <rect id="Rectangle_239_bh" rx="12" ry="12" x="0" y="0" width="100%"
                                                  height="243">
                                            </rect>
                                        </svg>
                                        <div id="Username_Prof_vedette">
                                            <span>{this.props.teachers[3] ? this.props.teachers[3].username : null}</span>
                                        </div>
                                        <div id="specialite_prof_vedette">
                                            <span>{this.props.teachers[3] ? this.props.teachers[3].study_field : null}</span>
                                        </div>
                                        <div id="Group_228_bk_Mobile">
                                            {/*<svg className="Rectangle_11_bl">*/}
                                            {/*    <rect id="Rectangle_11_bl" rx="13.538150787353516"*/}
                                            {/*          ry="13.538150787353516" x="0"*/}
                                            {/*          y="0" width="165" height="27.076">*/}
                                            {/*    </rect>*/}
                                            {/*</svg>*/}
                                            <div className="Programme">
                                                <span>{this.props.teachers[3] ? this.props.teachers[3].education_system : null}</span>
                                            </div>
                                            {/*<div id="Group_246_bn">*/}
                                            {/*    <svg className="Rectangle_10_bo">*/}
                                            {/*        <rect id="Rectangle_10_bo" rx="13.5" ry="13.5" x="0" y="0"*/}
                                            {/*              width="104"*/}
                                            {/*              height="27">*/}
                                            {/*        </rect>*/}
                                            {/*    </svg>*/}
                                            {/*    <div id="ID48_______153">*/}
                                            {/*        <span>4.8       (153)</span>*/}
                                            {/*    </div>*/}
                                            {/*    <svg className="Path_20_bq" viewBox="0 0 19.033 18.85">*/}
                                            {/*        <path id="Path_20_bq"*/}
                                            {/*              d="M 9.516317367553711 0 L 12.13562774658203 6.665873527526855 L 19.03263473510742 7.200098991394043 L 13.75451755523682 11.85408115386963 L 15.39772510528564 18.85013389587402 L 9.516317367553711 15.06072044372559 L 3.634911298751831 18.85013389587402 L 5.278272151947021 11.85408115386963 L 0 7.200098991394043 L 6.897006034851074 6.665873527526855 L 9.516317367553711 0 Z">*/}
                                            {/*        </path>*/}
                                            {/*    </svg>*/}
                                            {/*</div>*/}
                                        </div>

                                        <div id="Group_229">
                                            <Link onClick={() => this.props.reservationHundler(3)}
                                                  to={(this.props.teachers[3] ? "/student/" + this.props.teachers[3].id + "/reserve/calendar" : "home")}>
                                                <svg className="Rectangle_240_bs">
                                                    <rect id="Rectangle_240_bs" rx="13.538150787353516"
                                                          ry="13.538150787353516"
                                                          x="0" y="0" width="171" height="27.076">
                                                    </rect>
                                                </svg>
                                                <div className="Reserver_bt_Mobile">
                                                    <span>Réserver</span>
                                                </div>
                                            </Link>

                                        </div>
                                        <div id="description_prof_vedette">
                                            <span>
                                            {this.props.teachers[3] ?
                                                this.props.teachers[3].description.length>100 ?
                                                    this.props.teachers[3].description.slice(0,100)+"..." :
                                                    this.props.teachers[3].description
                                                : null}
                                            </span>
                                        </div>
                                        <div id="carosel_item1_bv_Mobile">
                                            <svg className="Rectangle_9_bw_Mobile">
                                                <rect id="Rectangle_9_bw_Mobile" rx="91.5" ry="91.5" x="0" y="0">
                                                </rect>
                                            </svg>
                                            {
                                            this.props.teachers[3] ?
                                                <img id="face_co_Mobile" src={this.props.teachers[3].profile_pic==="" ? user_inconnu : this.props.teachers[3].profile_pic} alt=""/>:
                                                <img alt="" id="face_co_Mobile"  src={user_inconnu}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                </Carousel>
                            </div>

                    <div className="section-description">
                        <span><Trans i18nKey="translation:bloc-professeur.description"></Trans></span>
                    </div>
                </div>

            </section>
        );
    }

}
export default withTranslation()(StudentProfComponentMobile);