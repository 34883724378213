import React, {useState} from 'react';
import {createStyles,Popover} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import {Breakpoint} from "react-socks";


const useStyles = makeStyles((theme) =>
    createStyles({
        popover: {
            pointerEvents: 'none',
        },
        paper: {
            padding: theme.spacing(1),
        },
    }),
);

export default function LibraryComponent() {

    const classes = useStyles();
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);

    const handleSearch = ({target}) => {
        setSearchText(target.value)
    }

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            {/* Cyrcles */}
            <Breakpoint large up>
                <div id="artifices_ek">
                    <svg className="Ellipse_32_ek">
                        <ellipse id="Ellipse_32_ek" rx="323" ry="323" cx="323" cy="323">
                        </ellipse>
                    </svg>
                    <svg className="Line_58_ek" viewBox="0 0 15 159.27">
                        <path id="Line_58_ek" d="M 0 0 L 0 159.26953125">
                        </path>
                    </svg>
                    <svg className="Line_59_ek" viewBox="0 0 15 159.27">
                        <path id="Line_59_ek" d="M 0 0 L 0 159.26953125">
                        </path>
                    </svg>
                    <svg className="Line_60_ek" viewBox="0 0 15 159.27">
                        <path id="Line_60_ek" d="M 0 0 L 0 159.26953125">
                        </path>
                    </svg>
                </div>
            </Breakpoint>

            {/*library*/}
            <div className="library-core">
                <div className="library-title">
                    <span>Biblio</span><span>thèque</span>
                </div>
                {/*input + button*/}
                <div className="search-div">
                    <input
                        className="search-box"
                        type="text"
                        placeholder="Recherchez par titre, auteur, langue, description"
                        value={searchText}
                        onChange={handleSearch}
                    />
                    <Button
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={(e) => handlePopoverOpen(e)}
                        onMouseLeave={(e) => handlePopoverClose(e)}
                        className="search-btn">
                        Rechercher dans la bibliotèque
                    </Button>
                </div>
            </div>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>Bientôt disponible</Typography>
            </Popover>
        </>
    );
}
