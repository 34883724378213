import React from 'react'
import image from "../../../../../../Assets/Images/maths.jpg";

export function Description(props) {


	return (
		<>
			<div className="chapitre-description-container ">


				<div className="chapitre-image-container">
					<img className="chapitre-image" src={image} alt=""></img>
					<div className="image-gradient"></div>
				</div>
				<div className="chapitre-details">
					<div className="chapitre-matiere">
						<span>Mathématiques</span>
					</div>
					<div className="chapitre-tittle">
						<span>Trigonométries</span>
					</div>
					<div className="date-debut">
						<span>Démarre le 02/10/2020</span>
					</div>
				</div>
				<div className="description-container">
					<div className="description-tittle">
						<span>Description</span>
					</div>
					<div className="description">
						<span>La trigonométrie (du grec τρίγωνος / trígonos, « triangulaire », et μέτρον / métron, « mesure ») est une branche des mathématiques qui traite des relations entre distances et angles dans les triangles et des fonctions trigonométriques telles que sinus, cosinus, tangente.<br/>Une définition possible des fonctions trigonométriques est d'utiliser les triangles rectangles, c’est-à-dire les triangles qui possèdent un angle droit (90 degrés ou π/2 radians). Et parce que la somme des angles d'un triangle fait 180° (ou π radians), l'angle le plus grand dans un tel triangle est l'angle droit. Le côté le plus long dans un triangle rectangle, c’est-à-dire le côté opposé à l'angle le plus grand (l'angle droit), s'appelle l'hypoténuse.</span>
					</div>
				</div>
			</div>
		</>
	)
}
