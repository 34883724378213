import React from 'react';
import SingleLineGridList from './SingleLineGridList';
import {Button} from "@material-ui/core";
import { useHistory } from "react-router-dom";

export function Mescoursaccueil(props) {

    const history = useHistory();

    const routeChange = () =>{
        let path = `profs_list`;
        history.push(path)
    }

    return (
        <div className="mes_cours_accueil">
            <div id="Mes_Cours">
                <span>Mes Cours</span>
                {props.user.is_teacher && <a href="https://talented-academy.live/courses">
                        <Button type="submit" className="energy_btn" variant="contained">Commencer un cours virtuel</Button>
                    </a>
                }
                {props.user.is_student &&
                    <Button type="submit" className="energy_btn" onClick={routeChange} variant="contained">Trouver votre professeur idéal</Button>
                }
            </div>
            <div id="Boc_MAtiers">
               <SingleLineGridList
                   user={props.user}
                   //teacher_courses={props.teacher_courses}
               />
            </div>

        </div>
    )
}
