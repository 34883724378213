import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';

import {Actualitsaaccueil} from './Shared/Dashboard/Actualite/ActualitsaAccueil';
//import {Agendaaccueil} from './Shared/Dashboard/Agenda/AgendaAccueil';
import {Mescoursaccueil} from './Shared/Dashboard/Cours/MesCoursAccueil';
import Profil from './Shared/Dashboard/Profil';

import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getTeacherCoursesAction, getArticles} from "../../../Redux/ActionCreators";
import Loading from "../Loading";
import Notification from "../AlertComponent";

class StudentDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.session.user,
            allertStatus: false,
			notify: {message: '', type: '' },
			notyIsOpen: false,
            //data_courses:this.props.data,
        };
    };

    setNotify = () => {
		this.setState({
			notify: {
				message: 'error',
            	type: 'error'
			},
			notyIsOpen: !this.state.notyIsOpen,
		});
	}
    componentDidUpdate(prevProps) {
        if (this.props.session !== prevProps.session) {
            this.setState({
                user: this.props.session.user
            });
        }
         if(this.props.session.user && this.props.student.course_cart_id && this.state.user.is_student){
        this.props.history.push("/student/cart");

      }
        //   if (this.props.data_courses !== prevProps.data_courses) {
        //     this.setState({
        //         data_courses: this.props.data_courses.techear_courses
        //     });
        // }
    }

    componentDidMount() {
        this.handleChargeCourses();
        return this.props
            .dispatch(getArticles())
            .then(data => {
                if (data.payload) {
                    this.setState({
                        List_articles: data.payload.results,
                    })
                }
            })
            .catch(err => {
                console.log("err", err);
                this.setNotify();

            });
    };

    handleChargeCourses = () => {
        let data = {
            teacher_id: this.state.user.id
        };
        return this.props
            .dispatch(getTeacherCoursesAction(data))
            .then({
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    globalMessage: err.message
                });
            });
    }

    render() {
        return (
            <>
                <div className="dash-home-page">
                    <div className="text-name">
                        <h1 className="welcome-text">Bienvenu, {this.state.user.first_name}</h1>
                    </div>

                    <Grid container spacing={1} className="grid-style">
                        <Grid item md={3} xs={12}>
                            <Profil
                                //user={this.state.user}
                            />
                        </Grid>
                        <Grid item md={9} xs={12}>
                            <Mescoursaccueil
                                user={this.state.user}
                                //teacher_courses={this.state.data_courses}
                            />
                        </Grid>
                    </Grid>
                    {/*<Agendaaccueil*/}
                    {/*    user={this.state.user}*/}
                    {/*/>*/}
                    {
                        this.props.articles.isLoad ?
                            <Actualitsaaccueil articles={this.props.articles.List_articles}/>:
                            <div style={{"margin":"60px"}}>
                                <Loading />
                            </div>
                    }
                </div>
                <Notification
					notify={this.state.notify}
					setNotify={this.setNotify}
					isOpen={this.state.notyIsOpen}
				/>
            </>
        );
    }
}

const mapStateToProps = state => ({session: state.session,articles:state.articles,student:state.student});
export default connect(mapStateToProps)(withRouter(StudentDashboard));