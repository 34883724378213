import React, {useState} from 'react'
import Footer from './components/footer'
import {Popover} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import Modal from "react-bootstrap/Modal";


import ContactForm from "./ContactForm";
import {EventGoogleAnalytics} from "../Components/GoogleAnalytics";

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

export default function FooterContainer() {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [show, setShow] = useState(false);
    const [showSecond, setShowSecond] = useState(false);
    const [showThird, setShowThird] = useState(false);
    const open = Boolean(anchorEl);
    // Show Model contact us
    const handleShowLarge = () => {
        setShow(true)
    }
    // Show Model 'qui sommes-nous'
    const handleShowLargeSecond = () => {
        setShowSecond(true)
    }
    // Show Model 'nos valeurs'
    const handleShowLargeThird = () => {
        setShowThird(true)
    }
    // Show Popover
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <Footer>
                <Footer.Wrapper>
                    <Footer.Row>
                        <Footer.Column>
                            {/*Meilleurs Cours*/}
                            <Footer.Title>Meilleurs cours </Footer.Title>
                            <Footer.Link>Mathématique</Footer.Link>
                            <Footer.Link>Physique</Footer.Link>
                            <Footer.Link>Informatique</Footer.Link>
                            <Footer.Link>Anglais</Footer.Link>
                            <Footer.Link>Français</Footer.Link>
                            <Footer.Link>Science de l'ingénieur</Footer.Link>

                        </Footer.Column>
                        <Footer.Column>
                            {/*Informations*/}
                            <Footer.Title>Informations</Footer.Title>
                            <Footer.Link onClick={() => handleShowLargeSecond()}>Qui sommes-nous?</Footer.Link>
                            <Footer.Link onClick={() => handleShowLargeThird()}>Nos valeurs</Footer.Link>
                            <Footer.Link>Mentions légales</Footer.Link>
                            <Footer.Link>Confidentialité</Footer.Link>
                            <Footer.Link onClick={() => handleShowLarge()}>Pour nous contacter</Footer.Link>
                        </Footer.Column>
                        <Footer.Column>
                            {/*Coordonnées*/}
                            <Footer.Title>Coordonnées</Footer.Title>
                            <Footer.Text>Adresse : <br/>155 bd d'Anfa, 20100 Casablanca</Footer.Text>
                            <Footer.Text>Courriel : info@talented.academy</Footer.Text>
                            <Footer.Text>Téléphone : <br/>+212 (0) 7 70 70 70 97</Footer.Text>
                        </Footer.Column>
                        <Footer.Column>
                            {/*Social Media*/}
                            <Footer.Title>Réseaux</Footer.Title>
                            <Footer.Link onClick={() => EventGoogleAnalytics("Social Media", "Facebook", "visit the social media Facebook")}
                                href="https://www.facebook.com/TalentedAcademyAfrica "><FacebookIcon/>Facebook</Footer.Link>
                            <Footer.Link onClick={() => EventGoogleAnalytics("Social Media", "Linkedin", "visit the social media Linkedin")}
                                href="https://www.linkedin.com/company/talented-academy "><LinkedInIcon/>Linkedin</Footer.Link>
                            <Footer.Link onClick={() => EventGoogleAnalytics("Social Media", "Instagram", "visit the social media Instagram")}
                                href="https://www.instagram.com/talented__academy/"><InstagramIcon/>Instagram</Footer.Link>
                            {/*<Footer.Link href="#"><Icon className="fab fa-youtube"/>Youtube</Footer.Link>*/}
                            {/*<Footer.Link href="#"><Icon className="fab fa-twitter"/>Twitter</Footer.Link>*/}
                        </Footer.Column>
                    </Footer.Row>
                    <Footer.Mobile>
                        {/*Mobile applications*/}
                        <Footer.Link
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={(e) => handlePopoverOpen(e)}
                            onMouseLeave={(e) => handlePopoverClose(e)}
                        ><img
                            src="https://d3njjcbhbojbot.cloudfront.net/web/images/icons/download_on_the_app_store_badge_fr.svg"
                            alt="Télécharger dans l'App Store" height="45"/></Footer.Link>
                        <Footer.Link
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={(e) => handlePopoverOpen(e)}
                            onMouseLeave={(e) => handlePopoverClose(e)}
                        ><img
                            src="https://d3njjcbhbojbot.cloudfront.net/web/images/icons/fr_generic_rgb_wo_45.png"
                            alt="Disponible sur Google Play" height="45"/></Footer.Link>
                    </Footer.Mobile>
                </Footer.Wrapper>
                <span className="row justify-content-center copyright copyright-style">© Copyright TalentED 2021. Tous droits réservés. Marque déposée</span>
            </Footer>
            {/*popover*/}
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>Bientôt disponible</Typography>
            </Popover>
            {/*Model nous contacter*/}
            <Modal
                className="style-model-payment"
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <span style={{color: "#c0306e", fontWeight: "bold"}}>Formulaire de contact</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactForm/>
                </Modal.Body>
            </Modal>
            {/*Model qui sommes nous?*/}
            <Modal
                className="style-model-payment"
                size="lg"
                show={showSecond}
                onHide={() => setShowSecond(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <span style={{color: "#c0306e", fontWeight: "bold"}}>Qui sommes-nous ?</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{textAlign: "justify"}}>
                        Notre start-up rend accessible l’apprentissage en ligne pour les jeunes avec des méthodes
                        ludiques et une pédagogie innovante. Elle les met en relation avec des enseignants soigneusement
                        sélectionnés et des contenus aux standards nationaux et internationaux. Elle utilise
                        les dernières technologies d’innovation en matière de visio-conférence sécurisé, d’IA,
                        de réalité virtuelle....
                    </div>
                </Modal.Body>
            </Modal>
            {/*Model Nos valeurs*/}
            <Modal
                className="style-model-payment"
                size="lg"
                show={showThird}
                onHide={() => setShowThird(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <span style={{color: "#c0306e", fontWeight: "bold"}}>Nos Valeurs</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{textAlign: "justify"}}>
                        <span style={{fontWeight: "bold",textDecorationLine : "underline"}}>L’excellence</span>
                        <br/>
                        Talented   Academy promeut d’une part l’excellence au sein de ses services. D’autre part, nous accompagnons
                        nos élèves à atteindre l’excellence scolaire et académique grâce à l’encadrement de nos enseignants experts, soigneusement choisis.
                        <br/>
                        <span style={{fontWeight: "bold",textDecorationLine : "underline"}}>L’innovation</span>
                        <br/>
                        La plateforme Talented Academy est le fruit de plusieurs innovations pédagogiques et technologiques.
                        Nous implémentons régulièrement des nouvelles fonctionnalités pour rendre votre expérience utilisateur encore plus agréable et plus interactive.
                        <br/>
                        <span style={{fontWeight: "bold",textDecorationLine : "underline"}}>L'engagement</span>
                        <br/>
                        Notre équipe est composée de personnel compétent et engagé pour vous offrir un service de qualité dans le respect des normes de sécurité et
                        du respect des données personnelles.
                        <br/>
                        Nos enseignants collaborateurs partagent nos valeurs.
                        <br/>
                        Nos prestations et tarifs sont clairement affichés sur notre site web.
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}



