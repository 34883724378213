import React from 'react'

export function Prerequis(props) {
    

    return (
        <>
			<div className="prerequis-cours-container">
				<div className="prerequis-cours-tittle">
						<span>Prérequis du cours</span>
					</div>
				<div className="prerequis-cours">
					<span>Repérage sur le cercle trigonométrique ;<br/>     - Angles en radians ;<br/>     - Angles orientés  de vecteurs ;<br/>     - Cosinus et sinus d'un angle orienté ;<br/>     - Formules de trigonométrie : addition, duplication, angles associés.</span>
				</div>
			</div> 
        </>
    )
}
