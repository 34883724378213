import React, {Component} from 'react';
//import {Modal} from 'reactstrap';
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Modal from "react-bootstrap/Modal";

import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {partial_update_studentAction, partial_update_teacherAction} from "../../../Redux/ActionCreators";
import IconButton from "@material-ui/core/IconButton";
import {EventGoogleAnalytics} from "../../Common/Components/GoogleAnalytics";


class ModalProfilAvatar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.session.user,
        }
    }

    EditModal = () => {
        this.props.handleEditOpen();
    };
    // function of passing url to teacher & student's profile
    onclick = (url) => {
        // Event Google Analytics
        EventGoogleAnalytics("Profile_Picture_button","Button","Submit avatar")
        this.props.handleEditOpen()
        this.props.handleOpenLinearBar()
        const data = {
            "profile_pic": url,
            "user_id": this.state.user.id
        };
        if (this.state.user.is_teacher) {
            return this.props
                .dispatch(partial_update_teacherAction(data))
                .then(data => {
                    if (data.payload.Success) {
                        this.props.handleOpenLinearBar();
                        this.props.clickHandler(url)
                        this.props.setNotify(data.payload.message, 'success')
                    }
                })
                .catch(err => {
                    this.props.setNotify("Erreur est survenue, Réessayez plus tard", 'error')
                    this.props.handleOpenLinearBar();
                });
        } else {
            // TODO:REMOVE DUPLICATION OR REPLACE DISPATCH
            return this.props
                .dispatch(partial_update_studentAction(data))
                .then(data => {
                    if (data.payload.Success) {
                        this.props.handleOpenLinearBar();
                        this.props.clickHandler(url)
                        this.props.setNotify(data.payload.message, 'success')
                    }
                })
                .catch(err => {
                    this.props.setNotify("Erreur est survenue, Réessayez plus tard", 'error')
                    this.props.handleOpenLinearBar();
                });
        }
    };

    render() {
        return (
            <Modal
                className="modal-size"
                size="lg"
                show={this.props.open}
                onHide={() => this.props.handleEditOpen()}
                aria-labelledby="example-modal-sizes-title-lg"
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <span style={{fontWeight: "bold", color: "darkblue"}}>Choisir votre Avatar préféré</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{flexGrow: 1}}>
                        <Grid container spacing={2}>
                            {this.props.avatars.List_avatars && this.props.avatars.List_avatars.map((avatar, key) => {
                                return (
                                    <IconButton onClick={() => this.onclick(avatar.avatar)}>
                                        <Grid item key={key}>
                                            <Avatar alt="" src={avatar.avatar} className="avatarN"/>
                                        </Grid>
                                    </IconButton>
                                )
                            })}
                        </Grid>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({avatars: state.avatars, session: state.session});
export default connect(mapStateToProps)(withRouter(ModalProfilAvatar));



