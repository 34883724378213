import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import 'react-datepicker/dist/react-datepicker.css'
import {Button, CircularProgress} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {partial_update_studentAction, partial_update_teacherAction} from "../../../Redux/ActionCreators";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import {EventGoogleAnalytics} from "../../Common/Components/GoogleAnalytics";


class Formation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.session.user,
            selectedStartDate: this.props.session.user.start_date,
            selectedEndDate: this.props.session.user.end_date,
            openEdit: true,
            openProgressBar: false,
            university: this.props.session.user.university,
            degree: this.props.session.user.degree,
            study_field: this.props.session.user.study_field,
        }
    }

    handleOpenEdit = () => {
        // Event Google Analytics
        EventGoogleAnalytics("Profile_graduation_button","Button","Edit graduation")
        this.setState({
            openEdit: !this.state.openEdit,
        })
    };

    handleChangeUniversity = (event) => {
        this.setState({
            university: event.target.value,
        });
    };

    handleChangeDegree = (event) => {
        this.setState({
            degree: event.target.value,
        })
    };

    handleChangeField_Study = (event) => {
        this.setState({
            study_field: event.target.value,
        })
    };

    handleChangeStart_date = (event) => {
        this.setState({
            selectedStartDate: event.target.value,
        })
    };

    handleChangeEnd_date = (event) => {
        this.setState({
            selectedEndDate: event.target.value,
        })
    };
    // Submit graduation
    handleSubmit = () => {
        // Event Google Analytics
        EventGoogleAnalytics("Profile_graduation_button","Button","Submit graduation")
        this.setState({
            openProgressBar: !this.state.openProgressBar
        });
        const data = {
            "university": this.state.university,
            "degree": this.state.degree,
            "study_field": this.state.study_field,
            "start_date": this.state.selectedStartDate,
            "end_date": this.state.selectedEndDate,
            "user_id": this.state.user.id
        };
        if (this.state.user.is_teacher) {
            return this.props
                .dispatch(partial_update_teacherAction(data))
                .then(data => {
                    if (data.payload.Success) {
                        this.handleOpenEdit();
                        this.setState({
                            openProgressBar: !this.state.openProgressBar,
                            university: data.payload.user.university,
                            degree: data.payload.user.degree,
                            study_field: data.payload.user.study_field,
                            selectedStartDate: data.payload.user.start_date,
                            selectedEndDate: data.payload.user.end_date,
                        });
                        this.props.setNotify(data.payload.message, 'success')
                    }
                })
                .catch(err => {
                    this.props.setNotify("Erreur est survenue, Réessayez plus tard", 'error')
                    this.setState({
                        openProgressBar: !this.state.openProgressBar
                    });
                });
        } else {
            // TODO:REMOVE DUPLICATION OR REPLACE DISPATCH
            return this.props
                .dispatch(partial_update_studentAction(data))
                .then(data => {
                    if (data.payload.Success) {
                        this.handleOpenEdit();
                        this.setState({
                            openProgressBar: !this.state.openProgressBar,
                            university: data.payload.user.university,
                            degree: data.payload.user.degree,
                            study_field: data.payload.user.study_field,
                            selectedStartDate: data.payload.user.start_date,
                            selectedEndDate: data.payload.user.end_date,
                        });
                        this.props.setNotify(data.payload.message, 'success')
                    }
                })
                .catch(err => {
                    this.props.setNotify("Erreur est survenue, Réessayez plus tard", 'error')
                    this.setState({
                        openProgressBar: !this.state.openProgressBar
                    });
                });
        }
    }

    render() {
        const {
            selectedStartDate,
            selectedEndDate,
            openEdit,
            openProgressBar,
            university,
            degree,
            study_field,
        } = this.state
        return (
            <>
                <div className="bloc-formation">
                    {openEdit &&
                    <Button
                        className="edit-btn"
                        onClick={this.handleOpenEdit}
                    >
                        <EditIcon fontSize="large"/>
                    </Button>
                    }
                    {openProgressBar
                    &&
                    <CircularProgress color="secondary" className="progress-bar"/>
                    }
                    <div className="bloc-title">
                        <span>Formation</span>
                    </div>
                    {openEdit ?
                        <div id="Universit_Dipme_ou_formation_P">
                            <span>{university}<br/>{degree}<br/>{study_field}<br/>{selectedStartDate} / {selectedEndDate}</span>
                        </div>
                        :
                        <form className="Formation-Profil" noValidate autoComplete="off">
                            <Button className="save-profile" variant="contained"
                                    onClick={() => this.handleSubmit()}>
                                <CheckIcon fontSize="medium"/>
                            </Button>
                            <Button className="cancel-profile" variant="contained"
                                    onClick={this.handleOpenEdit}>
                                <CloseIcon fontSize="medium"/>
                            </Button>
                            <br/>
                            <TextField
                                className="outlined-basic"
                                label="university or school"
                                variant="outlined"
                                value={university}
                                onChange={this.handleChangeUniversity}
                            />
                            <TextField
                                className="outlined-basic"
                                label="Degree"
                                variant="outlined"
                                value={degree}
                                onChange={this.handleChangeDegree}
                            />
                            <br/>
                            <TextField
                                className="outlined-basic"
                                label="Field of study" variant="outlined"
                                value={study_field}
                                onChange={this.handleChangeField_Study}
                            />
                            <TextField
                                type="date"
                                fullWidth
                                className="date_picker-start"
                                name="start_date"
                                label="Start date graduation"
                                value={selectedStartDate}
                                onChange={this.handleChangeStart_date}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                type="date"
                                fullWidth
                                className="date_picker-start"
                                name="start_date"
                                label="End Date graduation"
                                value={selectedEndDate}
                                onChange={this.handleChangeEnd_date}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </form>
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({session: state.session});
export default connect(mapStateToProps)(withRouter(Formation));
