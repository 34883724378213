import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {
    FormControlLabel,
    Paper,
    Step,
    StepButton,
    Stepper
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import NoteIcon from '@material-ui/icons/Note';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import BookIcon from '@material-ui/icons/Book';
import {
    getCourseAdvancement,
    getCourseParts,
    updateCourseAdvancement
} from "../../../Redux/ActionCreators";
import Checkbox from "@material-ui/core/Checkbox";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

class StudentCourseContentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseParts: [],
            courseQuizzes: [],
            allParts: [],
            display: "info",
            order: 0,
            MenuIsOpen: true,
            course_id: this.props.match.params.id_course,
            course: null,
            activeStep: 0,
            completed: new Set(),
            skipped: new Set(),
            quizAnswer: [],
            resultat: false,
            advancement: 0,


        }
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.props
            .dispatch(getCourseParts(this.state.course_id))
            .then(data => {
                this.setState({
                    courseParts: data.payload.parts,
                    courseQuizzes: data.payload.quizzes,
                    course: data.payload.course[0],
                });
                let allParts = [...data.payload.quizzes, ...data.payload.parts,];
                allParts.sort((a, b) => (a.order > b.order));
                this.setState({
                    allParts: allParts,
                });

            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message
                });
            });
        this.props
            .dispatch(getCourseAdvancement(this.state.course_id))
            .then(data => {
                this.setState({
                    advancement: data.payload.advancement[0],
                    activeStep: data.payload.advancement[0].advancement,
                });
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message
                });
            });
    }

    displayPart = (part) => {
        return (
            <div className={"partContentStudent"}>
                <div className={"PartCourseTitle"}>{part.title}</div>
                <div className={"videoPart"}>
                    <video id={part.order}
                           key={part.video} controls>
                        <source src={part.video} type="video/mp4"/>
                        <source src={part.video} type="video/ogg"/>
                        Your browser does not support HTML video.
                    </video>
                </div>
                <div className={"TextPart"}>
                    <div dangerouslySetInnerHTML={{__html: part.partContent}}></div>
                </div>
            </div>
        );
    }

    displayQuize = (quiz, quizanswer) => {

        const handleChange = (e, i, j) => {
            quizanswer[i].options[j].isCorrect = e.target.checked;
        }
        const handelValid = () => {
            this.setState({
                quizAnswer: quizanswer,
                resultat: true,

            });
        }
        const Score = () => {
            const quizanswer = this.state.quizAnswer;
            let score = 0;
            let scoreQuestion = 0;
            quiz.questions.map((question, i) => {
                question.options.map((op, j) => {
                    if (op.isCorrect === quizanswer[i].options[j].isCorrect) {
                        scoreQuestion++;
                    }
                });
                score = score + (scoreQuestion / question.options.length);
                scoreQuestion = 0;
            });
            return Math.trunc((score / quiz.questions.length) * 100);
        }
        return (
            <div className={"quizStudent"}>
                <div className={"title"}>{quiz.title}</div>
                {
                    this.state.resultat ?
                        <>

                            <div className={"title"}>Score : {Score()} %</div>

                            {
                                quiz.questions.map((question, i) => (
                                    <>
                                        <div className={"question"}>{question.question}</div>
                                        {this.state.quizAnswer[i].options.map((option, j) => (
                                            <div className={"option"}>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={option.isCorrect}
                                                        // checked={true}
                                                        onChange={(e) => handleChange(e, i, j)}
                                                        name={option.option}/>}
                                                    label={option.option}
                                                    disabled
                                                />{(question.options[j].isCorrect === option.isCorrect) ?
                                                <CheckIcon style={{color: "green"}}/> :
                                                <CloseIcon style={{color: "red"}}/>
                                            }

                                            </div>
                                        ))}

                                        {question.justification !== "" ?
                                            "justification :" + question.justification :
                                            null
                                        }

                                    </>
                                ))
                            }</>
                        :
                        <>{
                            quiz.questions.map((question, i) => (
                                <>
                                    <div className={"question"}>{question.question}</div>
                                    {question.options.map((option, j) => (
                                        <div className={"option"}>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    // checked={option.isCorrect}
                                                    // checked={true}
                                                    onChange={(e) => handleChange(e, i, j)}
                                                    name={option.option}/>}
                                                label={option.option}
                                            />
                                        </div>
                                    ))}

                                </>
                            ))
                        }
                         <Button
                                    variant="outlined"
                                    color="primary"
                                        onClick={handelValid}>Valider</Button>
                        </>
                }

            </div>
        );
    }
    displayCourse = () => {
        if (this.state.allParts.length > 0) {
            const parts = this.state.allParts.filter(part => part.order === this.state.activeStep);

            if (parts.length > 0) {

                if (parts[0].questions) {
                    let quizanswer = [];
                    let options = [];
                    parts[0].questions.forEach(question => {
                        question.options.forEach(op => {
                            options.push({option: op.option, isCorrect: false});
                        });
                        quizanswer.push({question: question.question, options: options})
                        options = [];
                    });

                    return this.displayQuize(parts[0], quizanswer);
                } else {
                    return this.displayPart(parts[0]);
                }

            }
        } else {
            return <>this course is empty</>
        }
    }
    coursInfos = () => {
        const course = this.state.course;
        return (
            <div style={{marginLeft: "50px", fontFamily: "Merriweather,Georgia,serif", whiteSpace: "break-spaces"}}>
                {course ?
                    <>
                        <div style={{
                            fontSize: "40px",
                            lineHeight: "42px",
                            textAlign: "center",
                            fontFamily: "Merriweather,Georgia,serif",
                        }}>
                            {course ? course.course_intitule : null}
                        </div>
                        <div>
                            <div style={{fontSize: "25px"}}>
                                À propos de ce cours :
                            </div>
                            <p style={{fontSize: "18px",}}>{course ? course.course_description : null}</p>
                        </div>
                        <div>
                            <div style={{fontSize: "25px"}}>
                                Les objectifs de ce cours :
                            </div>
                            <p style={{fontSize: "18px",}}>{course ? course.course_objectifs : null}</p>
                        </div>
                        <div>
                            <div style={{fontSize: "25px"}}>
                                Prérequis :
                            </div>
                            <p style={{fontSize: "18px",}}>{course ? course.course_prerequis : null}</p>
                        </div>

                        <Link to={"/student/" + course.course_teacher + "/reserve/calendar"}>
                            <div style={{}}>
                                <Button style={{
                                    width: "160px",
                                    color: "white",
                                    backgroundColor: "rgb(111, 8, 236)",
                                    height: "30px",
                                    textTransform: "none",
                                }}>
                                    Réserver
                                </Button>
                            </div>
                        </Link>
                    </>
                    : null}
            </div>
        );
    }
    displayContet = () => {
        switch (this.state.display) {
            case "cours":
                return this.coursInfos();
            default :
                return this.displayCourse();
        }
    }
    allPart = () => {
        let l = this.state.courseParts;
        let b = this.state.courseQuizzes;
        l.push(...b);
        return l;
    }
    closeMenu = () => {
        this.setState({
            MenuIsOpen: !this.state.MenuIsOpen,
        });
    }

    getSteps = () => {
        let parts = []
        this.state.allParts.map(part => {
            parts.push({title: part.title, type: (part.questions ? "quiz" : "cours")});
        });
        return parts;
    }

    handleNext = () => {
        if (this.state.activeStep < this.state.advancement.advancement) {
            this.setState({
                activeStep: this.state.activeStep + 1,
                quizAnswer: [],
                resultat: false,
            });
        } else {
            const advancement = this.state.advancement;
            const data = {id: advancement.id, advancement: {advancement: this.state.activeStep + 1},}
            this.props
                .dispatch(updateCourseAdvancement(data))
                .then(data => {
                    this.setState({
                        advancement: data.payload.advancement,
                        activeStep: this.state.activeStep + 1,
                        quizAnswer: [],
                        resultat: false,
                    });
                })
                .catch(err => {
                    console.log("err", err);
                    this.setState({
                        errorGlobal: err.message
                    });
                });
        }
    };

    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1,
            quizAnswer: [],
            resultat: false,
        });
    };
    handleSkip = () => {

        this.handleNext();
        this.setState({
            skipped: new Set(this.state.skipped.values())
        });
    };
    totalSteps = () => {
        return this.getSteps().length;
    };
    handleSkip = this.handleNext;
    completedSteps = () => {
        return this.state.completed.size;
    };
    skippedSteps = () => {
        return this.state.skipped.size;
    };
    allStepsCompleted = () => {
        return this.completedSteps() === this.totalSteps() - this.skippedSteps();
    };
    isLastStep = () => {
        return this.state.activeStep === this.totalSteps() - 1;
    };

    handleStep = (step) => () => {
        this.setState({
            activeStep: step,
        })
    };

    render() {
        const {activeStep, advancement} = this.state;
        const steps = this.getSteps();
        // const course = this.state.course;
        return (

            <div>
                <div className={"sideMenu"}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const buttonProps = {optional: <Typography variant="caption">Optional</Typography>};
                            return (
                                <Step key={label.title} {...stepProps}>
                                    <StepButton
                                        onClick={this.handleStep(index)}
                                        // completed={this.isStepComplete(index)}
                                        {...buttonProps}
                                    >
                                        {label.title}
                                    </StepButton>
                                </Step>
                            );
                        })}
                        {activeStep === steps.length && (
                            <Paper square elevation={0}>
                                <Typography>All steps completed - you&apos;re finished</Typography>
                                {/*<Button onClick={this.handleReset} className={classes.button}>*/}
                                {/*  Reset*/}
                                {/*</Button>*/}
                            </Paper>
                        )}</Stepper>
                    {this.allStepsCompleted() ? (
                        <div>
                            <Typography>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Button onClick={this.handleReset}>Reset</Button>
                        </div>
                    ) : (
                        <div>
                            {/*/!*<Typography>{this.getStepContent(activeStep)}</Typography>*!/*/}
                            {/*<div>*/}
                            {/*    <Button disabled={activeStep === 0} onClick={this.handleBack}>*/}
                            {/*        Back*/}
                            {/*    </Button>*/}
                            {/*    <Button*/}
                            {/*        variant="contained"*/}
                            {/*        color="primary"*/}
                            {/*        onClick={this.handleNext}*/}
                            {/*        // className={classes.button}*/}
                            {/*    >*/}
                            {/*        Next*/}
                            {/*    </Button>*/}
                            {/*    {!this.state.completed.has(activeStep) && (*/}
                            {/*        <Button*/}
                            {/*            variant="contained"*/}
                            {/*            color="primary"*/}
                            {/*            onClick={this.handleSkip}*/}
                            {/*            // className={classes.button}*/}
                            {/*        >*/}
                            {/*            Skip*/}
                            {/*        </Button>*/}
                            {/*    )}*/}

                            {/*    {activeStep !== steps.length &&*/}
                            {/*    (this.state.completed.has(activeStep) ? (*/}
                            {/*        <Typography variant="caption">*/}
                            {/*            Step {activeStep + 1} already completed*/}
                            {/*        </Typography>*/}
                            {/*    ) : (*/}
                            {/*        <Button variant="contained" color="primary" onClick={this.handleComplete}>*/}
                            {/*            {this.completedSteps() === this.totalSteps() - 1 ? 'Finish' : 'Complete Step'}*/}
                            {/*        </Button>*/}
                            {/*    ))}*/}
                            {/*</div>*/}
                        </div>
                    )}
                </div>

                <div className={"courseDisplay"}>
                    {
                        this.displayContet()
                    }
                    <div className={"ButtonNextBack"}>
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={activeStep === 0}
                            onClick={this.handleBack}>
                            précédent
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={activeStep === (this.state.allParts.length - 1)}
                            onClick={this.handleNext}
                            className={"buttonNext"}
                        >suivant</Button>
                    </div>
                </div>
                <div className={"CourseNavigationButtom"}>
                    {/*<h1>{advancement.advancement}</h1>*/}
                    <div className="CourseNavigation">
                        <Link to="#" className={"blogLink blogLinkActive"}>
                            <ImportContactsIcon className={"icon"} onClick={this.closeMenu} fontSize={"large"}/>Vue de
                            l'ensemble
                        </Link>
                        <Link to="#" className={"blogLink "}><NoteIcon onClick={this.closeMenu}
                                                                       fontSize={"large"}/>Notes</Link>
                        <Link to="#" className={"blogLink "}><LibraryBooksIcon className={"icon"}
                                                                               onClick={this.closeMenu}
                                                                               fontSize={"large"}/>Ressources</Link>
                        <Link to="#" className={"blogLink "}><BookIcon className={"icon"} onClick={this.closeMenu}
                                                                       fontSize={"large"}/>Notes Personnelles</Link>
                        <Link to="#" className={"blogLink "}><LiveHelpIcon className={"icon"} onClick={this.closeMenu}
                                                                           fontSize={"large"}/>Cours infos</Link>
                    </div>
                </div>
            </div>
        )

    }
}

const mapStateToProps = state => ({session: state.session, data: state.data,});
export default connect(mapStateToProps)(withRouter(StudentCourseContentComponent));