import React, {Component, createRef} from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {Link, withRouter} from "react-router-dom";
import {logoutAction} from "../../../Redux/ActionCreators";
import NotificationsIcon from '@material-ui/icons/Notifications';
import {connect} from "react-redux";
import {getNotifications, Read_Notification} from "../../../Redux/ActionCreators";
import {Badge} from "reactstrap";
import {CircularProgress, Menu, MenuItem} from "@material-ui/core";
import user_inconnu from "../../../Assets/Images/user_inconnu.png";

class AdminFormationNav extends Component{
    constructor(props) {
        super(props);
        this.state={
            notificationsMenu: false,
            user:this.props.session.user,
            notificationsIsLoad: this.props.notifications.isLoad,
            notifications: this.props.notifications.List_notification,
            notificationsError: this.props.notifications.errMess,
            second:3,
            timout:new Date(),
            getRequest:true,
        };
        this.wrapper = createRef();
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if(this.state.second > 0){
            setTimeout(()=> this.setState({second: this.state.second-1}),10000);
            console.log("check 1")
        }else{
            this.setState({second: 3});
            console.log("check 2")
            if(this.state.getRequest && !this.state.notificationsError){
                this.setState({
                    getRequest: false,
                });
                console.log("check 3")
                this.props
                    .dispatch(getNotifications(this.state.user_id))
                    .then(data => {
                        console.log("then 1")
                        if(this.props.notification !== prevProps.notifications && !this.state.notificationsError){

                            this.setState({
                                notifications: data.payload.notifications,
                                notificationIsLoad: true,
                                notificationError: false,
                                getRequest: true,
                            });
                            console.log("state should be changed")
                        }
                    })
                    .catch(err=>{
                        this.setState({
                            notificationError:true,
                        });
                        console.log("err",err);
                    })
            }
            console.log("check **** 4");
            console.log(this.state.notificationIsLoad);
        }
        if(this.props.session !== prevProps.session){
            this.setState({
                user: this.props.session.user
            });
        }
    }

    handleLogout = () => {
        // Event Google Analytics
        this.props.history.push("/home");
        return this.props
            .dispatch(logoutAction())
            .then(data => {
                if (data.payload.Success) {
                    this.props.history.push("/home");
                }
            })
            .catch(err => {
                this.setState({
                    errorGlobal: err.message
                });
            });
    };

    handleClickNotificationMenu = (event)=>{
        this.setState({
            notificationsMenu: event.currentTarget
        });
    };

    readNotification = (notification) =>{
        if(!notification.read){
            this.props
                .dispatch(Read_Notification(notification.id))
                .then(data => {
                    if(data.payload.Success){
                        console.log("read is true")
                    }
                })
                .catch(err=>{
                    console.log(err.message);
                });
        }
        this.handleCloseNotificationsMenu();
    }

    handleCloseNotificationsMenu = () => {
        this.setState({
            notificationsMenu: null
        });
    }

    diffDate = (date1, date2) => {
        const result = Math.abs(date1 - date2);
        if (result <= 1000) {
            return "maintenant";
        } else {
            if (result < 1000 * 60) {
                return "Il y a " + Math.floor(result / 1000) + " seconds";
            } else {
                if (result < 1000 * 60 * 60) {
                    if (Math.floor(result / (1000 * 60)) === 1) {
                        return "Il y a une minute";
                    } else {
                        return "Il y a " + Math.floor(result / (1000 * 60)) + " minutes";
                    }
                } else {
                    if (result < 1000 * 60 * 60 * 24) {
                        if (Math.floor(result / (1000 * 60 * 60)) === 1) {
                            return "Il y a environ une heure";
                        } else {
                            return "Il y a " + Math.floor(result / (1000 * 60 * 60)) + " heures";
                        }
                    } else {
                        if (result < 1000 * 60 * 60 * 24 * 40) {
                            if (Math.floor(result / (1000 * 60 * 60 * 24)) === 1) {
                                return "Il y a un jour";

                            } else {
                                //console.log(result/100);
                                return "Il y a " + Math.floor(result / (1000 * 60 * 60 * 24)) + " jours";
                            }
                        } else {
                            return "il y a longtemps"
                        }
                    }
                }
            }
        }
    }

    render(){
        return(
            <AppBar
                color="default"
                position="static"
                ref={this.wrapper}
                >
                <Toolbar>
                    <div className="grow-bar">
                        <Link to={"/"}>
                            <div className="app-name">
                                <span>TalentEd</span> <br/>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <IconButton
                            aria-controls="notifications-menu"
                            aria-haspopup="true"
                            onClick={(e) =>this.handleClickNotificationMenu(e)}
                            aria-label="new notifications"
                            color="inherit">
                            <Badge
                                badgeContent={this.state.notificationIsLoad ? this.state.notifications.filter(element => !element.read).length:null}
                                color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    </div>
                    <Menu
                        className="notifications"
                        id="notifications-menu"
                        anchorEl={this.state.notificationsMenu}
                        keepMounted
                        open={Boolean(this.state.notificationsMenu)}
                        onClose={this.handleCloseNotificationsMenu}
                        style={{"maxHeight": "450px", "marginRight": "30px"}}>
                        <div style={{"marginLeft": "10px", "fontSize": "22px", "width": "300px"}}>
                            Notifications
                        </div>
                        {
                            this.state.notificationIsLoad ?
                                <>
                                    {
                                        this.state.notifications.slice(0,6).map((notification) =>(
                                            <MenuItem onClick={() =>{
                                            this.readNotification(notification);
                                            }}
                                                className={notification.read ? "notification_container" : "notification_container notification_container_read"}
                                                key={notification.id}>
                                                <div
                                                    className={notification.read ? "message_notif message_not_read" : "message_notif"}
                                                >
                                                    {notification.message}
                                                </div>
                                                <div
                                                    className={notification.read ? "date_notif" : "date_notif date_notif_not_read"}
                                                >
                                                    {this.diffDate(new Date(notification.notif_data), new Date())}
                                                </div>
                                                {!notification.read ?
                                                    <div className="notification_point_read"></div>
                                                    : null
                                                }
                                                <div className="notification_sender">
                                                    <img className="notifications_sender_picture" alt=""
                                                        src={notification.notification_pic ?
                                                        notification.notification_pic :
                                                        user_inconnu}
                                                    />
                                                </div>
                                            </MenuItem>
                                        ))
                                    }
                                    {
                                        this.state.notifications.length === 0 ?
                                            <div className="display_all_notification">
                                                Aucune notification
                                            </div>
                                            :
                                            <div className="display_all_notification">
                                                <Link onClick={this.handleCloseNotificationsMenu} to={"notifications"}>
                                                    Afficher tous les notifications
                                                </Link>

                                            </div>
                                    }

                                </>
                                :
                                <MenuItem className="loading_notification"
                                         >
                                        <CircularProgress />
                                </MenuItem>

                        }
                    </Menu>
                    <div>
                        <button className="btn btn-danger " onClick={() => this.handleLogout()}>Logout</button>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }
}

const mapStateToProps = state => ({ session: state.session,notifications: state.notifications});
export default connect(mapStateToProps)(withRouter(AdminFormationNav));