import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {Button} from '@material-ui/core';

function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        isOpen={props.show}
        toggle={props.onHide}
        centered
      >
        <ModalHeader className="Nouveau_message_cw" toggle={props.onHide}>Détails sur votre professeur</ModalHeader>
        <ModalBody>
          <h4>{props.tittle}:</h4>
          <p>
            {props.body}
          </p>

          <ModalFooter className="prof-modal-footer">
            <Button className="cancel_btn" onClick={props.onHide} variant="contained" >Fermer</Button>
            <Button className="choisir_un_creneau" onClick={() => props.handleChangeRoute(props.teacher_id)} variant="contained">Choisir un créneau</Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    );
  }

export default MyVerticallyCenteredModal;
