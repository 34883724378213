import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import {Cours} from './Cours';
import {useHistory, withRouter} from "react-router-dom";

import mathImg from "../../../../../../Assets/Images/maths.jpg";
import maths_isometrie from "../../../../../../Assets/Images/anglais_grammaire.jpg";
import philo_platon from "../../../../../../Assets/Images/platon_Le_banquet.png";
import {CircularProgress} from "@material-ui/core";
import {AddCoursButton} from "./AddCoursButton";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import {getImage} from "../../../../CommonFunction"

const images = {
    "Mathématique": mathImg,
    "Physique": maths_isometrie,
    "Philosophie": philo_platon,
}
// const student_courses = [
//     {
//         "course_module": 'Analyse',
//         "image": mathImg,
//         "course_description": 'Test' ,
//         "matiere_name": 'Mathématique',
//     },
//     {
//         "image": mathImg,
//         "course_description": 'Test' ,
//         "course_module": 'Analyse',
//         "matiere_name": 'Mathématique',
//     },
//     {
//         "image": mathImg,
//         "course_description": 'Test test',
//         "course_module": 'Analyse',
//         "matiere_name": 'Physic',
//     },
//     {
//         "image": mathImg,
//         "course_description": 'Test' ,
//         "course_module": 'Analyse',
//         "matiere_name": 'Mathématique',
//     }
//
// ]

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        width: "100%",
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        flex: 1
    },
    title: {
        color: theme.palette.primary.light,
    },

}));


function SingleLineGridList(props) {
    const classes = useStyles();

    const history = useHistory()

    const routeChange = (cours) => {
        let path;
        if (props.user.is_student) {
            path = `/student/${props.user.id}/courses/chapter/${cours.id}`;
        }
        if (props.user.is_teacher) {
            path = `/teacher/${props.user.id}/courses/chapter/${cours.id}`;
        }
        history.push(path)
    }
    const GoToCourseContent=(course)=>{
        console.log(course);
        let path = '/teacher/course/content';
        history.push(
                    {pathname: path,
                        state: {course: course}
                    })

    }
    const getSystemEducation=(course)=>{
        const e =props.data.system_education.filter(system=>{return course.course_systeme===system.id});
        return e.length>0 ? e[0].system_name : "";
    }
    return (
        <div className={classes.root}>
            {props.data.isLoad ?
                <GridList className={classes.gridList} cols={4} cellHeight={290}>
                    {props.user.is_teacher && <AddCoursButton addCourse="Click to add course"/>}
                    {props.user.is_teacher && props.data.techear_courses && props.data.techear_courses.slice(0, 10).map((course, key) => (
                        <GridListTile key={key}>
                              {/*<Button onClick={()=>GoToCourseContent(course)} >course content</Button>*/}
                            <Cours
                                changeroutes={(course) => routeChange(course)}
                                imageDefault={images[course.course_matiere.matiere_name]}
                                image={getImage(course)}
                                nom={course.course_matiere.matiere_name}
                                chapitre={course.course_module}
                                description={course.course_description}
                                user={props.user}
                                course={course}
                                system={getSystemEducation(course)}
                                matiere={course.course_matiere.matiere_name}
                            />

                        </GridListTile>
                    ))}
                    {props.user.is_student && props.data.course_achats.slice(0, 10).map((course, key) => (
                        <GridListTile key={key}>
                            <Cours
                                // changeroutes={(course.achat_course) => routeChange(course.achat_course)}
                                image={getImage(course.achat_course)}
                                nom={course.achat_course.matiere_name}
                                chapitre={course.achat_course.course_module}
                                description={course.achat_course.course_description}
                                user={props.user}
                                course={course.achat_course}
                                system={getSystemEducation(course.achat_course)}
                            />
                        </GridListTile>
                    ))}
                </GridList>
                :
                <CircularProgress color="secondary" className="progress-bar"/>
            }
        </div>
    );
}

const mapStateToProps = state => ({data: state.data});
export default connect(mapStateToProps)(withRouter(SingleLineGridList));