import React, {Component, Fragment} from "react";
import Paper from '@material-ui/core/Paper';
import {EditingState, IntegratedEditing, ViewState} from '@devexpress/dx-react-scheduler';
import {
    DateNavigator,
    Toolbar,
    TodayButton,
    Scheduler,
    DayView,
    WeekView,
    MonthView,
    Appointments,
    AppointmentForm,
    AppointmentTooltip,
    EditRecurrenceMenu,
    ConfirmationDialog,
    ViewSwitcher,
    Resources
} from '@devexpress/dx-react-scheduler-material-ui';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {red} from '@material-ui/core/colors';
import {
    addTeacherSeanceAction,
    deleteTeacherSeanceAction,
    getTeacherSeancesAction, updateSeanceAction
} from "../../../Redux/ActionCreators";
import {PageViewGoogleAnalytics} from "../../Common/Components/GoogleAnalytics";

const currentDate = new Date();

const TimeTableCell = ({ onDoubleClick, ...restProps }) => {
    return <DayView.TimeTableCell onClick={onDoubleClick} {...restProps} />;
};

// to delete the existing title of textEditor on appointment form
const messages = {
    moreInformationLabel: '',
};

// to delete the existing textEditor on appointment form
const TextEditor = (props) => {
    // eslint-disable-next-line react/destructuring-assignment
    if (props.type === 'multilineTextEditor') {
        return null;
    } return <AppointmentForm.TextEditor {...props} />;
};

// to edit the color of an appointment
const Appointment = ({children, style, ...restProps}) => (
    <Appointments.Appointment
        {...restProps}
        style={{
            ...style,
            backgroundColor: restProps.data.color,
            borderRadius: '8px',
        }}
    >
        {children}
    </Appointments.Appointment>
);

class TeacherCalendarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.session.user,
            intervals: [],
            currentViewName: 'Week',
            resources: [
                {
                    fieldName: 'courseId',
                    title: 'Cours',
                    instances: this.props.techear_courses.map((course, key) => {
                        return {
                            id: course.id,
                            text: course.course_intitule+" - "+course.course_module,
                            color: red
                        }
                    }),
                },
            ],
        };

        this.currentViewNameChange = (currentViewName) => {
            this.setState({ currentViewName });
        };
    }

    getTeacherSeances = () => {
        let data = {
            user_id: this.props.session.user.id
        }
        return this.props
            .dispatch(getTeacherSeancesAction(data))
            .then(data => {
                if (data.payload) {
                    this.setState({
                        intervals: data.payload.teacher_seances.map((seance, key) => {
                            return {
                                id: seance.id,
                                title: seance.seance_title,
                                courseId: seance.seance_course,
                                startDate: seance.seance_startDate,
                                endDate: seance.seance_endDate,
                                allDay: seance.seance_allDay,
                                rRule: seance.rRule,
                                color: seance.seance_color,
                                exDate: seance.exDate,
                                teacherId : seance.seance_teacher,
                                seance_type: seance.seance_type,
                            }
                        }),
                    })
                }
            })
            .catch(err => {
                console.log("err", err);
            });
    }

    addTeacherSeance = (added) => {
        let data = {
            seance_title: added.title,
            seance_allDay: added.allDay,
            seance_type: "ouverte",
            rRule: added.rRule,
            seance_course: added.courseId,
            seance_teacher: this.props.session.user.id,
            seance_startDate: added.startDate,
            seance_endDate: added.endDate,
            seance_color:"green"
        }
        return this.props
            .dispatch(addTeacherSeanceAction(data))
            .then(data => {
                if (data.payload) {
                    this.setState({
                        intervals: [...this.state.intervals,{id: data.payload.seance.id,
                                    title: data.payload.seance.seance_title,
                                    courseId: data.payload.seance.seance_course,
                                    startDate: data.payload.seance.seance_startDate,
                                    endDate: data.payload.seance.seance_endDate,
                                    allDay: data.payload.seance.seance_allDay,
                                    rRule: data.payload.seance.rRule,
                                    color: data.payload.seance.seance_color,
                                    exDate: data.payload.seance.exDate,
                                    teacherId : data.payload.seance.seance_teacher,
                                    seance_type: data.payload.seance.seance_type,
                                }
                            ]
                    })
                }
            })
            .catch(err => {
                console.log("err", err);
            });
    }

    deleteTeacherSeance = (deleted) => {
        let data = {
            seance_id: deleted,
        }
        return this.props
            .dispatch(deleteTeacherSeanceAction(data))
            .then(data => {
                if (data.payload) {
                    this.setState({
                        intervals: this.state.intervals.filter(seance => seance.id !== deleted),
                    })
                }
            })
            .catch(err => {
                console.log("err", err);
            });
    }

    updateTeacherSeance = (changed) => {
        let updated_seance = changed[Object.keys(changed)[0]]
        let current_seance = this.state.intervals.filter(seance => seance.id === Object.keys(changed)[0])[0]
        let data = {
            seance_id: Object.keys(changed)[0],
            seance_title: updated_seance.title ? updated_seance.title : current_seance.title,
            seance_allDay: updated_seance.allDay ? updated_seance.allDay : current_seance.allDay,
            rRule: updated_seance.rRule ? updated_seance.rRule : current_seance.rRule,
            seance_course: updated_seance.courseId ? updated_seance.courseId : current_seance.courseId,
            exDate: updated_seance.exDate ? updated_seance.exDate : current_seance.exDate,
            seance_startDate: updated_seance.startDate ? updated_seance.startDate : current_seance.startDate,
            seance_endDate: updated_seance.endDate ? updated_seance.endDate : current_seance.endDate,
        }
        return this.props
            .dispatch(updateSeanceAction(data))
            .then(data => {
                if (data.payload) {
                    this.setState({
                        intervals: this.state.intervals.map(obj => data.payload.seance.id === obj.id ? {id: data.payload.seance.id,
                                title: data.payload.seance.seance_title,
                                courseId: data.payload.seance.seance_course,
                                startDate: data.payload.seance.seance_startDate,
                                endDate: data.payload.seance.seance_endDate,
                                allDay: data.payload.seance.seance_allDay,
                                rRule: data.payload.seance.rRule,
                                color: data.payload.seance.seance_color,
                                exDate: data.payload.seance.exDate,
                                teacherId : data.payload.seance.seance_teacher,
                                seance_type: data.payload.seance.seance_type,
                            } : obj)
                    })
                }
            })
            .catch(err => {
                console.log("err", err);
            });
    }

    componentDidMount() {
        this.getTeacherSeances();
        PageViewGoogleAnalytics("Teacher Calender", window.location.pathname + window.location.search)
    }

    commitChanges = ({ added, changed, deleted }) => {
        if (added) {
            this.addTeacherSeance(added);
        }
        if (changed) {
            this.updateTeacherSeance(changed)
        }
        if (deleted !== undefined) {
            this.deleteTeacherSeance(deleted)
        }
    }

    render() {
        const { intervals, currentViewName, resources } = this.state;
        return (
            <Fragment>
                <Paper>
                    <Scheduler
                        data={intervals}
                        locale='fr-FR'
                        height={535}
                        firstDayOfWeek={1} // the day that start the week should be the current day
                    >
                        <ViewState
                            defaultCurrentDate={currentDate}
                            currentViewName={currentViewName}
                            onCurrentViewNameChange={this.currentViewNameChange}
                        />
                        <EditingState
                            onCommitChanges={this.commitChanges}
                        />
                        <DayView
                            cellDuration={60}
                            startDayHour={6}
                            endDayHour={24}
                            timeTableCellComponent={TimeTableCell}
                        />
                        <WeekView
                            cellDuration={60}  // duration between two hours
                            startDayHour={6}  // the hour of the start of days
                            endDayHour={24} // the end hour of a day
                            startDate={currentDate} // the start day to display in the calendar
                        />
                        <MonthView
                            startDate={currentDate}
                            intervalCount={0.7} // the number of days row
                        />

                        <Toolbar />
                        <ViewSwitcher />
                        <DateNavigator />
                        <TodayButton />

                        <IntegratedEditing />
                        <EditRecurrenceMenu />
                        <ConfirmationDialog /> {/* to show confirmation popup */}
                        <Appointments
                            appointmentComponent={Appointment}
                        /> {/* to show all appointments on calendar */}
                        <AppointmentTooltip
                            showOpenButton
                            showDeleteButton
                        />

                        <AppointmentForm
                            textEditorComponent={TextEditor}
                            messages={messages}
                        /> {/* to show the input form after two clicks */}
                        <Resources
                            data={resources}
                            mainResourceName="courseId"  // to specify the principal data
                        />
                    </Scheduler>
                </Paper>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    session: state.session,
    teacher_calendar: state.teacher_calendar,
    techear_courses: state.data.techear_courses
});
export default connect(mapStateToProps)(withRouter(TeacherCalendarComponent));
