import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {EditorState} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {getArticles} from "../../Redux/ActionCreators";
import Loading from "./Loading";
import Notification from "./AlertComponent";
import {Trans} from "react-i18next";
import Carousel from "react-multi-carousel";
import {Button, Grid} from "@material-ui/core";

class PublicArticleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
            editorState: EditorState.createEmpty(),
            isLoad: this.props.articles.isLoad,
            article: this.props.articles.List_articles,
            allertStatus: false,
            notify: {message: '', type: ''},
            notyIsOpen: false,
        };
    };

    setNotify = () => {
        this.setState({
            notify: {
                message: 'error',
                type: 'error'
            },
            notyIsOpen: !this.state.notyIsOpen
        });
    }

      short = (str, n) => {
        return str.substring(0, n);
    }

    componentDidMount() {
        if (!this.state.isLoad) {
            return this.props
                .dispatch(getArticles())
                .then(data => {
                    if (data.payload) {
                        this.setState({
                            article: data.payload.article_list,
                            isLoad: true,
                        })
                    }
                })
                .catch(err => {
                    console.log("err", err);
                    this.setNotify();
                });
        }

    };

    render() {
        const articles = this.state.article.length>3 ? this.state.article.slice(0,3): this.state.article;
        const condition = articles !== undefined && articles.length>0
        return (
            <>
                {this.state.isLoad ? condition ?
                    <section className="section-3">
                        <div className="section-name">
                            <span><Trans i18nKey="translation:bloc-blog.bloc-name"></Trans></span>
                        </div>

                        <div id="Scroll_Group_2">
                            <Carousel
                                arrows={false}  partialVisible={true}
                                responsive={this.props.responsive_event}>
                                {condition ? articles.map((article, key) => {
                                    return <Grid item key={key}>
                                        <div className="events-container">
                                            <img className="event-image" alt=""
                                                 src={article.article_pic}/>
                                            <div className="blog-student_home">
                                                <div className="event-tittle">
                                                    <span> {article.title && this.short(article.title,80)}</span>
                                                </div>
                                                 {/*<div className="event-description">*/}
                                                 {/*    <span>*/}
                                                 {/*        {article.content && this.short(article.content, 70) + " ....."}*/}
                                                 {/*    </span>*/}
                                                 {/*</div>*/}
                                                {/*<div className="event-date">*/}
                                                {/*    <span><b>le {article.pub_date} </b></span>*/}
                                                {/*</div>*/}
                                                <Link
                                                    to={"Actuality/" + article.id}>
                                                    <Button type="submit" className="Lire_larticle">Lire
                                                        l'article
                                                    </Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </Grid>
                                    }): null
                                }



                                    {/*<Grid item>*/}
                                    {/*    <div className="events-container">*/}
                                    {/*        <img className="event-image" alt=""*/}
                                    {/*             src={condition ? process.env.REACT_APP_API_KEY.slice(0, -1) + this.state.article[1].article_pic : null}/>*/}
                                    {/*        <div className="image-gradient"></div>*/}
                                    {/*        <div className="event-infos">*/}
                                    {/*            <div className="event-tittle">*/}
                                    {/*                <span> {condition ? this.short(article[1].title, 30) : null}</span>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="event-description">*/}
                                    {/*    <span>*/}
                                    {/*        {condition ? this.short(article[1].content, 70) + " ....." : null}*/}
                                    {/*    </span>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="event-date">*/}
                                    {/*                <span><b>le {condition ? article[1].pub_date : null} </b></span>*/}
                                    {/*            </div>*/}
                                    {/*            <Link*/}
                                    {/*                to={condition ? "Actuality/" + article[1].id : null}>*/}
                                    {/*                <Button type="submit" className="Lire_larticle">Lire*/}
                                    {/*                    l'article</Button>*/}
                                    {/*            </Link>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="bottom-shadow"></div>*/}
                                    {/*    </div>*/}
                                    {/*</Grid>*/}

                                {/*<Grid item>*/}
                                {/*    <div className="events-container">*/}
                                {/*        <img className="event-image" alt=""*/}
                                {/*             src={condition ? process.env.REACT_APP_API_KEY.slice(0, -1) + this.state.article[2].article_pic : null}/>*/}
                                {/*        <div className="image-gradient"></div>*/}
                                {/*        <div className="event-infos">*/}
                                {/*            <div className="event-tittle">*/}
                                {/*                <span> {condition ? this.short(article[2].title, 30) : null}</span>*/}
                                {/*            </div>*/}
                                {/*            <div className="event-description">*/}
                                {/*    <span>*/}
                                {/*        {condition ? this.short(article[2].content, 70) + " ....." : null}*/}
                                {/*    </span>*/}
                                {/*            </div>*/}
                                {/*            <div className="event-date">*/}
                                {/*                <span><b>le {condition ? article[2].pub_date : null} </b></span>*/}
                                {/*            </div>*/}
                                {/*            <Link*/}
                                {/*                to={condition ? "Actuality/" + article[2].id : null}>*/}
                                {/*                <Button type="submit" className="Lire_larticle">Lire*/}
                                {/*                    l'article</Button>*/}
                                {/*            </Link>*/}
                                {/*        </div>*/}
                                {/*        <div className="bottom-shadow"></div>*/}
                                {/*    </div>*/}
                                {/*</Grid>*/}
                                {/*</Grid>*/}
                            </Carousel>
                        </div>
                        <Button
                            type="submit"
                            onClick={() => this.props.changeUserHome("Blog")}
                            className="lire_plus_article_button mb-3"
                        >Lire plus d'article</Button>
                    </section> : null
                    :
                    <div className="loading">
                        <Loading/>
                    </div>
                }
                <Notification
                    notify={this.state.notify}
                    setNotify={this.setNotify}
                    isOpen={this.state.notyIsOpen}
                />
            </>
        );
    }

}

const mapStateToProps = state => ({
        session: state.session
        ,
        articles: state.articles
    }

);
export default connect(mapStateToProps)(withRouter(PublicArticleComponent));
