import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from "react-redux";

import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import Paper from '@material-ui/core/Paper';
import {EditingState, IntegratedEditing, ViewState} from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    WeekView,
    Appointments,
    AppointmentTooltip,
    DayView,
    MonthView,
    Toolbar,
    ViewSwitcher,
    DateNavigator,
    TodayButton,
    EditRecurrenceMenu,
    ConfirmationDialog,
    Resources,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
    getCourseToCart,
    getTeacherSeancesAction,
    reservationTeachersAction, studentReservationAction,
} from "../../../Redux/ActionCreators";
import {Button} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const currentDate = new Date();

const TimeTableCell = ({ onDoubleClick, ...restProps }) => {
    return <WeekView.TimeTableCell onClick={onDoubleClick} {...restProps} />;
};

class StudentPartialCalender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.session.user,
            intervals: [],
            currentViewName: 'Week',
            visible: false,
        };
        this.currentViewNameChange = (currentViewName) => {
            this.setState({ currentViewName });
        };
    };

    // to edit the color of an appointment
    Appointment = ({children, style, ...restProps}) => (
        <Appointments.Appointment
            {...restProps}
            style={{
                ...style,
                backgroundColor: restProps.data.color,
                borderRadius: '8px',
            }}

        >
            {children}
        </Appointments.Appointment>
    );

    getTeacherSeances = () => {
        let data = {
            user_id: this.props.match.params.user_id
        }
        return this.props
            .dispatch(getTeacherSeancesAction(data))
            .then(data => {
                if (data.payload) {
                    this.setState({
                        intervals: data.payload.teacher_seances.map((seance, key) => {
                            return {
                                id: seance.id,
                                title: seance.seance_title,
                                courseId: seance.seance_course,
                                startDate: seance.seance_startDate,
                                endDate: seance.seance_endDate,
                                allDay: seance.seance_allDay,
                                rRule: seance.rRule,
                                color: seance.seance_color,
                                exDate: seance.exDate,
                                teacherId : seance.seance_teacher,
                                seance_type: seance.seance_type,
                                student_id: this.state.user.id
                            }
                        }),
                    })
                }
            })
            .catch(err => {
                console.log("err", err);
            });
    }

    // to modify and adjust the Appointment Tool Content
    appointmentToolContent = ({ props, style, ...restProps }) => {
        return (
            <AppointmentTooltip.Content {...restProps}>
                {/*Todo: should add the course name or subject*/}
                {/*<Grid container style={{paddingBottom: "10px", alignItems: "center"}}>*/}
                {/*    <Grid item xs={2} style={{height: "18px", textAlign: "center"}}>*/}
                {/*        <AttachMoneyOutlinedIcon style={{color: "rgba(0, 0, 0, 0.54)"}}/>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={10}>*/}
                {/*        <span>300 DH/h</span>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
                <Grid container style={{paddingBottom: "10px", alignItems: "center"}}>
                    <Grid item xs={2} style={{height: "18px", textAlign: "center"}}>
                        <AttachMoneyOutlinedIcon style={{color: "rgba(0, 0, 0, 0.54)"}}/>
                    </Grid>
                    <Grid item xs={10}>
                        <span>300 DH/h</span>
                    </Grid>
                </Grid>
                <div className={restProps.container}>
                    <Button
                        className="reserver-btn"
                        variant="contained"
                        onClick={()=> this.handleReserve(restProps)}
                    >Reserver</Button>
                    <Button
                        className="annuler-btn"
                        variant="contained"
                        onClick={() => this.toggleVisibility()}
                    >Annuler</Button>
                </div>
            </AppointmentTooltip.Content>
        );
    };

    componentDidMount() {
        this.getTeacherSeances();
        this.props.dispatch(reservationTeachersAction(null));
        // let data = {
        //     user_id: this.props.match.params.user_id
        // }
        // return this.props
        //     .dispatch(teacherGetCalendarAction(data))
        //     .then(data => {
        //         if (data.payload.Success) {
        //             let _data = data.payload.teacher_calendar;
        //             let max = _data.length;
        //             let i;
        //             for (i = 0; i < max; i++) {
        //                 let interval = {
        //                     endDate: moment(_data[i].end_time),
        //                     startDate: moment(_data[i].start_time),
        //                     title: _data[i].availability_name,
        //                     id: _data[i].id,
        //                     teacher_id: this.props.match.params.user_id,
        //                     student_id: this.state.user.id
        //                 }
        //                 this.setState({
        //                     intervals: this.state.intervals.concat(interval)
        //                 });
        //             }
        //         }
        //     })
        //     .catch(err => {
        //         console.log("err", err);
        //         this.setState({
        //             errorGlobal: err.message
        //         });
        //     });
    }

    toggleVisibility = () => {
      const { visible: tooltipVisibility } = this.state;
      this.setState({ visible: !tooltipVisibility });
    };
    UpdateToCart = () => {
        let data = {
            ordered_user: this.state.user.id
        };
        return this.props
            .dispatch(getCourseToCart(data))
            .then(data => {
            })
            .catch(err => {
                console.log(err.message)
            });
    }
    handleReserve = (restProps) => {
        const AppData = restProps.appointmentData;
        let data = {
            reservation_seance: AppData.id,
            reservation_startDate: AppData.startDate,
            reservation_endDate: AppData.endDate,
            reservation_student: AppData.student_id,
            reservation_color: AppData.color
        }
        return this.props
            .dispatch(studentReservationAction(data))
            .then(data => {
                if (data.payload.Success) {
                    this.toggleVisibility();
                    this.UpdateToCart();
                }
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message
                });
            });
    };

    render() {
        const { visible, intervals, currentViewName, resources } = this.state;
        return (
            <Paper>
                <Scheduler
                    data={intervals}
                    locale='fr-FR'
                    height={535}
                    firstDayOfWeek={1} // the day that start the week should be the current day
                >
                    <ViewState
                        defaultCurrentDate={currentDate}
                        currentViewName={currentViewName}
                        onCurrentViewNameChange={this.currentViewNameChange}
                    />

                    <EditingState
                        onCommitChanges={this.commitChanges}
                    />

                    <DayView
                        cellDuration={60}
                        startDayHour={6}
                        endDayHour={24}
                        timeTableCellComponent={TimeTableCell}
                    />

                    <WeekView
                        startDate={currentDate}
                        cellDuration={60}
                        startDayHour={6}
                        endDayHour={24}
                        timeTableCellComponent={TimeTableCell}
                    />
                    <MonthView
                        startDate={currentDate}
                        intervalCount={0.7} // the number of days row
                    />
                    <Toolbar />
                    <ViewSwitcher />
                    <DateNavigator />
                    <TodayButton />

                    <IntegratedEditing />
                    <EditRecurrenceMenu />
                    <ConfirmationDialog /> {/* to show confirmation popup */}

                    <Appointments
                        appointmentComponent={this.Appointment}
                    />
                    <AppointmentTooltip
                        contentComponent={this.appointmentToolContent}
                        visible={visible}
                        onVisibilityChange={this.toggleVisibility}
                        handleReserve={(evt) => this.handleReserve(evt)}
                    />

                    <Resources
                        data={resources}
                        mainResourceName="courseId"  // to specify the principal data
                    />
                </Scheduler>
            </Paper>
        );
    }
}

const mapStateToProps = state => ({ session: state.session, teacher_calendar: state.teacher_calendar });
export default connect(mapStateToProps)(withRouter(StudentPartialCalender));
