import { Grid } from '@material-ui/core';
import React from 'react';

import FavoriteTeacherList from './FavoriteTeacherList';

const FavoriteTeacher = () => {
    return (
        <div className="favorite-teacher-component">
            <div className="tittle">
                <span>
                    Vos profs favoris
                </span>
            </div>
            <Grid container spacing={1}>
                <Grid item xs={9}>
                    <FavoriteTeacherList />
                </Grid>
                <Grid item xs={3}>
                    <div className="special-offer">
                        <div className="special-offer-tittle">
                            <span>
                                Nos Offres Spéciales
                            </span>
                        </div>
                        <div className="special-offer-description">
                            <span>
                                Nous n'avons pas d'offres spéciales pour le moment.
                                <br/><br/>
                                Connectez-vous fréquemment sur Talented Academy pour découvrir toutes nos surprises.
                            </span>
                        </div>
                    </div>
                </Grid>

            </Grid>

        </div>
    );
}

export default FavoriteTeacher;
