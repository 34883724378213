import React, { Component } from 'react';

import laptop from '../../Assets/Images/laptop-2.png'
import beneficier from '../../Assets/Images/beneficier.png'
import impact from '../../Assets/Images/impact.png'
// import partenaire from '../../Assets/Images/nos_partenaire.png'

// avatar
import face_1 from "../../Assets/Images/face_co_17.png";
import face_5 from "../../Assets/Images/face_co_5.png";
import face_3 from "../../Assets/Images/face_co_4.png";
import face_4 from "../../Assets/Images/face_co_6.png";
import face_6 from "../../Assets/Images/face_co_2.png";
import face_7 from "../../Assets/Images/face_co_10.png";

import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import {Breakpoint} from "react-socks";
import {Grid} from "@material-ui/core";
import Carousel from "react-multi-carousel";
import Particles from "react-tsparticles";
import particlesOptions from "../Student/particles_background.json";

const responsive_temoignages = {
    desktop: {
        breakpoint: {max: 3000, min: 1280},
        items: 6,
        paritialVisibilityGutter: 0,
    },
    labtop: {
        breakpoint: {max: 1280, min: 1024},
        items: 6,
        paritialVisibilityGutter: 0,
    },
    tablet1: {
        breakpoint: {max: 1024, min: 560},
        items: 4,
        paritialVisibilityGutter: 30
    },
    tablet2: {
        breakpoint: {max: 560, min: 415},
        items: 3,
        paritialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {max: 415, min: 0},
        items: 2,
        paritialVisibilityGutter: 70
    },
    mobile2: {
        breakpoint: {max: 325, min: 0},
        items: 2,
        paritialVisibilityGutter: 0
    },
};

class TeacherHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    };

    render() {
        return (
            <>
                <div className="teacher-home" data-stellar-background-ratio="0.5">
                    {/*<div className="particles-wrapper">*/}
                    {/*    <Particles options={particlesOptions} />*/}
                    {/*</div>*/}
                    <div className="container">
                        <div className="row">
                            <div className="home-title">
                                <h1><Trans i18nKey="translation:en-tete-home.espace-t"></Trans></h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="home-description">
                                <div className="accompagnement">
                                    <span><Trans i18nKey="translation:en-tete-home.description"></Trans></span>
                                </div>
                                <div className="education-levels">
                                    <span><Trans i18nKey="translation:en-tete-home.education-level"></Trans></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* lancez la video */}
                <div className="lancer_video">
                    {/* section 2 */}
                    <div className="section-2">
                        <div className="row">
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <div className="Lorem_ipsum_dolor_sit_amet_con" >
                                        <p><Trans i18nKey="translation:teacher-home.description"></Trans></p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Breakpoint medium up>
                                        <div className="cyrcles">
                                            <svg className="Ellipse_15_wu">
                                                <ellipse id="Ellipse_15_wu" rx="130.5" ry="130.5" cx="130.5"
                                                         cy="130.5">
                                                </ellipse>
                                            </svg>
                                            <svg className="Ellipse_16_wv">
                                                <ellipse id="Ellipse_16_wv" rx="40" ry="40" cx="40" cy="40">
                                                </ellipse>
                                            </svg>
                                            <svg className="Ellipse_24_ww">
                                                <ellipse id="Ellipse_24_ww" rx="40" ry="40" cx="40" cy="40">
                                                </ellipse>
                                            </svg>
                                        </div>
                                    </Breakpoint>
                                </Grid>
                            </Grid>
                        </div>
                        <Link to="/inscription/enseignant">
                            <Button className="Enseignant_ss" >
                                <Trans i18nKey="translation:teacher-home.rejoindre"></Trans>
                            </Button>
                        </Link>
                    </div>

                    {/* section 3 */}
                    <div class="section_3">
                        <Breakpoint xlarge up>
                            <div className="Group_244">
                                <svg class="Ellipse_15_sp">
                                    <ellipse id="Ellipse_15_sp" rx="260" ry="260" cx="260" cy="260"></ellipse>
                                </svg>
                                <svg class="Ellipse_16_sq">
                                    <ellipse id="Ellipse_16_sq" rx="80" ry="80" cx="80" cy="80"></ellipse>
                                </svg>
                            </div>
                        </Breakpoint>

                        <div className="Avantage_1_tj">
                            <div className="container">
                                <div className="row">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={5}>
                                            <div className="image-container">
                                                <img src={laptop} alt="" />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={7}>
                                            <div className="pointilles-1">
                                                <svg className="Line_40" viewBox="0 0 10 91.249">
                                                    <path id="Line_40" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                                <svg className="Line_41" viewBox="0 0 10 91.249">
                                                    <path id="Line_41" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                                <svg className="Line_42" viewBox="0 0 10 91.249">
                                                    <path id="Line_42" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                                <svg className="Line_43" viewBox="0 0 10 91.249">
                                                    <path id="Line_43" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                                <svg className="Line_44" viewBox="0 0 10 91.249">
                                                    <path id="Line_44" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                                <svg className="Line_45" viewBox="0 0 10 91.249">
                                                    <path id="Line_45" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                            </div>
                                            <div className="avantage-argument">
                                                <span><Trans i18nKey="translation:teacher-home.benef-1.name"></Trans></span>
                                                <div className="avantage-description">
                                                    <span><Trans i18nKey="translation:teacher-home.benef-1.description"></Trans></span>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>

                        <div className="Avantage_1_tj">
                            <div className="container">
                                <div className="row">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={7}>
                                            <div className="pointilles-2">
                                                <svg className="Line_40_s" viewBox="0 0 10 91.249">
                                                    <path id="Line_40_s" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                                <svg className="Line_41_s" viewBox="0 0 10 91.249">
                                                    <path id="Line_41_s" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                                <svg className="Line_42_s" viewBox="0 0 10 91.249">
                                                    <path id="Line_42_s" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                                <svg className="Line_43_s" viewBox="0 0 10 91.249">
                                                    <path id="Line_43_s" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                                <svg className="Line_44_ta" viewBox="0 0 10 91.249">
                                                    <path id="Line_44_ta" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                                <svg className="Line_45_tb" viewBox="0 0 10 91.249">
                                                    <path id="Line_45_tb" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                            </div>
                                            <div className="avantage-argument-2">
                                                <span><Trans i18nKey="translation:teacher-home.benef-2.name"></Trans></span>
                                            </div>
                                            <div className="avantage-description">
                                                <span><Trans i18nKey="translation:teacher-home.benef-2.description"></Trans></span>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <div className="image-container">
                                                <img src={beneficier} alt="" />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                        <div className="Avantage_1_tj">
                            <div className="container">
                                <div className="row">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={5}>
                                            <div className="image-container">
                                                <img src={impact} alt="" />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={7}>
                                            <div className="pointilles-3">
                                                <svg className="Line_40_td" viewBox="0 0 10 91.249">
                                                    <path id="Line_40_td" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                                <svg className="Line_41_te" viewBox="0 0 10 91.249">
                                                    <path id="Line_41_te" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                                <svg className="Line_42_tf" viewBox="0 0 10 91.249">
                                                    <path id="Line_42_tf" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                                <svg className="Line_43_tg" viewBox="0 0 10 91.249">
                                                    <path id="Line_43_tg" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                                <svg className="Line_44_th" viewBox="0 0 10 91.249">
                                                    <path id="Line_44_th" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                                <svg className="Line_45_ti" viewBox="0 0 10 91.249">
                                                    <path id="Line_45_ti" d="M 0 0 L 0 91.24853515625">
                                                    </path>
                                                </svg>
                                            </div>
                                            <div className="avantage-argument-last">
                                                <span><Trans i18nKey="translation:teacher-home.benef-3.name"></Trans></span>
                                                <div className="avantage-description">
                                                    <span><Trans i18nKey="translation:teacher-home.benef-3.description"></Trans></span>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* section 4 */}
                    <div className="section_4">
                        <div className="row Group_245">
                            <Breakpoint large up>
                                <svg className="Ellipse_15_sm">
                                    <ellipse id="Ellipse_15_sm" rx="200" ry="200" cx="200" cy="200">
                                    </ellipse>
                                </svg>
                            </Breakpoint>
                            <svg class="Ellipse_16_sn">
                                <ellipse id="Ellipse_16_sn" rx="60" ry="60" cx="60" cy="60">
                                </ellipse>
                            </svg>
                        </div>
                        <div className="Rectangle_255">
                            <div className="container btn-container">
                                <Link to="/inscription/enseignant">
                                    <Button className="Enseignant_ss" >
                                        <Trans i18nKey="translation:teacher-home.rejoindre"></Trans>
                                    </Button>
                                </Link>
                            </div>
                            <svg class="Ellipse_23">
                                <ellipse id="Ellipse_23" rx="80" ry="80" cx="80" cy="80">
                                </ellipse>
                            </svg>

                            <div className="container">
                                <div className="row student-temoin" id="Entte_tz">
                                    <div className="student-temoin-title">
                                        <span><Trans i18nKey="translation:bloc-temoignage.bloc-name"></Trans></span>
                                    </div>
                                </div>
                            </div>

                            <Carousel
                                className="row student-temoin"
                                arrows={false} showDots={window.innerWidth > 1200 ? false: true}
                                partialVisible={true}
                                responsive={responsive_temoignages}
                            >
                                <div className="col-2">
                                    <div className="student-temoin-container">
                                        <div className="image-container">
                                            <img className="student-picture" src={face_6} alt="" />
                                        </div>
                                        <div className="temoin-name">
                                            <span>Ayoub</span>
                                        </div>
                                        <div className="temoin-tittle">
                                            <span>Prépa Mp*</span>
                                        </div>
                                        <div className="temoin-text">
                                            <span>Élève en classe prépa, je prends des cours particuliers avec
                                                un centralien sur la plateforme TalentED. Je fais une
                                                nette amélioration de mes résultats. Grace à son accompagnement,
                                                je me projette facilement dans une grande
                                                école d’ingénieurs.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="student-temoin-container">
                                        <div className="image-container">
                                            <img className="student-picture" src={face_7} alt="" />
                                        </div>
                                        <div className="temoin-name">
                                            <span>Imane</span>
                                        </div>
                                        <div className="temoin-tittle">
                                            <span>Elève-ingénieur </span>
                                        </div>
                                        <div className="temoin-text">
                                            <span>A cause de la charge de travail, je n’ai pas pu réviser mon
                                                anglais pour le TOIEC. Heureusement que j’ai trouvé à temps
                                                le programme intensif de TalentED Academy. J&#39;ai vite
                                                progressé en quelques séances et atteindre un bon score.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="student-temoin-container">
                                        <div className="image-container">
                                            <img className="student-picture" src={face_4} alt="" />
                                        </div>
                                        <div className="temoin-name">
                                            <span>Adam</span>
                                        </div>
                                        <div className="temoin-tittle">
                                            <span>Prof'</span>
                                        </div>
                                        <div className="temoin-text">
                                            <span>Je donne des cours via la plateforme numérique TalentED
                                                Academy uniquement le soir. Ses différentes fonctionnalités
                                                me facilitent l’organisation de mes séances. Je suis satisfait
                                                du cadre de travail et du respect des délais du paiement.
                                                    </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="student-temoin-container">
                                        <div className="image-container">
                                            <img className="student-picture" src={face_3} alt="" />
                                        </div>
                                        <div className="temoin-name">
                                            <span>Nassima</span>
                                        </div>
                                        <div className="temoin-tittle">
                                            <span>Lycéenne</span>
                                        </div>
                                        <div className="temoin-text">
                                            <span>J&#39;ai découvert la plateforme digitale TalentED Academey
                                                par hasard sur Facebook. J&#39;ai bénéficié d’un premier cours
                                                gratuit. Depuis, je prends un cours par semaine tout en
                                                économisant une heure de déplacement pour mes révisions. Je la
                                                recommande vivement.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="student-temoin-container">
                                        <div className="image-container">
                                            <img className="student-picture" src={face_5} alt="" />
                                        </div>
                                        <div className="temoin-name">
                                            <span>Amine</span>
                                        </div>
                                        <div className="temoin-tittle">
                                            <span>Lycéen, mission française</span>
                                        </div>
                                        <div className="temoin-text">
                                            <span>J’avais besoin de rattraper deux chapitres de physique en très peu du
                                                temps avant l’examen. J’ai réussi à les maitriser grâce à un élève
                                                centralien qui m’a en plus partagé pleins d’astuces. Voila mon examen
                                                bien réussi !
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="student-temoin-container">
                                        <div className="image-container">
                                            <img className="student-picture" src={face_1} alt="" />
                                        </div>
                                        <div className="temoin-name">
                                            <span>Amani</span>
                                        </div>
                                        <div className="temoin-tittle">
                                            <span>Elève-ingénieur</span>
                                        </div>
                                        <div className="temoin-text">
                                            <span>
                                                J’ai suivi des séances de coaching qui m’ont aidées à
                                                surmonter mes difficultés et aborder efficacement mes
                                                difficultés. Merci à mon coach et à l&#39;équipe TalentED
                                                Acasemey !
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>

                            {/*<div className="container">*/}
                            {/*    <div className="row">*/}
                            {/*        <div id="Group_231_vd">*/}
                            {/*            <span>Nos partenaires</span>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className="row">*/}
                            {/*        <img id="nos_partenaire_vh" src={partenaire} alt=""/>    */}
                            {/*    </div>                                */}
                            {/*</div>*/}
                            <div className="row inscrivez-vous">
                                <Link style={{ margin: "auto" }} to="/inscription/enseignant">
                                    <Button className="Enseignant_btn" >Rejoignez-nous</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                </div>
            </>
        );
    }
}
export default TeacherHome;