import React, {useState} from 'react'
import {Button, TextField} from "@material-ui/core";

// Languages
const langues = [
    {
        value: 'Français',
        label: 'Français',
    },
    {
        value: 'Anglais',
        label: 'Anglais',
    },
    {
        value: 'Arabe',
        label: 'Arabe',
    },
];

export function FilterTeachersParams(props) {

    let obj = {params: {}}

    const [selectSystem, SetSelectSystem] = useState(undefined);
    const [selectLevel, SetSelectLevel] = useState(undefined);
    const [selectLanguage, SetselectLanguage] = useState(undefined);
    const [selectSubject, SetselectSubject] = useState(undefined);

    const handleChangeEducationSystem = (event) => {
        SetSelectSystem(event.target.value);
    }

    const GetData = () => {
        if (selectSystem) {
            obj.params.education_system =selectSystem
        }
        if (selectLanguage) {
            obj.params.language = selectLanguage
        }
        if (selectSubject) {
            obj.params.subject = selectSubject
        }
        if (selectLevel) {
            obj.params.level = selectLevel
        }
        props.clickHandler(obj)
    }

    const handleChangeLevel = (event) => {
        SetSelectLevel(event.target.value)
    }
    const handleChangeLanguage = (event) => {
        SetselectLanguage(event.target.value)
    }
    const handleChangeSubject = (event) => {
        SetselectSubject(event.target.value)
        //console.log(selectSubject)
    }
    return (
        <div className="research-params">
            <span> Filter Courses by</span>
            <div className="search-params-content">
                <TextField
                    id="outlined-select-currency-native"
                    select
                    fullWidth
                    className="outlined-select"
                    name="Systéme"
                    label="Systéme"
                    value={selectSystem}
                    onChange={handleChangeEducationSystem}
                    SelectProps={{
                        native: true,
                    }}
                >
                    {selectSystem === undefined ?
                        <option key="default" value="Choose">
                            Choose ...
                        </option>
                        : null
                    }
                    {props.EdducationalSystem && props.EdducationalSystem.map((option) => (
                        <option key={option.id} value={option.system_name}>
                            {option.system_name}
                        </option>
                    ))}
                </TextField>
                <TextField
                    id="outlined-select-currency-native"
                    select
                    fullWidth
                    className="outlined-select"
                    name="cours_systeme"
                    label="Niveau"
                    value={selectLevel}
                    onChange={handleChangeLevel}
                    SelectProps={{
                        native: true,
                    }}
                >
                    {selectSystem === undefined ?
                        <option key="default" value="Choose">
                            Choose  ...
                        </option>
                        : null
                    }
                        {props.EdducationalSystem && props.EdducationalSystem.filter(system=>system.system_name===selectSystem)[0] && props.EdducationalSystem.filter(system=>system.system_name===selectSystem)[0].systeme_levels.map(level=> (
                        <option key={level.id} value={level.level_name}>
                            {level.level_name}
                        </option>
                    ))}

                </TextField>
                <TextField
                    id="outlined-select-currency-native"
                    select
                    fullWidth
                    className="outlined-select"
                    name="Matiére"
                    label="Matiére"
                    value={selectSubject}
                    onChange={handleChangeSubject}
                    SelectProps={{
                        native: true,
                    }}
                >
                    {selectSubject === undefined ?
                        <option key="default" value="Choose">
                            Choose ...
                        </option>
                        : null
                    }
                    {props.Subjects && props.Subjects.map((option) => (
                        <option key={option.id} value={option.matiere_name}>
                            {option.matiere_name}
                        </option>
                    ))}
                </TextField>

                <TextField
                    id="outlined-select-currency-native"
                    select
                    fullWidth
                    className="outlined-select"
                    name="langue"
                    label="langue"
                    value={selectLanguage}
                    onChange={handleChangeLanguage}
                    SelectProps={{
                        native: true,
                    }}
                >
                    {selectLanguage === undefined ?
                        <option key="default" value="Choose">
                            Choose ...
                        </option>
                        : null
                    }
                    {langues.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
                <Button onClick={() => GetData()} className="create-course" variant="contained">Filter</Button>
            </div>
        </div>

    );
}


