import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';

class AccountActivation extends Component {
    constructor(props){
        super(props);
        this.state = {
        };
    };

    render(){
      return (
          <>
            <Row className="register-page">
              <div className="modal-activation-page">
                <Container className="modal-activation-title">
                    <h1 className="title">
                        {this.props.message}
                        <h1 className="text-email">{this.props.email}</h1>,
                        Il arrive que ce mail soit directement dirigé vers vos mails de <strong>Promotions</strong>
                        ou de <strong>Publicités</strong>. Merci de bien vouloir chercher dans ces répertoire avant
                        de contacter notre support.
                    </h1>
                </Container>
              </div>
            </Row>
          </>
      );
    }
}
export default AccountActivation;