import React from 'react';

import Chapitreliste from './ChapitreListe';


export default function CoursesList() {
  
  return (
    <div>
        <div class="chpitre_list">
            <Chapitreliste/>
        </div>
    </div>
  );
}