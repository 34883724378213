import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Button } from '@material-ui/core';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {ResetPwdAction} from '../../Redux/ActionCreators';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {ContactUs} from "./Layouts/Shared/ContactUs/ContactUs";

class ChangePwdComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            success: false,
            errorGlobal: ''
        };
    };

    handleSubmit = (values) => {
        const data = {
            "email": values.email,
        };
        return this.props
            .dispatch(ResetPwdAction(data))
            .then(data => {
                if (data.payload.Success) {
                    this.setState({
                        success: true
                    })
                }
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message
                });
            });
    };

    render(){
        const { errorGlobal } = this.state;
        return (
            <>
                <Row className="resetPwd-form">
                    <div className="modal-page">
                        <Container className="modal-title">
                            <h1 className="title">Réinitialisez votre mot de passe</h1>
                        </Container>
                        {errorGlobal && (
                            <div className="textErrors">{errorGlobal}</div>
                        )}
                        <Container className="text-container">
                            <p>Veuillez fournir l'adresse e-mail que vous avez utilisée lors de votre
                                inscription à votre compte TalenTed.
                            </p>
                        </Container>
                        {this.state.success &&
                            <Container className="alert-success success-container">
                                <p>
                                    Un e-mail contenant les instructions de réinitialisation
                                    du mot de passe a été envoyé à votre adresse e-mail.
                                </p>
                            </Container>
                        }
                        <Container className="input-container">
                            <Formik
                              initialValues={{ email: '' }}
                              validationSchema={Yup.object({
                                  email: Yup.string().email('Invalid email address').required('Required'),
                              })}
                              onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                  this.handleSubmit(values)
                                  setSubmitting(false);
                                }, 400);
                              }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                }) => (
                                    <Form>
                                        <Field
                                            name="email"
                                            value={values.email}
                                            className="text-field"
                                            placeholder="Email"
                                        />
                                        <div className="error-field"> {errors.email && touched.email && errors.email}</div>
                                        <Button
                                            type="submit"
                                            className="seconnecter"
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Envoyer e-mail
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Container>
                    </div>
                </Row>
                <ContactUs position={"pass"}/>
            </>
        );
    }
}

export default connect()(withRouter(ChangePwdComponent));
