import React, {Component} from 'react'
import OutlinedInput from "@material-ui/core/TextField";
import {Button, CircularProgress} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {partial_update_studentAction, partial_update_teacherAction} from "../../../Redux/ActionCreators";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import {EventGoogleAnalytics} from "../../Common/Components/GoogleAnalytics";


class Teacherprofildescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorGlobal: "",
            user: this.props.session.user,
            openEdit: true,
            description: this.props.session.user.description,
            openProgressBar: false
        }
    }

    handleChange = (event) => {
        this.setState({
            description: event.target.value
        });
    };

    handleOpenEdit = () => {
        // Event Google Analytics
        EventGoogleAnalytics("Profile_description_button","Button","Edit descripion")
        this.setState({
            openEdit: !this.state.openEdit
        });
    };

    // Submit description update
    handleSubmit = () => {
        // Event Google Analytics
        EventGoogleAnalytics("Profile_description_button","Button","Submit descripion")
        this.setState({
            openProgressBar: !this.state.openProgressBar
        });
        const data = {
            "description": this.state.description,
            "user_id": this.state.user.id
        };
        if (this.state.user.is_teacher) {
            return this.props
                .dispatch(partial_update_teacherAction(data))
                .then(data => {
                    if (data.payload.Success) {
                        this.setState({
                            openProgressBar: !this.state.openProgressBar
                        });
                        this.handleOpenEdit();
                        this.props.setNotify(data.payload.message, 'success')
                    }
                })
                .catch(err => {
                    this.props.setNotify("Erreur est survenue, Réessayez plus tard", 'error')
                    this.setState({
                        openProgressBar: !this.state.openProgressBar
                    });
                });
        } else {
            return this.props
                .dispatch(partial_update_studentAction(data))
                .then(data => {
                    if (data.payload.Success) {
                        this.setState({
                            openProgressBar: !this.state.openProgressBar
                        });
                        this.handleOpenEdit();
                        this.props.setNotify(data.payload.message, 'success')
                    }
                })
                .catch(err => {
                    this.props.setNotify("Erreur est survenue, Réessayez plus tard", 'error')
                    this.setState({
                        openProgressBar: !this.state.openProgressBar
                    });
                });
        }
    };

    render() {
        const {user, openEdit, description, openProgressBar} = this.state;
        return (
            <>
                <div className="profile-description">
                    {
                        openEdit
                        &&
                        <Button
                            className="edit-btn"
                            onClick={
                                this.handleOpenEdit
                            }
                        >
                            <EditIcon fontSize="large"/>
                        </Button>
                    }
                    {openProgressBar
                    &&
                    <CircularProgress color="secondary" className="progress-bar"/>
                    }
                    <div className="bloc-title">
                        <span>Description</span>
                    </div>
                    <div className="bloc-user-name">
                        <span>@{user.first_name} en quelques mots</span>
                    </div>
                    {
                        openEdit ?
                            <div className="description-text">
						<span>Présentation : {description}
						</span>
                            </div>
                            :
                            <div className="description-text">

                                <form className="FormeProfil" noValidate autoComplete="off">
                                    <OutlinedInput
                                        className="outlined-multiline"
                                        label="Presentation"
                                        value={description}
                                        onChange={this.handleChange}
                                        multiline
                                        rows={3}
                                        variant="outlined"
                                    />
                                    <Button className="save-profile" variant="contained"
                                            onClick={() => this.handleSubmit()}>
                                        <CheckIcon fontSize="medium"/>
                                    </Button>
                                    <Button className="cancel-profile" variant="contained"
                                            onClick={this.handleOpenEdit}>
                                        <CloseIcon fontSize="medium"/>
                                    </Button>
                                </form>
                            </div>
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({session: state.session});
export default connect(mapStateToProps)(withRouter(Teacherprofildescription));
