import React, {Component} from 'react';

// images for subjects
import forum from '../../Assets/Images/forum.png';
import talented from '../../Assets/Images/talented.png'
import orange11 from '../../Assets/Images/Orange11.jpeg'
import GEN from '../../Assets/Images/Global-Education-Network.jpeg'
import exam from '../../Assets/Images/exam.jpg'

import Mathématiques from '../../Assets/Images/calculating.png';
import Chimie from '../../Assets/Images/accounting.png';
import Français from '../../Assets/Images/eiffel-tower.png';
import Anglais from '../../Assets/Images/english-language.png';
import Arabe from '../../Assets/Images/Al-Isra.png';
import Economie from '../../Assets/Images/economic.png';
import Coaching from '../../Assets/Images/chief.png';
import Physiques from '../../Assets/Images/atoms.png';
import Programmation from '../../Assets/Images/data.png';

// images for courses
import work from '../../Assets/Images/class_work.png';
import course_coding from '../../Assets/Images/course_coding.png';
import afroexam from '../../Assets/Images/afroexam.png';
import data_security from "../../Assets/Images/data_security.png";
import viva from "../../Assets/Images/viva.jpeg";
import BAD from "../../Assets/Images/BAD.jpeg";

// avatar
import face_1 from "../../Assets/Images/face_co_16.png";
import face_3 from "../../Assets/Images/face_co_13.png";
import face_4 from "../../Assets/Images/face_co_6.png";
import face_5 from "../../Assets/Images/face_co_15.png";
import face_6 from "../../Assets/Images/face_co_2.png";
import face_7 from "../../Assets/Images/face_co_10.png";
import SettingsIcon from '@material-ui/icons/Settings';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Button from "react-bootstrap/Button";
import {Grid, IconButton, Menu, MenuItem} from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";

import {Trans, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    AddToCartNoAuthentifiedAction,
    getAllCoursesByModuleAction,
    getAllTeachersAction, getCourseAchat,
    getCoursesByMatiere,
    getTeacherCoursesByIntituleAction,
    getTeachersByDescriptionAction,
    reservationTeachersAction,
    searchAll
} from "../../Redux/ActionCreators";
import StudentProfComponent from "./StudentSections/StudentProfComponent";
import StudentProfComponentMobile from "./StudentSections/StudentProfComponentMobile"

import Loading from "../Common/Loading";
import PublicArticleComponent from "../Common/PublicArticleComponent";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";
import {Pagination} from "@material-ui/lab";
import {Resultcourse} from "../Common/Layouts/Shared/CourStore/ResultCourse";
import {GoRocket} from "react-icons/all";
import StudentMatieres from "./StudentMatieres";

import particlesOptions from "./particles_background.json";
import "./style.css";
import Particles from "react-tsparticles";
import StudentCoursesByModuleHeader from "./StudentCoursesByModuleHeader";

const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 7,
        paritialVisibilityGutter: 60,
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2,
        paritialVisibilityGutter: 50
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
        paritialVisibilityGutter: 30
    }
};

const responsive_event = {
    desktop: {
        breakpoint: {max: 3000, min: 1024, showDots: false},
        items: 3,
        paritialVisibilityGutter: 0,
    },
    tablet: {
        breakpoint: {max: 1024, min: 544, showDots: true},
        items: 2,
        paritialVisibilityGutter: 50
    },
    mobile: {
        breakpoint: {max: 544, min: 0, showDots: true},
        items: 1,
        paritialVisibilityGutter: 30
    }
};
const responsive_avantage = {
    desktop: {
        breakpoint: {max: 3000, min: 1280},
        items: 4,
        paritialVisibilityGutter: 0,
    },
    labtop: {
        breakpoint: {max: 1280, min: 1024},
        items: 3,
        paritialVisibilityGutter: 0,
    },
    tablet: {
        breakpoint: {max: 1024, min: 600},
        items: 2,
        paritialVisibilityGutter: 0
    },
    mobile: {
        breakpoint: {max: 600, min: 0},
        items: 1,
        paritialVisibilityGutter: 10
    }
};

const responsive_temoignages = {
    desktop: {
        breakpoint: {max: 3000, min: 1280},
        items: 6,
        paritialVisibilityGutter: 0,
    },
    labtop: {
        breakpoint: {max: 1280, min: 1024},
        items: 6,
        paritialVisibilityGutter: 0,
    },
    tablet1: {
        breakpoint: {max: 1024, min: 560},
        items: 4,
        paritialVisibilityGutter: 30
    },
    tablet2: {
        breakpoint: {max: 560, min: 415},
        items: 3,
        paritialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {max: 415, min: 0},
        items: 2,
        paritialVisibilityGutter: 70
    },
    mobile2: {
        breakpoint: {max: 325, min: 0},
        items: 2,
        paritialVisibilityGutter: 0
    },
};

const images = [
    {image: Mathématiques, subject: "Mathématiques", search: "Mathématique"},
    {image: Physiques, subject: "Physique", search: "Physique"},
    {image: Anglais, subject: "Anglais", search: "Anglais"},
    {image: Français, subject: "Français", search: "Français",},
    {image: Programmation, subject: "Programmation",  search: "Programmation",},
    {image: Coaching, subject: "Coaching",  search: "Coaching",},
    // {image: Chimie, subject: "Chimie",  search: "Chimie",},
    {image: Economie, subject: "Economie",  search: "Economie",},
    // {image: Arabe, subject: "Arabe",  search: "Arabe",},

];
let number = 0;

class StudentHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openEdit: false,
            searchText: '',
            width: window.innerWidth,
            data_All_courses: [],
            data_All_teachers: [],
            data_All_Examens: [],
            display: false,
            search: false,
            anchorEl: null,
            searchType: "Tous",
            searchLoading: false,
            endPage: 1,
            page: 1,
            searchForAll: [],
            refresh:false,
        }
    }

    handleSearch = (e) => {
        this.setState({
            searchText: e.target.value
        })
        if (this.state.searchText === '') {
            this.setState({
                search: false,
            })
        }
    };

    componentDidMount() {
        if(this.props.history.action ==="POP"){
            return this.props.history.push("/");
        }
        return this.props
            .dispatch(getAllTeachersAction())
            .then(data => {
                if (data.payload) {
                    this.setState({
                        teachers: data.payload.results,//.payload.teachers,
                    })
                }
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message
                });
            });
    };

    reservationHundler = (n) => {
        return this.props
            .dispatch(reservationTeachersAction(this.props.student.teachers[n].id))
            .then(data => {
                if (data.payload) {
                    this.setState({
                        teachers: data.payload.results,//.payload.teachers,
                    })
                }
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message
                });
            });
    }

    updateDimensions = () => {
        this.setState({width: window.innerWidth});
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    SearchAllCoursesByModule = (Subject) => {
        this.setState({
            search: true,
            searchLoading: true,
        })
        let data = {
            search: this.state.searchText
        };
        if (Subject) {
            data = {
                search: Subject
            };
            console.log("====================");
        }
        const getdata = (_data) => {
            switch (this.state.searchType) {
                case "module":
                    return getAllCoursesByModuleAction(_data);
                case "course":
                    return getTeacherCoursesByIntituleAction(_data);
                case "teachers":
                    return getTeachersByDescriptionAction(_data);
                case "matiere" :
                    return getCoursesByMatiere(_data);
                default :
                    return searchAll(_data);
            }
        }
        return this.props
            .dispatch(
                getdata(data)
            )
            .then(data => {
                if (data.payload.results) {
                    let pagesCount = data.payload.results.length;
                    // let list = data.payload.results;
                    this.setState({
                        display: true,
                        searchLoading: false,
                        data_All_courses: [],
                        searchForAll: [],
                        data_All_teachers: [],
                        data_All_Examens: [],
                        refresh:true,
                    });
                    if (this.state.searchType === "Tous") {
                        pagesCount = pagesCount + data.payload.blog_examens.length + data.payload.teachers.length;
                        let list = data.payload.results;
                        list = list.concat(data.payload.blog_examens, data.payload.teachers);
                        // ,...data.payload.teachers);
                        console.log(list);
                        this.setState({
                            data_All_teachers: data.payload.teachers,
                            data_All_Examens: data.payload.blog_examens,
                            endPage: Math.ceil((pagesCount) / 15),
                            searchForAll: list,
                        })

                        this.props.history.push("/courses_by_module");

                    } else {
                        this.setState({
                            data_All_courses: data.payload.results,
                            endPage: Math.ceil((data.payload.results.length) / 15),
                        });

                    }
                }
            })
            .catch(err => {
                console.log(err.message)
            });
    }
    handleKeypressSearch = (e) => {
        if (e.keyCode === 13) {
            this.SearchAllCoursesByModule();
        }
    }
    handleClickSetting = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        })
    };

    handleCloseSetting = () => {
        this.setState({
            anchorEl: null,

        })
    };

    handleChangeSearchType = (type) => {
        this.setState({
            searchType: type,
            searchText: "",
        });
    };
    handleChangePage = (event, value) => {
        this.setState({
            page: value,
        });
    };
    SimpleSearch=(Subject)=>{
        this.setState({
            searchText: Subject,
        });
        this.SearchAllCoursesByModule(Subject);

    }
    handleIncrement = () => {
        number += 1
        return number
    }
    AddToCart = (cours_id) =>{
        this.props.dispatch(AddToCartNoAuthentifiedAction(cours_id));
        if(this.props.session.user){
            this.props.history.push("/student/cart");
        }else{
            this.props.history.push("/connexion");
        }
    }
    render() {
        //let result = this.state.data_All_courses.filter(teacher => teacher.name.toLowerCase().includes(this.state.searchText.toLowerCase()))
        const searchPlaceholder = this.props.t("translation:en-tete-home.search");
        const {
            data_All_courses,
            data_All_teachers,
            search,
            display,
            searchLoading,
            data_All_Examens,
            endPage,
            page,
            searchForAll
        } = this.state;

        return (
            <div className="student-home">
                {/*<div className="particles-wrapper">*/}
                {/*    <Particles options={particlesOptions} />*/}
                {/*</div>*/}

                <div className="page-head" data-stellar-background-ratio="0.5">
                    <div className="container">
                        <div className="row">
                            <div className={"cours_particuliers"}>
                                Les cours particuliers innovants <GoRocket color={"#2a3051"}/>
                            </div>
                            <div className={"large_choix"}>
                                Avec les meilleurs enseignants
                            </div>
                            <div className={"presentiel_distanciel"}>
                                En Présentiel & Distanciel
                            </div>
                            <div className={"inscris_button inscris_button_anim"}>
                                <Link  to={"inscription/apprenant"}>Je m'inscris</Link>
                            </div>
                            {/*<div className={"college_lycee_prepa"}>Collège-Lycée-Prépa*/}
                            {/*</div>*/}
                            <div className="offset">
                                <Paper className="search-box">
                                    <input
                                        type="text"
                                        placeholder={searchPlaceholder}
                                        value={this.state.searchText}
                                        onKeyDown={this.handleKeypressSearch}
                                        onChange={(e) => this.handleSearch(e)}
                                    />
                                    <IconButton
                                        size="small"
                                        onClick={() => this.SearchAllCoursesByModule()}
                                        component="span"
                                    >
                                        <SearchIcon color="black"/>
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={this.handleClickSetting}
                                        component="span"
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        edge="end">
                                        <SettingsIcon className="iconSettings"/>
                                    </IconButton>
                                </Paper>
                                <Menu
                                    // id="simple-menu"
                                    className={"menu_setting_search"}
                                    getContentAnchorEl={null}
                                    anchorEl={this.state.anchorEl}
                                    keepMounted
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={this.handleCloseSetting}
                                >
                                    <MenuItem onClick={() => {
                                        this.handleCloseSetting();
                                        this.handleChangeSearchType("Tous")
                                    }} selected={this.state.searchType === "Tous"}>Tous</MenuItem>
                                    <MenuItem onClick={() => {
                                        this.handleCloseSetting();
                                        this.handleChangeSearchType("module")
                                    }} selected={this.state.searchType === "module"}>Modules</MenuItem>
                                    <MenuItem onClick={() => {
                                        this.handleCloseSetting();
                                        this.handleChangeSearchType("matiere")
                                    }} selected={this.state.searchType === "matiere"}>Matières</MenuItem>
                                    <MenuItem onClick={() => {
                                        this.handleCloseSetting();
                                        this.handleChangeSearchType("course")
                                    }} selected={this.state.searchType === "course"}>Cours</MenuItem>
                                    <MenuItem onClick={() => {
                                        this.handleCloseSetting();
                                        this.handleChangeSearchType("teachers")
                                    }} selected={this.state.searchType === "teachers"}>Enseignants</MenuItem>
                                </Menu>
                            </div>
                        </div>
                        {/*<div className="row">*/}
                        {/*    <div className="marg-text">*/}
                        {/*        <div className="text-description">*/}
                        {/*                <span>*/}
                        {/*                    <Trans i18nKey="translation:en-tete-home.description"></Trans>*/}
                        {/*                </span>*/}
                        {/*        </div>*/}
                        {/*        <div className="education-level">*/}
                        {/*            <span><Trans i18nKey="translation:en-tete-home.education-level"></Trans></span>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                {
                    // (this.state.searchText === '' && !this.state.search)
                    (this.state.searchText === '' || !this.state.search) ?
                        <div>
                            {/* section 2 */}
                            <section className="section-2">
                                <div className="container">
                                    <div className="section-name ">
                                        <span><Trans i18nKey="translation:bloc-matiere.bloc-name"></Trans></span>
                                    </div>
                                    <Carousel
                                        ssr
                                        centerMode={true}
                                        deviceType="desktop"
                                        itemClass="image-item"
                                        responsive={responsive}
                                        style={{"padding-bottom": "1em"}}
                                    >
                                        {images.slice(0, 12).map((image, key) => {
                                            return (
                                                <a to={"/"} onClick={()=>this.SimpleSearch(image.search)}>

                                                    <StudentMatieres key={key} image={image}/>
                                                </a>
                                            );
                                        })}
                                    </Carousel>
                                </div>
                            </section>

                            {!this.props.student.isLoad ? <Loading/> :

                                (window.innerWidth > 1302) ?

                                    <>
                                        {<StudentProfComponent isLoading={this.props.student.teachers}
                                                               reservationHundler={this.reservationHundler}
                                                               teachers={this.props.student.teachers.filter(e=>e.is_featured)}/>}
                                    </> :
                                    <>
                                        {<StudentProfComponentMobile isLoading={this.props.student.teachers}
                                                                     reservationHundler={this.reservationHundler}
                                                                     teachers={this.props.student.teachers.filter(e=>e.is_featured)}/>}
                                    </>

                            }
                            {/* ------------------------------------------{{{{{{{{{{{}}}}}}}}}}----------------------------------------------------- */}

                            {/* section 3 */}
                            <PublicArticleComponent changeUserHome={this.props.changeUserHome}
                                                    responsive_event={responsive_event}/>

                            {/* section 4 */}
                            <section className="section-3">
                                <div className="section-name">
                                    <span><Trans i18nKey="translation:bloc-news.bloc-name"></Trans></span>
                                </div>
                                <div id="Scroll_Group_2">
                                    <Carousel
                                        arrows={false} partialVisible={true} responsive={responsive_event}>
                                        {/*<Grid container spacing={1}  justify="center" alignItems="center">*/}
                                        {/*<Grid item>*/}
                                        {/*    <a href="https://app.seekube.com/5eme-edition-du-forum-ecc-entreprises-la-resilience-defis-humains-industriels-et-sociaux-economiques1">*/}
                                        {/*        <div className="events-container">*/}
                                        {/*            <img className="event-image" alt="" src={forum}/>*/}
                                        {/*            <div className="image-gradient"></div>*/}
                                        {/*            <div className="event-infos">*/}
                                        {/*                <div className="event-tittle">*/}
                                        {/*                    <span>Forum Ecole Centrale Casablanca</span>*/}
                                        {/*                </div>*/}


                                        {/*                <div className="event-description">*/}
                                        {/*                    <span>Regoinez DIGITED à la 5ème édition du Forum ECC-entreprises. </span>*/}
                                        {/*                </div>*/}
                                        {/*                /!*<div className="event-date">*!/*/}
                                        {/*                /!*    <span><b>28 DECEMBRE 2020</b></span>*!/*/}
                                        {/*                /!*</div>*!/*/}
                                        {/*            </div>*/}
                                        {/*            <div className="bottom-shadow"></div>*/}
                                        {/*        </div>*/}
                                        {/*    </a>*/}
                                        {/*</Grid>*/}
                                        {/*<Grid item>*/}
                                        {/*    <a href="https://www.medias24.com/2021/04/19/voici-les-dates-des-examens-scolaires-2020-2021/">*/}
                                        {/*        <div className="events-container">*/}
                                        {/*            <img className="event-image" alt="" src={exam}/>*/}
                                        {/*            <div className="image-gradient"></div>*/}
                                        {/*            <div className="event-infos">*/}

                                        {/*                <div className="event-tittle">*/}
                                        {/*                    <span>Examens et concours Maroc	</span>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="event-description">*/}
                                        {/*                    <span>Découvrez le programme des examens et concours de l’année 2020/2021 au Maroc</span>*/}
                                        {/*                </div>*/}
                                        {/*                /!*<div className="event-date">*!/*/}
                                        {/*                /!*    <span>WWW.DIGITED.TECH</span>*!/*/}
                                        {/*                /!*</div>*!/*/}

                                        {/*            </div>*/}

                                        {/*            <div className="bottom-shadow"></div>*/}
                                        {/*        </div>*/}
                                        {/*    </a>*/}
                                        {/*</Grid>*/}

                                        
                                        <Grid item>
                                            <a href="https://www.linkedin.com/posts/digited_innovation-startups-vivatechnology-activity-6940329004931403776-HCwa?utm_source=linkedin_share&utm_medium=ios_app">
                                                <div className="events-container">
                                                    <img className="event-image" alt="" src={viva}/>
                                                    <div className="image-gradient"></div>
                                                    <div className="event-infos">
                                                        <div className="event-tittle">
                                                            <span>Talented Academy à Vivatech</span>
                                                        </div>

                                                        {/* <div className="event-description">
                                                            <span>Dans un ecosystème pour une éducation plus dynamique, et surtout plus Agile!</span>
                                                        </div> */}
                                                    </div>
                                                    <div className="bottom-shadow"></div>
                                                </div>
                                            </a>
                                        </Grid>
                                        <Grid item>
                                            <a href="https://www.linkedin.com/posts/digited_partnerships-financing-education-activity-6941884986924908544-RSJW?utm_source=linkedin_share&utm_medium=ios_app">
                                                <div className="events-container">
                                                    <img className="event-image" alt="" src={BAD}/>
                                                    <div className="image-gradient"></div>
                                                    <div className="event-infos">
                                                        <div className="event-tittle">
                                                            <span>Récompensée par la BAD</span>
                                                        </div>

                                                        {/* <div className="event-description">
                                                            <span>Retrouvez TalentED Academy sur les réseaux sociaux</span>
                                                        </div> */}
                                                    </div>
                                                    <div className="bottom-shadow"></div>
                                                </div>
                                            </a>
                                        </Grid>
                                        <Grid item>
                                            <a href="https://www.facebook.com/253109491766621/posts/1183064062104488/?d=n">
                                                <div className="events-container">
                                                    <img className="event-image" alt="" src={orange11}/>
                                                    <div className="image-gradient"></div>
                                                    <div className="event-infos">
                                                        <div className="event-tittle">
                                                            <span>Innovation Edtech reconnue!</span>
                                                        </div>

                                                        <div className="event-description">
                                                            <span>Talented Academy projet innovant de DigitED finaliste du POESAM</span>
                                                        </div>
                                                    </div>
                                                    <div className="bottom-shadow"></div>
                                                </div>
                                            </a>
                                        </Grid>
                                        {/* <Grid item>
                                            <a href="https://fb.watch/8eXdjo636a/">
                                                <div className="events-container">
                                                    <img className="event-image" alt="" src={GEN}/>
                                                    <div className="image-gradient"></div>
                                                    <div className="event-infos">
                                                        <div className="event-tittle">
                                                            <span>Innovation Pedagogique, Enseigner Autrement</span>
                                                        </div>

                                                        <div className="event-description">
                                                            <span>Dans un ecosystème pour une éducation plus dynamique, et surtout plus Agile!</span>
                                                        </div>
                                                    </div>
                                                    <div className="bottom-shadow"></div>
                                                </div>
                                            </a>
                                        </Grid>
                                        <Grid item>
                                            <a href="https://www.facebook.com/TalentedAcademyAfrica">
                                                <div className="events-container">
                                                    <img className="event-image" alt="" src={talented}/>
                                                    <div className="image-gradient"></div>
                                                    <div className="event-infos">
                                                        <div className="event-tittle">
                                                            <span>TalentED Academy</span>
                                                        </div>

                                                        <div className="event-description">
                                                            <span>Retrouvez TalentED Academy sur les réseaux sociaux</span>
                                                        </div>
                                                    </div>
                                                    <div className="bottom-shadow"></div>
                                                </div>
                                            </a>
                                        </Grid> */}
                                        {/*</Grid>*/}
                                    </Carousel>
                                </div>
                            </section>

                            {/*{ section 4 }*/}



                            {/* section 5 */}
                            <section className="section-5">
                                <div id="Group_376">
                                    <div className="section-name">
                                        <span><Trans i18nKey="translation:bloc-benefices.bloc-name"></Trans></span>
                                    </div>


                                    {/*<Grid container spacing={1}  justify="center" alignItems="center">*/}

                                    <Carousel arrows={false} showDots={window.innerWidth > 1200 ? false : true}
                                              partialVisible={true}
                                              responsive={responsive_avantage}>

                                        <Grid item>
                                            <div id="Group_294">
                                                <img className="benefit-image" src={work} alt=""/>


                                                <div className="image-gradient"></div>
                                                <div id="AVOIR_LE_CHOIX">
                                                <span><Trans
                                                    i18nKey="translation:bloc-benefices.benef-1"></Trans></span>
                                                    <p className="img_description_294">De ton enseignant en toute
                                                        transparence parmi les meilleurs enseignants soigneusement
                                                        sélectionnés ... de ton rythme en présentiel ou en ligne ...
                                                        individuel ou petits groupes.</p>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div id="Group_293">
                                                <img className="benefit-image" src={afroexam} alt=""/>
                                                <div className="image-gradient"></div>
                                                <div id="GARANTI_DE_RESULTATS">
                                                <span><Trans
                                                    i18nKey="translation:bloc-benefices.benef-2"></Trans></span>
                                                    <p className="img_description_293">Nos enseignants partagent nos
                                                        valeurs
                                                        pour t'apprendre à apprendre ... développer ton potentiel et
                                                        atteindre tes meilleurs résultats avec bienveillance.</p>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div id="Group_292">
                                                <img className="benefit-image" src={course_coding} alt=""/>
                                                <div className="image-gradient"></div>
                                                <div id="LEARN_TO_LEARN">
                                                <span><Trans
                                                    i18nKey="translation:bloc-benefices.benef-3"></Trans></span>
                                                    <p className="img_description_292">Nous t'aidons à développer ton
                                                        autonomie de travail et à acquérir les soft skills nécessaires à
                                                        ton
                                                        progrès et à ton épanouissement.</p>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div id="Group_373">
                                                <img className="benefit-image" src={data_security} alt=""/>
                                                <div className="image-gradient"></div>
                                                <div id="PROTECTION_">
                                                <span><Trans
                                                    i18nKey="translation:bloc-benefices.benef-4"></Trans></span>
                                                    <p className="img_description_373">Notre support technique et
                                                        informatique protège vos données personnelles et contacts
                                                        conformément aux réglementations CNDP
                                                    </p>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Carousel>

                                </div>
                            </section>
                            {/*<section className="section-6">*/}
                            {/*    <Grid container spacing={1}>*/}
                            {/*        <Grid item xs={6} sm={3}>*/}
                            {/*            <div className="Rectangle_243">*/}
                            {/*                <div className="div-number">*/}
                            {/*                    <span>100</span>*/}
                            {/*                </div>*/}
                            {/*                <div id="Professeurs_">*/}
                            {/*                    <span><Trans i18nKey="translation:bloc-statistic.professors"></Trans></span>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </Grid>*/}
                            {/*        <Grid item xs={6} sm={3}>*/}
                            {/*            <div className="Rectangle_243">*/}
                            {/*                <div className="div-number">*/}
                            {/*                    <span>+8</span>*/}
                            {/*                </div>*/}
                            {/*                <div id="Professeurs_">*/}
                            {/*                    <span><Trans i18nKey="translation:bloc-statistic.matieres"></Trans></span>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </Grid>*/}
                            {/*        <Grid item xs={6} sm={3}>*/}
                            {/*            <div className="Rectangle_243">*/}
                            {/*                <div className="div-number">*/}
                            {/*                    <span>7523</span>*/}
                            {/*                </div>*/}
                            {/*                <div id="Professeurs_">*/}
                            {/*                    <span><Trans i18nKey="translation:bloc-statistic.heures"></Trans></span>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </Grid>*/}
                            {/*        <Grid item xs={6} sm={3}>*/}
                            {/*            <div className="Rectangle_243">*/}
                            {/*                <div className="div-number">*/}
                            {/*                    <span>754</span>*/}
                            {/*                </div>*/}
                            {/*                <div id="Professeurs_">*/}
                            {/*                    <span><Trans i18nKey="translation:bloc-statistic.student"></Trans></span>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </Grid>*/}
                            {/*    </Grid>*/}
                            {/*</section>*/}

                            {/* lancez la video */}
                            <div className="lancer_video">
                                {/* section 4 */}
                                <div className="section_4">
                                    <div className="Rectangle_255">
                                        <svg className="Ellipse_23">
                                            <ellipse id="Ellipse_23" rx="80" ry="80" cx="80" cy="80">
                                            </ellipse>
                                        </svg>

                                        <div className="container">
                                            <div className="row student-temoin" id="Entte_tz">
                                                <div className="student-temoin-title">
                                                <span><Trans
                                                    i18nKey="translation:bloc-temoignage.bloc-name"></Trans></span>
                                                </div>
                                            </div>
                                        </div>

                                        <Carousel className="row student-temoin" arrows={false}
                                                  showDots={window.innerWidth > 1200 ? false : true}
                                                  partialVisible={true} responsive={responsive_temoignages}>
                                            {/*<div className="row student-temoin" id="padding_left">*/}
                                            <div className="col-2">
                                                <div className="student-temoin-container">
                                                    <div className="image-container">
                                                        <img className="student-picture" src={face_1} alt=""/>
                                                    </div>
                                                    <div className="temoin-name">
                                                        <span>Amani</span>
                                                    </div>
                                                    <div className="temoin-tittle">
                                                        <span>Elève-ingénieur</span>
                                                    </div>
                                                    <div className="temoin-text">
                                                    <span>
                                                        J’ai suivi des séances de coaching qui m’ont aidées à
                                                        surmonter mes difficultés et les aborder efficacement. Merci à mon coach et à l&#39;équipe TalentED
                                                        Academy !
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="student-temoin-container">
                                                    <div className="image-container">
                                                        <img className="student-picture" src={face_6} alt=""/>
                                                    </div>
                                                    <div className="temoin-name">
                                                        <span>Ayoub</span>
                                                    </div>
                                                    <div className="temoin-tittle">
                                                        <span>Prépa Mp*</span>
                                                    </div>
                                                    <div className="temoin-text">
                                                    <span>Élève en classe prépa, je prends des cours particuliers avec
                                                        un centralien sur la plateforme TalentED. Je fais une
                                                        nette amélioration de mes résultats. Grace à son accompagnement,
                                                        je me projette facilement dans une
                                                        grande école d’ingénieurs.
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="student-temoin-container">
                                                    <div className="image-container">
                                                        <img className="student-picture" src={face_7} alt=""/>
                                                    </div>
                                                    <div className="temoin-name">
                                                        <span>Imane</span>
                                                    </div>
                                                    <div className="temoin-tittle">
                                                        <span>Elève-ingénieur</span>
                                                    </div>
                                                    <div className="temoin-text">
                                                    <span>A cause de la charge de travail, je n’ai pas pu réviser mon
                                                        anglais pour le TOIEC. Heureusement que j’ai trouvé à temps
                                                        le programme intensif de TalentED Academy. J&#39;ai vite
                                                        progressé en quelques séances et atteindre un bon score.
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="student-temoin-container">
                                                    <div className="image-container">
                                                        <img className="student-picture" src={face_4} alt=""/>
                                                    </div>
                                                    <div className="temoin-name">
                                                        <span>Adam</span>
                                                    </div>
                                                    <div className="temoin-tittle">
                                                        <span>Prof'</span>
                                                    </div>
                                                    <div className="temoin-text">
                                                    <span>Je donne des cours via la plateforme numérique TalentED
                                                        Academy uniquement le soir. Ses différentes fonctionnalités
                                                        me facilitent l’organisation de mes séances. Je suis satisfait
                                                        du cadre de travail et du respect des délais du paiement.
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="student-temoin-container">
                                                    <div className="image-container">
                                                        <img className="student-picture" src={face_3} alt=""/>
                                                    </div>
                                                    <div className="temoin-name">
                                                        <span>Nassima</span>
                                                    </div>
                                                    <div className="temoin-tittle">
                                                        <span>Lycéenne</span>
                                                    </div>
                                                    <div className="temoin-text">
                                                    <span>J&#39;ai découvert la plateforme digitale TalentED Academey
                                                        par hasard sur Facebook. J&#39;ai bénéficié d’un premier cours
                                                        gratuit. Depuis, je prends un cours par semaine tout en
                                                        économisant une heure de déplacement pour mes révisions. Je la
                                                        recommande vivement.
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="student-temoin-container">
                                                    <div className="image-container">
                                                        <img className="student-picture" src={face_5} alt=""/>
                                                    </div>
                                                    <div className="temoin-name">
                                                        <span>Amine</span>
                                                    </div>
                                                    <div className="temoin-tittle">
                                                        <span>Lycéen, mission française</span>
                                                    </div>
                                                    <div className="temoin-text">
                                                    <span>J’avais besoin de rattraper deux chapitres de physique en très peu du
                                                        temps avant l’examen. J’ai réussi à les maitriser grâce à un élève
                                                        centralien qui m’a en plus partagé pleins d’astuces. Voilà mon examen
                                                        bien réussi !
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Carousel>


                                        {/*<div className="container">*/}
                                        {/*    <div className="row">*/}
                                        {/*        <div id="Group_231_vd">*/}
                                        {/*            <span>Nos partenaires</span>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="row">*/}
                                        {/*        <img id="nos_partenaire_vh" src={partenaire} alt=""/>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="row inscrivez-vous">
                                            <Link style={{margin: "auto"}} to="/inscription/apprenant">
                                                <Button className="Enseignant_btn">
                                                    Rejoignez-nous
                                                    {/*<Trans*/}
                                                    {/*    i18nKey="translation:btn-inscription.inscription-student"></Trans>*/}
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        (search && searchLoading) ?
                            <Loading/>
                            :
                            (display && data_All_courses.length === 0 && data_All_teachers.length === 0 && data_All_Examens.length === 0 && searchForAll.length === 0) ?
                                <div>
                                    <div className="text-center">
                                        <StudentCoursesByModuleHeader subject={this.state.searchText} />
                                    </div>
                                    <div className="container home-search">

                                        <span> No results</span>
                                    </div>
                                </div>

                                :
                                <>
                                    <div className="text-center">
                                        <StudentCoursesByModuleHeader subject={this.state.searchText} />
                                    </div>
                                    <div className="BlogContainer">

                                        {display && data_All_courses && data_All_courses.slice((page - 1) * 15, page * 15).map((course, key) => {
                                            return (<>

                                                    {course.username ?
                                                        <div className="ResultContainer">
                                                            <img className="course-image" alt={course.username}
                                                                 src={course.profile_pic}
                                                            />

                                                            <div className="course-content">
                                                                <div className="course-title">
                                                                    <span> {course.username}</span>
                                                                </div>
                                                                <div className="course-description">
                                                                    <span>{course.description.length>70 ? course.description.slice(0,70)+"..." : course.description}</span>
                                                                </div>
                                                                {/*<div className="course-matiere">*/}
                                                                {/*    <span><b>{course.course_matiere.matiere_name} </b></span>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                            <div className="buy_course">
                                                                {/*<a*/}
                                                                {/*    href={process.env.REACT_APP_API_KEY + "media/" + examen.exam_file}>*/}
                                                                <a style={{display: "table-cell"}}
                                                                   href={"/student/" + course.id + "/reserve/calendar"}
                                                                   target="_blank" rel="noreferrer">
                                                                    <Button type="submit"
                                                                            className="Lire_larticle">Réserver </Button></a>
                                                            </div>

                                                        </div>
                                                        :
                                                        // <div className="ResultContainer">
                                                        //     <img className="course-image_search" alt=""
                                                        //          src={course.course_pic}/>
                                                        //     <div className={"courseSystem"}>
                                                        //         {this.props.data
                                                        //         && this.props.data.system_education
                                                        //         && this.props.data.system_education.filter(s => {
                                                        //             return course.course_systeme === s.id
                                                        //         }).length > 0
                                                        //         && this.props.data.system_education.filter(s => {
                                                        //             return course.course_systeme === s.id
                                                        //         })[0].system_name}
                                                        //     </div>
                                                        //     <div className={"courseMatiere"}>
                                                        //         {
                                                        //             this.props.data
                                                        //             && this.props.data.get_matieres
                                                        //             && this.props.data.get_matieres.filter(matiere => {
                                                        //                 return course.course_matiere.id === matiere.id
                                                        //             }).length > 0
                                                        //             && this.props.data.get_matieres.filter(matiere => {
                                                        //                 return course.course_matiere.id === matiere.id
                                                        //             })[0].matiere_name
                                                        //         }
                                                        //     </div>
                                                        //     <div className={"courseTitle"}>
                                                        //         {course.course_intitule}
                                                        //     </div>
                                                        //     <div className={"courseClasse"}>
                                                        //         {course.course_classe}
                                                        //     </div>
                                                        //     <div className={"courseDuree"}>
                                                        //         {course.course_duree}h
                                                        //     </div>
                                                        //     {/*<div className="buy_course">*/}
                                                        //     {/*    /!*<a*!/*/}
                                                        //     {/*    /!*    href={process.env.REACT_APP_API_KEY + "media/" + examen.exam_file}>*!/*/}
                                                        //     {/*    <a style={{display: "table-cell"}}*/}
                                                        //     {/*       href=""*/}
                                                        //     {/*       target="_blank" rel="noreferrer">*/}
                                                        //     {/*        <Button type="submit"*/}
                                                        //     {/*                className="Lire_larticle">Ajouter au panier </Button></a>*/}
                                                        //     {/*</div>*/}
                                                        //
                                                        // </div>
                                                        <div className={"course_result"}>
                                                            <Resultcourse
                                                                course={course}
                                                                system=
                                                                    {this.props.data
                                                                    && this.props.data.system_education
                                                                    && this.props.data.system_education.filter(s => {
                                                                        return course.course_systeme === s.id
                                                                    }).length > 0
                                                                    && this.props.data.system_education.filter(s => {
                                                                        return course.course_systeme === s.id
                                                                    })[0].system_name}
                                                                increment={this.handleIncrement()}
                                                                // handleAddToFavorite={(id) => this.handleAddToFavorite(id)}
                                                                clickToAddCart={(id) => this.AddToCart(id)}
                                                                // clickToAddRating={(score, course_id) => this.AddToRating(score, course_id)}

                                                            />
                                                        </div>
                                                    }
                                                </>
                                            )
                                        })}

                                        {this.state.searchType === "Tous" && display && searchForAll && searchForAll.slice((page - 1) * 15, page * 15).map(element => {
                                            if (element.username) {
                                                return (
                                                    <div className="ResultContainer">
                                                        <img className="course-image" alt=""
                                                             src={element.profile_pic}
                                                        />

                                                        <div className="course-content">
                                                            <div className="course-title">
                                                                <span> {element.username}</span>
                                                            </div>
                                                            <div className="course-description">
                                                                <span>{element.description.length>70 ? element.description.slice(0,70)+"..." : element.description}</span>
                                                            </div>
                                                            {/*<div className="course-matiere">*/}
                                                            {/*    <span><b>{course.course_matiere.matiere_name} </b></span>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                        <div className="buy_course">
                                                            {/*<a*/}
                                                            {/*    href={process.env.REACT_APP_API_KEY + "media/" + examen.exam_file}>*/}
                                                            <a style={{display: "table-cell"}}
                                                               href={"/student/" + element.id + "/reserve/calendar"}
                                                               target="_blank" rel="noreferrer">
                                                                <Button type="submit"
                                                                        className="Lire_larticle">Réserver </Button></a>
                                                        </div>

                                                    </div>
                                                )
                                            } else {
                                                if (element.exam_file) {
                                                    return(
                                                        <>

                                                            <div className="ResultContainer">
                                                                <img className="course-image" alt=""
                                                                     src={element.picture}
                                                                />

                                                                <div className="course-content">
                                                                    <div className="course-title">
                                                                        <span> {element.title}</span>
                                                                    </div>
                                                                    <div className="course-description">
                                                                        <span>{element.description.length>70 ? element.description.slice(0,70)+"..." : element.description}</span>
                                                                    </div>
                                                                    {/*<div className="course-matiere">*/}
                                                                    {/*    <span><b>{course.course_matiere.matiere_name} </b></span>*/}
                                                                    {/*</div>*/}
                                                                </div>
                                                                <div className="buy_course">
                                                                    {/*<a*/}
                                                                    {/*    href={process.env.REACT_APP_API_KEY + "media/" + examen.exam_file}>*/}
                                                                    <a style={{display: "table-cell"}}
                                                                       href={element.exam_file + ".png"}
                                                                       target="_blank" rel="noreferrer">
                                                                        <Button type="submit"
                                                                                className="Lire_larticle">ouvrir </Button></a>
                                                                </div>
                                                            </div>
                                                        </>)
                                                }
                                                else{
                                                    return (
                                                        <div className={"course_result"}>
                                                            <Resultcourse
                                                                course={element}
                                                                system=
                                                                    {this.props.data
                                                                    && this.props.data.system_education
                                                                    && this.props.data.system_education.filter(s => {
                                                                        return element.course_systeme === s.id
                                                                    }).length > 0
                                                                    && this.props.data.system_education.filter(s => {
                                                                        return element.course_systeme === s.id
                                                                    })[0].system_name}
                                                                increment={this.handleIncrement()}
                                                                user={this.props.session.authenticated}
                                                                // handleAddToFavorite={(id) => this.handleAddToFavorite(id)}
                                                                clickToAddCart={(id) => this.AddToCart(id)}
                                                                // clickToAddRating={(score, course_id) => this.AddToRating(score, course_id)}

                                                            />
                                                        </div>
                                                    )
                                                }

                                            }
                                        })

                                        }

                                        {/*{*/}
                                        {/*    this.state.searchType === "Tous" && display && data_All_teachers && data_All_teachers.map((course, key) => {*/}
                                        {/*        return (<>*/}
                                        {/*                {course.username ?*/}
                                        {/*                    <div className="ResultContainer">*/}
                                        {/*                        <img className="course-image" alt=""*/}
                                        {/*                             src={course.profile_pic}*/}
                                        {/*                        />*/}

                                        {/*                        <div className="course-content">*/}
                                        {/*                            <div className="course-title">*/}
                                        {/*                                <span> {course.username}</span>*/}
                                        {/*                            </div>*/}
                                        {/*                            <div className="course-description">*/}
                                        {/*<span>*/}
                                        {/*    {course.description}*/}
                                        {/*</span>*/}
                                        {/*                            </div>*/}
                                        {/*                            /!*<div className="course-matiere">*!/*/}
                                        {/*                            /!*    <span><b>{course.course_matiere.matiere_name} </b></span>*!/*/}
                                        {/*                            /!*</div>*!/*/}
                                        {/*                        </div>*/}
                                        {/*                        <div className="buy_course">*/}
                                        {/*                            /!*<a*!/*/}
                                        {/*                            /!*    href={process.env.REACT_APP_API_KEY + "media/" + examen.exam_file}>*!/*/}
                                        {/*                            <a style={{display: "table-cell"}}*/}
                                        {/*                               href={"/student/" + course.id + "/reserve/calendar"}*/}
                                        {/*                               target="_blank" rel="noreferrer">*/}
                                        {/*                                <Button type="submit"*/}
                                        {/*                                        className="Lire_larticle">Réserver </Button></a>*/}
                                        {/*                        </div>*/}

                                        {/*                    </div>*/}

                                        {/*                    : null*/}
                                        {/*                }*/}
                                        {/*            </>*/}
                                        {/*        )*/}
                                        {/*    })}*/}

                                        {/*{*/}
                                        {/*    this.state.searchType === "Tous" && display && data_All_Examens && data_All_Examens.map((exam, key) => {*/}
                                        {/*        return (<>*/}

                                        {/*                <div className="ResultContainer">*/}
                                        {/*                    <img className="course-image" alt=""*/}
                                        {/*                         src={exam.picture}*/}
                                        {/*                    />*/}

                                        {/*                    <div className="course-content">*/}
                                        {/*                        <div className="course-title">*/}
                                        {/*                            <span> {exam.title}</span>*/}
                                        {/*                        </div>*/}
                                        {/*                        <div className="course-description">*/}
                                        {/*<span>*/}
                                        {/*    {exam.description}*/}
                                        {/*</span>*/}
                                        {/*                        </div>*/}
                                        {/*                        /!*<div className="course-matiere">*!/*/}
                                        {/*                        /!*    <span><b>{course.course_matiere.matiere_name} </b></span>*!/*/}
                                        {/*                        /!*</div>*!/*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="buy_course">*/}
                                        {/*                        /!*<a*!/*/}
                                        {/*                        /!*    href={process.env.REACT_APP_API_KEY + "media/" + examen.exam_file}>*!/*/}
                                        {/*                        <a style={{display: "table-cell"}}*/}
                                        {/*                           href={exam.exam_file + ".png"}*/}
                                        {/*                           target="_blank" rel="noreferrer">*/}
                                        {/*                            <Button type="submit"*/}
                                        {/*                                    className="Lire_larticle">ouvrir </Button></a>*/}
                                        {/*                    </div>*/}

                                        {/*                </div>*/}

                                        {/*            </>*/}
                                        {/*        )*/}
                                        {/*    })}*/}

                                    </div>
                                    <Pagination className="pagination"
                                                defaultPage={1}
                                                count={endPage}
                                                page={page}
                                                onChange={this.handleChangePage}
                                                color="primary"/>
                                </>
                }

            </div>
        );
    }

}


const mapStateToProps = state => ( {
        student: state.student
        ,
        data: state.data
        ,
        session: state.session
    }

);
export default connect(mapStateToProps)(withRouter(withTranslation()(StudentHome)));