import React, {useEffect} from 'react';
import './App.scss';
import Main from './Pages/MainComponent';

import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {ConfigureStore} from './Redux/ConfigureStore'
import {useTranslation, I18nextProvider} from 'react-i18next';
import {BreakpointProvider} from "react-socks";

const store = ConfigureStore();

function App() {
    const {i18n} = useTranslation().i18n;
    // Goggle Analytics
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'UA-200877313-1', {
            'page_title': window.location.pathname + window.location.search,
        });
        gtag('config', 'G-3EMPKJQYC5', {
            'page_title': window.location.pathname + window.location.search,
        });
    }, [])

    return (
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <BreakpointProvider>
                    <BrowserRouter>
                        <Main/>
                    </BrowserRouter>
                </BreakpointProvider>
            </Provider>
        </I18nextProvider>
    );
}
export default App

