import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getStudentById} from "../../../Redux/ActionCreators";
import {CircularProgress} from "@material-ui/core";



class SuiviBloc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.session.user,
            student_username: '',
            isLoad: false,
        }
    }

    componentDidMount() {
        const {user} = this.state;
        const data = {
            student_id: this.props.student_id
        }
        if (user !== null && user !== undefined) {
            return this.props
                .dispatch(getStudentById(data))
                .then(data => {
                    if (data.payload) {
                        this.setState({
                            student_username: data.payload.student.username,
                            isLoad: true,
                        });
                    }
                    console.log("student", data.payload.student);
                })
                .catch(err => {
                    console.log("err", err);
                })
        }


    }

    render() {
        const {id, seance, montant, duree, date} = this.props;
        const {student_username, isLoad} = this.state;
        let formattedDate = new Date(date);
        return (
            <>
                {isLoad ?
                    <tr key={id}>
                        <td className="text-center"></td>
                        <td className="text-center">{seance}</td>
                        <td className="text-center">{student_username}</td>
                        <td className="text-center">{duree}</td>
                        <td className="text-center">{formattedDate.toLocaleDateString()}</td>
                        <td className="text-center">{montant}</td>
                    </tr>
                    :
                    <div>
                        <CircularProgress color="secondary" className="progress-bar"/>
                    </div>

                }
            </>


        )
    }

}

const mapStateToProps = state => ({ adminFormation : state.adminFormation,session: state.session});
export default connect(mapStateToProps)(withRouter(SuiviBloc));