import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {ContactUs} from "./Layouts/Shared/ContactUs/ContactUs";

class RegisterComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
        };
    };

    render(){
      return (
          <>
            <Row className="register-page">
              <div className="modal-page">
                <Container className="modal-title">
                  <h1 className="title">Je crée un compte</h1>
                </Container>
                <Row className="btns">
                  <Link className="btn" to="/inscription/apprenant">Apprenants</Link>
                  <Link className="btn" to="/inscription/enseignant">Enseignants</Link>
                  <Link className="btn" to="/inscription/parent">Parents</Link>
                </Row>
                <Container className="connectez-vous">
                  <span>Vous êtes déjà inscrit ?</span><Link to="/connexion"> Connectez-vous</Link>
                </Container>
              </div>
                <ContactUs position={"/regis"}/>
            </Row>
          </>
      );
    }
}
export default RegisterComponent;