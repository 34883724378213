import React, {Component} from "react";
import "./coursesByModuleHeader.css";
import TextAnimation from "react-animate-text";
import {Link} from "react-router-dom";
class StudentCoursesByModuleHeader extends Component{
    constructor(props) {
        super(props);

    }

    render(){
        const renderHeader = (subject) => {
            switch(subject) {

                case "Mathématique":   return (
                    <div className="jumbotron w-50  container-courses-by-module-header shadow-lg p-3 mb-5 bg-white rounded ">
                        <TextAnimation charInterval={75}>
                            <h1 className="display-4 mb-5 subject-title">{subject}</h1>
                        </TextAnimation>

                        <p className="lead">Les mathématiques requièrent une pratique régulière afin d’en percer la logique et les secrets, et d’adopter les bonnes
techniques. Nos enseignants te guideront pour intégrez les méthodes essentielles pour résoudre efficacement les sujets,
adopter les bons réflexes et aborder cette épreuve en toute sérénité.</p>
                        <hr className="my-4" />
                            <p>Nos professeurs particuliers vous accompagneront sur la durée
                                pour vous aider à combler vos éventuelles lacunes et intégrer l’ensemble des notions clés :</p>
                            <ul className="text-left m-3">
                                <li>Revue initiale des acquis : le professeur de mathématiques établira avec
                                    vous un diagnostic initial détaillé de vos connaissances et de vos besoins.</li>
                                <li>Planning sur-mesure : sur cette base, l’enseignant mettra en place un plan de travail personnalisé pour organiser
                                    votre apprentissage tout au long de l’année.</li>
                                <li>Acquérir la logique et les savoir-faire pour réussir vos vos devoirs surveillés et vos concours.</li>
                            </ul>


                            <Link className="btn commencer-button btn-lg" to="/connexion">
                                Commencer
                            </Link>
                    </div>
                );
                case "Physique": return (
                    <div className="jumbotron w-50  container-courses-by-module-header shadow-lg p-3 mb-5 bg-white rounded">
                        <TextAnimation charInterval={75}>
                            <h1 className="display-4 mb-5 subject-title" >{subject}</h1>
                        </TextAnimation>
                        <p className="lead">La physique est une science de la nature expérimentale qui étudie les phénomènes naturels et leurs évolutions. Souvent
jugée difficile par les apprenants, nous la simplifions par notre méthodologie et avec des outils ludiques pour te la rendre
accessible et même attractive.</p>
                        <hr className="my-4" />
                        <div className="d-flex justify-content-center align-items-center ">
                            <div className="w-50">
                                <p>Certaines compétences ont été retenues pour caractériser la démarche scientifique :   </p>
                                <ul className="text-left m-3">
                                    <li>s’approprier</li>
                                    <li>analyser-raisonner</li>
                                    <li>réaliser</li>
                                    <li>valider </li>
                                    <li>communiquer</li>

                                </ul>
                            </div>
                            <div className="w-50">
                                <p>Le programme s’articule en plusieurs grands thèmes : </p>
                                <ul className="text-left m-3">
                                    <li>Mecanique classqiue et quantique</li>
                                    <li>Conversions et transferts d’énergie </li>
                                    <li>Thermodynamique</li>
                                    <li>Ondes et signaux</li>
                                    <li>Electronique</li>
                                </ul>
                            </div>

                        </div>


                        <Link className="btn commencer-button btn-lg" to="/connexion">
                            Commencer
                        </Link>
                    </div>
                );
                case "Anglais": return (
                    <div className="jumbotron w-50  container-courses-by-module-header shadow-lg p-3 mb-5 bg-white rounded">
                        <div className="div-subject">
                            <TextAnimation charInterval={75}>
                                <h1 className="display-4 mb-5 subject-title" style={{fontFamily: 'Yellowtail'}}>{subject}</h1>
                            </TextAnimation>
                        </div>

                        <p className="lead">Fais de l’anglais un atout pour te démarquer en profitant d’un programme adapté à ton rythme. Des exercices variés axés
sur des sujets d’intérêt pour toi, te feront progresser sans grands efforts en vocabulaire, grammaire compréhension
orale et écrite.

Pour ta préparation du TOEIC, IELTS, ou TOEFL nos spécialistes te partageront leurs méthodes et astuces.</p>
                        <hr className="my-4" />
                        <p>Quelle que soit sa classe, pour une remise à niveau ou du perfectionnement, TalentEd Academy vous propose des cours particuliers d’anglais, à domicile, avec des professeurs particuliers qualifiés.
                            Les points de grammaire sont retravaillés et le vocabulaire s’enrichit permettant un oral plus riche et plus fluide.
                        </p>
                        <p className="text-left">
                            Ces cours, adaptés à votre niveau, couvrent les 4 aspects de la langue : compréhension orale et écrite, expression orale et écrite. Les activités et exercices effectués en cours sont basés sur des situations concrètes et abordent des problèmes de la langue anglaise auxquels vous serez confrontés au quotidien. Cela vous permet ainsi de découvrir le vocabulaire et les structures grammaticales dont vous avez besoin.
                            Le professeur vous testera dès votre arrivée, puis mettra en place un programme personnalisé. Il pourra vous proposer, parfois, de suivre certains cours d'expression orale pendant des visites ou des promenades de façon à faciliter le dialogue.
                            Divers supports de cours seront utilisés, en fonction du professeur et des thèmes abordés.
                            De manière générale les cours ont lieu du lundi au vendredi.
                            De niveau faux-débutant à avancé (A1 à C2).
                            <p>
                                Si vous souhaitez travailler sur des sujets précis : anglais des affaires, examens de Cambridge, TOEFL ou TOEIC... Rejoignez-nous !
                            </p>

                        </p>

                        <Link className="btn commencer-button btn-lg" to="/connexion">
                            Commencer
                        </Link>
                    </div>
                );
                case "Français":  return(
                    <div className="jumbotron w-50  container-courses-by-module-header ">
                        <TextAnimation charInterval={75}>
                            <h1 className="display-4 mb-5 subject-title">{subject}</h1>
                        </TextAnimation>

                        <p className="lead">Tu as besoin de parfaire ton Français ? Faire une remise à niveau ? Passer une certification?
<br/>
Nos enseignants t’accompagneront avec un large choix de méthodes d'apprentissage et te motiveront à progresser
chaque jour. Ton aisance en communication sera remarquable.</p>
                        <hr className="my-4" />

                        <p>Talented Academy vous offre de nombreuses ressources pour pratiquer votre français, et ameliorer votre esprit d'analyse.</p>

                        <Link className="btn commencer-button btn-lg" to="/connexion">
                            Commencer
                        </Link>
                    </div>
                );
                case "Programmation": return(
                    <div className="jumbotron w-50  container-courses-by-module-header shadow-lg p-3 mb-5 bg-white rounded">
                        <TextAnimation charInterval={75}>
                            <h1 className="display-4 mb-5 subject-title">{subject}</h1>
                        </TextAnimation>

                        <p className="lead">
                        Tu souhaites avoir des bases pour faire des programmes simples et rigolos? Ou bien approfondir ta maitrise des derniers
langages de programmation? Tu es à la bonne adresse!
</p>
                        <hr className="my-4" />

                        <p>Talented Academy vous offre des cours de haut niveau dans la l'informatique pour réussir les concours et approfondir dans le domaine de la programmation</p>

                        <Link className="btn commencer-button btn-lg" to="/connexion">
                            Commencer
                        </Link>
                    </div>
                );
                case "Coaching":  return(
                    <div className="jumbotron w-50  container-courses-by-module-header shadow-lg p-3 mb-5 bg-white rounded">
                        <TextAnimation charInterval={75}>
                            <h1 className="display-4 mb-5 subject-title">{subject}</h1>
                        </TextAnimation>

                        <p className="lead">
                        Talented Academy te propose de profiter de l’expertise de coachs reconnus et soigneusement sélectionnés.

Il te guideront pour surmonter les difficultés, dépasser tes croyances limitantes, passer à l'action, et atteindre ton plein
potentiel.</p>
                        <hr className="my-4" />

                        <p>
                            Coaching dans votre langue maternelle pour atteindre vos objectifs professionnels ou personnels plus rapidement .</p>

                        <Link className="btn commencer-button btn-lg" to="/connexion">
                            Commencer
                        </Link>
                    </div>
                ) ;
                // case "Chimie": return(<div className="jumbotron w-50  container-courses-by-module-header shadow-lg p-3 mb-5 bg-white rounded">
                //     <TextAnimation charInterval={75}>
                //         <h1 className="display-4 mb-5 subject-title">{subject}</h1>
                //     </TextAnimation>
                //
                //     <p className="lead">Trouves des cours qui présentent les concepts théoriques et un ensemble de ressources didactiques en chimie couvrant les principaux savoirs et savoir-faire disciplinaires et les grandes compétences des thèmes et notions de base abordés en chimie générale</p>
                //     <hr className="my-4" />
                //
                //     <p> Des cours démarrent aux bases de la chimie en posant les prérequis nécessaires à la construction de notions plus complexes permettant l'acquisition des savoirs et savoir-faire disciplinaires et compétences en chimie pour tous le niveaux.
                //     </p>
                //     <p>Le programme s’articule en plusieurs grands thèmes : </p>
                //     <ul className="text-left m-3">
                //         <li>Constitution et transformations de la matière </li>
                //         <li>Cinétique chimiqu</li>
                //         <li>Thermodynamique des systèmes chimiques</li>
                //         <li>Chimie organique</li>
                //         <li>Chimie des solutions aqueuses</li>
                //     </ul>
                //
                //     <Link className="btn commencer-button btn-lg" to="/connexion">
                //         Commencer
                //     </Link>
                // </div>);
                case "Economie":  return(
                    <div className="jumbotron w-50  container-courses-by-module-header shadow-lg p-3 mb-5 bg-white rounded">
                        <TextAnimation charInterval={75}>
                            <h1 className="display-4 mb-5 subject-title">{subject}</h1>
                        </TextAnimation>

                        <p className="lead">
                        Tu souhaites comprendre les enjeux de la production, la distribution, l'échange et la consommation de bien et services?
                        <br/>
Préparer ton parcours de Grande Ecole de Commerce? Talented Academy te donne le choix du programme le mieux
adapté à ton projet.                            </p>
                        <hr className="my-4" />

                        <p>Talented Acadamy vous offre des cours de haute qualité afin d'améliorer vos compétences en économie ainsi que préparer pour le concours CNAEM</p>

                        <Link className="btn commencer-button btn-lg" to="/connexion">
                            Commencer
                        </Link>
                    </div>
                );
                // case "Arabe":  return(
                //     <div className="jumbotron w-50  container-courses-by-module-header ">
                //         <TextAnimation charInterval={75}>
                //             <h1 className="display-4 mb-5 subject-title">{subject}</h1>
                //         </TextAnimation>
                //
                //         <p className="lead">Découvre le moyen le plus efficace d'apprendre l'arabe avec nos cours complet.</p>
                //         <hr className="my-4" />
                //
                //         <p>Préparer vous exams d'Arabe grâce à notre plateforme qui offre un enseignement accompagnés par des professeurs compétents.</p>
                //
                //         <Link className="btn commencer-button btn-lg" to="/connexion">
                //             Commencer
                //         </Link>
                //     </div>
                // );

                default:      return <h1>error</h1>
            }
        }
        return(
            <div className="text-center d-flex flex-column justify-content-center mt-5 ">

                <div className="d-flex justify-content-center">
                    {renderHeader(this.props.subject)}
                </div>
                <div className="mb-5 cours-dispo">
                    <h2>Cours disponibles :</h2>
                </div>

            </div>
        )
    }

}

export default StudentCoursesByModuleHeader;