import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {CircularProgress, Grid, TextField} from '@material-ui/core';
import {Formik, Form} from "formik";
import * as Yup from "yup";

import {addCourseAction, addCourseContinueAction, addCoursePictureAction} from "../../../../Redux/ActionCreators"
import InputAdornment from "@material-ui/core/InputAdornment";

const langues = [
    {
        value: 'Français',
        label: 'Français',
    },
    {
        value: 'Anglais',
        label: 'Anglais',
    },
    {
        value: 'Arabe',
        label: 'Arabe',
    },
];

class AddCoursModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accept: false,
            errorGlobal: "",
            user: this.props.session.user,
            open: this.props.open,
            modalPage: 1,
            education_systems: this.props.data.system_education,
            matieres: this.props.data.get_matieres,
            selected_system: "",
            selected_niveau: "",
            selected_module: "",
            selected_matiere: "",
            selected_classe: "",
            selected_langue: "",
            selected_type: "",
            selected_pic: null,
            submitted : false,
        }
    }

    EditModal = () => {
        this.setState({
            errorGlobal: ""
        });
        this.props.handleOpenModal();
    };

    getFirstValidationSchema = () => {
        return Yup.object().shape({
            intitule: Yup.string().required("required"),
            start_date: Yup.string().required("required"),
            cours_systeme: Yup.string().required("required"),
            cours_niveau: Yup.string().required("required"),
            cours_matiere: Yup.string().required("required"),
            cours_classe: Yup.string().required("required"),
            cours_module: Yup.string().required("required"),
            cours_langue: Yup.string().required("required"),
            cours_type: Yup.string().required("required"),
        });
    };

    getSecondValidationSchema = () => {
        return Yup.object().shape({
            cours_description: Yup.string().required("required"),
            // todo: keep this part of code, we need it
            // cours_objectifs: Yup.string().required("required"),
            // cours_prerequis: Yup.string().required("required"),
        });
    };

    handleSubmit = (values) => {
        this.setState({
            selected_system: values.cours_systeme,
            selected_niveau: values.cours_niveau,
            selected_module: values.cours_module,
            selected_duree: values.cours_duree,
            selected_date: values.start_date,
            // selected_duree: values.cours_duree,
            selected_intitule: values.intitule,
            selected_langue: values.cours_langue,
            selected_classe: values.cours_classe,
            selected_matiere: values.cours_matiere,
            modalPage: 2,
            Continue : false,
            Sauvegarder : false,
        })
    };


    handleSecondSubmit = (values) => {
        let prefix = this.state;
        let data = {
            course_intitule: prefix.selected_intitule,
            course_language: prefix.selected_langue,
            course_type: "TUTO",
            course_matiere: prefix.matieres.filter(matiere => matiere.matiere_name === prefix.selected_matiere)[0],
            course_module: prefix.selected_module,
            course_teacher: prefix.user.id,
            course_duree: prefix.selected_duree,
            course_classe: prefix.selected_classe,
            course_date_d: prefix.selected_date,
            course_systeme: prefix.education_systems.filter(education_system => education_system.system_name === prefix.selected_system)[0].id,
            course_level: prefix.selected_niveau,
            course_description: values.cours_description,
            course_prerequis: values.cours_prerequis,
            course_objectifs: values.cours_objectifs,
            course_pic: null,
        }

        let formData = new FormData();
        formData.append("course_pic", prefix.selected_pic);
        this.setState({
            Continue: true,
        })
        return this.props
            .dispatch(addCourseContinueAction(data))
            .then(data => {
                this.setState({
                    course: data.payload.course
                });
                let course = data.payload.data;
                data = {
                    data: formData,
                    id: course.id
                }
                this.props
                    .dispatch(addCoursePictureAction(data))
                    .then(data => {
                        this.setState({
                            Continue: false,
                        })
                        let path = '/teacher/course/content/'+course.id;
                        this.props.history.push(
                            {
                                pathname: path,
                                // state: {course: course}
                            })
                    })
                    .catch(err => {
                        console.log("err", err);
                        this.setState({
                            errorGlobal: err.message,
                            Continue: false,
                        });
                    });

            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message,
                    Continue: false,
                });
            });


    };

    handleChangeModalPage = () => {
        this.setState({
            modalPage: 1
        })
    }

    handleSubmitSave = (values) => {
        let prefix = this.state;
        let data = {
            course_intitule: prefix.selected_intitule,
            course_language: prefix.selected_langue,
            course_type: "TUTO",
            course_matiere: prefix.matieres.filter(matiere => matiere.matiere_name === prefix.selected_matiere)[0],
            course_module: prefix.selected_module,
            course_teacher: prefix.user.id,
            course_duree: prefix.selected_duree,
            course_classe: prefix.selected_classe,
            course_date_d: prefix.selected_date,
            course_systeme: prefix.education_systems.filter(education_system => education_system.system_name === prefix.selected_system)[0].id,
            course_level: prefix.selected_niveau,
            course_description: values.cours_description,
            course_prerequis: values.cours_prerequis,
            course_objectifs: values.cours_objectifs,
            course_pic: null,
        }

        let formData = new FormData();
        formData.append("course_pic", prefix.selected_pic);
        this.setState({
            Sauvegarder: true,
        })
        return this.props
            .dispatch(addCourseAction(data))
            .then(data => {
                this.setState({
                    course: data.payload.course
                });
                data = {
                    data: formData,
                    id: data.payload.course.id
                }
                this.props
                    .dispatch(addCoursePictureAction(data))
                    .then(data => {
                        this.props.handleOpenModal("success");
                        this.setState({
                            course: data.payload.course
                        });
                        console.log(data.payload)
                        this.setState({
                            Sauvegarder: false,
                            Continue: false,
                        })
                    })
                    .catch(err => {
                        console.log("err", err);
                        this.setState({
                            errorGlobal: err.message,
                            Continue: false,
                        });
                    });
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message
                });
            });

    }

    handleChangeSystem = (selected) => {
        this.setState({
            selected_system: selected
        })
    }

    handleChangeNiveau = (selected) => {
        this.setState({
            selected_niveau: selected
        })
    }

    handleChangeMatiere = (selected) => {
        this.setState({
            selected_matiere: selected
        })
    }
    handleChangeDuree = (selected) => {
        this.setState({
            selected_duree: selected
        })
    }

    handleChangePicture = ({target}) => {
        this.setState({
            selected_pic: target.files[0],
        })
    }

    render() {
        const {
            open,
            education_systems,
            matieres,
            selected_duree,
            selected_intitule,
            selected_langue,
            selected_date,
            selected_classe,
            selected_matiere,
            selected_module,
            selected_niveau,
            selected_system,
            selected_type,
            selected_pic,
        } = this.state;
        return (
            <Modal
                isOpen={open}
                toggle={this.EditModal}
                centered
            >
                <ModalHeader className="add-cours-title" toggle={this.EditModal}>Creation d'un nouveau
                    cours</ModalHeader>
                <ModalBody>
                    {this.state.modalPage === 1 ?
                        <Formik key="1"
                                initialValues={{
                                    intitule: selected_intitule,
                                    start_date: selected_date,
                                    cours_duree: selected_duree,
                                    cours_niveau: selected_niveau,
                                    cours_matiere: selected_matiere,
                                    cours_classe: selected_classe,
                                    cours_module: selected_module,
                                    cours_langue: selected_langue,
                                    cours_type: selected_type,
                                }}
                                validationSchema={this.getFirstValidationSchema}
                                onSubmit={values => {
                                    this.handleSubmit(values);
                                }}
                        >
                            {({errors, touched, values, handleChange}) => {
                                return (
                                    <Form>
                                        <TextField
                                            fullWidth
                                            className={`textInput`}
                                            type="text"
                                            name="intitule"
                                            label="Intitulé"
                                            value={values.intitule}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />
                                        {errors.intitule && touched.intitule ? (
                                            <div className="textErrors">{errors.intitule}</div>
                                        ) : null}
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="datetime-local"
                                                    type="date"
                                                    fullWidth
                                                    className={`textInput`}
                                                    name="start_date"
                                                    label="Date de début"
                                                    value={values.start_date}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                {errors.start_date && touched.start_date ? (
                                                    <div className="textErrors">{errors.start_date}</div>
                                                ) : null}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    className={`textInput`}
                                                    type="number"
                                                    name="cours_duree"
                                                    label="La durée"
                                                    value={values.cours_duree}
                                                    onChange={handleChange}
                                                    onClick={() => this.handleChangeDuree(values.cours_duree)}
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment
                                                            position="start">h</InputAdornment>,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                {errors.cours_duree && touched.cours_duree ? (
                                                    <div className="textErrors">{errors.cours_duree}</div>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="outlined-select-currency-native"
                                                    select
                                                    fullWidth
                                                    className={`textInput`}
                                                    name="cours_systeme"
                                                    label="Systéme"
                                                    value={values.cours_systeme}
                                                    onChange={handleChange}
                                                    onClick={() => this.handleChangeSystem(values.cours_systeme)}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    variant="outlined"
                                                >
                                                    {selected_system === "" ?
                                                        <option key="default" value="Choose">
                                                            Choisir systéme...
                                                        </option>
                                                        : null
                                                    }
                                                    {education_systems && education_systems.map((option) => (
                                                        <option key={option.id} value={option.system_name}>
                                                            {option.system_name}
                                                        </option>
                                                    ))}
                                                </TextField>
                                                {errors.cours_systeme && touched.cours_systeme ? (
                                                    <div className="textErrors">{errors.cours_systeme}</div>
                                                ) : null}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="outlined-select-currency-native"
                                                    select
                                                    fullWidth
                                                    className={`textInput`}
                                                    name="cours_niveau"
                                                    label="Niveau"
                                                    value={values.cours_niveau}
                                                    onChange={handleChange}
                                                    onClick={() => this.handleChangeNiveau(values.cours_niveau)}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    variant="outlined"
                                                >
                                                    {selected_niveau === "" ?
                                                        <option key="default" value="Choose">
                                                            Choisir niveau ...
                                                        </option>
                                                        : null
                                                    }
                                                    {values.cours_systeme && education_systems.filter(system => system.system_name === values.cours_systeme)[0].systeme_levels.map((option, key) => (
                                                        <option key={key} value={option.level_name}>
                                                            {option.level_name}
                                                        </option>
                                                    ))}
                                                </TextField>
                                                {errors.cours_niveau && touched.cours_niveau ? (
                                                    <div className="textErrors">{errors.cours_niveau}</div>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="outlined-select-currency-native"
                                                    select
                                                    fullWidth
                                                    className={`textInput`}
                                                    name="cours_matiere"
                                                    label="Matiére"
                                                    value={values.cours_matiere}
                                                    onChange={handleChange}
                                                    onClick={() => this.handleChangeMatiere(values.cours_matiere)}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    variant="outlined"
                                                >
                                                    {selected_matiere === "" ?
                                                        <option key="default" value="Choose">
                                                            Choisir matiére ...
                                                        </option>
                                                        : null
                                                    }
                                                    {matieres && matieres.map((option) => (
                                                        <option key={option.id} value={option.matiere_name}>
                                                            {option.matiere_name}
                                                        </option>
                                                    ))}
                                                </TextField>
                                                {errors.cours_matiere && touched.cours_matiere ? (
                                                    <div className="textErrors">{errors.cours_matiere}</div>
                                                ) : null}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="outlined-select-currency-native"
                                                    select
                                                    fullWidth
                                                    className={`textInput`}
                                                    name="cours_module"
                                                    label="Module"
                                                    value={values.cours_module}
                                                    onChange={handleChange}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    variant="outlined"
                                                >
                                                    {selected_module === "" ?
                                                        <option key="default" value="Choose">
                                                            Choisir module ...
                                                        </option>
                                                        : null
                                                    }
                                                    {values.cours_matiere && matieres.filter(matiere => matiere.matiere_name === values.cours_matiere)[0].matiere_modules.map((option, key) => (
                                                        <option key={key} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </TextField>
                                                {errors.cours_module && touched.cours_module ? (
                                                    <div className="textErrors">{errors.cours_module}</div>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="outlined-select-currency-native"
                                                    select
                                                    fullWidth
                                                    className={`textInput`}
                                                    name="cours_classe"
                                                    label="Classe"
                                                    value={values.cours_classe}
                                                    onChange={handleChange}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    variant="outlined"
                                                >
                                                    {selected_classe === "" ?
                                                        <option key="default" value="Choose">
                                                            Choisir année ...
                                                        </option>
                                                        : null
                                                    }
                                                    {values.cours_systeme && values.cours_niveau && education_systems.filter(system => system.system_name === values.cours_systeme)[0].systeme_levels.filter(system => system.level_name === values.cours_niveau)[0].level_years.map((option, key) => (
                                                        <option key={key} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </TextField>
                                                {errors.cours_classe && touched.cours_classe ? (
                                                    <div className="textErrors">{errors.cours_classe}</div>
                                                ) : null}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="outlined-select-currency-native"
                                                    select
                                                    fullWidth
                                                    className={`textInput`}
                                                    name="cours_langue"
                                                    label="Langue"
                                                    value={values.cours_langue}
                                                    onChange={handleChange}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    variant="outlined"
                                                >
                                                    {selected_langue === "" ?
                                                        <option key="default" value="Choose">
                                                            Choisir langue ...
                                                        </option>
                                                        : null
                                                    }
                                                    {langues.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </TextField>
                                                {errors.cours_langue && touched.cours_langue ? (
                                                    <div className="textErrors">{errors.cours_langue}</div>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="outlined-select-currency-native"
                                                    select
                                                    fullWidth
                                                    className={`textInput`}
                                                    name="cours_type"
                                                    label="Type de cours"
                                                    value={values.cours_type}
                                                    onChange={handleChange}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    variant="outlined"
                                                >
                                                    {selected_classe === "" ?
                                                        <option key="default" value="Choose">
                                                            Choisir le type ...
                                                        </option>
                                                        : null
                                                    }
                                                    <option key={"TUTO"} value={"TUTO"}>
                                                        {"Tutoriel"}
                                                    </option>
                                                    <option key={"VISIO"} value={"VISIO"}>
                                                        {"Visioconférence"}
                                                    </option>
                                                </TextField>
                                                {errors.cours_type && touched.cours_type ? (
                                                    <div className="textErrors">{errors.cours_type}</div>
                                                ) : null}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                 <Button
                                                                        variant="contained"
                                                                        component="label"
                                                                        // disabled={this.state.part_pdf}
                                                     type={"button"}
                                                                        onClick={()=>document.getElementById('upload_pic').click()}
                                                                        className={"uploadCoursPicture"}
                                                 >
                                                     {selected_pic ?
                                                         selected_pic.name:
                                                         "image de cours"}

                                                                    </Button>
                                                  <input
                                                                            name="cours_pic"
                                                                            accept="image/*"
                                                                            type="file"
                                                                            id={"upload_pic"}
                                                    onChange={this.handleChangePicture}
                                                                            // onChange={handleChange}
                                                                            style={{display: "none"}}
                                                                        />
                                                 <div></div>
                                            </Grid>
                                        </Grid>

                                        <ModalFooter className="add-cours-footer">
                                            <Button type="cancel" className="energy_btn"
                                                    onClick={() => this.EditModal()} variant="contained">
                                                Annuler
                                            </Button>
                                            <Button type="submit" className="edit_btn"
                                                    variant="contained">Suivant</Button>
                                        </ModalFooter>
                                    </Form>
                                );
                            }}
                        </Formik> :
                        <Formik key="2"
                                initialValues={{
                                    cours_description: "",
                                    cours_objectifs: "",
                                    cours_prerequis: "",
                                }}
                                validationSchema={this.getSecondValidationSchema()}
                                onSubmit={values => {
                                    this.handleSecondSubmit(values);
                                }}
                        >
                            {({errors, touched, values, handleChange}) => {
                                return (
                                    <Form>
                                        <TextField
                                            className="textInput-multiline"
                                            fullWidth
                                            type="description"
                                            name="cours_description"
                                            label="Description"
                                            multiline
                                            rows={2}
                                            value={values.cours_description}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />
                                        {errors.cours_description && touched.cours_description ? (
                                            <div className="textErrors">{errors.cours_description}</div>
                                        ) : null}
                                        <TextField
                                            className="textInput-multiline"
                                            fullWidth
                                            type="text"
                                            name="cours_objectifs"
                                            label="Objectifs"
                                            multiline
                                            rows={2}
                                            value={values.cours_objectifs}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />
                                        {/*todo: keep this part of code, we need it*/}
                                        {/*{errors.cours_objectifs && touched.cours_objectifs ? (*/}
                                        {/*    <div className="textErrors">{errors.cours_objectifs}</div>*/}
                                        {/*) : null}*/}
                                        <TextField
                                            className="textInput-multiline"
                                            fullWidth
                                            type="text"
                                            name="cours_prerequis"
                                            label="Prérequis"
                                            multiline
                                            rows={2}
                                            value={values.cours_prerequis}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />
                                        {/*todo: keep this part of code, we need it*/}
                                        {/*{errors.cours_prerequis && touched.cours_prerequis ? (*/}
                                        {/*    <div className="textErrors">{errors.cours_prerequis}</div>*/}
                                        {/*) : null}*/}
                                        <ModalFooter className="add-cours-footer">
                                            <Button type="cancel" className="energy_btn"
                                                    onClick={() => this.handleChangeModalPage()} variant="contained">
                                                Précedant
                                            </Button>

                                            <Button className="energy_btn"
                                                    onClick={() => this.handleSubmitSave(values)}
                                                    variant="contained"
                                                    disabled={this.state.Sauvegarder || this.state.Continue}>
                                                {
                                                    this.state.Sauvegarder ?
                                                        <CircularProgress color="secondary" size={18}/>
                                                        : "Sauvegarder"}


                                            </Button>
                                            <Button type="submit" className="edit_btn"
                                                    variant="contained"
                                                    disabled={this.state.Sauvegarder || this.state.Continue}>

                                                {
                                                    this.state.Continue ?
                                                        <CircularProgress color="secondary" size={18}/>
                                                        : "Continue"}</Button>
                                        </ModalFooter>
                                    </Form>
                                );
                            }}
                        </Formik>
                    }
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({session: state.session, data: state.data});
export default connect(mapStateToProps)(withRouter(AddCoursModal));