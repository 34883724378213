import React, {Component} from 'react';
import {Card, CircularProgress} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {CartCourseItem} from "./CartCourseItem";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal'
import {
    addCourseToAchat, addCourseToCart,
    addCourseToFavorite, addCourseToRating, AddToCartNoAuthentifiedAction,
    deleteCourseFromCart, getCourseFromFavorite,
    getCourseToCart
} from "../../../Redux/ActionCreators";
import SummaryTable from "./SummaryTable"
import ButtonGroupPaymentChoice from "./ButtonGroupPaymentChoice"
import Paypal from "./Paypal"
import {EventGoogleAnalytics, PageViewGoogleAnalytics} from "../../Common/Components/GoogleAnalytics"
import Pagination from "../../Common/Components/Pagination";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";


let number = 0;
const usersPerPage = 12;

class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.session.user,
            is_Load: this.props.data.isLoad,
            coursesToCart: this.props.data.get_courses_to_cart,
            seancesToCart: this.props.data.get_seances_to_cart,
            finalPrice: this.props.data.total_price,
            show: false,
            lgShow: false,
            lgShowSecond: false,
            pageNumber: 0,
            firstPaypal: true,
            closeButton: true,
            showPaymentPaypal: false,
            showPaymentCreditCard: true
        }

    }

    componentDidMount() {
        PageViewGoogleAnalytics("Cart", window.location.pathname + window.location.search);
        console.log(this.props.data.get_seances_to_cart);
        if (this.props.student.course_cart_id) {
            console.log(" cours_cart in course cibtainer okay");
            console.log(this.props.student.course_cart_id);
            console.log(this.props.data.get_seances_to_cart);
            // this.AddToCart(this.props.student.course_cart_id);
            // this.props.dispatch(AddToCartNoAuthentifiedAction(null));

            let data = {
                cart_course: this.props.student.course_cart_id,
                cart_user: this.state.user.id
            };
            return this.props
                .dispatch(addCourseToCart(data))
                .then(data => {
                    if (data.payload.Success) {
                        this.UpdateToCart()
                        // this.setNotify(data.payload.message, 'success')
                        this.props.dispatch(AddToCartNoAuthentifiedAction(null));

                    }
                })
                .catch(err => {
                    console.error(err.message)
                    this.setNotify("There is an error", 'error')
                });

        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                coursesToCart: this.props.data.get_courses_to_cart,
                seancesToCart: this.props.data.get_seances_to_cart,
                finalPrice: this.props.data.total_price,
            });
        }
    }

    pagesVisited = (pageNumber) => {
        return pageNumber * usersPerPage;
    }

    pageCount = (courses) => {
        Math.ceil(courses.length / usersPerPage);
    }

    changePage = ({selected}) => {
        this.setState({
            pageNumber: selected
        })
    };

    handleIncrement = () => {
        number += 1
        return number
    }

    handleClose = () => {
        this.setState({
            show: false
        })
    }
    handleShow = () => {
        this.setState({
            show: true
        })
        EventGoogleAnalytics("Cart_Remove_button", "Button", `Remove all courses from cart , userId: ${this.state.user.id}`)
    }
    handleShowLarge = () => {
        this.setState({
            lgShow: true
        })
        EventGoogleAnalytics("Cart_Buy_button", "Button", `Buy courses from cart , userId: ${this.state.user.id}`)
    }
    handleShowLargeSecond = () => {
        this.setState({
            lgShowSecond: true
        })
    }
    HandlePaypal = () => {
        this.setState({
            firstPaypal: true
        })
    }

    DeleteFromCart = (id) => {
        this.setState({
            is_Load: false,
            show: false,
            coursesToCart: [],
            seancesToCart: [],
            closeButton: false
        });
        let data = {
            course_id: id,
        };
        return this.props
            .dispatch(deleteCourseFromCart(data))
            .then(data => {
                if (data.payload.Success) {
                    this.UpdateToCart()
                    //this.setNotify(data.payload.message, 'success')
                }
            })
            .catch(err => {
                console.error(err.message)
                //this.setNotify("There is an error", 'error')
            });
    }
    UpdateToCart = () => {
        let data = {
            ordered_user: this.state.user.id
        };
        return this.props
            .dispatch(getCourseToCart(data))
            .then(data => {
                if (data.payload.Success) {
                    console.log("enter")
                    this.setState({
                        coursesToCart: data.payload.courses_to_cart,
                        seancesToCart: data.payload.seances_to_cart,
                        is_Load: true,
                        closeButton: true
                    });
                }
            })
            .catch(err => {
                console.log(err.message)
            });
    }
    AddToAchat = (data) => {
        this.setState({
            lgShowSecond: false
        });
        return this.props
            .dispatch(addCourseToAchat(data))
            .then(data => {
                this.DeleteFromCart();
                this.props.history("/student/dashboard");
            })
            .catch(err => {
                console.error(err.message)
                //this.setNotify("There is an error", 'error')
            });
    }

    ChangePaymentPaypal = () => {
        this.setState({
            showPaymentPaypal: true,
            showPaymentCreditCard: false
        });
    }
    ChangePaymentCreditCard = () => {
        this.setState({
            showPaymentPaypal: false,
            showPaymentCreditCard: true
        });
    }
    handleAddToFavorite = (id) => {
        let data = {
            favourite_course: id,
            favourite_user: this.state.user.id
        }
        return this.props
            .dispatch(addCourseToFavorite(data))
            .then(data => {
                if (data.payload.Success) {
                    this.GetCoursesFromFavorite()
                    this.setNotify(data.payload.message, 'success')
                }
            })
            .catch(err => {
                console.log(err.message)
            });
    }
    GetCoursesFromFavorite = () => {
        let data = {
            user_id: this.state.user.id
        };
        return this.props
            .dispatch(getCourseFromFavorite(data))
            .then(data => {
            })
            .catch(err => {
                console.log(err.message)
            });
    }
    AddToRating = (score, id) => {
        let data = {
            rating_course: id,
            rating_user: this.state.user.id,
            score: score
        }
        return this.props
            .dispatch(addCourseToRating(data))
            .then(data => {
                if (data.payload.Success) {
                    this.setNotify(data.payload.message, 'success')
                }
            })
            .catch(err => {
                console.log(err.message)
            });
    }

    dateFormating = (stringDate) => {
        return stringDate.slice(0, 10) + " " + stringDate.slice(11, 16)
    }

    render() {
        const {
            coursesToCart,
            seancesToCart,
            is_Load,
            show,
            lgShow,
            lgShowSecond,
            closeButton,
            finalPrice,
            user,
            showPaymentPaypal,
            showPaymentCreditCard,
            pageNumber
        } = this.state;
        const fullCart = [...coursesToCart, ...seancesToCart];
        return (
            <>
                <div className="courses-tete">
                    <span>Mon Panier</span>
                </div>
                <div className="cours-store-grid">
                    <div className="button_cart">
                        <div className="button_buy">
                            <Button size="lg" onClick={() => this.handleShowLarge()}
                                    disabled={!coursesToCart || !seancesToCart || coursesToCart.length + seancesToCart.length === 0}
                                    variant="outline-info"
                                    block>Acheter des cours</Button>
                        </div>
                        <div className="button_clear">
                            <Button size="lg" onClick={() => this.handleShow()}
                                    disabled={!coursesToCart || !seancesToCart || coursesToCart.length + seancesToCart.length === 0}
                                    variant="outline-danger">Vider le panier</Button>
                        </div>
                    </div>
                    <Grid container spacing={1} className="grid-style">
                        {((!fullCart || fullCart.length === 0) && closeButton) ?
                            <p className="empty-cart"> Votre panier est vide</p>
                            :
                            <>
                                <Grid item xs={11}>
                                    <div className="courses-store-container">
                                        <div className="course-container-header">
                                            <div className="found-results">
                                                <span>{fullCart.length} Elément ajouté(s)</span>
                                            </div>
                                        </div>
                                        <div className="cours-store-grid-list">
                                            {fullCart.length>0 ?
                                                <Grid container spacing={3} style={{flexGrow: "1", marginLeft: "2%"}}>
                                                    {fullCart && fullCart.slice(this.pagesVisited(pageNumber), this.pagesVisited(pageNumber) + usersPerPage).map((course, key) => (
                                                        <Grid item key={key} alignItems="center" spacing={2} xs sm={3}>
                                                            {course.cart_course ?
                                                                <CartCourseItem
                                                                    course={course}
                                                                    system={this.props.data.system_education.filter(system => course.cart_course.course_systeme === system.id)[0]}
                                                                    user={user}
                                                                    increment={this.handleIncrement()}
                                                                    isFavorite={course.course_is_favorite}
                                                                    clickToDeleteFromCart={(id) => this.DeleteFromCart(id)}
                                                                    handleAddToFavorite={(id) => this.handleAddToFavorite(id)}
                                                                    clickToAddRating={(score, course_id) => this.AddToRating(score, course_id)}
                                                                />
                                                                :

                                                                <Card sx={{Width: 100}}>
                                                                    <CardActionArea>
                                                                        <CardContent>
                                                                            <Typography gutterBottom variant="h4"
                                                                                        component="div">
                                                                                Séance de {course.seance.seance_title}
                                                                            </Typography>
                                                                            <Typography gutterBottom variant="h6"
                                                                                        color="text.secondary">
                                                                                Enseignant : {course.seance.teacher}
                                                                            </Typography>
                                                                            <Typography sx={{mb: 1.5}}
                                                                                        color="text.secondary">
                                                                                De {this.dateFormating(course.reservation_startDate)}
                                                                            </Typography>
                                                                            <Typography sx={{mb: 1.5}}
                                                                                        color="text.secondary">
                                                                                à {this.dateFormating(course.reservation_endDate)}
                                                                            </Typography>

                                                                        </CardContent>
                                                                    </CardActionArea>
                                                                </Card>
                                                            }
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                                :
                                                <CircularProgress color="secondary" className="second-progress-bar"/>
                                            }
                                        </div>
                                        <div className="scroll-pages-options-favorite-course">
                                            {Pagination(this.pageCount(coursesToCart), this.changePage)}
                                        </div>
                                    </div>
                                </Grid>
                            </>
                        }
                    </Grid>
                </div>
                <Modal show={show} onHide={() => this.handleClose()} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation la suppression</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Vous voulez continuer la suppression!</Modal.Body>
                    <Modal.Footer className="style-model_footer">
                        <Button variant="outline-danger" onClick={() => this.DeleteFromCart()}>
                            Confirmer
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    className="style-model-payment"
                    size="lg"
                    show={lgShow}
                    onHide={() => this.setState({
                        lgShow: false
                    })}
                    aria-labelledby="example-modal-sizes-title-lg"
                    scrollable
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Paiement
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="style-model-cart-title"> Vous avez {coursesToCart.length + seancesToCart.length} éléments à payer.
                        </div>
                        <div> Le tableau récupétilatif des cours à acheter:</div>
                        <div className="style-model-cart-price"> Total : {finalPrice} MAD</div>
                        <SummaryTable coursesToCart={coursesToCart}/>
                    </Modal.Body>
                    <Modal.Footer className="style-model_footer">
                        <Button variant="outline-success" onClick={() => this.setState({
                            lgShow: false,
                            lgShowSecond: true,
                        })}>
                            Continuer paiement
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal animation={false}
                       className="style-model-payment"
                       size="lg"
                       show={lgShowSecond}
                       onHide={() => this.setState({
                           lgShowSecond: false
                       })}
                       aria-labelledby="example-modal-sizes-title-lg"
                       scrollable
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Paiement
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ButtonGroupPaymentChoice
                            clickToChangePaymentCreditCard={() => this.ChangePaymentCreditCard()}
                            clickToChangePaymentPaypal={() => this.ChangePaymentPaypal()}
                        />
                        <br/>
                        {showPaymentPaypal && <Paypal
                            finalPrice={finalPrice}
                            clickToAddToAchat={(data) => this.AddToAchat(data)}
                            coursesToCart={coursesToCart}
                            user={user}
                        />}
                        {showPaymentCreditCard && <div style={{textAlign: "center"}}>
                            <span style={{fontSize: "14px"}}><b>•</b> Effectuez votre paiement par virement bancaire sur le compte :
                                <b>RIB 011780000087210010500259</b> et puis contactez nos services pour confirmer la réception au <Link>RH@talented.academy.</Link>
                            <br/> Le paiement par carte bancaire sera bientôt disponible.</span>
                        </div>}
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({data: state.data, session: state.session, student: state.student});
export default connect(mapStateToProps)(withRouter(Cart));




