import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import * as React from "react";
import {useState} from "react";
import MenuIcon from "@material-ui/icons/Menu";
import {NavLink} from "react-router-dom";
import {logoutAction} from "../../../Redux/ActionCreators";
import {useDispatch} from "react-redux";

const useStyles = makeStyles({
    list: {
        width: 250
    },
    linkText: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: `black`
    }
});

const SideDrawer = ({navLinks}) => {
    const classes = useStyles();
    const [state, setState] = useState({right: false, errorGlobal: ""});
    const [errorGlobal, setErrorGlobal] = useState({errorGlobal: ""});
    const dispatch = useDispatch()

    const toggleDrawer = (anchor, open) => event => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({[anchor]: open});
    };

    const handleLogout = () => {
        dispatch(logoutAction())
            .then(data => {
                if (data.payload.Success) {
                    this.props.history.push("/home");
                }
            })
            .catch(err => {
                setErrorGlobal({[errorGlobal]: err.message});
            });
    };

    const sideDrawerList = anchor => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List component="nav">
                {navLinks.map(({title, path}) => (
                    <NavLink to={path} key={title} className={classes.linkText}>
                        <ListItem button>
                            <ListItemText primary={title}/>
                        </ListItem>
                    </NavLink>
                ))}
                <ListItem button onClick={handleLogout}>
                    <ListItemText primary={"Logout"}/>
                </ListItem>
            </List>
        </div>
    );

    return (
        <React.Fragment>
            <IconButton
                edge="start"
                aria-label="menu"
                onClick={toggleDrawer("right", true)}
            >
                <MenuIcon/>
                {/*<Menu fontSize="large" style={{ color: `white` }} />*/}
            </IconButton>

            <Drawer
                anchor="right"
                open={state.right}
                onOpen={toggleDrawer("right", true)}
                onClose={toggleDrawer("right", false)}
            >
                {sideDrawerList("right")}
            </Drawer>
        </React.Fragment>
    );
};

export default SideDrawer;
