import React, {useState} from 'react';
import Button from "react-bootstrap/Button";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import {Popover} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

export default function ButtonGroupPaymentChoice(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <div className={classes.root}>
                <ButtonGroup size="large" aria-label="small outlined button group">
                    <Button onClick={() => props.clickToChangePaymentCreditCard()}
                        variant="outline-info">Carte bancaire</Button>
                    <Button
                        onClick={() => props.clickToChangePaymentPaypal()}
                        variant="outline-primary">Paypal</Button>
                    <Button
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={(e) => handlePopoverOpen(e)}
                        onMouseLeave={(e) => handlePopoverClose(e)}
                        variant="outline-warning">Orange Money</Button>
                </ButtonGroup>
            </div>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>En cours de développement</Typography>
            </Popover>
        </>
    );
}