import React, {Component} from 'react'

import {Cercle} from './Cercle'
import Competences from './Competences'
import Formation from './Formation'
import Hobbies from './Hobbies'
import Langues from './Langues'
import Teacherprofildescription from './TeacherProfilDescription'
import {countries} from "./Countries";
import unknownProfile from "../../../Assets/Images/Image_9.png";
import Notification from "../../Common/AlertComponent";

import {Grid, Button, CircularProgress, LinearProgress} from "@material-ui/core";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import EditIcon from '@material-ui/icons/Edit';
import Select from "react-select";

import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ModalProfilAvatar from "./ModalProfilAvatar";
import {
    partial_update_studentAction,
    partial_update_teacherAction
} from "../../../Redux/ActionCreators";
import {EventGoogleAnalytics, PageViewGoogleAnalytics} from "../../Common/Components/GoogleAnalytics";


class Teacherprofil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openEdit: true,
            user: this.props.session.user,
            firstname: this.props.session.user.first_name,
            lastname: this.props.session.user.last_name,
            countryName: this.props.session.user.country,
            system_level: "",
            system_level_display: this.props.session.user.level,
            system_year: "",
            system_year_display: this.props.session.user.college_year,
            openModal: false,
            system_education: "",
            system_education_display: this.props.session.user.education_system,
            avatar: this.props.session.user.profile_pic,
            yearsExperience: this.props.session.user.years_experience,
            errorGlobal: "",
            openProgressBar: false,
            class_level: this.props.session.user.class_level,
            notify: {message: '', type: ''},
            notyIsOpen: false,
            openLinearBarPicture: false,
            education_system: this.props.data.system_education,
        }
    }
    componentDidMount() {
        PageViewGoogleAnalytics("Profile", window.location.pathname + window.location.search)
    }

    setNotify = (message, type) => {
        this.setState({
            notify: {
                message: message,
                type: type
            },
            notyIsOpen: !this.state.notyIsOpen
        })
    }

    handleOpenEdit = () => {
        // Event Google Analytics
        EventGoogleAnalytics("Profile_Personal_info_button","Button","Change Personnal info")
        this.setState({
            openEdit: !this.state.openEdit,
        })
    };
    // Open Modal Popup
    handleOpenModal = () => {
        // Event Google Analytics
        EventGoogleAnalytics("Profile_Avatar_button","Button","Show Modal Avatars")
        this.setState({
            openModal: !this.state.openModal,
        })
    };
    // OPen Linear Bar
    handleOpenLinearBar = () => {
        this.setState({
            openLinearBarPicture: !this.state.openLinearBarPicture
        })
    };
    // Display avatar
    displayAvatar = (url) => {
        this.setState({
            avatar: url,
        })
        //this.handleOpenModal();
        console.log(this.state.avatar)
    };
    handleChangeFirstname = (event) => {
        this.setState({
            firstname: event.target.value,
        });
    };
    handleChangeLastname = (event) => {
        this.setState({
            lastname: event.target.value,
        })
    };
    // select country
    handleChangeCountry = (selectedOption) => {
        this.setState({
            countryName: selectedOption.label
        })
    };
    // select system education
    handleChangeEducationSystem = (selectedOption) => {
        this.setState({
            system_education: selectedOption.target.value
        })
    };
    // select levels
    handleChangeLevel = (selectedOption) => {
        this.setState({
            system_level: selectedOption.target.value
        })
    };
    // Years Level
    handleChangeYear = (selectedOption) => {
        this.setState({
            system_year: selectedOption.target.value
        })
    };
    // enter Years Experience
    handleChangeYearsExperience = (event) => {
        this.setState({
            yearsExperience: event.target.value,
        });
    };
    // enter the name of the class
    handleChangeClass = (event) => {
        this.setState({
            class_level: event.target.value,
        });
    };
    // submit updated information's teacher and student
    handleSubmit = () => {
        this.setState({
            openProgressBar: !this.state.openProgressBar
        });
        if (this.state.user.is_teacher) {
            const dataTeacher = {
                "first_name": this.state.firstname,
                "last_name": this.state.lastname,
                "country": this.state.countryName,
                "years_experience": this.state.yearsExperience,
                "education_system": this.state.system_education === "" ? this.props.system_education_display : this.state.system_education,
                "level": this.state.system_level === "" ? this.props.system_level_display : this.state.system_level,
                "class_level": this.state.class_level,
                "user_id": this.state.user.id
            };
            return this.props
                .dispatch(partial_update_teacherAction(dataTeacher))
                .then(data => {
                    if (data.payload.Success) {
                        this.handleOpenEdit();
                        this.setState({
                            openProgressBar: !this.state.openProgressBar,
                            yearsExperience: data.payload.user.years_experience,
                            firstname: data.payload.user.first_name,
                            lastname: data.payload.user.last_name,
                            countryName: data.payload.user.country,
                            system_education_display: data.payload.user.education_system,
                            system_level_display: data.payload.user.level,
                            class_level: data.payload.user.class_level,
                        });
                        this.setNotify(data.payload.message, 'success')
                    }
                })
                .catch(err => {
                    this.setNotify("Erreur est survenue, Réessayez plus tard", 'error')
                    this.setState({
                        openProgressBar: !this.state.openProgressBar
                    });
                });
        } else {
            const dataStudent = {
                "user_id": this.state.user.id,
                "first_name": this.state.firstname,
                "last_name": this.state.lastname,
                "country": this.state.countryName,
                "education_system": this.state.system_education === "" ? this.props.system_education_display : this.state.system_education,
                "level": this.state.system_level === "" ? this.props.system_level_display : this.state.system_level,
                "college_year": this.state.system_year === "" ? this.props.system_year_display : this.state.system_year,
            };
            // TODO:REMOVE DUPLICATION OR REPLACE DISPATCH
            return this.props
                .dispatch(partial_update_studentAction(dataStudent))
                .then(data => {
                    if (data.payload.Success) {
                        this.handleOpenEdit();
                        this.setState({
                            openProgressBar: !this.state.openProgressBar,
                            firstname: data.payload.user.first_name,
                            lastname: data.payload.user.last_name,
                            countryName: data.payload.user.country,
                            system_education_display: data.payload.user.education_system,
                            system_level_display: data.payload.user.level,
                            system_year_display: data.payload.user.college_year,
                        });
                        this.setNotify(data.payload.message, 'success')
                    }
                })
                .catch(err => {
                    this.setNotify("Erreur est survenue, Réessayez plus tard", 'error')
                    this.setState({
                        openProgressBar: !this.state.openProgressBar
                    });
                });
        }
    };

    render() {
        const {
            openEdit,
            firstname,
            lastname,
            countryName,
            openModal,
            avatar,
            openProgressBar,
            yearsExperience,
            class_level,
            user,
            openLinearBarPicture,
            system_level,
            system_year,
            system_education,
            education_system,
            system_education_display,
            system_level_display,
            system_year_display,
        } = this.state;

        return (
            <>
                <div className="profile-teacher">
                    <Cercle/>
                    <div id="Bienvenue_Baky_be">
                        <span>Bienvenu, {firstname}</span>
                    </div>
                    <div className="profile">
                        <Grid container className="Rectangle_260_bg" spacing={2}>
                            <Grid item xs={6} sm={3} style={{padding: "2.5em 2.5em"}}>
                                <img className="profile-image"
                                     src={avatar === "" ? unknownProfile : avatar}
                                     alt=""/>
                                {openLinearBarPicture
                                &&
                                <LinearProgress color="secondary"/>
                                }
                                <div className="overlay" onClick={() => this.handleOpenModal()}>
                                    <label>
                                        <PhotoCameraIcon
                                            className="photo-icon"
                                            fontSize="large"
                                        />
                                    </label>
                                    {openModal &&
                                    <ModalProfilAvatar
                                        open={openModal}
                                        handleEditOpen={this.handleOpenModal}
                                        clickHandler={(url) => this.displayAvatar(url)}
                                        setNotify={(message, type) => this.setNotify(message, type)}
                                        handleOpenLinearBar={this.handleOpenLinearBar}
                                    />
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={9}>
                                <div className="Rectangle_354">
                                    {openEdit &&
                                    <Button
                                        className="edit-btn"
                                        onClick={this.handleOpenEdit}
                                    >
                                        <EditIcon fontSize="large"/>
                                    </Button>
                                    }
                                    {openProgressBar
                                    &&
                                    <CircularProgress color="secondary" className="progress-bar"/>
                                    }
                                    {openEdit ?
                                        <>
                                            <div id="Benali_bk">
                                                <span>{firstname + " " + lastname}</span>
                                            </div>
                                            {user.is_teacher ?
                                                <div id="Benali_bd">
                                                    <span>System {system_education_display} - {system_level_display} - {class_level}</span>
                                                </div>
                                                : null}
                                            <div id="mub7_bj">
                                                <span>{countryName}</span>
                                            </div>
                                            <div id="ID254_EGY_bn">
                                                <span>Nbr de  Points</span>
                                            </div>
                                            {user.is_teacher ?
                                                <div id="Group_466">
                                                    <span>{yearsExperience === 0 ? "Pas d'expérience" : yearsExperience+ " ans d'expérience"} </span>
                                                </div>
                                                :
                                                <div id="Group_466_School_Year">
                                                    <span>System {system_education_display} - {system_level_display} - {system_year_display}</span>
                                                </div>}
                                        </>
                                        :
                                        <form className="FormProfil" noValidate autoComplete="off">
                                            <br/>
                                            <TextField
                                                className="outlined-basic"
                                                label="First name"
                                                variant="outlined"
                                                size="small" value={firstname}
                                                onChange={this.handleChangeFirstname}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AccountCircle/>
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                            <TextField
                                                className="outlined-basic"
                                                label="Last name"
                                                variant="outlined"
                                                size="small"
                                                value={lastname}
                                                onChange={this.handleChangeLastname}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AccountCircle/>
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                            <br/>
                                            {user.is_teacher ?
                                                <TextField
                                                    className="outlined-number"
                                                    type="number"
                                                    label="Years of experience"
                                                    onChange={this.handleChangeYearsExperience}
                                                    variant="outlined"
                                                    size="small"
                                                    value={yearsExperience}
                                                />
                                                : null}
                                            <TextField
                                                select
                                                fullWidth
                                                className="outlined-select"
                                                name="cours_systeme"
                                                label="Systéme"
                                                size="small"
                                                //value={system_education_display}
                                                onChange={this.handleChangeEducationSystem}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                            >
                                                {system_education === "" ?
                                                    <option key="default" value="Choose">
                                                        Choose...
                                                    </option>
                                                    : null
                                                }
                                                {education_system && education_system.map((option) => (
                                                    <option key={option.id} value={option.system_name}>
                                                        {option.system_name}
                                                    </option>
                                                ))}
                                            </TextField>
                                            <TextField
                                                select
                                                fullWidth
                                                className="outlined-select"
                                                name="cours_niveau"
                                                size="small"
                                                label="Niveau"
                                                //value={system_level_display}
                                                onChange={this.handleChangeLevel}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                            >
                                                {system_level === "" ?
                                                    <option key="default" value="Choose">
                                                        Select Niveau ...
                                                    </option>
                                                    : null
                                                }
                                                {system_education && education_system.filter(system => system.system_name === system_education)[0].systeme_levels.map((option, key) => (
                                                    <option key={key} value={option.level_name}>
                                                        {option.level_name}
                                                    </option>
                                                ))}
                                            </TextField>
                                            {user.is_teacher ?
                                                <TextField
                                                    className="outlined-basic"
                                                    type="text"
                                                    label="Your class"
                                                    onChange={this.handleChangeClass}
                                                    variant="outlined"
                                                    size="small"
                                                    value={class_level}
                                                />
                                                :
                                                <TextField
                                                    select
                                                    fullWidth
                                                    className="outlined-select"
                                                    name="cours_classe"
                                                    label="Classe"
                                                    size="small"
                                                    //value={system_year}
                                                    onChange={this.handleChangeYear}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    variant="outlined"
                                                >
                                                    {system_year === "" ?
                                                        <option key="default" value="Choose">
                                                            Select year ...
                                                        </option>
                                                        : null
                                                    }
                                                    {system_education && system_level && education_system.filter(system => system.system_name === system_education)[0].systeme_levels.filter(system => system.level_name === system_level)[0].level_years.map((option, key) => (
                                                        <option key={key} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            }
                                            <Select
                                                className={user.is_teacher ? "outlined-select-country" : "outlined-select"}
                                                placeholder="Select your country"
                                                onChange={this.handleChangeCountry} isSearchable
                                                options={countries}/>
                                            <>
                                                <Button className="save-profile" variant="contained"
                                                        onClick={() => this.handleSubmit()}>
                                                    <CheckIcon
                                                        fontSize="large"
                                                    />
                                                </Button>
                                                <Button className="cancel-profile" variant="contained"
                                                        onClick={this.handleOpenEdit}>
                                                    <CloseIcon
                                                        fontSize="large"
                                                    />
                                                </Button>
                                            </>
                                        </form>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        <Teacherprofildescription setNotify={(message, type) => this.setNotify(message, type)}/>
                        <div className="profile-about-you">
                            <div className="bloc-title">
                                <span>A propos de vous </span>
                            </div>
                            <Formation setNotify={(message, type) => this.setNotify(message, type)}/>
                            <Competences setNotify={(message, type) => this.setNotify(message, type)}/>
                            <Langues setNotify={(message, type) => this.setNotify(message, type)}/>
                            <Hobbies setNotify={(message, type) => this.setNotify(message, type)}/>
                        </div>
                    </div>
                    <Notification
                        notify={this.state.notify}
                        setNotify={this.setNotify}
                        isOpen={this.state.notyIsOpen}
                    />
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({session: state.session, data: state.data});
export default connect(mapStateToProps)(withRouter(Teacherprofil));
