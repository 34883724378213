import React, {useState} from 'react'
import {short} from '../../../Common/SplitWord';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {Favorite, FavoriteBorder} from "@material-ui/icons";
import Popover from 'react-bootstrap/Popover'
import MenuBookTwoToneIcon from '@material-ui/icons/MenuBookTwoTone';
import SchoolTwoToneIcon from '@material-ui/icons/SchoolTwoTone';
import {OverlayTrigger} from "react-bootstrap";
import LanguageRoundedIcon from '@material-ui/icons/LanguageRounded';
import CastForEducationRoundedIcon from '@material-ui/icons/CastForEducationRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import maths_trigo from "../../../../Assets/Images/maths_equations.png";
import StarIcon from '@material-ui/icons/Star';


export function TeacherStoreCoursesItem(props) {

    const [showTRigger, setShowTrigger] = useState(false);
    const [state, setState] = useState({
        checkedA: false,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({...state, [event.target.name]: event.target.checked});
        props.handleAddToFavorite(props.course.id)
    };

    const popover = (
        <Popover id="popover-basic"
                 onMouseEnter={() => setShowTrigger(true)}
                 onMouseLeave={() => setShowTrigger(false)}
        >
            <Popover.Title as="h2">Détail du Cours</Popover.Title>
            <Popover.Content>
                <div className="popper-course">
                    <div className="popper-course-intitule">
                        <div className="popper-course-intitule-icon">
                            <MenuBookTwoToneIcon/>
                        </div>
                        <div>
                            {props.course.course_intitule}
                        </div>
                    </div>
                    <div className="popper-course-system">
                        <div className="popper-course-system-icon">
                            <CastForEducationRoundedIcon/>
                        </div>
                        <div>
                            Systéme {props.system}
                        </div>
                    </div>
                    <div className="popper-course-module">
                        <div className="popper-course-module-icon">
                            <SchoolTwoToneIcon/>
                        </div>
                        <div>
                            {props.course.course_matiere.matiere_name} - {props.course.course_module}
                        </div>
                    </div>
                    <div className="popper-course-language">
                        <div className="popper-course-language-icon">
                            <LanguageRoundedIcon/>
                        </div>
                        <div>
                            {props.course.course_language}
                        </div>
                    </div>
                    <hr/>
                    <div className="popper-course-description">
                        <div className="popper-course-description-icon">
                            <DescriptionRoundedIcon fontSize="small"/>
                        </div>
                        <div>
                            {props.course.course_description}
                        </div>
                    </div>
                    <div className="popper-course-actions">
                        <div className="popper-course-actions-icon">
                            <FormControlLabel
                                control={<Checkbox checked={state.checkedA || props.course.course_is_favorite}
                                                   onChange={handleChange}
                                                   name="checkedA"
                                                   className="popper-course-actions-icon-favorite"
                                                   icon={<FavoriteBorder/>} aria-label="add to favorites"
                                                   color="secondary"
                                                   checkedIcon={<Favorite/>}/>}
                            />
                        </div>
                    </div>
                </div>
            </Popover.Content>
        </Popover>
    );
    return (
        <>
            <OverlayTrigger show={showTRigger} placement={(props.increment % 2) === 0 ? "left" : "right"}
                            overlay={popover}>
                <div onMouseEnter={() => setShowTrigger(true)}
                     onMouseLeave={() => setShowTrigger(false)}
                     className="matiere-cour-store">
                    <Card>
                        <CardMedia
                            component="img"
                            alt=""
                            image={props.course.course_pic ? props.course.course_pic : maths_trigo}
                            className="matiere-image"
                        />
                        <CardContent>
                            <Typography variant="body2">
                            <span
                                className="matiere-intitule">{short(props.course.course_intitule, 30)}</span>
                            </Typography>
                            <Typography variant="body2" className="courstore-cours-resume">
                                {props.course.course_duree ? "Durée:" : null} {props.course.course_duree !== 0 ? props.course.course_duree : null} {props.course.course_duree ? "h" : null}
                            </Typography>
                            <Typography variant="body2">
                                <StarIcon className="rating-icon" fontSize="small"/>
                                <span
                                    className="rating-style">{props.course.course_is_rating === 0 ? null : props.course.course_is_rating}</span>
                            </Typography>
                            <Typography variant="body2">
                                <hr style={{borderColor: "#c0306e"}}/>
                                <span
                                    className="course_price-barre">{props.course.course_price} {props.course.course_price ? "MAD" : null}</span>
                                <span
                                    className="course_price-remise">{props.course.course_price ? "-30 %" : null}</span>
                            </Typography>
                            <Typography variant="body2">
                                <span
                                    className="course_price-real"> {props.course.course_price ? "357 MAD" : null}</span>
                            </Typography>
                        </CardContent>

                    </Card>
                </div>
            </OverlayTrigger>
        </>
    )
}

