import { Grid } from '@material-ui/core';
import React from 'react'
import { useHistory } from "react-router-dom";

const Titrecours = (props)=> {
    const history = useHistory();

    const routeChange = () => {
        let path = `/student/chapitre_details`;
        history.push(path);
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <div onClick={routeChange} id="Intgration_par_parties">
                        <span>{props.titre}</span>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div id="ID02112020">
                        <span>{props.date}</span>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div id="En_cours">
                        <span>{props.status}</span>
                    </div>
                </Grid>
            </Grid>

        </>
    );
}

export default Titrecours;
