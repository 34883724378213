import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {Breakpoint} from "react-socks";

import SearchBar from "../../../Common/Layouts/Shared/CourStore/SearchBar";
import {CircularProgress, TablePagination} from "@material-ui/core";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {
    addTeacherToFavorite,
    getAllTeachersStoreAction,
    getTeacherFromFavorite, getTeachersByDescriptionAction, getTeachersByFilterAction
} from "../../../../Redux/ActionCreators";
import {FilterTeachersParams} from "./FilterTeachers";
import ProfDetails from "./ProfsList/ProfDetails";

const pages = [4, 8];


class ProfsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.session.user,
            isModalOpen: false,
            allertStatus: false,
            isLoad: this.props.student.isLoad,
            teachers: this.props.student.allTeachers,
            notify: {message: '', type: ''},
            notyIsOpen: false,
            page: 0,
            itemPerPage: pages[0],
            education_system: this.props.data.system_education,
            matieres: this.props.data.get_matieres,
        };
    };


    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        })

    }

    componentDidMount() {
         let data = {
            user_id: this.state.user.id
        }
        return this.props
            .dispatch(getAllTeachersStoreAction(data))
            .then(data => {
                if (data.payload) {
                    this.setState({
                        teachers: data.payload.teachers.sort((a,b)=>b.description.length>0 && b.profile_pic.length>0),
                        isLoad: this.props.student.isLoad,
                    })
                }
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message
                });
            });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            itemPerPage: parseInt(event.target.value, 10),
            page: 0
        })

    }
    // set notification
    setNotify = (message, type) => {
        this.setState({
            notify: {
                message: message,
                type: type
            },
            notyIsOpen: !this.state.notyIsOpen
        })
    }

    CoursesAfterPaging = (courses) => {
        return courses.slice(this.state.page * this.state.itemPerPage, (this.state.page + 1) * this.state.itemPerPage)
    }

    SearchByDescription = (searchText) => {
        this.setState({
            isLoad: !this.state.isLoad
        })
        let data = {
            search: searchText
        };
        if (searchText !== "") {
            return this.props
                .dispatch(getTeachersByDescriptionAction(data))
                .then(data => {
                    if (data.payload.results) {
                        console.log(data.payload);
                        this.setState({
                            teachers: data.payload.results,
                            isLoad: !this.state.isLoad
                        });
                    }

                })
                .catch(err => {
                    console.log(err.message)
                });
        }
    }

    FilterTeachers = (data) => {
        this.setState({
            isLoad: !this.state.isLoad
        })
        console.log(data)
        return this.props
            .dispatch(getTeachersByFilterAction(data))
            .then(data => {
                if (data.payload.teachers) {
                    this.setState({
                        teachers: data.payload.teachers,
                        isLoad: true,
                    });
                }
            })
            .catch(err => {
                console.log(err.message)
            });
    }
    handleAddToFavorite = (id) => {
        let data = {
            favourite_teacher: id,
            favourite_user_id: this.state.user.id
        }
        return this.props
            .dispatch(addTeacherToFavorite(data))
            .then(data => {
                if (data.payload.Success) {
                    this.GetTeacherFromFavorite()
                    this.setNotify(data.payload.message, 'success')
                }
            })
            .catch(err => {
                console.log(err.message)
            });
    }
    GetTeacherFromFavorite = () => {
        let data = {
            user_id: this.state.user.id
        };
        return this.props
            .dispatch(getTeacherFromFavorite(data))
            .then(data => {
            })
            .catch(err => {
                console.log(err.message)
            });
    }

    render() {
        const {
            isLoad,
            teachers,
            itemPerPage,
            page,
        } = this.state;
        return (
            <div>
                <div className="profs-list-component">
                    <div className="cour-store-research">
                        <div className="search-profs">
                            <div className="search-tittle">
                                <span>Recherchez le professeur que vous souhaitez</span>
                            </div>
                            <div className="course-search-bar">
                                <SearchBar
                                    Default_text="Recherchez par description"
                                    clickHandler={(searchText) => this.SearchByDescription(searchText)}
                                    setNotify={(message, type) => this.setNotify(message, type)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="cours-store-grid">
                        <Breakpoint large up>
                            <Grid container spacing={1} className="grid-style">
                                <Grid item xs={3}>
                                    <FilterTeachersParams
                                        EdducationalSystem={this.props.data.system_education}
                                        Subjects={this.props.data.get_matieres}
                                        clickHandler={(params) => this.FilterTeachers(params.params)}
                                    />
                                </Grid>
                                <Grid item sm={9} xs={12}>
                                    <div className="courses-store-container">
                                        <div className="course-container-header">
                                            <div className="found-results">
                                        <span>
                                            {teachers.length} résultat(s) trouvé(s)</span>
                                            </div>
                                        </div>
                                        <Grid item xs={12}>
                                            <Grid container spacing={0}>
                                                {isLoad ?
                                                    <>
                                                        {this.CoursesAfterPaging(teachers) && this.CoursesAfterPaging(teachers).map((teacher, key) => (
                                                            <ProfDetails key={key}
                                                                         prix="250DH/h"
                                                                         teacher={teacher}
                                                                         handleAddToFavorite={(id) => this.handleAddToFavorite(id)}
                                                            />))}
                                                    </>

                                                    :
                                                    <CircularProgress color="secondary" className="progress-bar"/>
                                                }
                                            </Grid>
                                        </Grid>
                                        <div className="scroll-pages-options">
                                            <TablePagination
                                                count={teachers.length}
                                                onChangePage={this.handleChangePage}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                component="div"
                                                page={page}
                                                rowsPerPage={itemPerPage}
                                                rowsPerPageOptions={pages}
                                                SelectProps={{
                                                    inputProps: {'aria-label': 'rows per page'},
                                                    native: true,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Breakpoint>
                        <Breakpoint large down>
                            <div className="courses-store-container">
                                <div className="course-container-header">
                                    <div className="found-results">
                                    <span>
                                        {teachers.length}
                                        résultat(s) trouvé(s)</span>
                                    </div>
                                </div>
                                <Grid item xs={12}>
                                    <Grid container spacing={0}>
                                        {isLoad ?
                                            <>
                                                {this.CoursesAfterPaging(teachers) && this.CoursesAfterPaging(teachers).map((teacher, key) => (
                                                    <ProfDetails key={key}
                                                                 nom={teacher.username}
                                                                 teacher_id={teacher.id}
                                                                 image={teacher.profile_pic}
                                                                 origine={teacher.degree}
                                                                 matiere={teacher.study_field + " " + teacher.level}
                                                                 prix="250DH/h"
                                                                 details={teacher.description}
                                                                 handleAddToFavorite={(id) => this.handleAddToFavorite(id)}
                                                    />))}
                                            </>

                                            :
                                            <CircularProgress color="secondary" className="progress-bar"/>
                                        }
                                    </Grid>
                                </Grid>
                                <div className="scroll-pages-options">
                                    <TablePagination
                                        count={teachers.length}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        component="div"
                                        page={page}
                                        rowsPerPage={itemPerPage}
                                        rowsPerPageOptions={pages}
                                        SelectProps={{
                                            inputProps: {'aria-label': 'rows per page'},
                                            native: true,
                                        }}
                                    />
                                </div>
                            </div>
                        </Breakpoint>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({session: state.session, student: state.student, data: state.data});
export default connect(mapStateToProps)(withRouter(ProfsList));