import React from "react";
import ReactDOM from "react-dom"
import axios from "axios";
import {PurchaseGoogleAnalytics} from "../../Common/Components/GoogleAnalytics"

const PayPalButton = window.paypal.Buttons.driver("react", {React, ReactDOM});

class Paypal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Ids: [],
            rate: "",
            payer: ""
        }
    }

    componentDidMount() {
        for (let i = 0; i < this.props.coursesToCart.length; i++) {
            this.state.Ids.push(this.props.coursesToCart[i].id)
        }
        axios({
            method: "GET",
            url: `https://free.currconv.com/api/v7/convert?q=MAD_USD&compact=ultra&apiKey=b1768f928512431bc326`,
        })
            .then((response) => {
                console.log(response.data['MAD_USD'])
                this.setState({
                    rate: response.data['MAD_USD']
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    createOrder(data, actions) {
        return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
                {
                    description: "Payment Courses",
                    amount: {
                        currency_code: "USD",
                        // *OLD version
                        // value: (this.props.finalPrice * this.state.rate).toFixed(2),
                        value: 1, //* Ca marche, just on doit tjrs s assurer que le montant !==0,
                        // *  cad pas de course a 0dh avec acheter btn
                    },
                },
            ],
        });
    }

    async onApprove(data, actions) {
        const order = await actions.order.capture();
        console.log(order)
        let data_add_to_achat = {
            user_id: this.props.user.id,
            statut_achat: order.status,
            type: "paypal",
            payer_email: order.payer.email_address,
            payer_country_code: order.payer.address.country_code,
            currency_code:order.purchase_units[0].amount.currency_code,
            amount: order.purchase_units[0].amount.value,
            ids_courses: this.state.Ids
        };
        this.props.clickToAddToAchat(data_add_to_achat)
        PurchaseGoogleAnalytics(order.id, this.props.finalPrice, this.state.Ids)
    }

    render() {
        return (
            <PayPalButton
                createOrder={(data, actions) => this.createOrder(data, actions)}
                onApprove={(data, actions) => this.onApprove(data, actions)}
            />
        );
    }
}


export default Paypal;