import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import {BlocCourse} from './BlocCourse';
import english from "../../../../../Assets/Images/english.jpg";
import physique from "../../../../../Assets/Images/physique.jpg";
import maths_integrales from "../../../../../Assets/Images/maths_integrales.png";
import svt from "../../../../../Assets/Images/svt.jpg";
import {connect} from "react-redux";
import {withRouter, useHistory} from "react-router-dom";
import {CircularProgress, isWidthDown, withWidth} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        flexWrap: 'wrap',
        overflow: 'hidden',

    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },

}));

function LineGridList(props) {
    const classes = useStyles();

    const history = useHistory()

    const teacher_courses = props.data_courses.techear_courses;
    const isLoad = props.data_courses.isLoad

    const getGridListCols = () => {
        // Should respecte the order
        if (isWidthDown('xs', props.width)) {
            return 2;
        }
        if (isWidthDown('sm', props.width)) {
            return 3;
        }
        if (isWidthDown('md', props.width)) {
            return 4;
        }
        if (isWidthDown('lg', props.width)) {
            return 5;
        }
        return 5;
    }

    const routeChange = (cours) => {
        let path;
        if (props.user.is_student) {
            path = `/student/${props.user.id}/courses/chapter/${cours.id}`;
        }
        if (props.user.is_teacher) {
            path = `/teacher/${props.user.id}/courses/chapter/${cours.id}`;
        }
        history.push(path)
    }

    return (
        <div className={classes.root}>
            {props.user.is_teacher ?
                isLoad ?
                    <GridList className={classes.gridList} cols={getGridListCols()} cellHeight={"100%"}>
                        {teacher_courses && teacher_courses.slice(0, 10).map((course, key) => (
                            <GridListTile key={key}>
                                <BlocCourse
                                    image={course.course_pic}
                                    pourcentage="26%"
                                    title={course.course_matiere.matiere_name}
                                    resume={course.course_description}
                                    chapitre={course.course_module}
                                    changeroutes={() => routeChange(course)}
                                    user={props.user}
                                />
                            </GridListTile>
                        ))}
                    </GridList>
                    :
                    <CircularProgress color="secondary" className="progress-bar"/>
                :
                <GridList className={classes.gridList} cols={getGridListCols()} cellHeight={"100%"}>
                    <GridListTile key="b2">
                        <BlocCourse
                            chngeroutes={routeChange}
                            pourcentage="26%" title="Anglais"
                            user={props.user}
                            resume="Le programme de vos cours d'anglais..."
                            chapitre=""
                            image={english}
                        >
                        </BlocCourse>
                    </GridListTile>
                    && <GridListTile key="b3">
                    <BlocCourse
                        chngeroutes={routeChange}
                        pourcentage="26%"
                        title="Physique"
                        user={props.user}
                        resume=" De toutes les sciences expérimentales, la physique est la plus..."
                        chapitre=""
                        image={physique}
                    >
                    </BlocCourse>
                </GridListTile>
                   <GridListTile key="b4">
                    <BlocCourse
                        chngeroutes={routeChange}
                        pourcentage="26%"
                        title="Mathématiques"
                        user={props.user}
                        resume="Les intégrales de WALLIS font partie de la grande..."
                        chapitre="Intégrales de Wallis"
                        image={maths_integrales}
                    >
                    </BlocCourse>
                </GridListTile>
                    && <GridListTile key="b5">
                    <BlocCourse
                        chngeroutes={routeChange} pourcentage="26%" title="SVT"
                        user={props.user}
                        resume="L'expression sciences de la vie et de la Terre (SVT) est utilisée..."
                        chapitre="Géologie"
                        image={svt}
                    >

                    </BlocCourse>
                </GridListTile>
                </GridList>
            }
        </div>
    );
}

const mapStateToProps = state => ({data_courses: state.data});
export default connect(mapStateToProps)(withRouter(withWidth()(LineGridList)));