import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from '@material-ui/core';

export default function SimpleAccordion() {
    const preventDefault = (event) => event.preventDefault();

    return (
        <div className="accordion-component">
            <Accordion className="accordion-container">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="accordion-summ"
                >
                    <Typography className="accordion-tittle">Notre FAQ</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Pour découvrir les questions fréquentes posées sur TalentED, accédez à notre Foire Aux Questions : <Link> FAQ</Link>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className="accordion-container">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className="accordion-summ"
                >
                    <Typography className="accordion-tittle">Contactez notre support</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Pour discuter avec un support technique, contactez-nous à l'addresse mail : <Link href="mailto:contact@talented.academy" onClick={preventDefault}>contact@talented.academy</Link>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            
        </div>
    );
}
