import React from 'react'
import SearchBar from './SearchBar'

export function Recherche(props) {
    

    return (
        <div className="search-profs">
            <div  className = "search-tittle">
                <span>{props.titre}</span>
            </div>
            <div className="course-search-bar">
                <SearchBar Default_text ={props.parDefaut}></SearchBar>
            </div>
        </div>

    )
}
