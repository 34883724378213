import React, {Component} from "react";
import Mathématiques from "../../Assets/Images/calculating.png";
import Physiques from "../../Assets/Images/atoms.png";
import Anglais from "../../Assets/Images/english-language.png";
import Français from "../../Assets/Images/eiffel-tower.png";
import Programmation from "../../Assets/Images/data.png";
import Coaching from "../../Assets/Images/chief.png";
import Chimie from "../../Assets/Images/accounting.png";
import Economie from "../../Assets/Images/economic.png";
import Arabe from "../../Assets/Images/Al-Isra.png";
import "./style.css";

class StudentMatieres extends Component{
    constructor(props) {
        super(props);
    }

    render(){
        const images = [
            {image: Mathématiques, subject: "Mathématiques", search: "Mathématique"},
            {image: Physiques, subject: "Physiques", search: "Physique"},
            {image: Anglais, subject: "Anglais", search: "Anglais"},
            {image: Français, subject: "Français", search: "Français",},
            {image: Programmation, subject: "Programmation",  search: "Programmation",},
            {image: Coaching, subject: "Coaching",  search: "Coaching",},
            // {image: Chimie, subject: "Chimie",  search: "Chimie",},
            {image: Economie, subject: "Economie",  search: "Economie",},
            // {image: Arabe, subject: "Arabe",  search: "Arabe",},

        ];
        const matieres3DIcon = (subject) => {
            switch(subject) {

                case "Mathématiques":   return <i className="fa  fa-square-root-variable fa-5 fa-3dicon"></i>;
                case "Physique": return <i className="fa  fa-atom fa-5 fa-3dicon"></i>;
                case "Anglais": return <i className="fa fa-book fa-5 fa-3dicon"></i>;
                case "Français":  return <i className="fa  fa-book-open fa-5 fa-3dicon"></i>;
                case "Programmation": return <i className="fa  fa-laptop-code fa-5 fa-3dicon"></i>;
                case "Coaching":  return <i className="fa  fa-comments fa-5 fa-3dicon"></i>;
                // case "Chimie": return <i className="fa  fa-flask-vial fa-5 fa-3dicon"></i>;
                case "Economie":  return <i className="fa  fa-chart-line fa-5 fa-3dicon"></i>;
                // case "Arabe":  return <i className="fa  fa-mosque fa-5 fa-3dicon"></i>;

                default:      return <h1>error</h1>
            }
        }
        return(
            <div key={this.props.key} className="bloc-image">
                <div className="icon-block icon-style text-center">
                    {matieres3DIcon(this.props.image.subject)}
                </div>

                <h2>{this.props.image.subject}</h2>
            </div>
        )
    }
}

export default StudentMatieres;