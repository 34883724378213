import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
// import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
// import DirectionsIcon from '@material-ui/icons/Directions';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '60%',
        margin: 'auto',
        [theme.breakpoints.down("sm")]: {
          width: '80%',
        },
        [theme.breakpoints.down("xs")]: {
          width: '100%',
        },
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));


export default function SearchBar(props) {
    const classes = useStyles();
    const [searchText, SetsearchText] = useState('')

    const onclick = (event) => {
        SetsearchText(event.target.value)
    };

    return (
        <Paper component="form" className={classes.root}>

            <InputBase
                className={classes.input}
                variant="outlined"
                placeholder={props.Default_text}
                inputProps={{'aria-label': 'Recherchez le cours que vous souhaitez suivre'}}
                onChange={onclick}
                //onKeyDown={() => props.clickHandler(searchText)}
            />
            <IconButton //type="submit"
                className={classes.iconButton}
                aria-label="searchAllCourse"
                variant="outlined"
                onClick={() => props.clickHandler(searchText)}
            >
                <SearchIcon/>
            </IconButton>

        </Paper>
    );
}

export class searchAllCourse {
}