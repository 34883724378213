import React from 'react';

const FavoriteEvent = () => {
    return (
        <div className="favorite-event-component">
            <div className="tittle">
                <span>
                    Bientôt, tous vos évènements favoris seront disponibles ici.
                </span>
            </div>
        </div>
    );
}

export default FavoriteEvent;

