import React from "react";
import { Button } from "@material-ui/core";
function AugmentedRealityComponent() {

    return (
        <div>
            <div class="sketchfab-embed-wrapper"
                style={{
                    display: "flex",
                    flexDirection: 'row',
                    position: 'relative',
                    top: '-30px',
                    left: '742px',
                }}>
                <iframe
                    title="School Teacher"
                    frameborder="0"
                    allowfullscreen
                    mozallowfullscreen="true"
                    webkitallowfullscreen="true"
                    allow="autoplay; fullscreen; xr-spatial-tracking"
                    xr-spatial-tracking
                    execution-while-out-of-viewport
                    execution-while-not-rendered
                    web-share
                    src="https://sketchfab.com/models/dcbd0761567b41ce9da34f24a3dd9b31/embed?autospin=1&autostart=1&preload=1&ui_hint=0&dnt=1"
                />
                <Button
                    // onclick="window.location='https://www.zenataverse.com';"
                    variant="text"
                    color="primary"
                    // class="buttonAR"
                    style={{
                        marginLeft: ' 0px',
                        paddingLeft: '0px'
                    }}
                >
                    <a href="https://www.zenataverse.com"
                        rel="noreferrer"
                        target="_blank" >
                        Expérience Réaliteé Augmentée
                        {/* <span class="actual-textAR">&nbsp;Expérience Réalité Augmentée&nbsp;</span> */}
                        {/* <span class="hover-textAR" aria-hidden="true">&nbsp;Expérience Réaliteé Augmentée&nbsp;</span> */}
                    </a>
                </Button>
            </div>
        </div>
    );
}

export default AugmentedRealityComponent;
