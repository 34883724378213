import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {Button, FormControlLabel, Checkbox, Paper, IconButton} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Recaptcha from 'react-recaptcha';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {addCourseToCart, loginAction} from '../../Redux/ActionCreators';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {EventGoogleAnalytics} from "./Components/GoogleAnalytics";
import {ContactUs} from "./Layouts/Shared/ContactUs/ContactUs";

const recaptchaRef = React.createRef();

class LogInComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: this.props.session.user,
            remember: false,
            visibility: false,
            errorGlobal: '',
        };
    };

    handleRemanber = () => {
        this.setState({
            remember: !this.state.remember
        })
    }

    handleVisibility = () => {
        this.setState({
            visibility: !this.state.visibility
        })
    }


  handleVisibility = () => {
      this.setState({
          visibility: !this.state.visibility
      })
  }

  componentDidMount() {
      const user =this.props.session.user;
      if(this.props.history.action ==="POP"){
          return this.props.history.push("/");
      }
      if(user){
          if(user.is_student){
              console.log("NAME :", user.email)
              return this.props.history.push("/student");
          }
          else if(user.is_adminFormation){
              console.log("NAME :", user.email)
              return this.props.history.push("/formation");
          }
          else{
              console.log("NAME :", user.email)
              console.log("Token : ", user.token)
              console.log("ID : ", user.id)
              return this.props.history.push("/teacher");
          }
      }
  }

    handleSubmit = (values) => {
        const data = {
            "email": values.email,
            "password": values.password,
        };
        return this.props
            .dispatch(loginAction(data))
            .then(data => {
                if (data.payload.Success) {
                    // Event Google Analytics
                    EventGoogleAnalytics("Login", "Button", `Show Login credentials, email: ${values.email}, userId:${data.payload.user.id}`)
                    setTimeout(() => {
                        if(data.payload.user.is_adminFormation){
                            this.props.history.push("/formation");
                        }
                        if (data.payload.user.is_student) {
                            if (this.props.student.prof_reservation_id) {
                                this.props.history.push("/student/" + this.props.student.prof_reservation_id + "/reserve/calendar");
                            } else {
                                this.props.history.push("/student/dashboard");
                            }
                        }
                        if (data.payload.user.is_teacher) {
                            console.log("GGGG")
                            this.props.history.push("/teacher/dashboard");
                        }
                    }, 3000);
                }
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message
                });
            });
    };

    render(){
        const { errorGlobal,user } = this.state;
        return (
            <>
                <Row className="login-form">
                    <div className="modal-page">
                        <Container className="modal-title">
                            <h1 className="title">Connexion</h1>
                        </Container>
                        {errorGlobal && (
                            <div className="textErrors">Error</div>
                        )}
                        <Container className="input-container">
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                    recaptcha: '',
                                }}
                                validationSchema={Yup.object({
                                    password: Yup.string()
                                        .min(6, 'Must be 6 characters or more')
                                        .required('Required'),
                                    email: Yup.string().email('Invalid email address')
                                        .required('Required'),
                                    recaptcha: Yup.string()
                                        .required('Required'),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    // alert(
                                    //     JSON.stringify(
                                    //         {
                                    //             recaptcha: values.recaptcha,
                                    //         },
                                    //         null,
                                    //         2
                                    //     )
                                    // );
                                    setTimeout(() => {
                                        this.handleSubmit(values)
                                        setSubmitting(false);
                                    }, 400);
                                }}
                            >
                                {({
                                      isSubmitting,
                                      values,
                                      errors,
                                      touched,
                                      isValidating,
                                      handleSubmit,
                                      handleChange,
                                      setFieldValue
                                  }) => (
                                    <form onSubmit={handleSubmit}>
                                        <span className="name-field">Email</span>
                                        <Paper className="paper">
                                            <Field
                                                name="email"
                                                value={values.email}
                                                className="text-field"
                                            />
                                        </Paper>
                                        <div className="error-field"> {errors.email && touched.email && errors.email}</div>
                                        <span className="name-field">Password</span>
                                        <Paper className="paper">
                                            <Field
                                                type={this.state.visibility ? "text": "password"}
                                                name="password"
                                                value={values.password}
                                                className="password-field"
                                            />
                                            <IconButton
                                                size="small"
                                                onClick={() => this.handleVisibility()}
                                                component="span"
                                                className="icon-visibility"
                                            >
                                                {this.state.visibility ? <VisibilityOffIcon/>: <VisibilityIcon/>}
                                            </IconButton>
                                        </Paper>
                                        <div className="error-field"> {errors.password && touched.password && errors.password}</div>
                                        <Row className="marge">
                                            <FormControlLabel
                                                className="remember"
                                                control={
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                        name="checkedI"
                                                        value={this.state.remember}
                                                        onClick={() => this.handleRemanber()}
                                                    />
                                                }
                                                label="Se Souvenir de moi"
                                            />
                                            <Link to="/resetPassword" className="recovery">Mot de passe oublié ?</Link>
                                        </Row>



                                        <Recaptcha
                                            sitekey="6LenPggfAAAAANCgq0xQJ6NQgO_yUIafzEG-iUVc"
                                            render="explicit"
                                            verifyCallback={(response) => { setFieldValue("recaptcha", response); }}
                                            onloadCallback={() => { console.log("done loading!"); }}
                                        />
                                        <div className="error-field">
                                            {errors.recaptcha && touched.recaptcha && errors.recaptcha}
                                        </div>

                                        <Button type="submit" className="seconnecter" variant="contained" color="secondary">Se connecter</Button>
                                    </form>
                                )}
                            </Formik>
                        </Container>
                        <div className="divider">
                            <hr/><div>Ou</div><hr/>
                        </div>
                        <Container className="bloc-MI">
                            <Button className="media-connexion" variant="outlined" color="secondary">S'enregister avec Google</Button>
                            <Button className="media-connexion" variant="outlined" color="primary">S'enregister avec Facebook</Button>
                        </Container>
                        <Container className="inscrivez-vous">
                            <span>Vous n'êtes pas encore inscrit ?</span><Link to="/inscription"> Créez un compte maintenant.</Link>
                        </Container>
                    </div>
                </Row>
                <ContactUs position={"login"}/>
            </>

        );
    }
}

const mapStateToProps = state => ({ session: state.session,student:state.student });
export default connect(mapStateToProps)(withRouter(LogInComponent));
