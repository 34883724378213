import {Grid} from '@material-ui/core';
import React, {Component} from 'react';
import creercompte from "../../../Assets/Images/Image_9.png";
import creercours from "../../../Assets/Images/Image_10.png";
import paiement from "../../../Assets/Images/Image_11.png";
import reservation from "../../../Assets/Images/Image_12.png";
import SimpleAccordion from './Shared/Help/Accordion';
import Modal from "react-bootstrap/Modal";
import InfoIcon from '@material-ui/icons/Info';
import IconButton from "@material-ui/core/IconButton";
import {Link} from "react-router-dom";
import {EventGoogleAnalytics, PageViewGoogleAnalytics} from "../Components/GoogleAnalytics";


class Helper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lgShow: false,
            lgShowSecond: false,
            lgShowThird: false,
        }
    }
    componentDidMount() {
        PageViewGoogleAnalytics("Help", window.location.pathname + window.location.search)
    }


    handleShowLarge = () => {
          // Event Google Analytics
        EventGoogleAnalytics("help_center_button", "Button", "Show modal account management")
        this.setState({
            lgShow: true
        })
    }
    handleShowLargeSecond = () => {
        // Event Google Analytics
        EventGoogleAnalytics("help_center_button", "Button", "Show modal payment")
        this.setState({
            lgShowSecond: true
        })
    }
     handleShowLargeThird = () => {
         // Event Google Analytics
        EventGoogleAnalytics("help_center_button", "Button", "Show modal book courses")
        this.setState({
            lgShowThird: true
        })
    }

    render() {
        return (
            <>
                <div className="help-component">
                    <div className="tittle">
                        <span>
                            Centre d'aide
                        </span>
                    </div>
                    <div className="help-container">
                        <div className="help-subject-tittle">
                    <span>
                        NOS SUJETS D'AIDE
                    </span>
                        </div>
                        <div className="help-subject-container">
                            <Grid container spacing={1}>
                                <Grid item md={3} sm={6} xs={12}>
                                    <div className="help-subject">
                                        <div className="help-subject-image">
                                            <img className="help-image" src={creercompte} alt=""></img>
                                        </div>
                                        <div className="help-tittle">
                                            <span>
                                                Gestion compte
                                            </span>
                                        </div>
                                        <div className="help-description">
                                             <span>
                                            <b>Commencer avec TalentED :</b> <br/>
                                        Créer, modifier et partager votre profil avec les autres utilisateurs.
                                    </span>
                                            <br/>
                                             <IconButton onClick={() => this.handleShowLarge()}
                                                        style={{marginLeft: "20 px", color: "darkblue"}}>
                                                <InfoIcon fontSize="large"/>
                                            </IconButton>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <div className="help-subject">
                                        <div className="help-subject-image">
                                            <img className="help-image" src={creercours} alt=""></img>
                                        </div>
                                        <div className="help-tittle">
                                    <span>
                                        Création d'un cours
                                    </span>
                                        </div>
                                        <div className="help-description">
                                    <span>
                                        <b>Faire des cours sur TalentED :</b><br/>
                                        Créer, Modifier et partager des cours sur TalentED.
                                    </span>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <div className="help-subject">
                                        <div className="help-subject-image">
                                            <img className="help-image" src={paiement} alt=""></img>
                                        </div>
                                        <div className="help-tittle">
                                    <span>
                                        Paiement
                                    </span>
                                        </div>
                                        <div className="help-description">
                                    <span>
                                        <b>Paiement :</b> <br/>
                                        Procéder à un achat du cours.
                                    </span>
                                            <br/>
                                            <IconButton onClick={() => this.handleShowLargeSecond()}
                                                        style={{marginLeft: "20 px", color: "darkblue"}}>
                                                <InfoIcon fontSize="large"/>
                                            </IconButton>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <div className="help-subject">
                                        <div className="help-subject-image">
                                            <img className="help-image" src={reservation} alt=""></img>
                                        </div>
                                        <div className="help-tittle">
                                    <span>
                                        Réservation
                                    </span>
                                        </div>
                                        <div className="help-description">
                                    <span>
                                        <b>Suivre ses cours :</b><br/>
                                        Comment réserver et assister à un cours sur TalentED ?
                                    </span>
                                            <br/>
                                            <IconButton onClick={() => this.handleShowLargeThird()}
                                                        style={{marginLeft: "20 px", color: "darkblue"}}>
                                                <InfoIcon fontSize="large"/>
                                            </IconButton>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="more-help">
                            <div className="more-help-tittle">
                        <span>
                            Besoin de plus d'aides ?
                        </span>
                            </div>
                            <SimpleAccordion className="more-help-accordion"></SimpleAccordion>
                        </div>
                    </div>
                </div>
                <Modal
                    className="style-model-payment"
                    size="lg"
                    show={this.state.lgShow}
                    onHide={() => this.setState({
                        lgShow: false
                    })}
                    aria-labelledby="example-modal-sizes-title-lg"
                    scrollable
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            <span style={{fontWeight: "bold", color: "darkblue"}}>Gestion Compte</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{textAlign: "justify",fontSize: "14px"}}>
                            <span style={{fontSize: "16px"}}>Une fois sur votre compte, suivez ces étapes pour le compléter et booster votre visibilité :</span>
                            <br/>
                            <span style={{fontWeight: "bold"}}>•</span> Accéder à votre compte en cliquant sur votre photo d'avatar en haut à droite de l'enveloppe mail ;
                            <br/>
                            <span style={{fontWeight: "bold"}}>• Avatar :</span> Choisissez votre avatar pour
                            personnaliser votre profil et vous vous différenciez;
                            <br/>
                            <span style={{fontWeight: "bold"}}>• Mettez votre profil en avant :</span> Renseignez une
                            description soigneuse et attractive de
                            vous-même en tant que prof et mettez en avant votre méthodo et votre stratégie pédagogique
                            afin de
                            générer plus de l’intérêt ;
                            <br/>
                            <span style={{fontWeight: "bold"}}>• A propos de vous :</span> Veuillez renseignez les
                            informations
                            sur votre formation, vos compétences et vos
                            intérêts pour finaliser votre profil.
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    className="style-model-payment"
                    size="lg"
                    show={this.state.lgShowSecond}
                    onHide={() => this.setState({
                        lgShowSecond: false
                    })}
                    aria-labelledby="example-modal-sizes-title-lg"
                    scrollable
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            <span style={{fontWeight: "bold", color: "darkblue"}}>Paiement</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{textAlign: "justify"}}>
                            Pour procéder au paiement, veuillez suivre les étapes suivantes :
                            <br/>
                            <span style={{fontWeight: "bold",fontSize: "14px"}}>• Choisissez votre professeur;</span>
                            <br/>
                            <span style={{fontWeight: "bold",fontSize: "14px"}}>• Choisissez le créneau qui vous convient;</span>
                            <br/>
                            <span style={{fontWeight: "bold",fontSize: "14px"}}>•  Choisissez 1h ou pack 10h;</span>
                            <br/>
                            <span style={{fontSize: "14px"}}><b>•</b>  Effectuez votre paiement par virement bancaire sur le compte :
                                <b>RIB 011780000087210010500259</b> et puis contactez nos services pour confirmer la réception au <Link>RH@talented.academy.</Link></span>
                            <br/>
                            <span style={{fontSize: "14px"}}> D'autres moyens de paiement seront bientôt disponibles.</span>
                        </div>
                    </Modal.Body>
                </Modal>
                          <Modal
                    className="style-model-payment"
                    size="lg"
                    show={this.state.lgShowThird}
                    onHide={() => this.setState({
                        lgShowThird: false
                    })}
                    aria-labelledby="example-modal-sizes-title-lg"
                    scrollable
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            <span style={{fontWeight: "bold", color: "darkblue"}}>Réservation</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{textAlign: "justify"}}>
                            Ces étapes te permettent de réserver un cours avec un enseignant de ton choix  :
                            <br/>
                            <span style={{fontSize: "14px"}}>• Connectes-toi à ton espace apprenant;</span>
                            <br/>
                            <span style={{fontSize: "14px"}}>• Accèdes à la liste des enseignants disponible en cliquant le bouton <b>« Trouver votre prof idéal »</b>;</span>
                            <br/>
                            <span style={{fontSize: "14px"}}>• Sélectionnes ton enseignant, puis accèdes à son calendrier en cliquant sur <b>« Choisir un créneau »</b>;</span>
                            <br/>
                            <span style={{fontSize: "14px"}}>•  Choisis le créneau qui te convient puis clique sur <b>« réserver »</b>;</span>
                            <br/>
                            <span style={{fontSize: "14px"}}>•  Nos enseignants s’engagent à répondre à ta demande de réservation dans les 48h après la réservation. Au-delà des 24h ou en cas d'urgance, tu peux nous contacter sur <Link>RH@talented.academy</Link>;</span>
                            <br/>
                            <span style={{fontSize: "14px"}}>• Procèdes au paiement après validation de la réservation par l’enseignant.</span>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default Helper
