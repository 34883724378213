import React, {Component, createRef} from 'react';

//import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

import {connect} from "react-redux";
import SideDrawer from "./SideDrawer";

class NavbarMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openProfile: false,
            anchorEl: null,
            anchorElMSG: null,
            anchorElCart: null,
            anchorElMSGSecondMenu: null,
            notificationsMenu: false,
            user: this.props.session.user,
            notificationsIsLoad: this.props.notifications.isLoad,
            notifications: this.props.notifications.List_notification,
            notificationError: this.props.notifications.errMess,
            invisible: false,
            coursesToCart: [],
            timeout:new Date(),
            navLinks: [
              { title: `Accueil`, path: this.props.session.user.is_student ? `/student/dashboard`: this.props.session.user.is_teacher ? `/teacher/dashboard`: null},
              { title: `cours`, path: this.props.session.user.is_student ? `/student/courses`: this.props.session.user.is_teacher ? `/teacher/courses`: null },
              { title: `calendriers`, path: this.props.session.user.is_student ? `/student/calendrier`: this.props.session.user.is_teacher ? `/teacher/calendrier`: null },
              { title: `help`, path: this.props.session.user.is_student ? `/student/help`: this.props.session.user.is_teacher ? `/teacher/help`: null },
            ],
        };
        this.wrapper = createRef();
    };
    getContact=()=>{
        let contact = []
        this.props.messages.messages.map(m => {
            if (contact.filter(c => (
                (c.sender === this.props.session.user.id && (c.receiver === m.receiver || c.receiver === m.sender))
                || (c.receiver === this.props.session.user.id && (c.sender === m.receiver || c.sender === m.sender)))
            ).length === 0) {
                contact.push(m);
            }
        });
        return contact;
    }
    render() {
        return (
            <div className="sectionMobile">
                {this.props.is_student ?
                    <IconButton
                        edge="end"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => this.props.handleClickCart(e)}

                        aria-label="show 4 new mails"
                        color="inherit"
                    >
                        <Badge //invisible={invisible}
                               badgeContent={this.props.coursesToCart === undefined ? 0 : this.props.coursesToCart.length}
                               color="primary">
                            <ShoppingBasketIcon/>
                        </Badge>
                    </IconButton>
                : null}
                <IconButton
                    edge="end"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) => this.props.handleClick(e)}
                    aria-label="show 4 new mails"
                    color="inherit"
                >
                    <Badge
                        badgeContent={this.props.messages.isLoad ? this.getContact().filter(message => (!message.read && message.receiver === this.state.user.id)).length : null}
                        color="secondary">
                        <MailIcon/>
                    </Badge>
                </IconButton>
                <IconButton
                    aria-controls="notifications-menu"
                    aria-haspopup="true"
                    onClick={(e) => this.props.handleClickNotificationsMenu(e)}
                    aria-label="new notifications"
                    color="inherit">
                    <Badge
                        badgeContent={this.state.notificationsIsLoad ? this.state.notifications.filter(element => !element.read).length : null}
                        color="secondary">
                        <NotificationsIcon/>
                    </Badge>
                </IconButton>

                {/*<IconButton*/}
                {/*    aria-label="show more"*/}
                {/*    aria-controls={this.props.mobileMenuId}*/}
                {/*    aria-haspopup="true"*/}
                {/*    onClick={() => handleSideDrawer()}*/}
                {/*    color="inherit"*/}
                {/*>*/}
                {/*    <MenuIcon/>*/}
                {/*</IconButton>*/}
            <SideDrawer navLinks={this.state.navLinks}/>
        </div>
        );
    }
}

const mapStateToProps = state => ({session: state.session, notifications: state.notifications, data: state.data, messages : state.messages});
export default connect(mapStateToProps)(NavbarMobile);
