import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {
    Accordion,
    AccordionDetails,
    CircularProgress,
    Select,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import {
    addCoursePart,
    addCoursePictureAction, addCourseQuize, DeleteCoursePart, DeleteCourseQuiz,
    getCourseParts,
    updateCoursePart,
    updateCourseQuiz
} from "../../../../Redux/ActionCreators";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import {Editor} from "react-draft-wysiwyg";
import htmlToDraft from 'html-to-draftjs';
import {EditorState, ContentState} from 'draft-js';
import {convertToHTML} from "draft-convert";
import DeleteIcon from "@material-ui/icons/Delete";
import FormControl from "@material-ui/core/FormControl";
import AugmentedRealityComponent from './AugmentedRealityComponent';


const langues = [
    {
        value: 'Français',
        label: 'Français',
    },
    {
        value: 'Anglais',
        label: 'Anglais',
    },
    {
        value: 'Arabe',
        label: 'Arabe',
    },
];

class CourseContentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseParts: [],
            courseQuizzes: [],
            display: "info",
            order: 0,
            MenuIsOpen: true,
            course_id: this.props.match.params.id_course,
            course: null,
            updateZone: "",
            Editabel: null,
            saveIntitule: false,
            matieres: this.props.data.get_matieres,
            education_systems: this.props.data.system_education,
            updatePart: null,
            editorState: EditorState.createEmpty(),
            updatePartTitle: null,
            partTitle: "",
            partOrder: null,
            updatePartOrder: null,
            savePart: false,
            saveQuiz: false,
            quizzesEdit: null,
            editQuize: null,
            allParts: [],
            addingPart: false,
            partAdd: null,
            editorStateAddPart: EditorState.createEmpty(),
            quizAdd: null,
            addingQuiz: false,
            validQuiz: "",
            loading: false,
        }
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.props
            .dispatch(getCourseParts(this.state.course_id))
            .then(data => {
                this.setState({
                    courseParts: data.payload.parts,
                    courseQuizzes: data.payload.quizzes,
                    course: data.payload.course[0],
                    Editabel: data.payload.course[0],
                    loading: true,
                });
                // let allParts = [...data.payload.quizzes, ...data.payload.parts,];
                // this.setState({
                //     allParts: allParts,
                // });
                // allParts.sort((a, b) => (a.order > b.order));
                // this.setState({
                //     allParts: allParts,
                // });
                this.updateAllParts(data.payload.parts, data.payload.quizzes);
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message
                });
            });
    }

    updateAllParts = (quizzes, parts) => {
        let allParts = [...quizzes, ...parts,];
        allParts.sort((a, b) => (a.order > b.order));
        this.setState({
            allParts: allParts,
        });
    }
    handelEdit = (zone) => {
        this.setState({
            updateZone: zone,
        });
    }
    handelEditPartTitle = (part) => {
        this.setState({
            updatePartTitle: part.order,
            partTitle: part.title,
        });
    }
    handelEditPartOrder = (part) => {
        this.setState({
            updatePartOrder: part.order,
            partOrder: part.order,
        });
    }
    handleChangePartTitle = (event) => {
        this.setState({
            partTitle: event.target.value,
        });
    }
    handleChangePartOrder = (event) => {
        this.setState({
            partOrder: event.target.value - 1,
        });
    }
    handleCancelPartTitle = () => {
        this.setState({
            updatePartTitle: null,
            partTitle: "",
        });
    }
    handleCancelPartOrder = () => {
        this.setState({
            updatePartOrder: null,
            partOrder: null,
        });
    }
    handleChangeIntitule = (event) => {
        const course = {...this.state.Editabel, course_intitule: event.target.value};
        this.setState({
            Editabel: course
        });
    }
    handleChangeDateDebut = (event) => {
        const course = {...this.state.Editabel, course_date_d: event.target.value};
        this.setState({
            Editabel: course
        });
    }
    handleChangeDuree = (event) => {
        const course = {...this.state.Editabel, course_duree: event.target.value};
        this.setState({
            Editabel: course
        });
    }
    handleChangeSystem = (event) => {
        const course = {...this.state.Editabel, course_systeme: event.target.value};
        this.setState({
            Editabel: course
        });
    }
    handleChangeLevel = (event) => {
        const course = {...this.state.Editabel, course_level: event.target.value};
        this.setState({
            Editabel: course
        });
    }
    handleChangeMatiere = (event) => {
        const matiere = {...this.state.Editabel.course_matiere, matiere_name: event.target.value}
        const course = {...this.state.Editabel, course_matiere: matiere};
        this.setState({
            Editabel: course
        });
    }
    handleChangeModule = (event) => {
        const course = {...this.state.Editabel, course_module: event.target.value};
        this.setState({
            Editabel: course
        });
    }
    handleChangeClasse = (event) => {
        const course = {...this.state.Editabel, course_classe: event.target.value};
        this.setState({
            Editabel: course
        });
    }
    handleChangeLangue = (event) => {
        const course = {...this.state.Editabel, course_language: event.target.value};
        this.setState({
            Editabel: course
        });
    }
    handleChangeDescription = (event) => {
        const course = {...this.state.Editabel, course_description: event.target.value};
        this.setState({
            Editabel: course
        });
    }
    handleChangeObjectifs = (event) => {
        const course = {...this.state.Editabel, course_objectifs: event.target.value};
        this.setState({
            Editabel: course
        });
    }
    handleChangePrerequis = (event) => {
        const course = {...this.state.Editabel, course_prerequis: event.target.value};
        this.setState({
            Editabel: course
        });
    }
    handleSave = (courseData) => {
        // this.setState({
        //     course: this.state.courseEditabel,
        //     updateZone:""
        // });
        let course = this.state.course;
        // const data = {id: course.id, data: {course_intitule: this.state.Editabel.course_intitule}};
        const data = {id: course.id, data: courseData};
        this.setState({
            saveIntitule: !this.state.saveIntitule,
        });
        this.props
            .dispatch(addCoursePictureAction(data))
            .then(data => {
                this.setState({
                    course: data.payload.course,
                    Editabel: data.payload.course,
                    updateZone: "",
                    saveIntitule: false,
                });

            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message,
                    Continue: false,
                    saveIntitule: false,
                });
            });


    }
    handleChangePicture = ({target}) => {
        let formData = new FormData();
        formData.append("course_pic", target.files[0]);
        this.handleSave(formData);
        console.log(target.files[0]);

    }

    handleCancel = () => {
        this.setState({
            Editabel: this.state.course,
            updateZone: "",
        });
    }

    handleEditPart = (part) => {
        this.setState({
            updatePart: part.order,
            editorState: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(part.partContent).contentBlocks)),

        });
    }
    handleEditCancel = () => {
        this.setState({
            updatePart: null,
            editorState: EditorState.createEmpty(),

        });
    }

    handelSave = (data) => {
        this.setState({
            savePart: !this.state.savePart,
        });
        return this.props
            .dispatch(updateCoursePart(data))
            .then(data => {
                this.setState({
                    courseParts: data.payload.parts,
                    savePart: false,
                });
                this.updateAllParts(data.payload.parts, data.payload.quizzes);
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message,
                    savePart: false,
                });
            });
    }
    handleDeletePartTitle = (id) => {
        // this.setState({
        //     savePart: !this.state.savePart,
        // });
        return this.props
            .dispatch(DeleteCoursePart(id))
            .then(data => {
                this.setState({
                    courseParts: data.payload.parts,
                    // savePart: false,
                });
                this.updateAllParts(data.payload.parts, data.payload.quizzes);
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message,
                    // savePart: false,
                });
            });
    }
    handleDeleteQuizTitle = (id) => {
        // this.setState({
        //     savePart: !this.state.savePart,
        // });
        return this.props
            .dispatch(DeleteCourseQuiz(id))
            .then(data => {
                this.setState({
                    courseParts: data.payload.parts,
                    // savePart: false,
                });
                this.updateAllParts(data.payload.parts, data.payload.quizzes);
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message,
                    // savePart: false,
                });
            });
    }
    handelSaveQuiz = (data) => {
        this.setState({
            saveQuiz: !this.state.saveQuiz,
        });
        return this.props
            .dispatch(updateCourseQuiz(data))
            .then(data => {
                this.setState({
                    courseQuizzes: data.payload.quizzes,
                    saveQuiz: false,
                });
                this.updateAllParts(data.payload.parts, data.payload.quizzes);

            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message,
                    saveQuiz: false,
                });
            });
    }

    handleChangeVideo = (event, id) => {
        console.log(event.currentTarget.files[0]);
        let formData = new FormData();
        formData.append("video", event.currentTarget.files[0]);
        const data = {
            data: formData,
            id: id,
        }

        this.handelSave(data)

    }
    handleSavePartTextContent = (id) => {
        const data = {
            data: {partContent: convertToHTML(this.state.editorState.getCurrentContent())},
            id: id,
        }
        this.handelSave(data);
        this.setState({
            editorState: EditorState.createEmpty(),
            updatePart: null,
        });
    }
    handleSavePartTitle = (id) => {
        const data = {
            data: {title: this.state.partTitle},
            id: id,
        }
        this.handelSave(data);
        this.setState({
            updatePartTitle: null,
            partTitle: null,
        });
    }
    handleSavePartOrder = (id) => {
        const data = {
            data: {order: this.state.partOrder},
            id: id,
        }
        this.handelSave(data);
        this.setState({
            updatePartOrder: null,
            partOrder: null,
        });
    }
    handleSaveQuizTitle = (id) => {
        const data = {
            data: {title: this.state.partTitle},
            id: id,
        }
        this.handelSaveQuiz(data);
        this.setState({
            updatePartTitle: null,
            partTitle: null,
        });
    }
    handleSaveQuizOrder = (id) => {
        const data = {
            data: {order: this.state.partOrder},
            id: id,
        }
        this.handelSaveQuiz(data);
        this.setState({
            updatePartOrder: null,
            partOrder: null,
        });
    }
    handleSaveQuizQuestion = () => {
        const quiz = this.state.quizzesEdit;
        const data = {
            data: {questions: quiz.questions},
            id: quiz.id,
        }
        this.handelSaveQuiz(data);
        this.setState({
            editQuize: null,
        });
        console.log(quiz)
    }
    onEditorStateChange = (editorState) => {


        this.setState({
            editorState: editorState,
        });
    };
    editQuizeQuestion = (quiz) => {
        this.setState({
            editQuize: quiz.id,
            quizzesEdit: quiz
        });
        console.log(quiz);
    }
    cancelQuizeQuestion = (quiz) => {
        this.setState({
            editQuize: null,
            quizzesEdit: null
        });
        console.log(quiz);
    }

    Quize(props) {
        const removeOption = (i, j) => {
            const quizze = this.state.quizzesEdit;
            quizze.questions[i].options.splice(j, 1);
            this.setState({
                quizzesEdit: quizze,
            });
        }
        const addOption = (e, i) => {
            const quizze = this.state.quizzesEdit;
            const value = e.target.value;
            if (e.key === "Enter" && value) {
                // check duplicate skill
                if (e.key === "Enter" && value) {
                    // check if duplicate skill
                    if (quizze.questions[i].options.find(skill => skill.option.toLowerCase() === value.toLowerCase())) {
                        return this.setState({
                            alert: "No duplicate value allowed",
                            show: true
                        });
                    }
                    // else add skill to skills array
                    quizze.questions[i].options.push({
                        option: value,
                        isCorrect : false,
                    })
                    // set new state
                    this.setState({
                        quizzesEdit: quizze,
                    })
                    this.inputRef.current.value = null
                } else if (e.key === "Backspace" && !value) {
                    this.removeOption(i, quizze.questions[i].options.length - 1)
                }
            }
        }
        const OptionIsCorrect = (e, i, j) => {
            const quiz = this.state.quizzesEdit;
            const value = e.target.value;
            quiz.questions[i].options[j].isCorrect=(value==='true');
            this.setState({quizzesEdit: quiz,});
        }
        const removeQuestion = i => {
            const quizze = this.state.quizzesEdit;
            quizze.questions.splice(i, 1);
            this.setState({
                quizzesEdit: quizze
            });
        }
        const handleAddQuestion = () => {
            const quizze = this.state.quizzesEdit;
            quizze.questions.push({
                question: "",
                answer: "",
                options: [],
            },)
            this.setState({
                quizzesEdit: quizze
            });
        }
        const handelChangeQuestion = (e, i) => {
            let quizze = this.state.quizzesEdit;
            quizze.questions[i].question = e.target.value;
            this.setState({
                quizzesEdit: quizze,
            })
        }
        const handelChangeQuestionJustif = (e, i) => {
            let quizze = this.state.quizzesEdit;
            quizze.questions[i].justification = e.target.value;
            this.setState({
                quizzesEdit: quizze,
            })
        }
        const handelChangeAnswer = (e, i) => {
            let quizze = this.state.quizzesEdit;
            quizze.questions[i].answer = e.target.value;
            this.setState({
                quizzesEdit: quizze,
            })
        }
        return (
            <div className={"addQuiz"}>
                {this.state.quizzesEdit.questions.map((question, i) => {
                    return (

                        <div className="quize_content">
                            <fieldset className={"fieldset"}>
                                <legend className={"legend"}>{"Question " + (i + 1)}</legend>
                                <TextField
                                    id="outlined-basic"
                                    onChange={(e) => handelChangeQuestion(e, i)}
                                    className="QuestionQuiz"
                                    label={"Question " + (i + 1)}
                                    value={question.question}
                                    variant="outlined"/>
                                <div className="optionQuestion">
                                    <fieldset>
                                        <legend>Les options de réponse</legend>
                                        <ul>
                                            {question.options && question.options.map((option, j) => {
                                                return (
                                                    <li key={i}> {option.option}
                                                        <button onClick={() => removeOption(i, j)}>+</button>
                                                    </li>
                                                )
                                            })}
                                            <li className="input-Quize">
                                                <input onKeyDown={(e) => {
                                                    addOption(e, i)
                                                }} type="text" size="4" ref={this.inputRef}
                                                       placeholder={"Ajouter un option"}/>
                                            </li>
                                        </ul>
                                    </fieldset>
                                </div>
                                {/*<Select*/}
                                {/*    className="correct_answer"*/}
                                {/*    native*/}
                                {/*    variant="outlined"*/}
                                {/*    size="small"*/}
                                {/*    onChange={(e) => handelChangeAnswer(e, i)}*/}


                                {/*>*/}
                                {/*    <option aria-label="None" value="" disabled selected>Choisir*/}
                                {/*        la*/}
                                {/*        réponse*/}
                                {/*    </option>*/}

                                {/*    {question && question.options.map((option) => {*/}
                                {/*        return (*/}
                                {/*            <option style={{margin: "12px",}} value={option.option}*/}
                                {/*                    selected={question.answer === option.option}>*/}
                                {/*                {option.option.length > 30 ? option.option.slice(0, 30) + "..." : option.option}*/}
                                {/*            </option>*/}

                                {/*        )*/}
                                {/*    })}*/}

                                {/*</Select>*/}
                                <div className="answersIsCorrect">
                                {question && question.options.map((option,j) => {
                                    return (
                                    <FormControl className={"answersIsCorrectRadio"} component="fieldset">
                                        <FormLabel component="legend">{option.option}</FormLabel>
                                        <RadioGroup aria-label="gender" name="gender1"
                                                    value={option.isCorrect}
                                                    onChange={(e)=>OptionIsCorrect(e,i,j)}
                                            row
                                        >
                                            <FormControlLabel value={true} control={<Radio/>} label="vrai "/>
                                            <FormControlLabel value={false} control={<Radio/>} label="faux"/>
                                        </RadioGroup>
                                    </FormControl>

                                           )
                                    })}
                                </div>
                                <TextField id="outlined-basic"
                                           onChange={(e) => handelChangeQuestionJustif(e, i)}
                                           className="JustificationQuestion"
                                           label={"Justification"}
                                           value={question.justification}
                                           variant="outlined"
                                           size="small"
                                />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="delete_question"
                                    startIcon={<DeleteIcon/>}
                                    onClick={
                                        // this.state.quizzes[this.state.quizzes.length - 1].questions.length === 1 ? null : () => {
                                        () => removeQuestion(i)
                                    }
                                >
                                    Delete Question
                                </Button>
                            </fieldset>
                        </div>
                    );
                })
                }
                <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    className="add_questions"
                    onClick={handleAddQuestion}
                >
                    Ajouter une question
                </Button>

            </div>

        );
    }

    handeladdingPart = () => {
        this.setState({
            addingPart: !this.state.addingPart,
            partAdd: {
                title: "",
                order: this.state.allParts.length,
                video: null,
                course: this.state.course_id,
                partContent: EditorState.createEmpty(),
                support: null
            }
        });
    }
    addNewPart = () => {
        this.setState({
            addingPart: !this.state.addingPart,
            partAdd: {
                title: "",
                order: 0,
                video: null,
                course: this.state.course_id,
                partContent: EditorState.createEmpty(),
                support: null
            }
        });
        let formData = new FormData();
        formData.append("title", this.state.partAdd.title);
        formData.append("order", this.state.partAdd.order);
        formData.append("course", this.state.partAdd.course);
        if (this.state.partAdd.video) {
            formData.append("video", this.state.partAdd.video);
        }
        if (this.state.partAdd.part_pdf) {
            formData.append("support", this.state.partAdd.support);
        }
        if (this.state.partAdd.partContent) {
            formData.append("partContent", this.state.partAdd.partContent);
        }
        return this.props
            .dispatch(addCoursePart(formData))
            .then(data => {
                let response = data.payload.response;
                console.log(response);
                this.setState({
                    addPart: false,
                    courseParts: data.payload.parts,
                });
                // this.setNotify(data.payload.message, 'success');
                this.updateAllParts(data.payload.parts, data.payload.quizzes);

            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message,
                    // addPart : false
                });
                // this.setNotify("l'opération a echoué", 'error');
            });
    }

    TextEditor(props) {

        const onEditorStateChange = (editorState) => {
            let partAdd = this.state.partAdd;
            partAdd.partContent = convertToHTML(editorState.getCurrentContent());
            this.setState({
                // editorStateAddPart: editorState,
                partAdd: partAdd,
            });
        };

        return (
            <div
                style={{backgroundColor: "white", position: "relative", height: "12em"}}
            >
                <Editor
                    className="editor-bloc"
                    // editorState={this.state.editorStateAddPart}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                />
                {/*{this.state.partes[this.state.activeStep].part_content}*/}

            </div>
        );
    }

    handelChangeTitlePart = (e, i) => {
        let partAdd = this.state.partAdd;
        partAdd.title = e.target.value;
        this.setState({partAdd: partAdd});
    }
    handelChangeOrderPart = (e, i) => {
        let partAdd = this.state.partAdd;
        partAdd.order = e.target.value - 1;
        this.setState({partAdd: partAdd});
    }
    handelChangeVideo = (event) => {
        let partAdd = this.state.partAdd;
        partAdd.video = event.currentTarget.files[0];

        this.setState({partAdd: partAdd});
    }
    handelChangePdf = (event) => {
        let partAdd = this.state.partAdd;
        partAdd.support = event.currentTarget.files[0];
        this.setState({partAdd: partAdd});
    }
    handelAddingQuiz = () => {
        this.setState({
            quizAdd: {
                title: "",
                order: this.state.allParts.length,
                course: this.state.course_id,
                questions: [{question: "", options: [], answer: "", justification: ""},],
            },
            addingQuiz: !this.state.addingQuiz,
            validQuiz: false,
        })
    }
    handelCancelAddingQuiz = () => {
        this.setState({
            quizAdd: null,
            addingQuiz: false,
            validQuiz: "",
        })
    }

    quizValidation = (quiz) => {
        if (quiz.title === "" || quiz.questions === null) {
            return false;
        } else {
            let res = quiz.questions.filter((question) => {
                return (question.question === ""
                    || question.answer === ""
                    || question.options.length === 0)
            });
            if (res.length > 0) {
                return false
            }
        }
        return true;
    }

    handleSubmitQuiz = (values) => {
        let quize = this.state.quizAdd;
        // if (this.quizValidation(quize)) {
        if (true) {
            return this.props
                .dispatch(addCourseQuize(quize))
                .then(data => {

                    let response = data.payload.response;
                    console.log(response);
                    this.setState({
                        courseQuizzes: data.payload.quizzes,
                        addingQuiz: !this.state.addingQuiz,
                    });
                    this.updateAllParts(data.payload.parts, data.payload.quizzes);

                })
                .catch(err => {
                    console.log("err", err);
                    this.setState({
                        errorGlobal: err.message,
                        validQuiz: "",
                    });
                });
        } else {
            this.setState({
                validQuiz: "veuillez remplir tous les champs requis"
            });
            console.log("veuillez remplir tous les champs requis");
        }
    }


    AddQuize = (props) => {

        const removeOption = (i, j) => {
            const quizAdd = this.state.quizAdd;
            quizAdd.questions[i].options.splice(j, 1);
            this.setState({
                quizAdd: quizAdd,
            });
        }
        const addOption = (e, i) => {
            const quizAdd = this.state.quizAdd;
            const value = e.target.value;
            if (e.key === "Enter" && value) {
                // check duplicate skill
                if (e.key === "Enter" && value) {
                    // check if duplicate skill
                    if (quizAdd.questions[i].options.find(skill => skill.option.toLowerCase() === value.toLowerCase())) {
                        return this.setState({
                            alert: "No duplicate value allowed",
                            show: true
                        });
                    }
                    // else add skill to skills array
                    quizAdd.questions[i].options.push({
                        option: value,
                        isCorrect : "false",
                    })
                    // set new state
                    this.setState({
                        quizAdd: quizAdd,
                    })
                    this.inputRef.current.value = null
                } else if (e.key === "Backspace" && !value) {
                    removeOption(i, quizAdd.questions[i].options.length - 1)
                }
            }

        }
        const OptionIsCorrect = (e, i, j) => {
            const quizAdd = this.state.quizAdd;
            const value = e.target.value;
            quizAdd.questions[i].options[j].isCorrect=value;
            this.setState({quizAdd: quizAdd,});
        }

        const removeQuestion = i => {
            const quizAdd = this.state.quizAdd;
            quizAdd.questions.splice(i, 1);
            this.setState({
                quizAdd: quizAdd
            });
        }
        const handleAddQuestion = () => {
            const quizAdd = this.state.quizAdd;
            quizAdd.questions.push({
                question: "",
                // answer: "",
                options: [],
            },)
            this.setState({
                quizAdd: quizAdd,
            })
        }
        const handelChangeQuestion = (e, i) => {
            let quizAdd = this.state.quizAdd;
            quizAdd.questions[i].question = e.target.value;
        }
        // const handelChangeAnswer = (e, i) => {
        //     let quizAdd = this.state.quizAdd;
        //     quizAdd.questions[i].answer = e.target.value;
        // }
        const handelChangeTitleQuize = (e, i) => {
            let quizAdd = this.state.quizAdd;
            quizAdd.title = e.target.value;
        }
        const handelChangeOrderQuize = (e, i) => {
            let quizAdd = this.state.quizAdd;
            quizAdd.order = e.target.value - 1;
        }
        const handelChangeJustifQuestionQuize = (e, i) => {
            let quizAdd = this.state.quizAdd;
            quizAdd.questions[i].justification = e.target.value;

        }

        return (
            <div className={"addQuiz"}>
                <h1>Ajouter un nouveau quiz</h1>
                <p>{this.state.validQuiz}</p>
                <TextField id="outlined-basic"
                           onChange={(e) => handelChangeTitleQuize(e)}
                           className="TitleQuiz"
                           variant="outlined"
                           size="small"
                           label={"Titre "}
                />
                <TextField type="number"
                           id="outlined-basic"
                           onChange={(e) => handelChangeOrderQuize(e)}
                           className="order"
                           label={"Order "}
                           defaultValue={this.state.allParts.length + 1}
                           variant="outlined"
                           size="small"
                           InputProps={{inputProps: {min: 1, max: this.state.allParts.length + 1}}}
                />

                {this.state.quizAdd.questions.map((question, i) => {
                    return (

                        <div className="quize_content">
                            <fieldset className={"fieldset"}>
                                <legend className={"legend"}>{"Question " + (i + 1)}</legend>
                                <TextField id="outlined-basic"
                                           onChange={(e) => handelChangeQuestion(e, i)}
                                           variant="outlined"
                                           size="small"
                                           className="QuestionQuiz"
                                           label={"Question " + (i + 1)}/>
                                <div className="optionQuestion">
                                    <fieldset>
                                        <legend>Les options de réponse</legend>
                                        <ul>
                                            {question.options && question.options.map((option, j) => {
                                                return (
                                                    <li key={i}> {option.option}
                                                        <button onClick={() => removeOption(i, j)}>+</button>
                                                    </li>
                                                )
                                            })}
                                            <li className="input-Quize">
                                                <input onKeyDown={(e) => {
                                                    addOption(e, i)
                                                }} type="text" size="4" ref={this.inputRef}
                                                       placeholder={"Ajouter une option"}/>
                                            </li>
                                        </ul>
                                    </fieldset>

                                </div>
                                {/*<Select*/}
                                {/*    className="correct_answer"*/}
                                {/*    native*/}
                                {/*    // value={state.age}*/}
                                {/*    onChange={(e) => handelChangeAnswer(e, i)}*/}
                                {/*    variant="outlined"*/}
                                {/*    size="small"*/}
                                {/*    // label="La réponse"*/}

                                {/*>*/}
                                {/*    <option aria-label="None" value="" disabled selected>*/}
                                {/*        Choisir la réponse*/}
                                {/*    </option>*/}

                                {/*    {question && question.options.map((option) => {*/}
                                {/*        return (*/}
                                {/*            <option*/}
                                {/*                value={option.option}>{option.option.length > 30 ? option.option.slice(0, 30) + "..." : option.option}</option>*/}

                                {/*        )*/}
                                {/*    })}*/}

                                {/*</Select> */}
                                <div className="answersIsCorrect">
                                {question && question.options.map((option,j) => {
                                    return (
                                    <FormControl className={"answersIsCorrectRadio"} component="fieldset">
                                        <FormLabel component="legend">{option.option}</FormLabel>
                                        <RadioGroup aria-label="gender" name="gender1"
                                                    value={option.isCorrect}
                                                    onChange={(e)=>OptionIsCorrect(e,i,j)}
                                            row
                                        >
                                            <FormControlLabel value={"true"} control={<Radio/>} label="vrai "/>
                                            <FormControlLabel value={"false"} control={<Radio/>} label="faux"/>
                                        </RadioGroup>
                                    </FormControl>

                                           )
                                    })}
                                </div>
                                <TextField id="outlined-basic"
                                           onChange={(e) => handelChangeJustifQuestionQuize(e, i)}
                                           className="JustificationQuestion"
                                           variant="outlined"
                                           size="small"
                                           label={"Justification de réponse "}/>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="delete_question"
                                    startIcon={<DeleteIcon/>}
                                    onClick={this.state.quizAdd.questions.length === 1 ? null : () => {
                                        removeQuestion(i)
                                    }}
                                >
                                    Delete Question
                                </Button>
                            </fieldset>
                        </div>
                    );
                })
                }
                <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    className="add_questions"
                    onClick={handleAddQuestion}
                >
                    Ajouter une question
                </Button>

            </div>

        );
    }

    displayQuiz = (quiz) => {
        return (
            <div className={"addQuiz"}>{quiz.questions.map((question, i) => {
                return (

                    <div className="quize_content">
                        <fieldset className={"fieldset"}>
                            <legend
                                className={"legend"}>{"Question " + (i + 1)}</legend>
                            <TextField
                                id="outlined-basic"
                                className="QuestionQuiz"
                                label={"Question " + (i + 1)}
                                value={question.question}
                                variant="outlined"
                                disabled
                            />
                            <div className="optionQuestion">
                                <fieldset>
                                    <legend>Les options de réponse
                                    </legend>
                                    <ul>
                                        {question.options && question.options.map((option, j) => {
                                            return (
                                                <li key={i}> {option.option}
                                                    <button>+</button>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </fieldset>
                            </div>
                            <div className="answersIsCorrect">
                                {question && question.options.map((option, j) => {
                                    return (
                                        <FormControl className={"answersIsCorrectRadio"} component="fieldset">
                                            <FormLabel component="legend">{option.option}</FormLabel>
                                            <RadioGroup aria-label="gender" name="gender1"
                                                        value={option.isCorrect}
                                                        // onChange={(e) => OptionIsCorrect(e, i, j)}
                                                        row
                                            >
                                                <FormControlLabel value={true} control={<Radio/>} label="vrai " disabled/>
                                                <FormControlLabel value={false} control={<Radio/>} label="faux" disabled/>
                                            </RadioGroup>
                                        </FormControl>

                                    )
                                })}
                            </div>
                            <TextField id="outlined-basic"
                                       className="JustificationQuestion"
                                       label={"Justification"}
                                       value={question.justification}
                                       variant="outlined"
                                       size="small"
                                       disabled
                            />


                        </fieldset>
                    </div>
                );
            })
            }


            </div>

        );
    }

    render() {
        const {
            updatePartTitle,
            updatePartOrder,
            allParts,
            course,
            Editabel,
            updateZone,
            saveIntitule,
            loading
        } = this.state;
        const {system_education, get_matieres} = this.props.data;
        return (
            <div className={"courseContent"}>
                {
                    loading ?
                    // true ?
                        <>
                            <div className={"CoursePicture"}>
                                <IconButton color="primary" className="course_pic"
                                            onClick={() => document.getElementById('upload_pic').click()}
                                            style={{backgroundImage: course ? "url('" + course.course_pic + "')" : null}}>
                                    {this.state.saveIntitule && this.state.updateZone === "" ?
                                        <CircularProgress color="secondary" size={48}/> : <>
                                            <span className="glyphicon glyphicon-camera"><InsertPhotoIcon/></span>
                                            <span>Change Image</span></>
                                    }

                                </IconButton>
                                <input
                                    name="cours_pic"
                                    accept="image/*"
                                    type="file"
                                    id={"upload_pic"}
                                    onChange={this.handleChangePicture}
                                    // onChange={handleChange}
                                    style={{display: "none"}}
                                />

                            </div>
                            <div className={"CourseDetails"}>
                                <div className={"CourseIntitule"}>
                                    {updateZone === "intitule" ?
                                        <>
                                            <TextField
                                                // className="test"
                                                className="InputIntitule"
                                                label="Intitulé"
                                                variant="outlined"
                                                size="small"
                                                value={Editabel ? Editabel.course_intitule : null}
                                                onChange={this.handleChangeIntitule}
                                            />

                                            <IconButton color="primary" onClick={this.handleCancel}
                                                        className={"courseButtonCancel"} aria-label="upload picture"
                                                        component="span">
                                                <CancelIcon/>

                                            </IconButton><IconButton color="primary"
                                                                     onClick={() => this.handleSave({course_intitule: Editabel.course_intitule})}
                                                                     disabled={saveIntitule}
                                                                     className={"courseButtonSave"}
                                                                     aria-label="upload picture" component="span">
                                            {this.state.saveIntitule ? <CircularProgress color="secondary" size={18}/> :
                                                <SaveIcon/>}

                                        </IconButton>

                                        </>
                                        :
                                        <>
                                            {course ?
                                                <><TextField
                                                    // className="test"
                                                    className="InputIntitule"
                                                    label="Intitulé"
                                                    variant="outlined"
                                                    size="small"
                                                    value={course ? course.course_intitule : null}
                                                    onChange={this.handleChangeIntitule}
                                                    InputLabelProps={{shrink: true}}
                                                    disabled
                                                />
                                                    <IconButton color="primary"
                                                                onClick={() => this.handelEdit("intitule")}
                                                                className={"courseButtonEdit"}
                                                                aria-label="upload picture"
                                                                component="span">
                                                        <EditIcon/>
                                                    </IconButton>
                                                </>
                                                : null}
                                        </>
                                    }

                                </div>
                                <div className={"CourseDuree"}>
                                    {updateZone === "Date_debut" ?
                                        <>
                                            <TextField
                                                className="InputDate"
                                                id="datetime-local"
                                                type="date"
                                                fullWidth
                                                name="start_date"
                                                label="Date de début"
                                                value={Editabel ? Editabel.course_date_d : null}
                                                onChange={this.handleChangeDateDebut}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}

                                            />
                                            <IconButton color="primary" onClick={this.handleCancel}
                                                        className={"courseButtonCancel"} aria-label="upload picture"
                                                        component="span">
                                                <CancelIcon/>

                                            </IconButton><IconButton color="primary"
                                                                     onClick={() => this.handleSave({course_date_d: Editabel.course_date_d})}
                                                                     disabled={saveIntitule}
                                                                     className={"courseButtonSave"}
                                                                     aria-label="upload picture" component="span">
                                            {this.state.saveIntitule ? <CircularProgress color="secondary" size={18}/> :
                                                <SaveIcon/>}

                                        </IconButton>
                                        </>
                                        :
                                        <>
                                            <TextField
                                                className="InputDate"
                                                id="datetime-local"
                                                type="date"
                                                fullWidth
                                                name="start_date"
                                                label="Date de début"
                                                value={course ? course.course_date_d : null}
                                                // onChange={handleChange}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled

                                            />
                                            {/*date debut : {course ? course.course_date_d : null}*/}
                                            <IconButton color="primary" className={"courseButtonEdit"}
                                                        onClick={() => this.handelEdit("Date_debut")}
                                                        aria-label="upload picture"
                                                        component="span">
                                                <EditIcon/>
                                            </IconButton>
                                        </>
                                    }

                                </div>
                                <div className={"CourseDuree"}>
                                    {updateZone === "Duree" ?

                                        <>
                                            <TextField
                                                //fullWidth
                                                className="InputDate"
                                                type="number"
                                                name="cours_duree"
                                                label="La durée"
                                                value={Editabel ? Editabel.course_duree : null}
                                                onChange={this.handleChangeDuree}
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: <InputAdornment
                                                        position="start">H</InputAdornment>,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}

                                            />
                                            <IconButton color="primary" onClick={this.handleCancel}
                                                        className={"courseButtonCancel"} aria-label="upload picture"
                                                        component="span">
                                                <CancelIcon/>

                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                onClick={() => this.handleSave({course_duree: Editabel.course_duree})}
                                                disabled={saveIntitule}
                                                className={"courseButtonSave"}
                                                aria-label="upload picture"
                                                component="span"
                                            >
                                                {this.state.saveIntitule ?
                                                    <CircularProgress color="secondary" size={18}/>
                                                    :
                                                    <SaveIcon/>}
                                            </IconButton>
                                        </>
                                        :
                                        <>
                                            <TextField
                                                className="InputDate"
                                                type="number"
                                                name="cours_duree"
                                                label="La durée"
                                                value={Editabel ? Editabel.course_duree : null}
                                                onChange={this.handleChangeDuree}
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">H</InputAdornment>,
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                disabled
                                            />
                                            {/*duree : {course ? course.course_duree : null}*/}
                                            <IconButton color="primary" className={"courseButtonEdit"}
                                                        aria-label="upload picture"
                                                        onClick={() => this.handelEdit("Duree")}
                                                        component="span">
                                                <EditIcon/>
                                            </IconButton>
                                        </>
                                    }


                                </div>

                                <div className={"CourseSystem"}>
                                    {updateZone === "System" ?

                                        <>
                                            <TextField
                                                id="outlined-select-currency-native"
                                                select
                                                fullWidth
                                                className={`InputDate`}
                                                name="cours_systeme"
                                                label="Systéme"
                                                onChange={this.handleChangeSystem}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                            >
                                                {system_education && system_education.map((option) => (
                                                    <option key={option.id} value={option.id}
                                                            selected={Editabel ? option.id === Editabel.course_systeme : null}>
                                                        {option.system_name}
                                                    </option>
                                                ))}
                                            </TextField>
                                            <IconButton color="primary" onClick={this.handleCancel}
                                                        className={"courseButtonCancel"} aria-label="upload picture"
                                                        component="span">
                                                <CancelIcon/>

                                            </IconButton><IconButton color="primary"
                                                                     onClick={() => this.handleSave({course_systeme: Editabel.course_systeme})}
                                                                     disabled={saveIntitule}
                                                                     className={"courseButtonSave"}
                                                                     aria-label="upload picture" component="span">
                                            {this.state.saveIntitule ? <CircularProgress color="secondary" size={18}/> :
                                                <SaveIcon/>}

                                        </IconButton>
                                        </>
                                        :
                                        <>
                                            <TextField
                                                id="outlined-select-currency-native"

                                                fullWidth
                                                className={`InputDate`}
                                                name="cours_systeme"
                                                label="Systéme"
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                                disabled
                                                InputLabelProps={{shrink: true}}
                                                value={(Editabel && system_education && system_education.filter((option) => option.id === Editabel.course_systeme).length > 0) ? system_education.filter((option) => option.id === Editabel.course_systeme)[0].system_name : ""}
                                            />

                                            <IconButton color="primary" className={"courseButtonEdit"}
                                                        aria-label="upload picture"
                                                        onClick={() => this.handelEdit("System")}
                                                        component="span">
                                                <EditIcon/>

                                            </IconButton>
                                        </>
                                    }
                                </div>
                                <div className={"CourseSystem"}>
                                    {updateZone === "Niveau" ?

                                        <>
                                            <TextField
                                                id="outlined-select-currency-native"
                                                select
                                                fullWidth
                                                className={`InputDate`}
                                                name="course_level"
                                                label="Niveau"
                                                onChange={this.handleChangeLevel}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                            >
                                                {system_education && system_education.filter((system) => system.id === Editabel.course_systeme)[0].systeme_levels.map((level, key) => (
                                                    <option key={key} value={level.level_name}
                                                            selected={Editabel ? level.level_name === Editabel.course_level : null}>
                                                        {/*// selected={Editabel ? option.id===Editabel.cours_systeme : false}>*/}
                                                        {level.level_name}
                                                    </option>
                                                ))}
                                            </TextField>
                                            <IconButton color="primary" onClick={this.handleCancel}
                                                        className={"courseButtonCancel"} aria-label="upload picture"
                                                        component="span">
                                                <CancelIcon/>

                                            </IconButton><IconButton color="primary"
                                                                     onClick={() => this.handleSave({course_level: Editabel.course_level})}
                                                                     disabled={saveIntitule}
                                                                     className={"courseButtonSave"}
                                                                     aria-label="upload picture" component="span">
                                            {this.state.saveIntitule ? <CircularProgress color="secondary" size={18}/> :
                                                <SaveIcon/>}

                                        </IconButton>
                                        </>
                                        :
                                        <>

                                            <TextField
                                                id="outlined-select-currency-native"

                                                fullWidth
                                                className={`InputDate`}
                                                name="course_level"
                                                label="Niveau"
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                                disabled
                                                InputLabelProps={{shrink: true}}
                                                value={Editabel ? Editabel.course_level : null}
                                            />
                                            <IconButton color="primary" className={"courseButtonEdit"}
                                                        aria-label="upload picture"
                                                        onClick={() => this.handelEdit("Niveau")}
                                                        component="span">
                                                <EditIcon/>

                                            </IconButton>

                                        </>
                                    }
                                </div>
                                <div className={"CourseSystem"}>
                                    {updateZone === "Matiere" ?

                                        <>
                                            <TextField
                                                id="outlined-select-currency-native"
                                                select
                                                fullWidth
                                                className={`InputDate`}
                                                name="course_matiere"
                                                label="Matière"
                                                // value={values.cours_systeme}
                                                onChange={this.handleChangeMatiere}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                            >

                                                {get_matieres && get_matieres.map((matiere, key) => (
                                                    <option key={matiere.id} value={matiere}
                                                            selected={Editabel ? matiere.id === Editabel.course_matiere.id : null}>
                                                        {matiere.matiere_name}
                                                    </option>
                                                ))}
                                            </TextField>
                                            <IconButton color="primary" onClick={this.handleCancel}
                                                        className={"courseButtonCancel"} aria-label="upload picture"
                                                        component="span">
                                                <CancelIcon/>

                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                onClick={() => this.handleSave(Editabel.course_matiere)}
                                                disabled={saveIntitule}
                                                className={"courseButtonSave"}
                                                aria-label="upload picture"
                                                component="span"
                                            >
                                                {this.state.saveIntitule ?
                                                    <CircularProgress color="secondary" size={18}/>
                                                    :
                                                    <SaveIcon/>
                                                }
                                            </IconButton>
                                        </>
                                        :
                                        <>

                                            <TextField
                                                id="outlined-select-currency-native"

                                                fullWidth
                                                className={`InputDate`}
                                                name="course_level"
                                                label="Matière"
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                                disabled
                                                InputLabelProps={{shrink: true}}
                                                value={Editabel ? Editabel.course_matiere.matiere_name : null}
                                            />
                                            <IconButton color="primary" className={"courseButtonEdit"}
                                                        aria-label="upload picture"
                                                        onClick={() => this.handelEdit("Matiere")}
                                                        component="span">
                                                <EditIcon/>

                                            </IconButton>

                                        </>
                                    }

                                </div>
                                <div className={"CourseSystem"}>
                                    {updateZone === "Module" ?

                                        <>
                                            <TextField
                                                id="outlined-select-currency-native"
                                                select
                                                fullWidth
                                                className={`InputDate`}
                                                name="course_module"
                                                label="Module"
                                                // value={values.cours_systeme}
                                                onChange={this.handleChangeModule}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                            >

                                                {Editabel && get_matieres && get_matieres.filter(matiere => matiere.id === Editabel.course_matiere.id).length > 0 && get_matieres.filter(matiere => matiere.id === Editabel.course_matiere.id)[0].matiere_modules.map((option, key) => (
                                                    <option key={key} value={option}
                                                            selected={option === Editabel.course_module}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </TextField>
                                            <IconButton color="primary" onClick={this.handleCancel}
                                                        className={"courseButtonCancel"} aria-label="upload picture"
                                                        component="span">
                                                <CancelIcon/>

                                            </IconButton><IconButton color="primary"
                                                                     onClick={() => this.handleSave({course_module: Editabel.course_module})}
                                                                     disabled={saveIntitule}
                                                                     className={"courseButtonSave"}
                                                                     aria-label="upload picture" component="span">
                                            {this.state.saveIntitule ? <CircularProgress color="secondary" size={18}/> :
                                                <SaveIcon/>}

                                        </IconButton>
                                        </>
                                        :
                                        <>

                                            <TextField
                                                id="outlined-select-currency-native"

                                                fullWidth
                                                className={`InputDate`}
                                                name="course_level"
                                                label="Module"
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                                disabled
                                                InputLabelProps={{shrink: true}}
                                                value={Editabel ? Editabel.course_module : null}
                                            />
                                            <IconButton color="primary" className={"courseButtonEdit"}
                                                        aria-label="upload picture"
                                                        onClick={() => this.handelEdit("Module")}
                                                        component="span">
                                                <EditIcon/>

                                            </IconButton>

                                        </>
                                    }
                                </div>
                                <div className={"CourseSystem"}>

                                    {updateZone === "Classe" ?

                                        <>
                                            <TextField
                                                id="outlined-select-currency-native"
                                                select
                                                fullWidth
                                                className={`InputDate`}
                                                name="course_classe"
                                                label="Module"
                                                onChange={this.handleChangeClasse}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                            >
                                                {system_education && Editabel ? system_education.filter((system) => system.id === Editabel.course_systeme).length > 0 ?
                                                    system_education.filter((system) => system.id === Editabel.course_systeme)[0].systeme_levels.filter(level => level.level_name === Editabel.course_level)[0]
                                                        .level_years.map((year, key) => (
                                                        <option key={key} value={year}
                                                                selected={Editabel ? year === Editabel.course_classe : null}>
                                                            {year}
                                                        </option>

                                                    ))
                                                    : null : null}

                                            </TextField>

                                            <IconButton color="primary" onClick={this.handleCancel}
                                                        className={"courseButtonCancel"} aria-label="upload picture"
                                                        component="span">
                                                <CancelIcon/>

                                            </IconButton><IconButton color="primary"
                                                                     onClick={() => this.handleSave({course_classe: Editabel.course_classe})}
                                                                     disabled={saveIntitule}
                                                                     className={"courseButtonSave"}
                                                                     aria-label="upload picture" component="span">
                                            {this.state.saveIntitule ? <CircularProgress color="secondary" size={18}/> :
                                                <SaveIcon/>}

                                        </IconButton>
                                        </>
                                        :
                                        <>

                                            <TextField
                                                id="outlined-select-currency-native"

                                                fullWidth
                                                className={`InputDate`}
                                                name="course_level"
                                                label="Classe"
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                                InputLabelProps={{shrink: true}}
                                                disabled
                                                value={Editabel ? Editabel.course_classe : null}
                                            />
                                            <IconButton color="primary" className={"courseButtonEdit"}
                                                        aria-label="upload picture"
                                                        onClick={() => this.handelEdit("Classe")}
                                                        component="span">
                                                <EditIcon/>

                                            </IconButton>

                                        </>
                                    }

                                </div>
                                <div className={"CourseSystem"}>

                                    {updateZone === "Langue" ?
                                        <>
                                            <TextField

                                                id="outlined-select-currency-native"
                                                select
                                                fullWidth
                                                className={`InputDate`}
                                                name="course_langue"
                                                label="Langue"
                                                // value={values.cours_systeme}
                                                onChange={this.handleChangeLangue}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                            >
                                                {langues.map((option) => (
                                                    <option key={option.value} value={option.value}
                                                            selected={Editabel ? Editabel.course_language === option.value : false}

                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}

                                            </TextField>
                                            <IconButton color="primary" onClick={this.handleCancel}
                                                        className={"courseButtonCancel"} aria-label="upload picture"
                                                        component="span">
                                                <CancelIcon/>

                                            </IconButton><IconButton color="primary"
                                                                     onClick={() => this.handleSave({course_language: Editabel.course_language})}
                                                                     disabled={saveIntitule}
                                                                     className={"courseButtonSave"}
                                                                     aria-label="upload picture" component="span">
                                            {this.state.saveIntitule ? <CircularProgress color="secondary" size={18}/> :
                                                <SaveIcon/>}

                                        </IconButton>

                                        </>
                                        :
                                        <>

                                            <TextField
                                                // className="test"
                                                className="InputIntitule"
                                                fullWidth
                                                label="Langue"
                                                variant="outlined"
                                                size="small"
                                                value={course ? course.course_language : null}
                                                InputLabelProps={{shrink: true}}
                                                disabled
                                            />
                                            <IconButton color="primary" onClick={() => this.handelEdit("Langue")}
                                                        className={"courseButtonEdit"} aria-label="upload picture"
                                                        component="span">
                                                <EditIcon/>
                                            </IconButton>


                                        </>
                                    }
                                </div>

                            </div>
                            <div className={"CourseDetails2"}>
                                <div className={"courseDescriptionEdit"}>
                                    {updateZone === "Description" ?
                                        <>
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Description"
                                                multiline
                                                rows={7}
                                                fullWidth
                                                defaultValue={Editabel ? Editabel.course_description : null}
                                                onChange={this.handleChangeDescription}

                                                variant="outlined"
                                                InputLabelProps={{shrink: true}}

                                            />
                                            <IconButton color="primary" onClick={this.handleCancel}
                                                        className={"courseButtonCancel"} aria-label="upload picture"
                                                        component="span">
                                                <CancelIcon/>

                                            </IconButton><IconButton color="primary"
                                                                     onClick={() => this.handleSave({course_description: Editabel.course_description})}
                                                                     disabled={saveIntitule}
                                                                     className={"courseButtonSave"}
                                                                     aria-label="upload picture" component="span">
                                            {this.state.saveIntitule ? <CircularProgress color="secondary" size={18}/> :
                                                <SaveIcon/>}

                                        </IconButton>
                                        </>
                                        :
                                        <>
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Description"
                                                multiline
                                                rows={7}
                                                fullWidth
                                                defaultValue={Editabel ? Editabel.course_description : null}
                                                disabled
                                                variant="outlined"
                                                InputLabelProps={{shrink: true}}

                                            />

                                            <IconButton color="primary" onClick={() => this.handelEdit("Description")}
                                                        className={"courseButtonEdit"} aria-label="upload picture"
                                                        component="span">
                                                <EditIcon/>
                                            </IconButton>
                                        </>
                                    }

                                </div>
                                <div className="courseObjectifs">
                                    {updateZone === "Objectifs" ?
                                        <>
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Objectifs"
                                                multiline
                                                rows={7}
                                                fullWidth
                                                defaultValue={Editabel ? Editabel.course_objectifs : null}
                                                onChange={this.handleChangeObjectifs}

                                                variant="outlined"
                                                InputLabelProps={{shrink: true}}

                                            />
                                            <IconButton color="primary" onClick={this.handleCancel}
                                                        className={"courseButtonCancel"} aria-label="upload picture"
                                                        component="span">
                                                <CancelIcon/>

                                            </IconButton><IconButton color="primary"
                                                                     onClick={() => this.handleSave({course_objectifs: Editabel.course_objectifs})}
                                                                     disabled={saveIntitule}
                                                                     className={"courseButtonSave"}
                                                                     aria-label="upload picture" component="span">
                                            {this.state.saveIntitule ? <CircularProgress color="secondary" size={18}/> :
                                                <SaveIcon/>}

                                        </IconButton>
                                        </>
                                        :
                                        <>
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Objectifs"
                                                multiline
                                                rows={7}
                                                fullWidth
                                                defaultValue={Editabel ? Editabel.course_objectifs : null}
                                                disabled
                                                variant="outlined"
                                                InputLabelProps={{shrink: true}}

                                            />

                                            <IconButton color="primary" onClick={() => this.handelEdit("Objectifs")}
                                                        className={"courseButtonEdit"} aria-label="upload picture"
                                                        component="span">
                                                <EditIcon/>
                                            </IconButton>
                                        </>
                                    }

                                </div>
                                <div className="coursePrerequis">
                                    {updateZone === "Prerequis" ?
                                        <>
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Prérequis"
                                                multiline
                                                rows={7}
                                                fullWidth
                                                defaultValue={Editabel ? Editabel.course_prerequis : null}
                                                onChange={this.handleChangePrerequis}

                                                variant="outlined"
                                                InputLabelProps={{shrink: true}}

                                            />
                                            <IconButton color="primary" onClick={this.handleCancel}
                                                        className={"courseButtonCancel"} aria-label="upload picture"
                                                        component="span">
                                                <CancelIcon/>

                                            </IconButton><IconButton color="primary"
                                                                     onClick={() => this.handleSave({course_prerequis: Editabel.course_prerequis})}
                                                                     disabled={saveIntitule}
                                                                     className={"courseButtonSave"}
                                                                     aria-label="upload picture" component="span">
                                            {this.state.saveIntitule ? <CircularProgress color="secondary" size={18}/> :
                                                <SaveIcon/>}

                                        </IconButton>
                                        </>
                                        :
                                        <>
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="prerequis"
                                                multiline
                                                rows={7}
                                                fullWidth
                                                defaultValue={Editabel ? Editabel.course_prerequis : null}
                                                disabled
                                                variant="outlined"
                                                InputLabelProps={{shrink: true}}

                                            />

                                            <IconButton color="primary" onClick={() => this.handelEdit("Prerequis")}
                                                        className={"courseButtonEdit"} aria-label="upload picture"
                                                        component="span">
                                                <EditIcon/>
                                            </IconButton>
                                        </>
                                    }
                                </div>

                            </div>
                            <div className="partieCourseSection">
                                <div className={"partie"}>
                                    Parties de cours
                                </div>
                                {allParts.map(part => (
                                    <>
                                        {
                                            part.questions ?
                                                <Accordion className="AccordionStyle">
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon/>}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className={"AccordionSummaryStyle"}
                                                    >
                                                        <Typography>Quiz {part.order + 1} : {part.title}</Typography>
                                                        {/*<Button onClick={() => this.handleDeleteQuizTitle(part.id)}>Delete*/}
                                                        {/*    Quiz</Button>*/}
                                                         <IconButton className={"deleteButton"} onClick={() => this.handleDeleteQuizTitle(part.id)}>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="addQuiz">
                                                            <div className={"EditTitleAndOrder"}>
                                                                <div className="titeQuizedit">
                                                                    {updatePartTitle === part.order ?
                                                                        <>
                                                                            <TextField
                                                                                // className="test"
                                                                                className="TitleQuiz"
                                                                                label="Titre"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                value={this.state.partTitle}
                                                                                onChange={this.handleChangePartTitle}


                                                                            />

                                                                            <IconButton color="primary"
                                                                                        onClick={this.handleCancelPartTitle}
                                                                                        className={"courseButtonCancel"}
                                                                                        aria-label="upload picture"
                                                                                        component="span">
                                                                                <CancelIcon/>

                                                                            </IconButton><IconButton color="primary"
                                                                                                     onClick={() => this.handleSaveQuizTitle(part.id)}
                                                                            // disabled={saveIntitule}
                                                                                                     className={"courseButtonSave"}
                                                                                                     aria-label="upload picture"
                                                                                                     component="span">

                                                                            {this.state.savePart ?
                                                                                <CircularProgress color="secondary"
                                                                                                  size={18}/> :
                                                                                <SaveIcon/>}

                                                                        </IconButton>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <TextField
                                                                                className="TitleQuiz"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                label={"Titre"}
                                                                                value={part.title}
                                                                                // onChange={this.handleChangeIntitule}
                                                                                InputLabelProps={{shrink: true}}
                                                                                disabled
                                                                            />
                                                                            <IconButton color="primary"
                                                                                        onClick={() => this.handelEditPartTitle(part)}
                                                                                        className={"PartButtonEdit"}
                                                                                        aria-label="upload picture"
                                                                                        component="span">
                                                                                <EditIcon/>
                                                                            </IconButton>
                                                                        </>
                                                                    }
                                                                </div>
                                                                <div className="orderQuizedit">
                                                                    {updatePartOrder === part.order ?
                                                                        <>
                                                                            <TextField type="number"
                                                                                       id="outlined-basic"
                                                                                       onChange={this.handleChangePartOrder}
                                                                                       className="order"
                                                                                       label={"Order "}
                                                                                       variant="outlined"
                                                                                       size="small"
                                                                                       defaultValue={part.order + 1}
                                                                                       InputLabelProps={{shrink: true}}
                                                                                       InputProps={{
                                                                                           inputProps: {
                                                                                               min: 1,
                                                                                               max: this.state.allParts.length
                                                                                           }
                                                                                       }}

                                                                            />
                                                                            <IconButton color="primary"
                                                                                        onClick={this.handleCancelPartOrder}
                                                                                        className={"orderButtonCancel"}
                                                                                        aria-label="upload picture"
                                                                                        component="span">
                                                                                <CancelIcon/>
                                                                            </IconButton>
                                                                            <IconButton color="primary"
                                                                                        onClick={() => this.handleSaveQuizOrder(part.id)}
                                                                                        className={"orderButtonEdit"}
                                                                                        aria-label="upload picture"
                                                                                        component="span">

                                                                                {this.state.savePart ?
                                                                                    <CircularProgress color="secondary"
                                                                                                      size={18}/> :
                                                                                    <SaveIcon/>}

                                                                            </IconButton>

                                                                        </>
                                                                        :
                                                                        <>
                                                                            <TextField type="number"
                                                                                       id="outlined-basic"
                                                                                // onChange={(e) => handelChangeOrderQuize(e)}
                                                                                       className="order"
                                                                                       label={"Order "}
                                                                                       variant="outlined"
                                                                                       size="small"
                                                                                       value={part.order + 1}
                                                                                       disabled
                                                                            />
                                                                            <IconButton color="primary"
                                                                                        onClick={() => this.handelEditPartOrder(part)}
                                                                                        className={"orderButtonEdit"}
                                                                                        aria-label="upload picture"
                                                                                        component="span">
                                                                                <EditIcon/>
                                                                            </IconButton>
                                                                        </>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionDetails>

                                                    <div className={"questionQuiz"}>
                                                        {
                                                            this.state.editQuize === part.id ?
                                                                <>
                                                                    {this.Quize()}
                                                                    <div className={"saveCancelButton"}>
                                                                        <Button variant="outlined"
                                                                                color="primary"
                                                                                onClick={this.handleSaveQuizQuestion}>
                                                                            Enregistrer les modifications
                                                                        </Button>
                                                                        <Button
                                                                            onClick={() => {
                                                                                this.cancelQuizeQuestion(part)
                                                                            }}
                                                                            variant="outlined"
                                                                            color="primary"
                                                                        >
                                                                            annuler
                                                                        </Button>
                                                                    </div>


                                                                </>
                                                                :
                                                                <div>
                                                                    {this.displayQuiz(part)}
                                                                    <div className={"saveCancelButton"}>
                                                                        <Button
                                                                            onClick={() => this.editQuizeQuestion(part)}
                                                                            variant="outlined"
                                                                            color="primary"
                                                                        >
                                                                            Modifier les questions
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>

                                                </Accordion>
                                                :
                                                <Accordion className="AccordionStyle">
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon/>}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className={"AccordionSummaryStyle"}
                                                    >
                                                        <Typography>Partie {part.order + 1} : {part.title}</Typography>
                                                        <IconButton className={"deleteButton"} onClick={() => this.handleDeletePartTitle(part.id)}>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="addQuiz">
                                                            <div className={"EditTitleAndOrder"}>
                                                                <div className="titeQuizedit">
                                                                    {updatePartTitle === part.order ?
                                                                        <>
                                                                            <TextField
                                                                                // className="test"
                                                                                className="TitleQuiz"
                                                                                label="Titre"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                value={this.state.partTitle}
                                                                                onChange={this.handleChangePartTitle}


                                                                            />

                                                                            <IconButton color="primary"
                                                                                        onClick={this.handleCancelPartTitle}
                                                                                        className={"courseButtonCancel"}
                                                                                        aria-label="upload picture"
                                                                                        component="span">
                                                                                <CancelIcon/>

                                                                            </IconButton><IconButton color="primary"
                                                                                                     onClick={() => this.handleSavePartTitle(part.id)}
                                                                                                     className={"courseButtonSave"}
                                                                                                     aria-label="upload picture"
                                                                                                     component="span">
                                                                            {this.state.savePart ?
                                                                                <CircularProgress color="secondary"
                                                                                                  size={18}/> :
                                                                                <SaveIcon/>}

                                                                        </IconButton>

                                                                        </>
                                                                        :

                                                                        <>
                                                                            <TextField
                                                                                className="TitleQuiz"
                                                                                label="Intitulé"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                value={part.title}
                                                                                InputLabelProps={{shrink: true}}
                                                                                disabled
                                                                            />
                                                                            <IconButton color="primary"
                                                                                        onClick={() => this.handelEditPartTitle(part)}
                                                                                        className={"PartButtonEdit"}
                                                                                        aria-label="upload picture"
                                                                                        component="span">
                                                                                <EditIcon/>
                                                                            </IconButton>


                                                                        </>
                                                                    }
                                                                </div>
                                                                <div className="orderQuizedit">
                                                                    {updatePartOrder === part.order ?
                                                                        <>
                                                                            <TextField type="number"
                                                                                       id="outlined-basic"
                                                                                       onChange={this.handleChangePartOrder}
                                                                                       className="order"
                                                                                       label={"Order "}
                                                                                       variant="outlined"
                                                                                       size="small"
                                                                                       defaultValue={part.order + 1}
                                                                                       InputLabelProps={{shrink: true}}
                                                                                       InputProps={{
                                                                                           inputProps: {
                                                                                               min: 1,
                                                                                               max: this.state.allParts.length
                                                                                           }
                                                                                       }}

                                                                            />
                                                                            <IconButton color="primary"
                                                                                        onClick={this.handleCancelPartOrder}
                                                                                        className={"orderButtonCancel"}
                                                                                        aria-label="upload picture"
                                                                                        component="span">
                                                                                <CancelIcon/>
                                                                            </IconButton>
                                                                            <IconButton color="primary"
                                                                                        onClick={() => this.handleSavePartOrder(part.id)}
                                                                                        className={"orderButtonEdit"}
                                                                                        aria-label="upload picture"
                                                                                        component="span">

                                                                                {this.state.savePart ?
                                                                                    <CircularProgress color="secondary"
                                                                                                      size={18}/> :
                                                                                    <SaveIcon/>}

                                                                            </IconButton>

                                                                        </>
                                                                        :
                                                                        <>
                                                                            <TextField type="number"
                                                                                       id="outlined-basic"
                                                                                       className="order"
                                                                                       label={"Order "}
                                                                                       variant="outlined"
                                                                                       size="small"
                                                                                       value={part.order + 1}
                                                                                       disabled
                                                                            />
                                                                            <IconButton color="primary"
                                                                                        onClick={() => this.handelEditPartOrder(part)}
                                                                                        className={"orderButtonEdit"}
                                                                                        aria-label="upload picture"
                                                                                        component="span">
                                                                                <EditIcon/>
                                                                            </IconButton>
                                                                        </>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionDetails>
                                                    <AccordionDetails>

                                                        <div className={"videoPart"}>
                                                            <video id={part.order}
                                                                   key={part.video} controls>
                                                                <source src={part.video} type="video/mp4"/>
                                                                <source src={part.video} type="video/ogg"/>
                                                                Your browser does not support HTML video.
                                                            </video>
                                                            <Button
                                                                variant="outlined"
                                                                component="label"
                                                                color="primary"
                                                                style={{height: "40px"}}
                                                            ><EditIcon/>
                                                                Modifier la vidéo
                                                                <input
                                                                    name="part_video"
                                                                    accept="video/*"
                                                                    type="file"
                                                                    onChange={(event) => this.handleChangeVideo(event, part.id)}
                                                                    hidden
                                                                />
                                                            </Button>

                                                        </div>
                                                    </AccordionDetails>
                                                    <AccordionDetails>
                                                        <div className={"TextPart"}>
                                                            {this.state.updatePart === part.order ?
                                                                <>
                                                                    <div className={"editor"}>
                                                                        <Editor
                                                                            className="editor-bloc"
                                                                            editorState={this.state.editorState}
                                                                            toolbarClassName="toolbarClassName"
                                                                            wrapperClassName="wrapperClassName"
                                                                            editorClassName="editorClassName"
                                                                            onEditorStateChange={this.onEditorStateChange}
                                                                        />
                                                                    </div>
                                                                    <div className={"saveCancelButton"}>
                                                                        <Button
                                                                            onClick={() => this.handleSavePartTextContent(part.id)}
                                                                            variant="outlined"
                                                                            color="primary">
                                                                            Enregistrer
                                                                        </Button>
                                                                        <Button
                                                                            onClick={this.handleEditCancel}
                                                                            variant="outlined"
                                                                            color="primary">
                                                                            Annuler
                                                                        </Button>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{__html: part.partContent}}></div>
                                                                    <Button
                                                                        onClick={() => this.handleEditPart(part)}
                                                                        variant="outlined"
                                                                        color="primary">
                                                                        Modifier le texte
                                                                    </Button>
                                                                </>
                                                            }
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                        }
                                    </>
                                ))}

                                <div className={"saveCancelButton"}>
                                    <Button
                                        onClick={this.handelAddingQuiz}
                                        variant="outlined"
                                        color="primary">
                                        Ajouter un nouveau quiz
                                    </Button>
                                    <Button
                                        onClick={this.handeladdingPart}
                                        variant="outlined"
                                        color="primary">
                                        Ajouter une nouvelle partie
                                    </Button>
                                    <AugmentedRealityComponent/>
                                </div>

                            </div>
                            {this.state.addingPart ?
                                <div className={"addQuiz"}>


                                    <h1>Ajouter une nouvelle partie</h1>
                                    <TextField id="outlined-basic"
                                               onChange={(e) => this.handelChangeTitlePart(e)}
                                               className="TitleQuiz"
                                               label={"Titre "}
                                               variant="outlined"
                                               size="small"/>
                                    <TextField id="outlined-basic"
                                               onChange={(e) => this.handelChangeOrderPart(e)}
                                               InputProps={{inputProps: {min: 1, max: this.state.allParts.length + 1}}}
                                               defaultValue={this.state.allParts.length + 1}
                                               type="number"
                                               className="order"
                                               label={"Order"}
                                               variant="outlined"
                                               size="small"/>

                                    <div className={"AddcourseContentPart"}>
                                        <div className={"part"}>
                                            <Button
                                                className={"button"}
                                                variant="outlined"
                                                color="primary"
                                                component="label">
                                                {
                                                    this.state.partAdd.video ?
                                                        this.state.partAdd.video.name :
                                                        "Charger une vidéo"
                                                }
                                                <input
                                                    name="part_video"
                                                    accept="video/*"
                                                    type="file"
                                                    onChange={this.handelChangeVideo}
                                                    hidden
                                                />
                                            </Button>
                                            <p>la taille de video doit etre inferieur à 100 Mo</p>
                                        </div>

                                        <div className={"part"}>
                                            <Button
                                                className={"button"}
                                                variant="outlined"
                                                color="primary"
                                                component="label">
                                                {
                                                    this.state.partAdd.support ?
                                                        this.state.partAdd.support.name :
                                                        "Charger un fichier PDF"
                                                }
                                                <input
                                                    name="part_pdf"
                                                    accept="application/pdf"
                                                    type="file"
                                                    onChange={this.handelChangePdf}
                                                    hidden
                                                />
                                            </Button>
                                        </div>

                                    </div>

                                    {/*<h1>Contenu Text </h1>*/}
                                    <div className="editorContainer">
                                        {this.TextEditor()}
                                    </div>

                                    <div className={"saveCancelButton"}>
                                        <Button
                                            onClick={this.addNewPart}
                                            variant="outlined"
                                            color="primary">
                                            Enregistrer
                                        </Button>
                                        <Button
                                            onClick={this.handeladdingPart}
                                            variant="outlined"
                                            color="primary">
                                            Annuler
                                        </Button>
                                    </div>

                                </div>
                                :
                                null
                            }
                            {
                                this.state.addingQuiz ?
                                    <>
                                        {this.AddQuize()}
                                        <div className={"saveCancelButton"}>
                                            <Button
                                                onClick={this.handleSubmitQuiz}
                                                variant="outlined"
                                                color="primary">
                                                Enregistrer
                                            </Button>
                                            <Button
                                                onClick={this.handelCancelAddingQuiz}
                                                variant="outlined"
                                                color="primary">
                                                Annuler
                                            </Button>
                                        </div>
                                    </>
                                    :
                                    null

                            }
                            {/*<Button style={{backgroundColor: "red", width: "300px", height: "30px"}}*/}
                            {/*        onClick={this.handelDeleteCourse}*/}
                            {/*>Delete course</Button>*/}
                        </>
                        :
                        <div className={"LoadingCourseContent"}>
                            <CircularProgress color="secondary" size={100}/>
                        </div>
                }
            </div>
        )

    }

}

const
mapStateToProps = state => ( {
    session: state.session
,
    data: state.data
,
}

);
export default connect(mapStateToProps)

(
withRouter(CourseContentComponent)
)
;