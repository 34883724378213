import {Container, Row} from "react-bootstrap";
import React, {useEffect, useState,} from 'react';
import {accountActivationAction} from "../../Redux/ActionCreators";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {ContactUs} from "./Layouts/Shared/ContactUs/ContactUs";

export default function AccountActivationComponent(props) {
    const [success, setSuccess] = useState(null);
    const [globalMessage, setGlobalMessage] = useState("");
    const [seconds, setSeconds] = useState(10);
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        let params = props.match.params
        if ((params.type_account === "student" || params.type_account === "teacher") && params.activation_token) {
            const data = {
                "token": params.activation_token,
                "type": params.type_account,
            };
            dispatch(accountActivationAction(data))
                .then(data => {
                    setSuccess(data.payload.Success);
                    setGlobalMessage(data.payload.message);
                })
                .catch(err => {
                    console.log("err", err);
                    setGlobalMessage("Erreur");
                });
        }
        ;
    },[]);
    useEffect(() => {
        if (success) {
            if (seconds > 0) {
                setTimeout(() => setSeconds(seconds - 1), 1000);
            } else {
                history.push("/connexion")
            }
        }
    });

    return (
        <div className="App">
            <div>
                <Row className="account-activation-page">
                    <div className="modal-activation-page">
                        <Container className="modal-activation-title">
                            {success ?
                                <>
                                    <p className="title">
                                        Félicitation votre compte est activé
                                    </p>
                                    <p className="title">vous serez redirigé vers la page de connexion dans : </p>
                                    <div className={"counter"}>{seconds}</div>
                                </>
                                :
                                null
                            }
                        </Container>
                    </div>
                </Row>
            </div>
            <ContactUs position={"/activation"}/>
        </div>
    );
}