import React from 'react'
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";

export function Article(props) {
    //extraire la premier phrase

    const short = (str, n) => {
        return str.substring(0, n);
    }

    return (
        <div className="article">
            <img className="article_image" src={props.article ? props.article.article_pic: null}  alt={props.article ? props.article.title: null}  rx="8" ry="8" x="0" y="0"/>
            <div className="back_article" ></div>
            <div className="title_article">
                {props.article ? props.article.title: null}
            </div>
            <div className="article_content">
                <span>{props.article ?
                    props.article.introduction === "" ?
                        props.article.parts_article[0] ?
                            short(props.article.parts_article[0],160) : null
                        :
                        short(props.article.introduction,160) + " ....." : null
                } </span>
            </div>
                {/*<div className="article_date">*/}
                {/*    <span>le {props.article ? props.article.pub_date: null} par {props.article ? props.article.reporter: null}</span>*/}
                {/*</div>*/}
            <Link to={props.article ? "/Actuality/"+props.article.id: null}>
            <Button type="submit" className="Lire_larticle" variant="contained">Lire l'article</Button>
            </Link>

    </div>
    )
}
