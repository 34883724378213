// Split Word
import math from "../../Assets/Images/image_math.jpg";
import physique from "../../Assets/Images/image_physique.jpg";
import maths_trigo from "../../Assets/Images/maths_equations.png";

export function getImage (course){
        switch(course.course_matiere.matiere_name){
            case "Mathématique":
                return math;
                break;
            case "Physique":
                return physique;
            default :
                return course.course_pic ? course.course_pic : maths_trigo;
                break;
        }
    }