import StudentDashboard from '../Common/Layouts/DashboardComponent';
import CoursesNavbar from '../Common/Layouts/CoursesNavbar';

import {
    HomeIcon,
    CoursesIcon,
    CalenderIcon,
    //ResourceIcon,
    //EvaluationIcon,
    FavoriteIcon,
    //BlogIcon,
    InfoIcon,
} from "../Common/Layouts/Icones";
import CoursesList from "../Common/Layouts/Shared/Chapitres/CoursesList";
import ProfsList from '../Common/Layouts/LiveCourses/ProfsList';
import StudentPartialCalendar from "./StudentCalendar/StudentPartialCalendar";
import CoursDetails from '../Common/Layouts/Shared/CourStore/CoursDetails/CoursDetails';
import StudentCalendarComponent from "./StudentCalendar/StudentCalendarComponent";
import Calculator from '../Common/Layouts/Calculator';
//import Blog from '../Common/Layouts/Blog';
import Helper from '../Common/Layouts/Help';
import FavoriteTabs from '../Common/Layouts/MyFavorite';
import Teacherprofil from "../Teacher/TeacherProfil/TeacherProfil";
import Cart from "./StudentCart/Cart";
import StudentCourseContentComponent from "./StudentCours/StudentCourseContentComponent";
import MessagingComponent from "../Common/Messaging/MessagingComponent";
import SettingsComponent from "../Common/Layouts/Shared/SettingsComponent";

const StudentRoutes = [
    {
        side: true,
        path: "/dashboard",
        name: "Accueil",
        icon: HomeIcon,
        component: StudentDashboard,
        layout: "/student"
    },
    {
        side: true,
        path: "/courses",
        name: "Cours",
        icon: CoursesIcon,
        component: CoursesNavbar,
        layout: "/student"
    },
    {
        side: true,
        path: "/calendrier",
        name: "Calendriers",
        icon: CalenderIcon,
        component: StudentCalendarComponent,
        layout: "/student"
    },
    // {
    //     side: true,
    //     path: "/resource",
    //     name: "Ressources",
    //     icon: ResourceIcon,
    //     component: CoursesIcon,
    //     layout: "/student"
    // },
    // {
    //     side: true,
    //     path: "/evalution",
    //     name: "Evaluations",
    //     icon: EvaluationIcon,
    //     component: CoursesIcon,
    //     layout: "/student"
    // },
    {
        side: false,
        path: "/cart",
        name: "Cart",
        icon: "",
        component: Cart,
        layout: "/student",
    },
    {
        side: true,
        path: "/favorite",
        name: "Favoris",
        icon: FavoriteIcon,
        component: FavoriteTabs,
        layout: "/student"
    },
    // {
    //     side: true,
    //     path: "/blog",
    //     name: "Blog",
    //     icon: BlogIcon,
    //     component: Blog,
    //     layout: "/student"
    // },
    {
        side: true,
        path: "/help",
        name: "Help",
        icon: InfoIcon,
        component: Helper,
        layout: "/student"
    },
    {
        side: false,
        path: "/profile",
        name: "profile",
        icon: HomeIcon,
        component: Teacherprofil,
        layout: "/student"
    },
    {
        side: false,
        path: "/setting",
        name: "setting",
        icon: HomeIcon,
        component: SettingsComponent,
        layout: "/student"
    },
    {
        side: false,
        path: "/:id_student/courses/chapter/:id",
        name: "chapters",
        icon: "",
        component: CoursesList,
        layout: "/student",
    },
    {
        side: false,
        path: "/profs_list",
        name: "profs_List",
        icon: "",
        component: ProfsList,
        layout: "/student",
    },
    {
        side: false,
        path: "/calculateur_de_prix",
        name: "Calculateur_de_prix",
        icon: "",
        component: Calculator,
        layout: "/student",
    },
    {
        side: false,
        path: "/chapitre_details",
        name: "ChapitreDetails",
        icon: "",
        component: CoursDetails,
        layout: "/student",
    },
    {
        side: false,
        path: "/:user_id/reserve/calendar",
        name: "partial_calendar",
        icon: "",
        component: StudentPartialCalendar,
        layout: "/student"
    },
    {
        side: false,
        path: "/course/content/:id_course",
        name: "StudentCourseContent",
        component: StudentCourseContentComponent,
        layout: "/student"
    },
        {
        side: false,
        path: "/messages/:id_user",
        name: "Message",
        component: MessagingComponent,
        layout: "/student"
    },
    {
        side: false,
        path: "/messages",
        name: "Message",
        component: MessagingComponent,
        layout: "/student"
    },
];

export default StudentRoutes;
