import React, {Component} from 'react';
import {Button, Container, Grid} from '@material-ui/core';
import EditProfil from "./EditProfil";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import {Breakpoint} from "react-socks";
import {EventGoogleAnalytics} from "../../../Components/GoogleAnalytics";

class Profil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditOpen: false,
            user : this.props.session.user
        }
    }

    handleOpenProfile = () => {
        if (this.state.user.is_student) {
            // Event Google Analytics
            EventGoogleAnalytics("Edit_profile_button","Button","Student want to edit  profile ")
            this.props.history.push("/student/profile");

        }
        if (this.state.user.is_teacher) {
            // Event Google Analytics
            EventGoogleAnalytics("Edit_profile_button","Button","Teacher want to edit profile ")
            this.props.history.push("/teacher/profile");
        }
    };

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.session !== prevProps.session) {
            this.setState({
                user: this.props.session.user
            });
        }
    }

    handleEditOpen = () => {
        // Event Google Analytics
        EventGoogleAnalytics("Edit_mini_profile_button","Button","Edit mini profile ")
        this.setState({
            isEditOpen : !this.state.isEditOpen
        })
    };

    render() {
        const { isEditOpen, user } = this.state;
        return (
            <div className="profile">
                <Breakpoint medium up>
                    <div className="blue_rectangle">
                    </div>
                    <Avatar alt="" className="avatar" src={user.profile_pic === "" ? "" : user.profile_pic}> {user.profile_pic === "" ? user.username : null}</Avatar>
                    <div className="name">
                        <h1>{user.first_name+" "+user.last_name}</h1>
                    </div>
                    <div className="username">
                        <h1>@{user.username}</h1>
                    </div>
                    <div className="user-description">
                        {user.description !== undefined ? user.description.substr(0, 25):""}
                    </div>
                    <Container className="earned_pts">
                        <div>
                            <span>112 Pts</span>
                        </div>
                    </Container>
                    <Button type="submit" className="energy_btn" variant="contained" onClick={this.handleEditOpen}>Descriptif</Button>
                    <Button type="submit" className="edit_btn" variant="contained" onClick={this.handleOpenProfile}>Modifier le profil</Button>
                </Breakpoint>

                <Breakpoint small down>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                            <Avatar
                                alt=""
                                className="avatar"
                                src={user.profile_pic === "" ? "" : user.profile_pic}
                            >
                                {user.profile_pic === "" ? user.username : null}
                            </Avatar>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <div
                                className="name"
                            >
                                <h1>{user.first_name+" "+user.last_name}</h1>
                            </div>
                            <div
                                className="username"
                            >
                                <h1>@{user.username}</h1>
                            </div>
                                <Button
                                    type="submit"
                                    className="energy_btn"
                                    variant="contained"
                                    onClick={this.handleEditOpen}
                                >Descriptif</Button>
                                <Button type="submit" className="edit_btn" variant="contained" onClick={this.handleOpenProfile}>Modifier le profil</Button>
                        </Grid>
                    </Grid>
                </Breakpoint>
                {isEditOpen &&
                    <EditProfil
                        open={isEditOpen}
                        handleEditOpen={this.handleEditOpen}
                    />
                }
            </div>
        )
    }
}
const mapStateToProps = state => ({ session: state.session });
export default connect(mapStateToProps)(withRouter(Profil));