import React, {useState} from "react";
import {Button} from "@material-ui/core";
import MyVerticallyCenteredModal from "../../../LiveCourses/ProfsList/ProfModal/ProfModal";
import {Link, useHistory} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import user_inconnu from "../../../../../../Assets/Images/user_inconnu.png";
import DeleteForeverSharpIcon from "@material-ui/icons/DeleteForeverSharp";
import IconButton from "@material-ui/core/IconButton";
import Modal from "react-bootstrap/Modal";


const FavoriteTeacherListItem = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const [show, setShow] = useState(false);
    const history = useHistory();

    const handleClose = () => {
        setShow(false)
    }

    const handleDeleteFromFavorite = (idFavorite) => {
        props.clickToDeleteFromFavorite(idFavorite)
        handleClose()
    }

    const showModal = () => {
        setOpenModal(true);
    }
    const hideModal = () => {
        setOpenModal(false);
    }

    const handleShow = () => {
        setShow(true)
    }

    const handleChangeRoute = (user_id) => {
        let path = `/student/${user_id}/reserve/calendar`;
        history.push(path)
    }

    return (
        <>
            <div className="Profs_details">
                <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                        <div className="Prof_pic">
                            <img className="Profs_picture_insert"
                                 src={props.teacher.FavoriteTeacher_favourite.profile_pic === "" ? user_inconnu : props.teacher.FavoriteTeacher_favourite.profile_pic}
                                 alt=""/>
                        </div>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <div id="Nom_profs">
                            <span>{props.teacher.FavoriteTeacher_favourite.username}</span>
                            <Button
                                variant="contained"
                                className="choisir_un_creneau"
                                onClick={() => handleChangeRoute(props.teacher.FavoriteTeacher_favourite.id)}
                            >
                                Choisir un créneau
                            </Button>
                        </div>
                        <div id="Matieres_profs">
                            <span>{props.teacher.FavoriteTeacher_favourite.study_field + " " + props.teacher.FavoriteTeacher_favourite.level}</span>
                        </div>
                        <div className="Prix_profs">
                            <strike>300 DH</strike>
                            <span> {props.prix}</span>
                        </div>
                        <div id="Presentation_profs">
                            <span>
                                Présentation: {props.teacher.FavoriteTeacher_favourite.description.length > 60 ? props.teacher.FavoriteTeacher_favourite.description.slice(0, 60) + "..." : props.teacher.FavoriteTeacher_favourite.description}
                                <Link onClick={() => showModal()}> En savoir plus</Link>
                            </span>
                            <IconButton aria-label="share" color="secondary"
                                        onClick={() => handleShow()}
                            >
                                <DeleteForeverSharpIcon/>
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>
                <MyVerticallyCenteredModal
                    show={openModal}
                    onHide={() => hideModal()}
                    tittle={props.teacher.FavoriteTeacher_favourite.username}
                    body={props.teacher.FavoriteTeacher_favourite.description}
                    teacher_id={props.teacher.FavoriteTeacher_favourite.id}
                    handleChangeRoute={handleChangeRoute}
                />
            </div>
            <Modal show={show} onHide={() => handleClose()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmer la suppression</Modal.Title>
                </Modal.Header>
                <Modal.Body>Vous voulez continuer la suppression!</Modal.Body>
                <Modal.Footer className="style-model_footer">
                    <Button variant="outline-danger"
                            onClick={() => handleDeleteFromFavorite(props.teacher.id)}>
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default FavoriteTeacherListItem;