import React, {Component} from 'react';
import LineGridList from './Shared/LastCourses/LineGridList';
import {Video} from './Shared/LastCourses/Video';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getTeacherCoursesAction} from "../../../Redux/ActionCreators";
import {Button} from "@material-ui/core";
import AddCoursModal from "../../Teacher/TeacherCours/Layout/CourseModal";
import Notification from "../AlertComponent";
import {EventGoogleAnalytics} from "../Components/GoogleAnalytics";
import Coursplussuivis from "./Shared/LastCourses/CoursPlusSuivis";

class LastCourses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notify: {message: '', type: ''},
            notyIsOpen: false,
            isModalOpen: false,
        };
        this.user = this.props.session.user;
    };

    componentDidMount() {
        let data = {
            teacher_id: this.user.id
        };
        return this.props
            .dispatch(getTeacherCoursesAction(data))
            .then(data => {
                // console.log("data from API", data.payload.teacher_course)
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    globalMessage: err.message
                });
            });
    }

       handleOpenModal = (msg) => {
        // Event Google Analytics
        EventGoogleAnalytics("Add_course_button","Button","Add course")
        this.setState({
            isModalOpen: !this.state.isModalOpen
        })
        if (msg === "success") {
            this.setNotify('Course Added Successfully', 'success')
        }
    }
        // set notification
    setNotify = (message, type) => {
        this.setState({
            notify: {
                message: message,
                type: type
            },
            notyIsOpen: !this.state.notyIsOpen
        })
    }
    render() {
        const {isModalOpen,notyIsOpen,notify } = this.state
        return (
            <div className="recent-courses">
                <div className="div-create-course">
                    {this.user.is_teacher && <Button onClick={() => this.handleOpenModal()} className="create-course" variant="contained">
                        Ajouter un nouveau cours</Button>}
                </div>

                {/* My courses */}
                <div className="mes-cours-accueil-recent">
                    <LineGridList
                        {...this.props}
                        user={this.user}
                    />
                </div>

                <Video/>

                <Coursplussuivis/>

                {isModalOpen &&
                <AddCoursModal
                    open={isModalOpen}
                    handleOpenModal={(msg) => this.handleOpenModal(msg)}
                />
                }
                <Notification
                    notify={notify}
                    setNotify={this.setNotify}
                    isOpen={notyIsOpen}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({session: state.session});
export default connect(mapStateToProps)(withRouter(LastCourses));