import React from 'react'
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import {Courssuivi} from "./CoursSuivi";
import maths_equations from "../../../../../Assets/Images/maths_equations.png";
import histoire_bipo from "../../../../../Assets/Images/histoire_bipo.png";
import philo_lapaix from "../../../../../Assets/Images/philo_lapaix.png";
import maths_isometrie from "../../../../../Assets/Images/maths_isometriesTranslations.png";
import francais_tempete from "../../../../../Assets/Images/francais_tempete.png";
import {makeStyles} from "@material-ui/core/styles";
import {isWidthDown, withWidth} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        flexWrap: 'wrap',
        overflow: 'hidden',
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
}));

const cours_suggest = [
    // {
    //     title:"Histoire",
    //     vu:"158 vues",
    //     resume:"L'union sovietique décide d'attaquer le Cuba...",
    //     chapitre:"La bipolarisation",
    //     image :histoire_bipo
    // },
    {
        title:"Mathématiques",
        vu:"213 vues",
        resume:"En mathématiques, l'intégration est le fait de calculer...",
        chapitre:"Intégrales",
        image :maths_equations
    },
    // {
    //     title:"Philosophie",
    //     vu:"93 vues",
    //     resume:"La paix est un concept qui désigne un état...",
    //     chapitre:"La paix",
    //     image :philo_lapaix
    // },
    {
        title:"Mathématiques",
        vu:"234 vues",
        resume:"Une isométrie se définit par la conservation...",
        chapitre:"Isométries et Translations",
        image :maths_isometrie
    },
    {
        title:"Français",
        vu:"105 vues",
        resume:"Receuil thatral de Williams Shakespears, un grand...",
        chapitre:"La Tempête",
        image :francais_tempete
    },
    // {
    //     title:"Français",
    //     vu:"105 vues",
    //     resume:"Receuil thatral de Williams Shakespears, un grand...",
    //     chapitre:"La Tempête",
    //     image :francais_tempete
    // },
]

function Coursplussuivis(props) {
    const classes = useStyles();

    const getGridListCols = () => {
        // Should respecte the order
        if (isWidthDown('xs', props.width)) {
            return 1.3;
        }
        if (isWidthDown('sm', props.width)) {
            return 3;
        }
        if (isWidthDown('md', props.width)) {
            return 4;
        }
        if (isWidthDown('lg', props.width)) {
            return 5;
        }
        return 5;
    }

    return (
        <div className="Ms_cours_acc_bk">
            <div className="Cours_les_plus_suivis_bk">
                <span>Cours les plus suivis</span>
            </div>

            <div id="Boc_MAtiers_bk" className={classes.root + " Boc_MAtiers_bk"}>
                <GridList className={classes.gridList} cols={getGridListCols()} cellHeight={"auto"} >
                    {cours_suggest.map((course, key) => (
                        <GridListTile key={key}>
                            <Courssuivi
                                title={course.title}
                                vu= {course.vu}
                                resume= {course.resume}
                                chapitre={course.chapitre}
                                image = {course.image}
                            />
                        </GridListTile>
                    ))}
                </GridList>

            </div>
        </div>
    )
};

export default withWidth()(Coursplussuivis)