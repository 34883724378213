import React from 'react';
import MediaCard from '../Blog/Card';
import blog_image1 from "../../../../../Assets/Images/peaple.png"

const FavoriteArticle = () => {
    return (
        <div className="favorite-article-component">
            <div className="tittle">
                <span>
                    Vos articles les plus aimés
                </span>
            </div>
            <MediaCard image = {blog_image1}></MediaCard>
        </div>
    );
}

export default FavoriteArticle;
