import React, {Component} from 'react'
import MenuItem from "@material-ui/core/MenuItem";
import {EventGoogleAnalytics} from "../../Common/Components/GoogleAnalytics";
import {getAllStudents, getAllTeachersAction, logoutAction} from "../../../Redux/ActionCreators";
import {connect} from "react-redux";
import {Link, Redirect, withRouter} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
import {get_achat_detail} from "../../../Services/Api";

import AdminFormationNav from "./AdminFormationNav";


class AdminFormationDashboard extends Component{
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.session.user,
            teachers: this.props.student.teachers,
            isLoad: this.props.student.isLoad,
        }
    }

    componentDidMount() {
        const {user} = this.state;
        if(user !== null && user !== undefined ){
            return this.props
                .dispatch(getAllTeachersAction())
                .then(data => {
                    if(data.payload){
                        this.setState({
                            teachers:data.payload.results.sort((a,b)=> b.description.length>0 && b.profile_pic.length>0),
                            isLoad: this.props.student.isLoad
                        })
                    }
                })
                .catch(err => {
                    console.log("err",err);
                })
        }
    }








    renderTeachersList(teachers){
        return teachers.map((teacher,key) =>{
            const {id,username} = teacher
            return(
                <tr key={id}>
                    <td className="text-center">{username}</td>
                    <td className="text-center"><Link className="btn btn-secondary"
                              to={{
                                  pathname: "/formation/suivi",

                                  state:  {teacher_id:id}
                              }}
                    >Voir Suivi</Link></td>
                    <td className="text-center">
                        <Link  className="btn btn-primary"
                               to={{
                                   pathname: "/formation/classes",

                                   state:  {teacher_id:id}
                               }}
                        >
                            Gerer les classes
                        </Link>
                    </td>


                </tr>
            )
        })
    }

    render(){
        const {isLoad, teachers,user} = this.state;

        return(

            <div>
                {user !== undefined ?
                    <div>
                        <div>


                            <AdminFormationNav />
                        </div>
                        <div className="container mt-5">

                            {isLoad ?
                                <div >
                                    <table className="table">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th className="text-center" >Professeur</th>
                                                <th></th>
                                                <th></th>



                                            </tr>
                                        </thead>
                                        <tbody >
                                        {this.renderTeachersList(teachers)}
                                        </tbody>

                                    </table>


                                </div>

                                :
                                <CircularProgress color="secondary" className="progress-bar"/>
                            }


                        </div>
                    </div>
                    :
                    <Redirect key="to_home" from="*" to="/connexion" />
                }


            </div>
        )
    }
}



const mapStateToProps = state => ({ session: state.session,adminFormation : state.adminFormation,student: state.student});
export default connect(mapStateToProps)(withRouter(AdminFormationDashboard));