import React, {Component} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

class Loading extends Component {

    render() {
        return (
            <section className="loading">
            <CircularProgress size={40}
                              left={-20}
                              top={10}
                              status={'loading'}
                              style={{marginLeft: '50%'}}
                              disableShrink/>
            </section>
        );
    }
}
export default Loading;