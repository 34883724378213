import React from 'react'

export function Objectif(props) {
    

    return (
        <>
			<div className="cours-objectif-container">
				<div className="cours-objectif-tittle">
						<span>Objectif du cours</span>
					</div>
				<div className="cours-objectif">
					<span>Après ce cours tu seras capable de :<br/>1- Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>2- Sodales ut etiam sit amet nisl purus in. Amet justo donec enim diam vulputate. <br/>3- Lorem ipsum dolor sit amet, consectetur adipiscing .<br/>4- Sodales ut etiam sit amet nisl purus in. Amet justo donec enim diam vulputate. <br/>5- Porttitor leo Lorem ipsum dolor sit amet, consectetur adipiscing elit. </span>
				</div>
			</div> 
        </>
    )
}
