import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper";
import {CircularProgress, IconButton} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MenuItem from "@material-ui/core/MenuItem";
import SendIcon from "@material-ui/icons/Send";
import {
    getAllTeachersStoreAction,
    getArticles,
    getMessagesUser,
    searchUser,
    sendMessageUser
} from "../../../Redux/ActionCreators";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";


class MessagingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            searchResult: [],
            messages: [],
            contact: [],
            sendMessage: "",
            receiver: null,
            user: this.props.session.user,
            currentDiscussionUser: null,
            valid: true,
        };
    };

    componentDidMount() {
        window.scrollTo(0,document.body.scrollHeight);
        if (this.props.match.params.id_user) {
            this.setState({
                receiver: this.props.match.params.id_user,
            });
        }
        return this.props
            .dispatch(getMessagesUser(this.props.session.user.id))
            .then(data => {
                if (data.payload) {
                    this.setState({
                        messages: data.payload.messages,
                        // contact : data.payload.messages,
                    });
                    const MessageSend = data.payload.messages.filter(m => (m.sender === this.props.session.user.id));
                    const MessageReceiver = data.payload.messages.filter(m => (m.receiver === this.props.session.user.id));
                    this.setState({
                        MessageSend: MessageSend,
                        MessageReceiver: MessageReceiver,
                    });
                    let contact = []
                    data.payload.messages.map(m => {
                        if (contact.filter(c => (
                            (c.sender === this.props.session.user.id && (c.receiver === m.receiver || c.receiver === m.sender))
                            || (m.receiver === this.props.session.user.id && (c.sender === m.receiver || c.sender === m.sender)))
                        ).length === 0) {
                            contact.push(m);
                        }
                    });
                    this.setState({
                        contact: contact,
                    })

                }
            })
            .catch(err => {
                console.log("err", err);
                this.setState({
                    errorGlobal: err.message
                });
            });
    };

    getContact=()=>{
        let contact = []
        this.props.messages.messages.map(m => {
            if (contact.filter(c => (
                (c.sender === this.props.session.user.id && (c.receiver === m.receiver || c.receiver === m.sender)
                    && c.receiver !== this.props.session.user.id
                )
                || (c.receiver === this.props.session.user.id && (c.sender === m.receiver || c.sender === m.sender)
                && c.sender !== this.props.session.user.id
                )
                || (m.sender === this.props.session.user.id && m.receiver === this.props.session.user.id )
                )
            ).length === 0) {
                contact.push(m);
            }
        });
        return contact;
    }
    handelchangeSearch = (e) => {
        this.setState({
            search: e.target.value,
        });
    }
    searchClick = () => {
        this.props
            .dispatch(searchUser({search: this.state.search}))
            .then(data => {
                if (data.payload) {
                    this.setState({
                        searchResult: data.payload.results,
                    })

                }
            })
            .catch(err => {
                console.log("err", err);
            });
    }
    handleKeypressSearch=(e)=>{
         if (e.keyCode === 13) {
             this.searchClick();
         }
    }
    sendMessage = () => {
        const data = {
            message: this.state.sendMessage,
            receiver: this.state.receiver,
            sender: this.props.session.user.id,
        }
        console.log(data);
        return this.props
            .dispatch(sendMessageUser(data))
            .then(data => {
                if (data.payload) {
                    this.setState({
                        sendMessage: "",
                        valid : data.payload.valid,
                    })

                }
            })
            .catch(err => {
                console.log("err", err);
            });
    }
    handleKeypress=(e)=>{
         if (e.keyCode === 13) {
             this.sendMessage();
         }
    }
    handleChangesendMessage = (e) => {
        this.setState({
            sendMessage: e.target.value,
        })
    }
    diffDate = (date1, date2) => {
        const result = Math.abs(date1 - date2);
        if (result <= 1000) {
            return "maintenant";
        } else {
            if (result < 1000 * 60) {
                return "Il y a " + Math.floor(result / 1000) + " seconds";
            } else {
                if (result < 1000 * 60 * 60) {
                    if (Math.floor(result / (1000 * 60)) === 1) {
                        return "Il y a une minute";
                    } else {
                        return "Il y a " + Math.floor(result / (1000 * 60)) + " minutes";
                    }
                } else {
                    if (result < 1000 * 60 * 60 * 24) {
                        if (Math.floor(result / (1000 * 60 * 60)) === 1) {
                            return "Il y a environ une heure";
                        } else {
                            return "Il y a " + Math.floor(result / (1000 * 60 * 60)) + " heures";
                        }
                    } else {
                        if (result < 1000 * 60 * 60 * 24 * 40) {
                            if (Math.floor(result / (1000 * 60 * 60 * 24)) === 1) {
                                return "Il y a un jour";

                            } else {
                                //console.log(result/100);
                                return "Il y a " + Math.floor(result / (1000 * 60 * 60 * 24)) + " jours";
                            }
                        } else {
                            return "il y a longtemps"
                        }
                    }
                }
            }
        }
    }
    CurrentDiscusion = () => {
        const messages = this.props.messages.messages.filter(m => (m.receiver === this.props.match.params.id_user || m.sender === this.props.match.params.id_user)).reverse();
        if (this.state.currentDiscussionUser === null || this.state.receiver !== this.props.match.params.id_user) {
            if (messages.length > 0) {
                if (messages[0].sender === this.props.match.params.id_user) {
                    this.setState({
                        receiver: this.props.match.params.id_user,
                        currentDiscussionUser: {
                            username: messages[0].sender_username,
                            user_pic: messages[0].sender_pic
                        },
                    })
                } else {
                    this.setState({
                        receiver: this.props.match.params.id_user,
                        currentDiscussionUser: {
                            username: messages[0].receiver_username,
                            user_pic: messages[0].receiver_pic
                        },
                    })
                }
            } else {
                const users = this.state.searchResult.filter(m => (m.id === this.props.match.params.id_user))
                if (users.length > 0) {
                    this.setState({
                        receiver: this.props.match.params.id_user,
                        currentDiscussionUser: {
                            username: users[0].username,
                            user_pic: users[0].profile_pic,
                        },
                    })
                }
            }
        }
        return(
            <>
                <div className={"header"}>
                    <img src={this.state.currentDiscussionUser ? this.state.currentDiscussionUser.user_pic : null}/>
                    <div className="info">
                        <span
                            className="user">{this.state.currentDiscussionUser ? this.state.currentDiscussionUser.username : null}</span>
                        <span className="time"></span>
                    </div>
                    <div className="open">
                        <a onClick={this.handelUP}>UP</a>
                    </div>
                </div>
                <div className="message-wrap">
                    {
                        this.props.messages.messages.filter(m => (m.receiver === this.props.match.params.id_user || m.sender ===
                            this.props.match.params.id_user)).reverse().map(m => (

                                <div className={"message-list"+(m.receiver !== this.props.match.params.id_user ? "" : " me")}>
                                    <div className="msg">
                                        <p>
                                            {m.message}
                                        </p>
                                    </div>
                                    <div className="time">{this.diffDate(new Date(m.message_date), new Date())}</div>
                                </div>
                        ))}
                </div>
                    {/*// <div className={"messageText"}>*/}
                    {/*//     {m.receiver === this.props.match.params.id_user ?*/}
                    {/*//         <div className={"receiver"}> {m.message}</div> :*/}
                    {/*//         <div className={"sender"}> {m.message}</div>*/}
                    {/*//*/}
                    {/*//     }*/}
                    {/*// </div>*/}

                </>);
                }

    handelUP = (e) => {
        console.log("=============");
        const sidebar = document.querySelector("sidebar");
        sidebar.classList.toggle("opened");
        if (sidebar.classList.value == 'opened')
            e.target.innerText = "DOWN";
        else
            e.target.innerText = "UP";
    };

    handleCloseDialog=()=>{
        this.setState({
            valid:true,
        })
    }
    render() {
        const {searchResult, receiver, messages, search, contact,valid} = this.state;
        const user_id = this.props.session.user.id;
        return (
            <div className={"chat"}>
                <div className="container">
                    <sidebar>
                        <span className="logo">CHAT</span>
                            <div className={"searchBox"}>
                        <input
                            type="text"
                            placeholder={"recherche"}
                            // value={this.state.searchText}
                            onKeyDown={this.handleKeypressSearch}
                            onChange={this.handelchangeSearch}

                        />
                        <IconButton
                            size="small"
                            onClick={this.searchClick}
                            component="span"
                            className={"searchButton"}
                        >
                            <SearchIcon color="black"/>
                        </IconButton>
                    </div>
                        <div className="list-wrap">
                            {
                                search===""?
                                this.getContact().map(message => (
                                    <Link to={
                                        this.state.user.is_student ?
                                            "/student/messages/" + (user_id === message.receiver ? message.sender : message.receiver)
                                            :
                                            "/teacher/messages/" + (user_id === message.receiver ? message.sender : message.receiver)
                                    }>
                                        <div className="list">
                                            <img
                                                src={user_id === message.receiver ? message.sender_pic : message.receiver_pic}
                                                alt=""/>
                                            <div className="info">
                                                <span
                                                    className="user"> {user_id === message.receiver ? message.sender_username : message.receiver_username}</span>
                                                <span className="text">{message.message}</span>
                                            </div>
                                            {/*<span className="count">20</span>*/}
                                            <span className="time">{this.diffDate(new Date(message.message_date), new Date())}</span>
                                        </div>
                                    </Link>
                                ))
                                    :
                                    searchResult.map(user=>(
                                         <Link to={
                                        this.state.user.is_student ?
                                            "/student/messages/" + user.id
                                            :
                                            "/teacher/messages/" + user.id
                                    }>
                                        <div className="list">
                                            <img
                                                src={user.profile_pic}
                                                alt=""/>
                                            <div className="info">
                                                <span
                                                    className="user"> {user.username}</span>
                                                {/*<span className="text">{user.description}</span>*/}
                                            </div>
                                            {/*<span className="count">20</span>*/}
                                            {/*<span className="time">{this.diffDate(new Date(message.message_date), new Date())}</span>*/}
                                        </div>
                                    </Link>
                                    ))
                            }
                        </div>
                    </sidebar>
                    <div className="content">
                        { this.CurrentDiscusion()}
                        {/*<div className={"header"}>*/}
                        {/*    <img src={this.state.currentDiscussionUser ? this.state.currentDiscussionUser.user_pic : null}/>*/}
                        {/*        <div className="info">*/}
                        {/*            <span className="user">{this.state.currentDiscussionUser ? this.state.currentDiscussionUser.username : null}</span>*/}
                        {/*            <span className="time"></span>*/}
                        {/*        </div>*/}
                        {/*        <div className="open">*/}
                        {/*            <a href="javascript:;">UP</a>*/}
                        {/*        </div>*/}
                        {/*</div>*/}
                        {/*<div className="message-wrap">*/}
                        {/*    <div className="message-list">*/}
                        {/*        <div className="msg">*/}
                        {/*            <p>*/}
                        {/*                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Odit minus minima quo*/}
                        {/*                corporis.*/}
                        {/*            </p>*/}
                        {/*        </div>*/}
                        {/*        <div className="time">now</div>*/}
                        {/*    </div>*/}
                        {/*    <div className="message-list me">*/}
                        {/*        <div className="msg">*/}
                        {/*            <p>*/}
                        {/*                Lorem ipsum dolor sit amet.dddddddddddddddddddeeeeeeeeeeeeeeee m*/}
                        {/*            </p>*/}
                        {/*        </div>*/}
                        {/*        <div className="time">now</div>*/}
                        {/*    </div>*/}
                        {/*    <div className="message-list">*/}
                        {/*        <div className="msg">*/}
                        {/*            <p>Odit minus minima quo corporis.*/}
                        {/*            </p>*/}
                        {/*        </div>*/}
                        {/*        <div className="time">now</div>*/}
                        {/*    </div>*/}
                        {/*    <div className="message-list me">*/}
                        {/*        <div className="msg">*/}
                        {/*            <p>*/}
                        {/*                Lorem.*/}
                        {/*            </p>*/}
                        {/*        </div>*/}
                        {/*        <div className="time">now</div>*/}
                        {/*    </div>*/}
                        {/*    <div className="message-list">*/}
                        {/*        <div className="msg">*/}
                        {/*            <p>*/}
                        {/*                Lorem, ipsum dolor.sssssssssssssssssssssssssssss*/}
                        {/*            </p>*/}
                        {/*        </div>*/}
                        {/*        <div className="time">now</div>*/}
                        {/*    </div>*/}



                        {/*</div>*/}
                        <div className="message-footer">
                            <input type="text"
                                   data-placeholder="Send a message to {0}"
                                   value={this.state.sendMessage}
                                   onChange={this.handleChangesendMessage}
                                   onKeyDown={this.handleKeypress}
                                   disabled={receiver === null ? true : false}/>
                            <div className={"sendButton"}>
                            <IconButton
                                size="small"
                                onClick={this.sendMessage}
                                component="span"
                                className={"sendMessageButton"}
                                disabled={receiver === null ? true : false}
                            >
                                <SendIcon/>
                            </IconButton>
                        </div>
                        </div>
                    </div>
                </div>
                <Dialog open={!valid}
                       onClose={this.handleCloseDialog}
                        aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Violation des règles de la plateforme</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Il est interdit de insérer vos coordonnées (adresse e-mail, adresse, numéro de téléphone, ...)
                                dans un message
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog}  color="primary">
                                Annuler
                            </Button>
                        </DialogActions>
                    </Dialog>
            </div>
        );
    }
}

const mapStateToProps = state => ({
        session: state.session,
        messages: state.messages,
    }
);
export default connect(mapStateToProps)(withRouter(MessagingComponent));
