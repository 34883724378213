import React from 'react';

const FavoriteRessource = () => {
    return (
        <div className="favorite-ressource-component">
            <div className="tittle">
                <span>
                    Vos meilleures ressources sont disponibles ici
                </span>
            </div>
        </div>
    );
}

export default FavoriteRessource;
