import React, {Component} from 'react';
import Header from "./Header";
import Footer from "./Footer/Footer";
import ArticleComponent from "./ArticleComponent";
import {ContactUs} from "./Layouts/Shared/ContactUs/ContactUs";

class ArticlePublic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPage: "student",
        };
    };

    changeUserHome = (user) => {
        this.setState({
            selectedPage: user
        });
    }

    render() {
        return (
            <>
                <Header
                    color="transparent"
                    changeColorOnScroll
                    changeUserHome={(user) => this.changeUserHome(user)}
                    page="accueil"
                />
                <div style={{"marginTop": "50px", "marginInline": "20px"}}>
                    <ArticleComponent/>
                </div>
                <ContactUs position={"/article"}/>
                <Footer/>
            </>
        );
    }
}

export default ArticlePublic