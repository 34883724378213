// import React, { Component } from 'react';
import React, {Component, Fragment} from "react";
// import * as React from 'react';

import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EditingState, ViewState} from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    DayView,
    WeekView,
    MonthView,
    Appointments,
    AppointmentForm,
    AppointmentTooltip,
    EditRecurrenceMenu,
    ConfirmationDialog
} from '@devexpress/dx-react-scheduler-material-ui';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const currentDate = new Date();

const TimeTableCell = ({ onDoubleClick, ...restProps }) => {
  return <DayView.TimeTableCell onClick={onDoubleClick} {...restProps} />;
};

const ExternalViewSwitcher = (
    {currentViewName, onChange,}
    ) => (
        <RadioGroup
            aria-label="Views"
            style={{ flexDirection: 'row' }}
            name="views"
            value={currentViewName}
            onChange={onChange}
        >
            <FormControlLabel value="Day" control={<Radio />} label="Day" />
            <FormControlLabel value="Week" control={<Radio />} label="Week" />
            <FormControlLabel value="Month" control={<Radio />} label="Month" />
        </RadioGroup>
);

class StudentCalendarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.session.user,
            intervals: [],
            currentViewName: 'Month',
            addedAppointment: {},
            appointmentChanges: {},
            editingAppointment: undefined,
        };

        this.currentViewNameChange = (e) => {
            this.setState({ currentViewName: e.target.value });
        };
    }

    componentDidMount() {
    }

    changeAddedAppointment(addedAppointment) {
        this.setState({ addedAppointment });
      }

      changeAppointmentChanges(appointmentChanges) {
        this.setState({ appointmentChanges });
      }

      changeEditingAppointment(editingAppointment) {
        this.setState({ editingAppointment });
      }

      commitChanges({ added, changed, deleted }) {
        console.log(added)
        // this.setState(state => {
        //   let { intervals } = state;
        //   if (added) {
        //     const startingAddedId = intervals.length > 0 ? intervals[intervals.length - 1].id + 1 : 0;
        //     intervals = [...intervals, { id: startingAddedId, ...added }];
        //   }
        //   if (changed) {
        //     intervals = intervals.map(appointment => (
        //       changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment));
        //   }
        //   if (deleted !== undefined) {
        //     intervals = intervals.filter(appointment => appointment.id !== deleted);
        //   }
        //   return { intervals };
        // });
    }

    render() {
        const { data, currentViewName } = this.state;
        return (
            <Fragment>
                <ExternalViewSwitcher
                    currentViewName={currentViewName}
                    onChange={this.currentViewNameChange}
                />
                <Paper>
                    <Scheduler
                        data={data}
                        height={660}
                    >
                        <ViewState
                            defaultCurrentDate={currentDate}
                            currentViewName={currentViewName}
                        />
                        <EditingState
                            onCommitChanges={this.commitChanges}
                        />
                        <DayView
                            cellDuration={60}
                            startDayHour={6}
                            endDayHour={24}
                            timeTableCellComponent={TimeTableCell}
                        />
                        <WeekView
                            cellDuration={60}
                            startDayHour={6}
                            endDayHour={24}
                        />
                        <MonthView />

                        <EditRecurrenceMenu />
                        <ConfirmationDialog />
                        <Appointments />
                        <AppointmentTooltip
                            showOpenButton
                            showDeleteButton
                        />
                        <AppointmentForm />
                    </Scheduler>
                </Paper>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({ session: state.session, teacher_calendar: state.teacher_calendar });
export default connect(mapStateToProps)(withRouter(StudentCalendarComponent));
