import React from 'react';
import { Button } from "@material-ui/core";

import Forfaits from "./LiveCourses/Forfaits";
import LesPacks from "./LiveCourses/LesPacks";
import Intensifs from "./LiveCourses/Intensifs";


const LiveCourses = (props) => {

    const routeChange = (route) => {
        let path = `profs_list`;
        props.history.push(path)
    }

    // const calcRouteChange = (route) => {
    //     let path = `calculateur_de_prix`;
    //     props.history.push(path)
    // }

    return (
        <div className="nos-formules-component">
            {/*<Grid container className="calc-prix-content">*/}
            {/*    <Grid item xs={8}>*/}

            {/*    </Grid>*/}
            {/*    <Grid item xs={4}>*/}
            {/*        <Button*/}
            {/*            type="submit"*/}
            {/*            className="calculateur-de-prix"*/}
            {/*            variant="contained"*/}
            {/*            onClick={calcRouteChange}*/}
            {/*        >*/}
            {/*            Votre calculateur de prix*/}
            {/*        </Button>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}

            <Forfaits></Forfaits>
            <LesPacks></LesPacks>
            <Intensifs></Intensifs>


            <Button
                type="submit"
                className="trouver-son-prof"
                variant="contained"
                onClick={routeChange}
            >
                Trouver votre PROF idéal
            </Button>


        </div>
    );
}

export default LiveCourses;

