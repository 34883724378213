import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import emailjs from 'emailjs-com';
import {init} from 'emailjs-com';
import Notification from "../AlertComponent";
import Spinner from 'react-bootstrap/Spinner'
import Button from "@material-ui/core/Button";


init("user_t0d2NpLxUuCT5WfFJXHIw");


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            marginLeft: "0px",
            //width: "50ch",
            backgroundColor: "white",
            borderRadius: "15",
            height: "35",
        }
    },
    button: {
        margin: theme.spacing(1),
        marginRight: "0px",
        backgroundColor: "#ce0d48",
        left: "71%",
        top: "-6px",
    }
}));

export default function ContactForm() {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [notify, setNotify] = useState({message: '', type: ''});
    const [notyIsOpen, setNotyIsOpen] = useState(false);
    const [display, setDisplay] = useState(false);


    const Notify = (message, type) => {
        setNotyIsOpen(!notyIsOpen)
        setNotify({message: message, type: type})
    }
    const isEmail = () => {
        if (!name || !message || !email) {
            Notify("Remplir les champs requis", 'error')
            return false
        } else {
            let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (!email.match(regex)) {
                Notify("L'email n'est pas valide", 'error')
                return false
            }
        }
        return true
    }

    const handleSubmit = (e) => {
        console.log("launch")
        if (isEmail()) {
            setDisplay(true)
            console.log("enter")
            e.preventDefault();
            sendFeedback("template_8riuv08", {
                name,
                email,
                message,
            });
        }
    };

    const sendFeedback = (templateId, variables) => {
        console.log(name)
        emailjs.send("gmail", templateId, variables)
            .then((res) => {
                setDisplay(false)
                Notify("Messsage envoyé. Nous vous contacterons dés que possible", 'success')
                setName("");
                setEmail("");
                setMessage("");
            })
            .catch((err) => {
                    setDisplay(false)
                    Notify("Un probléme est survenu, réessayez plus tard !!!", 'error')
                    alert();
                });
    };


    return (
        <>
            <form className={classes.root} autoComplete="on">
                <div className="form-container">
                    <TextField
                        className="form-text-field"
                        required
                        id="outlined-search"
                        label="Nom complet"
                        type="nom"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        variant="outlined"
                        fullWidth="true"
                        size="small"
                    />
                    <TextField
                        className="form-text-field"
                        required
                        id="outlined-search"
                        label="Email"
                        type="email"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth="true"
                        size="small"
                    />
                    <TextField
                        className="form-text-field"
                        required
                        id="outlined-search"
                        value={message}
                        label="Message"
                        type="message"
                        onChange={(e) => setMessage(e.target.value)}
                        variant="outlined"
                        fullWidth="true"
                        multiline
                        rows={5}
                    />
                </div>
                <div className="contact-button">
                     {display && <Spinner animation="border" variant="danger"/>}
                    {/* This Button uses a Font Icon, see the installation instructions in the Icon component docs. */}
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        color="primary"
                        className={classes.button}
                        endIcon={<SendIcon/>}
                    >
                        Envoyer
                    </Button>
                </div>
            </form>
            <Notification
                notify={notify}
                setNotify={Notify}
                isOpen={notyIsOpen}
            />
        </>
    );
}
